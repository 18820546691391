import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {withStyles} from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControl from "@mui/material/FormControl/FormControl";
import RenderMetadataField from "../../common/RenderMetadataField";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select/Select";
import {getFieldConfig} from "../../common/helper";

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    table: {
        //minWidth: 1020,

    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableCell: {
        padding: theme.spacing(1)
    },
    tableAutoPopulateRow: {
        backgroundColor: theme.palette.primary.main + '30' //30% opacity
    },
    autoPopulateButton: {
        paddingLeft: theme.spacing(1), paddingRight: 0, paddingTop: 0, paddingBottom: 0
    }
});

const localTheme = theme => createTheme(({

    // to merge with parent theme
    ...theme,

    components: {

        //to fix spacing issue on input field in metadata table
        MuiInput: {
            styleOverrides: {
                formControl: {
                    "label + &": {
                        marginTop: "0px"
                    }
                }
            }
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: theme.palette.secondary.main
                    }
                }
            }
        },
    }
}));

class MetadataTableForUploads extends React.Component {

    constructor(props) {
        super(props);

        let fields = props.fields;
        fields.forEach(field => {
            const fieldConfig = getFieldConfig(props.metadataConfig, field.templateKey, field.metadataKey);
            field.label = fieldConfig ? fieldConfig.label : "";
        })

        let INITIAL_STATE = {
            order: 'asc',
            orderBy: 'name',
            selected: [],
            data: props.files,
            delimiter: "",
            positionOptions: [],
            fields: fields
        };
        this.state = INITIAL_STATE
    }

    handleOnChangeDelimiter = (event) =>{
        this.setState({[event.target.name]: event.target.value})
        //clear any previous position selections
        this.props.fields.forEach(field => {
            const positionFieldName = field.templateKey + "~" + field.metadataKey + "Position";
            this.setState({[positionFieldName]: []})
        })
    }

    handleOnChangePosition = (event) =>{
        const val = event.target.value;
        this.setState({[event.target.name]: typeof val === 'string' ? val.split(',') : val})
    }

    autoPopulate = (event, selectedField,  allFilesSameFormat, positionOptions ) =>{

        //either autopopulate all fields or just selected column depending on paramaters
        let fields = [];
        if (selectedField) {
            fields.push(selectedField)
        } else {
            fields = this.props.fields;
        }

        fields.forEach(field => {
            if (field.extractMetadataFromFileNameExclude !== true ){
                const fieldPrefix = field.templateKey + "~" + field.metadataKey
                const positionFieldName = fieldPrefix + "Position";
                const positionFieldValues = this.state[positionFieldName] ? this.state[positionFieldName] : []
                const delimiter = this.state.delimiter
                //now loop through files to get the corresponding metadata field
                this.props.files.forEach(file => {
                    const metadataFieldName = fieldPrefix + "_" + file.rowId
                    const filename = file.name.replace(/\.[^/.]+$/, "") //filename without extension.
                    const filenameElements = filename.split(delimiter)
                    let val = "";
                    positionFieldValues.forEach(pos => {
                        let i = pos - 1;
                        if (!allFilesSameFormat && pos === positionOptions.length) {
                            //if last position selected and not all files the same length then take the last value
                            i = filenameElements.length - 1
                        }
                        if (filenameElements[i]) {
                            val += val === "" ? filenameElements[i] : (delimiter + filenameElements[i])
                        }
                    })
                    this.props.handleOnChangeDocumentMetadata(metadataFieldName, val)
                })
            }
        })
    }

    clearAll = (event, selectedField ) =>{

        //either clear all metadata fields or just selected column depending on paramaters
        let fields = [];
        if (selectedField) {
            fields.push(selectedField)
        } else {
            fields = this.props.fields;
        }

        fields.forEach(field => {
            if (field.extractMetadataFromFileNameExclude !== true ) {
                const fieldPrefix = field.templateKey + "~" + field.metadataKey
                //now loop through files to get the corresponding metadata field
                this.props.files.forEach(file => {
                    const metadataFieldName = fieldPrefix + "_" + file.rowId
                    let val = "";
                    this.props.handleOnChangeDocumentMetadata(metadataFieldName, val)
                })
            }
        })
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const { classes} = this.props;
        // NB - need to ensure search results updated each time
        const data = this.props.files;
        const { order, orderBy, delimiter, fields} = this.state;

        let maxPositions = 0;
        let allFilesSameFormat = true
        let counter=0;
        if (delimiter) {
            data.forEach(file => {
                counter ++
                //get filename with most separators to determine list of position options
                const filename = file.name.replace(/\.[^/.]+$/, "") //filename without extension.
                if (filename.indexOf(delimiter) > -1) {
                    const elements = filename.split(delimiter);
                    if (elements.length > maxPositions) {
                        maxPositions = elements.length
                        if (counter > 1) {
                            allFilesSameFormat = false
                        }
                    }
                }
            })
        }
        const positionOptions = Array.from(Array(maxPositions), (_,x) => x+1);

        let positionSelected = false;

        fields.find(field => {
            const positionField = field.templateKey + "~" + field.metadataKey + "Position";

            if (this.state[positionField] && Array.isArray(this.state[positionField]) && this.state[positionField].length > 0) {
                positionSelected = true
                return true   //.find stops iterating when truthy val returned
            } else {
                return false // // keep looping
            }
        })

        return (
            <ThemeProvider theme={localTheme}>
                <React.Fragment>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <TableBody>
                                {/* Header row for selecting delimiter/extraction positions ============================================================ */}
                                {
                                    this.props.extractMetadataFromFileName &&
                                    <React.Fragment>
                                        <TableRow>
                                            <TableCell colspan={fields.length +1} style={{paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px'}}>
                                                <Typography variant={"subtitle2"}>Extract metadata from filename</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={classes.tableAutoPopulateRow}>
                                            <TableCell component="th" scope="row" className={classes.tableCell}>
                                                <InputLabel shrink htmlFor={"delimiter"} required={false}>{"Delimiter"}</InputLabel>
                                                <Input id={"delimiter"} name={"delimiter"} value={delimiter} disabled={false}
                                                       type="Input" margin="none" variant="outlined" autoComplete=""
                                                       style={{width: '40%'}}
                                                       onChange={this.handleOnChangeDelimiter}
                                                       InputLabelProps={{shrink: true}} //Note: needed for date fields, disabling for now as generates warning in console
                                                />
                                                {
                                                    delimiter && positionSelected &&
                                                    <React.Fragment>
                                                        <Tooltip title={'Autopopulate all'}>
                                                            <IconButton
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={event => this.autoPopulate(event, null, allFilesSameFormat, positionOptions)}
                                                                className={classes.autoPopulateButton}
                                                                size="medium">
                                                                <i className="material-icons">auto_fix_high</i>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title={'Clear all'}>
                                                            <IconButton
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={event => this.clearAll(event, null)}
                                                                className={classes.autoPopulateButton}
                                                                size="medium">
                                                                <i className="material-icons">clear</i>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </React.Fragment>
                                                }
                                            </TableCell>
                                            {/*Select position field for each document metadata field*/}
                                            {
                                                delimiter && positionOptions.length > 0 && fields.map(field => {
                                                    const fieldName = field.templateKey + "~" + field.metadataKey + "Position";
                                                    const fieldValue = this.state[fieldName] ? this.state[fieldName] : []
                                                    const hasFieldValue = fieldValue && Array.isArray(fieldValue) && fieldValue.length > 0
                                                    return (
                                                        <TableCell component="th" scope="row" className={classes.tableCell}>

                                                            {
                                                                field.extractMetadataFromFileNameExclude !== true &&

                                                                <React.Fragment>
                                                                    {/*    Take first filename, split filename to get humber of options*/}
                                                                    <InputLabel shrink htmlFor={fieldName} required={false}>{field.label + ' Position(s)'}</InputLabel>
                                                                    <Select id={fieldName} name={fieldName} style={{width: '50%'}}
                                                                            value={fieldValue} renderValue={(selected) => selected.join(', ')}
                                                                            select multiple disabled={false} type="Select" margin="none" autoComplete="" onChange={this.handleOnChangePosition}
                                                                            InputLabelProps={{shrink: true}} //Note: needed for date fields, disabling for now as generates warning in console
                                                                    >
                                                                        {positionOptions.map(opt => {
                                                                            const label = (!allFilesSameFormat && opt === positionOptions.length) ? (opt + '/last') : opt;
                                                                            return (
                                                                                <MenuItem key={opt} value={opt} style={{padding: '0'}}>
                                                                                    <Checkbox  color="secondary" checked={hasFieldValue && fieldValue.indexOf(opt) > -1} /><ListItemText primary={label} />
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                    </Select>
                                                                </React.Fragment>
                                                            }
                                                            {
                                                                hasFieldValue &&
                                                                <React.Fragment>
                                                                    <Tooltip title={'Autopopulate'}>
                                                                        <IconButton
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            className={classes.autoPopulateButton}
                                                                            onClick={event => this.autoPopulate(event, field, allFilesSameFormat, positionOptions)}
                                                                            size="medium">
                                                                            <i className="material-icons">auto_fix_normal</i>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title={'Clear all'}>
                                                                        <IconButton
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            className={classes.autoPopulateButton}
                                                                            onClick={event => this.clearAll(event, field)}
                                                                            size="medium">
                                                                            <i className="material-icons">clear</i>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </React.Fragment>
                                                            }
                                                        </TableCell>
                                                    );})
                                            }
                                        </TableRow>
                                    </React.Fragment>
                                }

                                <TableRow>
                                    <TableCell colspan={fields.length +1} style={{paddingLeft: '0px', paddingTop: '0px'}}>
                                        <Typography variant={"subtitle2"}><br/>File specific metadata</Typography>
                                    </TableCell>
                                </TableRow>


                                {/* row for each File =================================================================================== */}
                                {
                                    stableSort(data, getSorting(order, orderBy)).map(row => {
                                        const isSelected = this.isSelected(row.rowId);
                                        //generate object with all values in the row
                                        let formValues = {};
                                        Object.entries(this.props.genericMetadata).forEach(entry => {
                                            formValues[entry[1].templateKey + "~" + entry[1].metadataKey] = entry[1].value;
                                        });
                                        let fixedMetadata = this.props.fixedMetadata;
                                        if (fixedMetadata) {
                                            fixedMetadata.forEach((f) => {
                                                formValues[f.templateKey + "~" + f.metadataKey] = f.value;
                                            })
                                        }

                                        //Add metadata inherited from folder
                                        let metadataFromParentFolder = this.props.metadataFromParentFolder;
                                        let folderDetails = this.props.folderDetails
                                        if (metadataFromParentFolder && folderDetails) {
                                            for (let i=0; i < metadataFromParentFolder.length; i++) {
                                                for (let r = 0; r < metadataFromParentFolder.length; r++) {
                                                    //get metadata for each metadataKey listed
                                                    metadataFromParentFolder[i].metadataKeys.forEach(metadataKey => {
                                                        if (folderDetails[metadataKey]) {
                                                            formValues[metadataFromParentFolder[i].templateKey + "~" + metadataKey] = folderDetails[metadataFromParentFolder[i].templateKey + "~" + metadataKey]
                                                        }
                                                    })
                                                }
                                            }
                                        }

                                        fields.map(field => (
                                            formValues[field.templateKey + "~" + field.metadataKey] = (row.metadata[field.templateKey + "~" + field.metadataKey]) && row.metadata[field.templateKey + "~" + field.metadataKey])
                                        )
                                        // Return Row for each file
                                        return (
                                            <TableRow hover
                                                      //onClick={event => this.handleClick(event, row.rowId)}
                                                role="checkbox" aria-checked={isSelected} tabIndex={-1} key={row.rowId} selected={isSelected}>

                                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                                    {row.name}
                                                </TableCell>
                                                {/*Add a column for each document metadata field*/}
                                                {fields.map(
                                                    field => {
                                                        const fieldValue =
                                                            row.metadata[field.templateKey + "~" + field.metadataKey]?
                                                                row.metadata[field.templateKey + "~" + field.metadataKey] :
                                                                    "";
                                                        return(
                                                            <TableCell key={field.metadataKey} align={field.numeric ? 'right' : 'left'} className={classes.tableCell}>
                                                                <FormControl  variant="standard" fullWidth key={"fc" + field.templateKey + "~" + field.metadataKey + "_" + row.rowId}>
                                                                    <RenderMetadataField
                                                                        fieldValue={fieldValue}
                                                                        handleOnChange={this.props.handleOnChangeDocumentMetadata}
                                                                        rowId={row.rowId}
                                                                        metadataConfig={this.props.metadataConfig}
                                                                        optionsConfig={this.props.optionsConfig}
                                                                        metadataKey={field.metadataKey}
                                                                        templateKey={field.templateKey}
                                                                        formValues={formValues}
                                                                        usage={"upload"}
                                                                        required={field.required}
                                                                        //helperText={(field.required && (!fieldValue || fieldValue === "")) ? "Value required" : ""}
                                                                    />
                                                                </FormControl>
                                                            </TableCell>
                                                    )},
                                                    this,
                                                )}
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </div>
                </React.Fragment>
            </ThemeProvider>
        );
    }
}

MetadataTableForUploads.propTypes = {
    classes: PropTypes.object.isRequired,
    files: PropTypes.array.isRequired,
    fixedMetadata: PropTypes.array.isRequired,
    genericMetadata: PropTypes.array.isRequired,
    fields: PropTypes.array.isRequired,
    handleOnChangeDocumentMetadata: PropTypes.func.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    metadataFromParentFolder: PropTypes.array.isRequired,
    folderDetails: PropTypes.object,
    extractMetadataFromFileName: PropTypes.bool

}
export default withStyles(styles)(MetadataTableForUploads);
