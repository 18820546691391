import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import {Grid} from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader/ListSubheader";
import RelatedDocumentsTable from "./RelatedDocumentsTable";
import RelatedDocumentsGet from "./RelatedDocumentsGet";
import Typography from "@mui/material/Typography/Typography";

const styles = theme => ({
    root: {
        height: '75vh',
        // overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },

    left: {
        float: 'left',
        width: '50%'
    },

    right: {
        float: 'right',
        width: '50%'
    },

    smallAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    }

});

const INITIAL_STATE = {
    open: false,
    searchResults: [],
    mountRelatedDocumentsTable: false,
    mountRelatedDocumentsGet: true,
    searchLimit: parseInt(window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT),
    searchOffset: 0,
    searchTotalCount: 0,
    newSearch: true,
    isFetching: true
};

class RelatedDocumentsDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;

        this.remountRelatedDocumentsGet = this.remountRelatedDocumentsGet.bind(this);
        this.remountRelatedDocumentsTable = this.remountRelatedDocumentsTable.bind(this);
        this.getNextResults = this.getNextResults.bind(this);
        this.updateSearchResults = this.updateSearchResults.bind(this);
    }

    UNSAFE_componentWillMount() {

    }

    componentDidMount(){
        this.setState({ open: true });
    }

    unmountRelatedDocumentsTable = () => {
        this.setState({
            mountRelatedDocumentsTable : false
        });
    };

    remountRelatedDocumentsTable = () => {
        this.setState({
            mountRelatedDocumentsTable : true});
    };

    updateSearchResults(searchResults, totalCount, newSearch, nextMarker) {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('updateSearchResults', " searchResults= ", searchResults, " totalCount= ", totalCount, " newSearch=" , newSearch, "nextMarker=", nextMarker);

        //case searching uses page, content searching uses offset
        let newSearchResults = this.state.searchOffset === 0 ? searchResults : this.state.searchResults.concat(searchResults);

        this.setState({
            searchResults: newSearchResults,
            nextMarker: nextMarker,
            isFetching: false,
            mountRelatedDocumentsTable: newSearch ,   //trigger remount of results table
            searchTotalCount: totalCount ? totalCount : 0,
            searchLimit: parseInt(window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT),
            newSearch: false,
        });
    }

    handleCloseDialog = () => {
        this.setState({ open: false });
        this.props.handleCloseDialog();
    };

    unmountRelatedDocumentsGet = () => {
        this.setState({mountRelatedDocumentsGet : false});
    };

    remountRelatedDocumentsGet = () => {
        this.setState({mountRelatedDocumentsGet : true});
    };

    getNextResults = () => {

        this.setState({
            mountRelatedDocumentsGet: false,
            newSearch: false,
            isFetching: true,
            searchOffset: this.state.searchOffset + this.state.searchLimit
        });
    };


    render() {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('RelatedDocumentsDialog props = ' , this.props);

        return (
            <React.Fragment>
                {
                    this.state.mountRelatedDocumentsGet &&

                    <RelatedDocumentsGet
                        parentClasses={this.props.classes}
                        userDetails={this.props.userDetails}
                        metadataConfig={this.props.metadataConfig}
                        actionsConfig={this.props.actionsConfig}
                        folderDetails={this.props.folderDetails}
                        unmountComponent={this.unmountRelatedDocumentsGet}
                        remountComponent={this.remountRelatedDocumentsGet}
                        searchLimit={this.state.searchLimit}
                        searchOffset={this.state.searchOffset}
                        newSearch={this.state.newSearch}
                        updateSearchResults={this.updateSearchResults}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        nextMarker={this.state.nextMarker}
                        searchConfig={this.props.searchConfig}
                        actionConfig={this.props.actionsConfig.relatedDocuments}
                        documentDetails={this.props.documentDetails}
                    />
                }

                <Dialog
                    open={this.state.open}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth="xl"
                >
                    <DialogContent>
                        <Grid container spacing={1} style={ {height: "90vh"}}>

                            <Grid item xs={12} style={{height: "100%", overflowY: "auto"}}>

                                {
                                    (this.state.isFetching && this.state.newSearch) &&

                                    <React.Fragment>
                                        {/*<LinearProgress style={{marginTop: '0px'}} color="primary"/>*/}
                                        <div>
                                            <Typography variant="subtitle1">
                                                <ListSubheader disableSticky>Retrieving related documents...</ListSubheader>
                                            </Typography>
                                        </div>
                                    </React.Fragment>
                                }
                                {
                                    (this.state.mountRelatedDocumentsTable )&&
                                            <RelatedDocumentsTable
                                                searchConfig={this.props.searchConfig}
                                                searchResults={this.state.searchResults}
                                                userDetails={this.props.userDetails}
                                                nextMarker={this.state.nextMarker}
                                                searchTotalCount={this.state.searchTotalCount}
                                                getNextResults={this.getNextResults}
                                                isFetching={this.state.isFetching}
                                                searchLimit={this.state.searchLimit}
                                                unmountComponent={this.unmountRelatedDocumentsTable}
                                                remountComponent={this.remountRelatedDocumentsTable}
                                                actionsConfig={this.props.actionsConfig}
                                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                            />
                                }
                            </Grid>
                        </Grid>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} variant="contained" color="secondary">Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

RelatedDocumentsDialog.propTypes = {
    userDetails: PropTypes.object.isRequired,
    documentDetails: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    searchConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired
};

export default (withStyles(styles, { withTheme: true })(RelatedDocumentsDialog));