import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import MLCommentsDialog from "./MLCommentsDialog";
import {Badge} from "@mui/material";

const styles = theme => ({})

function MLCommentsButton(props) {

    const [showDialog, setShowDialog] = useState(false);

    const handleClickIcon = () => {
        setShowDialog(true)
    };

    const handleCloseDialog = (commentAdded) => {
        setShowDialog(false)
        if (commentAdded) {
            props.unmountSnippet()
        }
    };

    const commentCount = props.comments && Array.isArray(props.comments) ? props.comments.length : 0;

    return (
        <React.Fragment>

            <Tooltip title={"View Comments"}>
                <IconButton
                    className={props.classes.button}
                    aria-label={"Menu"}
                    aria-haspopup="true"
                    onClick={handleClickIcon}
                    color={"secondary"}
                    size="medium">
                    {
                        commentCount > 0 ?
                            <Badge badgeContent={commentCount} color="primary">
                                <i className='material-icons'>comment</i>
                            </Badge> :

                            <i className='material-icons'>comment</i>
                    }

                </IconButton>
            </Tooltip>

            {
                showDialog &&
                <MLCommentsDialog
                    userDetails={props.userDetails}
                    handleCloseDialog = {handleCloseDialog}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    comments={props.comments}
                    resultId={props.resultId}
                    snippetId={props.snippetId}
                    enableAddComment={props.enableAddComment}
                />
            }
        </React.Fragment>
    );

}

MLCommentsButton.propTypes = {
    classes: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    unmountSnippet: PropTypes.func, //to refresh counter after adding comment
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    snippetId: PropTypes.string.isRequired,
    comments: PropTypes.array.isRequired,
    actionConfig: PropTypes.object.isRequired,
    documentDetails: PropTypes.object.isRequired,
    enableAddComment: PropTypes.bool.isRequired
};

export default withStyles(styles)(MLCommentsButton);