import React from 'react';
import PropTypes from 'prop-types';
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {withStyles} from '@mui/styles';
import { useTranslation } from 'react-i18next'

const styles = theme => ({

    drawerMenuListItem: {
        color: theme.menuListItem.colour
    },

    listIcon: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },

});

function LogoutMenuItem(props) {

    const classes= props.classes;

    const { t } = useTranslation();
    const translate = (val) => window.REACT_APP_ENABLE_TRANSLATION === "true" ? t(val) : val

    return (
        <ListItem
            button={true}
            key={'logout'}
            id={'logout'}
            onClick={props.logout}
        >
            <ListItemIcon className={classes.listIcon}><i className='material-icons'>lock_open</i></ListItemIcon>
            <ListItemText
                className={classes.drawerMenuListItem}
                primary={translate('Logout')}
            />
        </ListItem>
    );

}


LogoutMenuItem.propTypes = {
    classes: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(LogoutMenuItem);
