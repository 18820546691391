import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Grid, IconButton, LinearProgress, Tooltip} from "@mui/material";
import PropTypes from "prop-types";
import {asyncForEach, getErrorMessageFromResponse} from "../../../common/helper";
import { enqueueSnackbar } from 'notistack'
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import RenderMetadataField from "../../../common/RenderMetadataField";

function AddFolderForFile(props) {
    //console.log ('AddFolderConfrim props = ', props);
    const [open, setOpen] = React.useState(false);
    const debug = window.location.pathname.toLowerCase().includes("debug");
    const [inProgress, setInProgress] = React.useState(false)

    let metadata = props.metadata;
    // props.addFolderConfig.fields.forEach( field => {
    //     metadata[field.templateKey + "~" + field.metadataKey] = "val here"
    //     }
    // )

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.getFolder(props.spvEntity, props.rowId)
        setOpen(false);

    };

    //createFolder = async () => {
    const createFolder = async() => {

        setInProgress(true);

        debug && console.log('createFolder start,  props=', props);

        //append generic metadata

        //extract templateKey & metadataKey and populate array
       // let metadataArray = JSON.parse(JSON.stringify(props.genericMetadata));

        let metadataArray = [];
        let folderName = ""

        //Add metadata as per config...TODO don't add fields that are not required
        console.log ('createFolder - add metadata for each field in config')

        props.addFolderConfig.fields.forEach( field => {

            if (props.addFolderConfig.folderNameField) {
                if (field.templateKey === props.addFolderConfig.folderNameField.templateKey && field.metadataKey === props.addFolderConfig.folderNameField.metadataKey) {
                    folderName = metadata[field.templateKey + "~" + field.metadataKey]
                }
            }


            metadataArray.push({
                templateKey: field.templateKey,
                metadataKey: field.metadataKey,
                value: metadata[field.templateKey + "~" + field.metadataKey]
            })

            }
        )

        //add any fixed metadata from config
        console.log ('createFolder - add fixed metadata');
        const fixedMetadata = props.addFolderConfig.fixedMetadata;
        if (fixedMetadata) {
            for (let i = 0; i < fixedMetadata.length; ++i) {
                console.log ('metadataArray.push, fixedMetadata[i]= ', fixedMetadata[i])
                metadataArray.push({
                    templateKey: fixedMetadata[i].templateKey,
                    metadataKey: fixedMetadata[i].metadataKey,
                    value: fixedMetadata[i].value,
                })
            }
        }

        debug && console.log('fixedMetadata = ', fixedMetadata);

        debug && console.log('createFolder - get applyGroupToFolderConfig');

        //If a folder group being created, add the folder group prefix to the metadata so that it can be used when adding users to case
        const applyGroupToFolderConfig = props.addFolderConfig.applyGroupToFolder;
        applyGroupToFolderConfig && applyGroupToFolderConfig.length > 0 && applyGroupToFolderConfig.forEach(groupConfig =>{
            groupConfig.groupName && groupConfig.groupName.forEach( groupNameConfig => {
                if (groupNameConfig.setFolderGroupPrefix && groupNameConfig.setFolderGroupPrefix.templateKey && groupNameConfig.setFolderGroupPrefix.metadataKey && groupNameConfig.setFolderGroupPrefix.value)  {
                    metadata[groupNameConfig.setFolderGroupName.templateKey +'~'+ groupNameConfig.setFolderGroupName.metadataKey] = groupNameConfig.setFolderGroupPrefix.value
                }
            })
        })


        let queryStr = "?userId=" + props.userDetails.boxId + '&autoGenTasks=false&username=' + props.userDetails.userName;

        let url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + "/" + props.addFolderConfig.folderId + queryStr;

        let prefix = ""
        if (props.addFolderConfig.prefix) {
            prefix = props.addFolderConfig.prefix;
        }

        let body = {
            name: folderName !== "" ? (prefix + folderName.trim()) : "new folder",
            metadata: metadataArray
        };

        debug && console.log('createFolder body: ', body);

        let request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };

        let subfolders = [];
        if (props.addFolderConfig.autoGenerateSubFolders) {
            subfolders = props.addFolderConfig.autoGenerateSubFolders;
        }

        debug && console.log("createFolder url:", url, 'request: ', request);

        await props.triggerRefreshAuthToken();
        fetch(url, request)
            .then(response => {
                if (response.ok) {
                    debug && console.log('createFolder RESPONSE ok: ', response);
                    if (subfolders.length > 0) {
                        enqueueSnackbar(props.addFolderConfig.label + " created successfully.  Generating subfolders...", {variant: 'success'});
                    } else {
                        enqueueSnackbar(props.addFolderConfig.label + " created successfully.", {variant: 'success'});
                    }
                    return (response.json()).then(result => {
                        debug && console.log('createFolder response.json = ', result);
                        if (result && result.boxId) {

                            // Apply security to folder if required
                            if (applyGroupToFolderConfig && Array.isArray(applyGroupToFolderConfig) && applyGroupToFolderConfig.length > 0 ) {
                                applyGroupToFolder(applyGroupToFolderConfig, result.boxId, metadata)
                            }

                            //Now generate subfolders asynchronously
                            if (subfolders.length > 0) {
                                createSubfoldersAsync(result, subfolders, metadataArray);
                            } else {
                                setInProgress(false)
                                handleClose()
                            }

                            //TODO when done then re-run folder search on FileTable.js...or re-run folder search for just this file
                        }
                    })
                } else {
                    debug && console.log('createFolder RESPONSE not ok: ', response);

                    //response not ok
                    Promise.resolve(getErrorMessageFromResponse(response, 'creating folder'))
                        .then(message => {
                            //Check for 409 Conflict error and give custom message if available
                            if (response.status === 409 && props.addFolderConfig.conflictMessage) {
                                enqueueSnackbar(message + ".  " + props.addFolderConfig.conflictMessage, {variant: 'error'});
                            } else {
                                enqueueSnackbar(message, {variant: 'error'});
                            }
                        })
                    setInProgress(false)
                    handleClose()

                }
            })
            .catch(e => {
                debug && console.log("createFolder Exception:", e, "url:", url, "request: ", request);
            });

    };

    const processSubfolder = async(subfolder, parentFolderId, parentFolderMetadataArray) => {
        try {

            debug && console.log ('processSubfolder: subFolder=' , subfolder, 'parentFolderId=', parentFolderId, 'parentFolderMetadataArray=', parentFolderMetadataArray);

            let metadataArray = [];
            //Add fixedMetadata;

            for (let i=0; i < subfolder.fixedMetadata.length; i++){
                metadataArray.push(subfolder.fixedMetadata[i])
            }

            console.log ('add fixed metadata');
            if (subfolder.setMetadataFromParentFolder && subfolder.setMetadataFromParentFolder.length > 0) {
                const setMetadataFromParentFolder = subfolder.setMetadataFromParentFolder;

                for (let i=0; i<setMetadataFromParentFolder.length; i++) {
                    setMetadataFromParentFolder[i].metadataKeys.forEach(metadataKey => {
                        //check if value available in parentFolderMetadata
                        for (let p=0; p < parentFolderMetadataArray.length; p++) {
                            if (parentFolderMetadataArray[p].metadataKey === metadataKey && parentFolderMetadataArray[p].templateKey === setMetadataFromParentFolder[i].templateKey) {
                                metadataArray.push({
                                    templateKey: setMetadataFromParentFolder[i].templateKey,
                                    metadataKey: metadataKey,
                                    value: parentFolderMetadataArray[p].value
                                })
                            }
                        }
                    })
                }
            }

            console.log('*** subfolder.setCaseId = ', subfolder.setCaseId)


            if (subfolder.setCaseId && subfolder.setCaseId.templateKey && subfolder.setCaseId.metadataKey) {
                metadataArray.push({
                    templateKey: subfolder.setCaseId.templateKey,
                    metadataKey: subfolder.setCaseId.metadataKey,
                    value: parentFolderId
                })
            }

            let body = {
                name: subfolder.name,
                metadata: metadataArray
                //metadata: subfolder.fixedMetadata
            };

            debug && console.log ("body: " , body);

            const queryStr = "?userId=" + props.userDetails.boxId + '&autoGenTasks=false';
            let url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + "/" + parentFolderId  + queryStr;

            debug && console.log ('url: ', url);
            const request = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + props.userDetails.accessToken
                },
                body: JSON.stringify(body)
            };

            //call service to create folder
            await props.triggerRefreshAuthToken();
            let response = await (fetch(url,request));

            debug && console.log ('processSubfolder response =', response);

            let data = await response.json();

            return data;

        } catch(err) {
            debug && console.log("processSubfolder error: " + err);
            return err;
        }
    }

    const createSubfoldersAsync = (parentFolder, subfolders, parentFolderMetadataArray) => {

        const parentFolderId = parentFolder.boxId;

        //Generate subfolders asynchronously

        //wrap execution of asyncforEach in an async so that we can wait for all to be completed before showing confirmation
        const start = async () => {

            await asyncForEach(
                subfolders,
                async (subfolder) => {

                    await processSubfolder(subfolder, parentFolderId, parentFolderMetadataArray)
                        .then(result => {
                            debug && console.log ('processSubfolder result = ', result);
                            if (result !== null) {
                                if (result.boxId) {
                                    return result.boxId
                                } else {
                                    return "0";
                                }
                            } else {
                                debug && console.log ("response is null for subfolder", subfolder);
                                return "0";
                            }
                        })
                        .then(id => {
                            debug && console.log ('Folder Id for ' , subfolder , ": " , id)
                        });

                });

            enqueueSnackbar("Subfolder generation complete", {variant: 'success'});
            setInProgress(false);
            //Close Dialog now
            handleClose()

        };

        //start upload
        start();

    };

    const applyGroupToFolder = async(applyGroupToFolderConfig, folderId, metadata) => {

        debug && console.log ('applyGroupToFolder ', folderId,  'metadata=', metadata, 'applyGroupToFolderConfig=', applyGroupToFolderConfig);

        //Colculate group names from config, group names can comprise of metadata, fixed values and folderId
        let body = [];

        console.log ('Calculate the groupName for each groupConfig');
        applyGroupToFolderConfig && applyGroupToFolderConfig.forEach(groupConfig =>{
            console.log ('groupConfig=',  groupConfig);
            //Calculate the groupName for each groupConfig
            let groupNameElements = [];
            let groupName = "";
            groupConfig.groupName && groupConfig.groupName.forEach( groupNameConfig => {
                groupNameConfig.value ?
                    groupNameElements.push(groupNameConfig.value):
                    groupNameConfig.templateKey && groupNameConfig.metadataKey &&
                    groupNameConfig.templateKey === "n/a" && groupNameConfig.metadataKey === "id" ?
                        groupNameElements.push(folderId) :
                        metadata[groupNameConfig.templateKey + '~' + groupNameConfig.metadataKey ] ?
                            groupNameElements.push(metadata[groupNameConfig.templateKey + '~' + groupNameConfig.metadataKey ]) :
                            enqueueSnackbar('Unable to get metadata value for ' + groupNameConfig.templateKey + '~' + groupNameConfig.metadataKey, {variant: 'error'});
                groupName = groupNameElements.join('.');
            })
            body.push ({
                groupName: groupName,
                boxId: folderId,
                accessLevel: groupConfig.accessLevel
            })

        })

        const url = window.REACT_APP_SECURITY_API_BASE_URL + "/okta/group/applyToFolder";

        const request = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + props.userDetails.accessToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }

        debug && console.log('applyGroupToFolder url=', url, 'body=', body, 'request=', request);

        await props.triggerRefreshAuthToken();

        await fetch(url, request)
            .then(response => {
                debug && console.log('applyGroupToFolder response=', response);
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'applying security to folder'))
                        .then(message => {
                            enqueueSnackbar(message, {variant: 'error'});
                        })
                    debug && console.log("applyGroup error.  url:", url, "request: ", request);
                    return null
                }
            })
            .then(data => {
                debug && console.log('applyGroup response.json = ', data)

                if (data) {
                    enqueueSnackbar("Security successfully applied to folder.", {variant: 'success'});
                }

            })
            .catch(e => {
                enqueueSnackbar("Error applying group (exception: " + e.message + ")" , {variant: 'error'});
                debug && console.log("applyGroupToFoler  exception:" , e);
            })
    }



    let genericVals = [];
    props.genericMetadata && Object.entries(props.genericMetadata).forEach(entry => {
        genericVals.push(entry[1])
    })

    return (
        <div>
            <Tooltip title={"Create new SPV"}>
                <IconButton onClick={handleClickOpen} color={"secondary"} size="medium">
                    <i className="material-icons">create_new_folder</i>
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Create new SPV"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{paddingBottom: '16px'}}>
                        { inProgress ? "Creating " + props.addFolderConfig.label : "Are you sure you wish to create a new " + props.addFolderConfig.label + " with the following details?" }
                    </DialogContentText>

                    <React.Fragment>
                        <Grid item xs={12}>
                            <Paper className={props.classes.paper}
                                   style={{
                                       //width: "100%",
                                       paddingLeft: "20px",
                                       paddingTop: "20px",
                                       paddingRight: "20px",
                                       textAlign: "left"
                                   }}>
                                <Grid container spacing={2}>
                                    {
                                        props.addFolderConfig.fields.map(field => {
                                            const fieldValue = (props.metadata[field.templateKey + "~" + field.metadataKey])? props.metadata[field.templateKey + "~" + field.metadataKey] : "";
                                            return (
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        variant="standard"
                                                        fullWidth
                                                        style={{paddingBottom: '10px', margin: '0px'}}
                                                        key={"fc" + field.templateKey + "~" + field.metadataKey}>
                                                        <RenderMetadataField
                                                            parentClasses={props.classes}
                                                            fieldValue={fieldValue}
                                                            metadataConfig={props.metadataConfig}
                                                            optionsConfig={props.optionsConfig}
                                                            metadataKey={field.metadataKey}
                                                            templateKey={field.templateKey}
                                                            forceDisable={true}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            );})
                                    }
                                </Grid>
                            </Paper>
                        </Grid>

                    </React.Fragment>

                </DialogContent>
                <DialogActions>
                    {
                        ! inProgress &&
                            <React.Fragment>
                                <Button onClick={handleClose} variant="contained" color="grey">{"Cancel"}</Button>
                                <Button onClick={createFolder} variant="contained" color="secondary" autoFocus disabled={inProgress}>Yes</Button>
                            </React.Fragment>
                    }

                </DialogActions>
                {
                    inProgress && <LinearProgress/>
                }
            </Dialog>
        </div>
    );
}
AddFolderForFile.propTypes = {
    classes: PropTypes.object.isRequired,
    genericMetadata: PropTypes.array.isRequired,
    addFolderConfig: PropTypes.object.isRequired,
    spvEntity: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    getFolder: PropTypes.func.isRequired,
    rowId: PropTypes.number.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired
   // updateFolders: PropTypes.func.isRequired
}
export default (AddFolderForFile)