import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import Preview from "../preview/Preview";
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import {Grid} from "@mui/material";
import CommentsPanel from "./CommentsPanel";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const styles = theme => ({});

const INITIAL_STATE = {
    open: false
};

class CommentsDialog extends React.Component {

    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;

       // this.handleOnChangeComment = this.handleOnChangeComment.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);

        const debug =window.location.pathname.toLowerCase().includes("debug");
        debug && console.log ('CommentsDialog props=', props)

    }

    componentDidMount(){
        this.setState({ open: true });
        window.location.pathname.toLowerCase().includes("debug") && console.log('boxDocId:', this.props.boxDocID);

        //auto-refresh comments at interval set in config
        //this.interval = setInterval(() => this.getComments(), this.props.actionsConfig.comments.viewCommentsPollingIntervalMilliseconds);
    }

    handleCloseDialog = (commentsAdded) => {

        // clearInterval(this.interval);
        // this.interval=0; //clearInterval on its own not  clearing val

        //clear state
        const END_STATE = {
            open: false
        };
        this.setState(END_STATE);

        //Added this to address issue of dialog not re-opening after initial open
        if (this.props.handleCloseDialog) {
            this.props.handleCloseDialog(commentsAdded)
        }
    };

    render() {

        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="xl"
            >
                <DialogContent>
                    <Grid container spacing={1} style={ {height: "600px"}}>
                        <Grid item xs={8} style={{height: "100%", overflowY: "auto"}}>
                            <Preview boxDocID={this.props.boxDocID}
                                     fileUrl={this.props.fileUrl}
                                     userDetails={this.props.userDetails}
                                     showHeader={true}
                                     showSidebar={false}
                                     actionsConfig={this.props.actionsConfig}
                                     triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            />
                        </Grid>

                        <Grid item xs={4} style={{height: "100%"}}>
                            <CommentsPanel
                                boxDocID={this.props.boxDocID}
                                userDetails={this.props.userDetails}
                                actionsConfig={this.props.actionsConfig}
                                handleCloseDialog = {this.handleCloseDialog}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                comments={this.props.comments}
                                documentDetails={this.props.documentDetails}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {
                        this.props.handleCloseDialog &&
                        <Button onClick={this.handleCloseDialog}  variant="contained" color="secondary">Close</Button>
                    }

                </DialogActions>

            </Dialog>
        );
    }
}

CommentsDialog.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    comments: PropTypes.array, //when document and comment source is elastic
    enableAddComment: PropTypes.bool,
    documentDetails: PropTypes.object.isRequired,
    fileUrl: PropTypes.string
};

export default (withStyles(styles)(CommentsDialog));