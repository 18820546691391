import Input from "@mui/material/Input";
import React, {useEffect, useReducer} from "react";
import PropTypes from "prop-types";
import { enqueueSnackbar } from 'notistack'
import {getFieldConfig, getOptions, getOptionsExternal, getYearOptions} from './helper'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {DatePicker} from "@mui/x-date-pickers";
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {withStyles} from '@mui/styles';
import "react-day-picker/lib/style.css";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import Avatar from '@mui/material/Avatar'
import CardMedia from "@mui/material/CardMedia";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from "@mui/material/ListItemIcon";
import {Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const styles = (theme) => ({
    //https://material-ui.com/customization/components/
    disabled: {}, //required for the rule below to work
    root: {
        '&.Mui-disabled': {
            color: theme.disabledField.colour
        },

        // "& .MuiInputBase-input-MuiInput-input": {
        //     fontSize: 'Inherit'
        // }
        // '& .MuiInputBase-input-MuiInput-input': {
        //     fontSize: '1px',
        //     color: 'red'
        // }
    },

    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    }

});


const fieldTheme = theme => createTheme(({

    // to merge with parent theme
    ...theme,

    //https://material-ui.com/customization/globals/#css
    //note, overrides the overrides in the parent theme in app.css

    //to fix left padding on date picker toolbar
    components: {
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    paddingLeft: theme.spacing(3)
                },
                gutters: {
                    paddingLeft: theme.spacing(2)
                },
                root: {
                    paddingLeft: "0px"
                }
            }
        },
        //MuiButtonBase-root-MuiIconButton-root - to fix alighment of date input adornment
        MuiIconButton: {
            styleOverrides: {
                root: {
                    marginRight: 0
                }
            }
        }
    }
}));

function EditComponentMaterialTable(props) {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    debug && console.log ('EditComponentMaterialTable ', props.col.field, 'props =', props);

    const { onChange, value, fieldConfig,col, optionsConfig, metadataConfig} = props;

    const useExternalOptions = Object.entries(fieldConfig).length > 0 && fieldConfig.optionsExternal && Object.entries(fieldConfig.optionsExternal).length > 0

    let initialState =  {
        externalOptions: [],
        initExternalOptionsDone: false
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {

        //debug && console.log ('RenderMetadataField props = ', props);

        async function initExternalOptions () {

            //debug && console.log('RenderMetadataField initExternalOptions');

            let options = [];

            options = await getOptionsExternal(fieldConfig.optionsExternal, props.userDetails, props.triggerRefreshAuthToken, props.formValues )

            if (options.length === 0) {
                enqueueSnackbar("No options found for " + fieldConfig.label , {variant: 'error'});
                debug && console.log ('No options found for fieldConfig', fieldConfig);
            }

            return (
                dispatch({type: "EXTERNAL OPTIONS", value: options})
            )

        }

        let disabled = props.forceDisable ? true : fieldConfig.disabled;
        disabled = props.forceEnable ? false : disabled;

        if (!disabled && Object.entries(fieldConfig).length > 0 && fieldConfig.optionsExternal && Object.entries(fieldConfig.optionsExternal).length > 0) {
            debug && console.log ('useEffect, fieldConfig optionExternal config length > 0');
            initExternalOptions()
        } else {
            dispatch({type: "EXTERNAL OPTIONS", value: []})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //[ debug, fieldConfig, props])

    function reducer(state, action) {

        switch (action.type) {
            case "EXTERNAL OPTIONS": {
                return {
                    ...state,
                    externalOptions: action.value,
                    initExternalOptionsDone: true
                }
            }

            default:
                return state
        }
    }

    function handleChange(value) {
        console.log ('EditComponentMaterialTable handleChange value=', value)
        props.onChange(value);
    }

    let fieldType = fieldConfig.fieldType;

    if (fieldType === "Input" && (fieldConfig.optionsDependencies || fieldConfig.useYearOptions ) ){
        console.log ('set fieldType to Select ')
        //Note, need fieldType in metadata config to be Input for the optionsDependencies to show a value in read mode on the Material-Table component in search results
        fieldType = "Select"
    }

    switch (fieldType) {

        case "Date":

            return (
                <React.Fragment>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={fieldTheme}>
                            <DatePicker
                                onAccept={(newDate) => {}}
                                margin="none"
                                inputFormat="dd/MM/yyyy"
                                value = {value || null}
                                defaultValue={null}
                                onChange={onChange}
                                KeyboardButtonProps={{'aria-label': 'change date',}}
                                autoFocus={props.autoFocus}
                                slotProps={{
                                    textField: {variant: 'standard', placeholder: 'dd/mm/yyyy'},
                                    actionBar: {actions: ['clear']},
                                    field: {clearable: true},
                                }}
                            />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </React.Fragment>
            );

        case "Input":

            return (
                <React.Fragment>
                    <Input
                        value={value}
                        type={fieldConfig.type}
                        margin="none"
                        autoComplete=""
                        onChange={e => onChange(e.target.value)}
                        startAdornment={
                            <React.Fragment>
                                {
                                    (fieldConfig.currencySymbol && fieldConfig.currencySymbol !== "") &&
                                    <InputAdornment position={'start'}>
                                        {fieldConfig.currencySymbol}
                                    </InputAdornment>
                                }
                            </React.Fragment>
                        }
                    />
                </React.Fragment>);

        case "Select":

            let dependencies = fieldConfig.optionsDependencies;
            let options= useExternalOptions ? state.externalOptions :
                fieldConfig.useYearOptions ? getYearOptions(fieldConfig.previousYears, fieldConfig.futureYears) :
                    fieldConfig.options ? fieldConfig.options : [];

            if (options && Array.isArray(options) && options.length > 0) {
                    return (
                        <TextField
                            variant="standard"
                            select
                            value={value}
                            type={fieldConfig.type}
                            native={true}
                            margin="none"
                            autoComplete=""
                            disabled={!col.editable}
                            onChange={e => props.onChange(e.target.value)}>
                            {
                                options.length > 0 &&
                                <MenuItem value={fieldConfig.type === "number" ? null : ""}>
                                    {(options[0].avatar || options[0].icon) && <ListItemIcon/>}
                                    <Typography
                                        variant="inherit">{'- ' + fieldConfig.placeholder + ' -'}</Typography>
                                </MenuItem>
                            }
                            {options.map(opt => {
                                return (
                                    <MenuItem id={opt.value} value={opt.value} key={opt.value}>
                                        {opt.icon ?
                                            <Tooltip title={<span><i className={'material-icons'} style={{
                                                color: '#fff',
                                                fontSize: "36px"
                                            }}>{opt.icon}</i>{opt.label}</span>}>
                                                        <span style={{width: '100%', display: 'block'}}>
                                                            <i className={'material-icons'} style={{
                                                                color: '#646464',
                                                                fontSize: "14px",
                                                                verticalAlign: "middle",
                                                                paddingRight: '4px'
                                                            }}>{opt.icon}</i>
                                                            {opt.label}
                                                        </span>
                                            </Tooltip> :
                                            opt.avatar ?
                                                <Tooltip title={<CardMedia component="img"
                                                                           image={window.location.origin + '/images/' + opt.avatar}/>}>
                                                    <ListItem style={{padding: '0px'}}>
                                                        <ListItemIcon style={{minWidth: '32px'}}>
                                                            <Avatar component={'span'} alt={opt.label}
                                                                    style={{width: '18px', height: '18px'}}
                                                                    src={window.location.origin + '/images/' + opt.avatar}/>
                                                        </ListItemIcon>
                                                        <ListItemText primary={opt.label}/>
                                                    </ListItem>
                                                </Tooltip> :
                                                opt.label}
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    );
            } else if (dependencies) {
                console.log ('....options dependencies')
                let dependencyLabels = [];
                if (dependencies.length > 0) {

                    for (let i = 0; i < dependencies.length; i++) {
                        const dependencyFieldConfig = getFieldConfig(metadataConfig, dependencies[i].templateKey, dependencies[i].metadataKey)
                        dependencyLabels.push(dependencyFieldConfig ? dependencyFieldConfig.label : "");
                    }

                    function validate(dependencies, metadataConfig, rowData) {
                        //check if required fields have been entered
                        let missingValues = [];
                        for (let i = 0; i < dependencies.length; i++) {
                            if (!rowData[dependencies[i].templateKey + "~" + dependencies[i].metadataKey] && !dependencies[i].optional) {
                                const dependencyFieldConfig = getFieldConfig(metadataConfig, dependencies[i].templateKey, dependencies[i].metadataKey)
                                missingValues.push(dependencyFieldConfig ? dependencyFieldConfig.label : "");
                            }
                        }
                        if (missingValues.length > 0) {
                            if (missingValues.length === 1) {
                                enqueueSnackbar('Please select a value for ' + missingValues.slice(-1) + ' before selecting a ' + fieldConfig.label, {variant: 'info'});
                            } else {
                                enqueueSnackbar('Please select a value for ' + missingValues.slice(0, missingValues.length - 1).join(', ') + " and " + missingValues.slice(-1) + ' before selecting a ' + fieldConfig.label, {variant: 'info'});
                            }
                            return false
                        } else {
                            return true
                        }
                    }

                    let requiredFieldsAvailable = validate(dependencies, metadataConfig, props.rowData);

                    let value = props.value ? props.value : "";

                    if (requiredFieldsAvailable) {
                        options = getOptions(optionsConfig, col.templateKey, col.metadataKey, dependencies, props.rowData, "edit");
                        if (options.length === 0) {
                            if (dependencyLabels.length === 1) {
                                debug  && console.log ('No options available for ' + fieldConfig.label + " with the selected " + dependencyLabels.slice(-1))
                            } else {

                                debug  && console.log('No options available for ' + fieldConfig.label + " with the selected " + dependencyLabels.slice(0, dependencyLabels.length - 1).join(', ') + " and " + dependencyLabels.slice(-1))
                            }

                            //Clear value if no options available to avoid invalid value being saved
                            value = "";
                        }
                    } else {
                        options = []
                    }

                    //check if current value is in the list of options, clear it if not to avoid invalid value being saved
                    //or display an error?
                    let notInList = false;
                    let previousValue;
                    if (value !== "" && value !== null && value !== 'undefined') {
                        let optionsValueList = [];
                        for (let v = 0; v < options.length; v++) {
                            optionsValueList.push(options[v].value)
                        }
                        if (!optionsValueList.includes(value)) {
                            previousValue = value;
                            //debug && console.log ('clear value as not in list');
                            //value = ""
                            notInList = true;
                        }
                    }

                    return (
                        <TextField
                            variant="standard"
                            select
                            value={value}
                            placeholder={fieldConfig.placeholder}
                            type={fieldConfig.type}
                            native={true}
                            margin="none"
                            error={notInList}
                            helperText={notInList && "'" + previousValue + "' is not a valid option"}
                            autoComplete=""
                            disabled={!col.editable}
                            // onChange={e => props.onChange(e.target.value)}>
                            onChange={e => handleChange(e.target.value)}>
                            {
                                options.length > 0 &&
                                <MenuItem value="">
                                    {(options[0].avatar || options[0].icon) && <ListItemIcon/>}
                                    <Typography variant="inherit">{'- ' + fieldConfig.placeholder+ ' -'}</Typography>
                                </MenuItem>
                            }
                            {options.map(opt => {
                                return (
                                    <MenuItem id={opt.value} value={opt.value} key={opt.value}>
                                        {opt.icon ?
                                            <Tooltip title={<span><i className={'material-icons'} style={{color: '#fff', fontSize: "36px"}}>{opt.icon}</i>{opt.label}</span>}>
                                                            <span style={{width: '100%', display: 'block'}}>
                                                                <i className={'material-icons'} style={{color: '#646464', fontSize: "14px", verticalAlign: "middle", paddingRight: '4px'}}>{opt.icon}</i>
                                                                {opt.label}
                                                            </span>
                                            </Tooltip>:
                                            opt.avatar ?
                                                <Tooltip title={<CardMedia component="img" image={window.location.origin + '/images/' + opt.avatar}/>}>
                                                    <ListItem style={{padding: '0px'}}>
                                                        <ListItemIcon style={{minWidth: '32px'}}>
                                                            <Avatar component={'span'} alt={opt.label} style={{ width: '18px', height: '18px' }} src={window.location.origin + '/images/' + opt.avatar} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={opt.label} />
                                                    </ListItem>
                                                </Tooltip> :
                                                opt.label}
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    );
                    // }
                }
            }
            break;


        case "Checkbox":

            return (
                <FormControlLabel
                    sx={(theme) => ({
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: theme.disabledField.colour,
                        },
                    })}
                    style={{paddingLeft: '16px'}}
                    value={props.fieldValue}
                    checked={props.fieldValue}
                    control={<Checkbox color="secondary" onChange={handleChange}/>}
                    label={fieldConfig.label}
                    labelPlacement="end"
                    readOnly={props.forceDisable}
                    disabled={props.forceDisable}
                />
            )

        default:
            console.log ('Invalid field type:' , fieldType, fieldConfig)
            return (
                <span>Invalid field type</span>
            )
    }
}

EditComponentMaterialTable.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
    onChange: PropTypes.func.isRequired,
    fieldConfig: PropTypes.object.isRequired,
    col: PropTypes.object.isRequired,
  //  options: PropTypes.array.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    rowData: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired
};

export default (withStyles(styles, { withTheme: true })(EditComponentMaterialTable))