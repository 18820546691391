import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import DashboardFolderList from "../../reporting/DashboardFolderList";
import Typography from '@mui/material/Typography';
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import {Grid} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AddFolderButton from "../../folder/add/AddFolderButton"
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import FolderHeader from "../../folder/details/FolderHeader";
import {hasAccess, numberWithCommas} from "../../common/helper";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DisplayOptionButtons from "./DisplayOptionButtons";

const styles = theme => ({});

const tableTheme = theme => createTheme(({

    // to merge with parent theme
    ...theme,

    //https://material-ui.com/customization/globals/#css
    //note, overrides the overrides in the parent theme in app.css
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '0px',
                    paddingLeft: "0px"
                }
            }
        },

        // MuiIconButton: {
        //     styleOverrides: {
        //         root: {
        //             color: theme.palette.secondary.main
        //         },
        //         colorInherit: {
        //             color: theme.palette.secondary.main
        //         }
        //     }
        // },

        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: theme.palette.secondary.main
                }
            }
        }
    }
}));

function ColumnIcon(props){

    if (props.val && props.val !== "") {

        let valueMapping = props.iconConfig.valueMapping[props.val.toLowerCase()];

        if (valueMapping) {
            return(
                <Tooltip title = {valueMapping.tooltipTitle}>
                    {
                        props.iconConfig.icon === "avatar" ?

                            <Avatar key={"avatar"} style={{backgroundColor: valueMapping.colour, height: 20, width: 20, fontSize: '12px'}}>
                                {props.val}
                            </Avatar> :

                            <i className='material-icons' style={{color: valueMapping.colour}}>
                                <i className="material-icons">{props.iconConfig.icon}</i>
                            </i>
                    }
                </Tooltip>
            )
        } else {
            window.location.pathname.toLowerCase().includes("debug") && console.log ('No value mapping found for ', props.val.toLowerCase());
            return(
                <span/>
            )
        }
    } else {
        return(
            <span/>
        )
    }
}

ColumnIcon.propTypes = {
    val: PropTypes.string,
    iconConfig: PropTypes.object.isRequired
};


class SearchResultsFoldersList extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            selectedListItem: "",
            //show overall dashboard if configured
            dashboardUrl: this.props.selectedWorkspaceConfig.searchConfig.folderSearch.showDashboard ? window.REACT_APP_DASHBOARD_URL : ""
        }
    }

    selectListItem = (key, dashboardKey, dashboardTitle) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('selectListItem props = ');

        //TODO change to pass in vehicle details
        let dashboardUrl = window.REACT_APP_DASHBOARD_URL;

        this.setState({
            selectedListItem: key,
            dashboardUrl: dashboardUrl
            //dashboardTitle: dashboardTitle
        })
    };

    componentDidUpdate(prevProps) {

        if (JSON.stringify(prevProps.searchResults)!== JSON.stringify(this.props.searchResults)){
            window.location.pathname.toLowerCase().includes("debug") && console.log ('searchResults changed...' +
                'prevProps.searchResults=', prevProps.searchResults,
                'props.searchResults=' , this.props.searchResults);
            let data = [];
            this.props.searchResults.forEach(sr => {
                data.push(sr)
            });
            window.location.pathname.toLowerCase().includes("debug") && console.log ('!!! updated data: ', data);
            this.props.unmountComponent();
        }
    }

    componentWillUnmount() {
        window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResultsFoldersList: componentWillUnmount');
        this.props.remountComponent();
    }

    render() {

        const searchConfig = this.props.selectedWorkspaceConfig.searchConfig.folderSearch;

        window.location.pathname.toLowerCase().includes("debug") && console.log ('render SearchResultsFoldersList - this.props = ', this.props);

        //To cater for Metadata api which doesn't return searchTotalCount
        let totalCountDisp ;

        if (!this.props.searchTotalCount || this.props.searchTotalCount === 0) {
            totalCountDisp = this.props.searchResults.length
        } else {
            totalCountDisp = this.props.searchTotalCount
        }

        let hasMore = false;
        if (this.props.nextMarker) {
            hasMore = true
        } else if (this.props.searchTotalCount !== 0 && (this.props.searchResults.length < this.props.searchTotalCount)) {
            hasMore = true
        }

        let tableTitle = numberWithCommas(totalCountDisp) + " " +
            (totalCountDisp === 1 ?
                searchConfig.resultsTitleSingular :
                searchConfig.resultsTitlePlural);


        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={tableTheme}>
                    <Grid container style={{height:"100%"}}>

                        <Grid item
                              xs={this.state.dashboardUrl === "" ? 12 : 5}
                              style={{
                                  height: "100%",
                                  overflowY: "auto",
                                  paddingLeft: "20px",
                                  paddingRight: "20px"
                              }}
                        >
                            <AppBar
                                position="sticky"
                                //["absolute","fixed","relative","static","sticky"].
                            style={{
                                backgroundColor: "white",
                                color: "black",
                                boxShadow: "none",
                            }}>
                                <Toolbar disableGutters>
                                    <Typography variant={"h6"}>
                                        {tableTitle}
                                        {
                                            hasMore &&
                                            <Tooltip title={this.props.isFetching ? "Retrieving more results..." : "Retrieve more results"}>
                                                <span>
                                                    <Button onClick={this.props.getNextResults}
                                                            key={"ibNext"}
                                                            color={"secondary"}
                                                            disabled={this.props.isFetching}
                                                            style={{fontWeight: 'bold', minWidth: '0px',paddingLeft: '5px',paddingRight: '5px',paddingTop: '5px', fontSize: '1rem'}}>
                                                        {this.props.isFetching ?
                                                            <CircularProgress color="secondary" size={15}/> :
                                                            "..."}
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        }

                                        <DisplayOptionButtons searchConfig = {searchConfig}
                                                              showResultsAsGrid={this.props.showFolderResultsAsGrid}
                                                              showResultsAsList={this.props.showFolderResultsAsList}
                                                              showResultsAsTable={this.props.showFolderResultsAsTable}
                                                              currentDisplay={"list"}
                                        />

                                        {
                                            this.props.selectedWorkspaceConfig.addFolderConfig.enabled &&
                                            this.props.actionsConfig.addFolder.enabled &&
                                            hasAccess(this.props.actionsConfig.addFolder, this.props.userDetails.userRoles) &&
                                            <AddFolderButton
                                                parentClasses={this.props.classes}
                                                userDetails={this.props.userDetails}
                                                metadataConfig={this.props.metadataConfig}
                                                optionsConfig={this.props.optionsConfig}
                                                addFolderConfig={this.props.selectedWorkspaceConfig.addFolderConfig}
                                                parentFolderId={this.props.parentFolderId}
                                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                openFolder={this.props.openFolder}
                                            />
                                        }

                                    </Typography>
                                </Toolbar>
                            </AppBar>

                            <List>
                                {
                                    this.props.searchResults.map(sr => {

                                        let secondaryVal = "";
                                        let headerIcons = [];

                                        searchConfig.resultsColumns.map(col => {

                                            if (col.displayAsIcon) {

                                                let iconConfig = {};
                                                if (this.props.metadataConfig[col.templateKey]) {
                                                    if (this.props.metadataConfig[col.templateKey].metadata[col.metadataKey]) {
                                                        if (this.props.metadataConfig[col.templateKey].metadata[col.metadataKey]) {
                                                            if (this.props.metadataConfig[col.templateKey].metadata[col.metadataKey].iconConfig) {
                                                                iconConfig = this.props.metadataConfig[col.templateKey].metadata[col.metadataKey].iconConfig;
                                                                headerIcons.push(<ColumnIcon iconConfig={iconConfig} val={sr[col.metadataKey]}/>)
                                                            }
                                                        }
                                                    }
                                                }

                                            } else  {
                                                //only display metadata if it exists
                                                if (sr[col.metadataKey] && !col.hide) {
                                                    secondaryVal = secondaryVal + col.label + " " + sr[col.metadataKey] + " | ";
                                                }
                                            }
                                            return(null);
                                        });
                                        secondaryVal = secondaryVal.slice(0, -2);

                                        return (
                                            <ListItem
                                                button={true}
                                                onClick = {() => this.props.openFolder(sr)}
                                                selected = {this.state.selectedListItem === "li." + sr.rowId }
                                                key={"li." + sr.rowId }
                                                disableGutters

                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <i className='material-icons'>folder</i>
                                                    </Avatar>
                                                </ListItemAvatar>

                                                <ListItemText
                                                    primary={
                                                        <FolderHeader
                                                            parentClasses={this.props.classes}
                                                            folderDetails={sr}
                                                            folderHeaderConfig={searchConfig.folderDetails.header}
                                                            metadataConfig={this.props.metadataConfig}
                                                            actionsConfig={this.props.actionsConfig}
                                                            userDetails={this.props.userDetails}
                                                            showFolderActions={false}
                                                            workflowConfig={this.props.workflowConfig.folderActions}
                                                            addFolderConfig={this.props.workspaceConfig.addFolderConfig}
                                                        />}
                                                    secondary=
                                                        {<React.Fragment>{secondaryVal}</React.Fragment>}
                                                />

                                                {
                                                    this.props.actionsConfig.dashboardFolder.enabled &&

                                                    <ListItemSecondaryAction
                                                        onClick={() => this.selectListItem("li." + sr.rowId, sr.name, sr.name)}
                                                    >
                                                        <Tooltip title={"Show Dashboard for " + sr.name}>
                                                            <IconButton edge="end" aria-label="Dashboaard" size="medium"color={"secondary"}>
                                                                <i className='material-icons'>
                                                                    {"insert_chart"}
                                                                </i>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ListItemSecondaryAction>
                                                }

                                                    </ListItem>
                                        );
                                    })
                                }
                            </List>

                        </Grid>
                        {
                            this.state.dashboardUrl !== "" &&
                            <Grid item
                                  xs={7}
                                  style={{height: "100%", overflowY: "auto",}}
                            >
                                <DashboardFolderList
                                    dashboardUrl={this.state.dashboardUrl}
                                />

                            </Grid>
                        }

                    </Grid>

                </ThemeProvider>
            </StyledEngineProvider>
        );
    };
}

SearchResultsFoldersList.propTypes = {
    classes: PropTypes.object.isRequired,
    searchResults: PropTypes.array.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    searchLimit: PropTypes.number.isRequired,
    getNextResults: PropTypes.func.isRequired,
    getAllResults: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    openFolder: PropTypes.func.isRequired,
    parentFolderId: PropTypes.string.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    workflowConfig: PropTypes.object.isRequired,
    remountSearch: PropTypes.func.isRequired,
    nextMarker: PropTypes.string,
    showFolderResultsAsList: PropTypes.func.isRequired,
    showFolderResultsAsGrid: PropTypes.func.isRequired,
    showFolderResultsAsTable: PropTypes.func.isRequired
};

export default (withStyles(styles, { withTheme: true })(SearchResultsFoldersList));

