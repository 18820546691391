import React, {Component} from "react";
import SearchResultsFoldersList from "./SearchResultsFoldersList";
import SearchResultsFoldersGrid from "./SearchResultsFoldersGrid";
import SearchResultsFoldersTable from "./SearchResultsFoldersTable";
import SearchResultsDocumentsTable from "./SearchResultsDocumentsTable";
import SearchResultsDocumentsGrid from "./SearchResultsDocumentsGrid";
import PropTypes from "prop-types";
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'

const styles = theme => ({});

class SearchResults extends Component {

    constructor(props) {
        super(props);
        const folderResultsDisplay = props.selectedSearchConfig.folderSearch.resultsDisplay;  //table, list or grid
        const documentResultsDisplay = props.selectedSearchConfig.documentSearch.resultsDisplay;  //table, list or grid

        //if default is table and results count <  defaultToGridWhenCountLessThan then default display to Grid rather than table
        let showDocumentResultsAsTable =  !documentResultsDisplay || documentResultsDisplay.default === "table"
        let showDocumentResultsAsGrid = documentResultsDisplay && documentResultsDisplay.default && documentResultsDisplay.default === "grid";

        //override default if count specified
        const defaultToGridWhenCountLessThan = documentResultsDisplay && documentResultsDisplay.defaultToGridWhenCountLessThan;
        if (showDocumentResultsAsTable && defaultToGridWhenCountLessThan && defaultToGridWhenCountLessThan > 0 && props.searchResults.length < defaultToGridWhenCountLessThan) {
            showDocumentResultsAsGrid = true;
            showDocumentResultsAsTable = false;
        }

        //default search settings
        this.state = {
            showFolderResultsAsTable: !folderResultsDisplay || folderResultsDisplay.default === "table",
            showFolderResultsAsList: folderResultsDisplay && folderResultsDisplay.default && folderResultsDisplay.default === "list",
            showFolderResultsAsGrid: folderResultsDisplay && folderResultsDisplay.default && folderResultsDisplay.default === "grid",
            showDocumentResultsAsTable: showDocumentResultsAsTable,
            showDocumentResultsAsGrid: showDocumentResultsAsGrid,
            mountSearchResultsDocumentsTable: true,
            mountSearchResultsDocumentsGrid: true,
            mountSearchResultsFoldersTable: true,
            mountSearchResultsFoldersList: true,
            mountSearchResultsFoldersGrid: true,
        };

        this.remountSearchResultsDocumentsTable = this.remountSearchResultsDocumentsTable.bind(this);
        this.remountSearchResultsDocumentsGrid = this.remountSearchResultsDocumentsGrid.bind(this);
        this.remountSearchResultsFoldersTable = this.remountSearchResultsFoldersTable.bind(this);
        this.remountSearchResultsFoldersList = this.remountSearchResultsFoldersList.bind(this);
        this.remountSearchResultsFoldersGrid = this.remountSearchResultsFoldersGrid.bind(this);

        window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResults props:', props);

    }

    showFolderResultsAsList = () => {
        this.setState({
            showFolderResultsAsGrid: false,
            showFolderResultsAsList: true,
            showFolderResultsAsTable: false,
            mountSearchResultsFoldersList : true
        });
    };

    showFolderResultsAsGrid = () => {
        this.setState({
            showFolderResultsAsGrid: true,
            showFolderResultsAsList: false,
            showFolderResultsAsTable: false,
            mountSearchResultsFoldersGrid : true
        });
    };

    showFolderResultsAsTable = () => {
        this.setState({
            showFolderResultsAsGrid: false,
            showFolderResultsAsList: false,
            showFolderResultsAsTable: true,
            mountSearchResultsFoldersTable : true
        });
    };
    showDocumentResultsAsTable = () => {
        this.setState({
            showDocumentResultsAsGrid: false,
            showDocumentResultsAsTable: true,
            mountSearchResultsDocumentsTable: true,
        });
    };

    showDocumentResultsAsGrid = () => {
        this.setState({
            showDocumentResultsAsGrid: true,
            showDocumentResultsAsTable: false,
            mountSearchResultsDocumentsGrid: true,
        });
    };

    unmountSearchResultsDocumentsTable = () => {
        //window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResults: unmountSearchResultsDocumentsTable');
        //called by componentWillUnmount on Search component
        this.setState({mountSearchResultsDocumentsTable : false});
    };

    remountSearchResultsDocumentsTable = () => {
        //window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResults: remountSearchResultsDocumentsTable');
        //called by componentWillUnmount on SearchResultsDocumentTable component
        this.setState({mountSearchResultsDocumentsTable : true});
    };

    unmountSearchResultsDocumentsGrid = () => {
        //window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResults: unmountSearchResultsDocumentsTable');
        this.setState({mountSearchResultsDocumentsGrid : false});
    };

    remountSearchResultsDocumentsGrid = () => {
        //window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResults: remountSearchResultsDocumentsTable');
        this.setState({mountSearchResultsDocumentsGrid : true});
    };


    unmountSearchResultsFoldersTable = () => {
        // window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResults: unmountSearchResultsFoldersTable');
        //called by componentWillUnmount on SearchResultsFoldersTable component
        this.setState({mountSearchResultsFoldersTable : false});
    };

    remountSearchResultsFoldersTable = () => {
        // window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResults: remountSearchResultsFoldersTable');
        //called by componentWillUnmount on SearchResultsFoldersTable component
        this.setState({mountSearchResultsFoldersTable : true});
    };

    unmountSearchResultsFoldersList = () => {
        // window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResults: unmountSearchResultsFoldersList');
        this.setState({mountSearchResultsFoldersList : false});
    };

    remountSearchResultsFoldersList = () => {
        // window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResults: remountSearchResultsFoldersList');
        this.setState({mountSearchResultsFoldersList : true});
    };

    unmountSearchResultsFoldersGrid = () => {
        // window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResults: unmountSearchResultsFoldersGrid');
        this.setState({mountSearchResultsFoldersList : false});
    };

    remountSearchResultsFoldersGrid = () => {
        // window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResults: remountSearchResultsFoldersGrid');
        this.setState({mountSearchResultsFoldersGrid : true});
    };

    render() {

        const { ...other } = this.props;

        return (
            <React.Fragment>
                {
                    this.props.displaySearchResults &&
                    this.props.searchType === "folder" ?
                        (this.state.showFolderResultsAsTable && this.state.mountSearchResultsFoldersTable) ?
                        <SearchResultsFoldersTable
                            parentClasses={this.props.classes}
                            searchResults={this.props.searchResults}
                            searchTotalCount={this.props.searchTotalCount}
                            searchLimit={this.props.searchLimit}
                            getNextResults={this.props.getNextResults}
                            getAllResults={this.props.getAllResults}
                            userDetails={this.props.userDetails}
                            metadataConfig={this.props.metadataConfig}
                            optionsConfig={this.props.optionsConfig}
                            workspaceConfig={this.props.workspaceConfig}
                            selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                            searchType={this.props.searchType}
                            openFolder={this.props.openFolder}
                            parentFolderId={this.props.selectedWorkspaceConfig.searchConfig.folderSearch.folderIds[0]}
                            showResultsAsList={this.showFolderResultsAsList}
                            showResultsAsGrid={this.showFolderResultsAsGrid}
                            showResultsAsTable={this.showFolderResultsAsTable}
                            isFetching={this.props.isFetching}
                            remountComponent={this.remountSearchResultsFoldersTable}
                            unmountComponent={this.unmountSearchResultsFoldersTable}
                            searchCriteria={this.props.searchCriteria}
                            searchCriteriaSimple={this.props.searchCriteriaSimple}
                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            actionsConfig={this.props.actionsConfig}
                            workflowConfig={this.props.workflowConfig}
                            reloadWorkspace={this.props.reloadWorkspace}
                            nextMarker={this.props.nextMarker}
                        /> :
                            (this.state.showFolderResultsAsList && this.state.mountSearchResultsFoldersList) ?
                                <SearchResultsFoldersList
                                    parentClasses={this.props.classes}
                                    searchResults={this.props.searchResults}
                                    searchTotalCount={this.props.searchTotalCount}
                                    searchLimit={this.props.searchLimit}
                                    getNextResults={this.props.getNextResults}
                                    getAllResults={this.props.getAllResults}
                                    userDetails={this.props.userDetails}
                                    metadataConfig={this.props.metadataConfig}
                                    optionsConfig={this.props.optionsConfig}
                                    workspaceConfig={this.props.workspaceConfig}
                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                    openFolder={this.props.openFolder}
                                    parentFolderId={this.props.selectedWorkspaceConfig.searchConfig.folderSearch.folderIds[0]}
                                    showResultsAsTable={this.showFolderResultsAsTable}
                                    isFetching={this.props.isFetching}
                                    remountComponent={this.remountSearchResultsFoldersList}
                                    unmountComponent={this.unmountSearchResultsFoldersList}
                                    searchCriteria={this.props.searchCriteria}
                                    searchCriteriaSimple={this.props.searchCriteriaSimple}
                                    actionsConfig={this.props.actionsConfig}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    workflowConfig={this.props.workflowConfig}
                                    reloadWorkspace={this.props.reloadWorkspace}
                                    nextMarker={this.props.nextMarker}
                                /> :

                                (this.state.showFolderResultsAsGrid && this.state.mountSearchResultsFoldersGrid) &&
                                <SearchResultsFoldersGrid
                                    parentClasses={this.props.classes}
                                    searchResults={this.props.searchResults}
                                    searchTotalCount={this.props.searchTotalCount}
                                    searchLimit={this.props.searchLimit}
                                    getNextResults={this.props.getNextResults}
                                    getAllResults={this.props.getAllResults}
                                    userDetails={this.props.userDetails}
                                    metadataConfig={this.props.metadataConfig}
                                    optionsConfig={this.props.optionsConfig}
                                    workspaceConfig={this.props.workspaceConfig}
                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                    openFolder={this.props.openFolder}
                                    parentFolderId={this.props.selectedWorkspaceConfig.searchConfig.folderSearch.folderIds[0]}
                                    showResultsAsTable={this.showFolderResultsAsTable}
                                    showResultsAsList={this.showFolderResultsAsList}
                                    showResultsAsGrid={this.showFolderResultsAsGrid}
                                    isFetching={this.props.isFetching}
                                    remountComponent={this.remountSearchResultsFoldersGrid}
                                    unmountComponent={this.unmountSearchResultsFoldersGrid}
                                    searchCriteria={this.props.searchCriteria}
                                    searchCriteriaSimple={this.props.searchCriteriaSimple}
                                    actionsConfig={this.props.actionsConfig}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    workflowConfig={this.props.workflowConfig}
                                    remountSearch={this.props.remountSearch}
                                    nextMarker={this.props.nextMarker}
                                /> :

                        (this.state.showDocumentResultsAsTable && this.state.mountSearchResultsDocumentsTable) ?

                        <SearchResultsDocumentsTable
                                {...other}
                                parentClasses={this.props.classes}
                                searchResults={this.props.searchResults}
                                searchTotalCount={this.props.searchTotalCount}
                                searchLimit={this.props.searchLimit}
                                getNextResults={this.props.getNextResults}
                                getAllResults={this.props.getAllResults}
                                userDetails={this.props.userDetails}
                                actionsConfig={this.props.actionsConfig}
                                metadataConfig={this.props.metadataConfig}
                                optionsConfig={this.props.optionsConfig}
                                workspaceConfig={this.props.workspaceConfig}
                                selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                folderDetails={this.props.folderDetails}
                                isFetching={this.props.isFetching}
                                remountComponent={this.remountSearchResultsDocumentsTable}
                                unmountComponent={this.unmountSearchResultsDocumentsTable}
                                searchCriteria={this.props.searchCriteria}
                                searchCriteriaSimple={this.props.searchCriteriaSimple}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                nextMarker={this.props.nextMarker}
                                templatesConfig={this.props.templatesConfig}
                                workflowConfig={this.props.workflowConfig.documentActions}
                                showResultsAsTable={this.showDocumentResultsAsTable}
                                showResultsAsGrid={this.showDocumentResultsAsGrid}
                                reloadWorkspace={this.props.reloadWorkspace}
                            /> :

                            (this.state.showDocumentResultsAsGrid && this.state.mountSearchResultsDocumentsGrid) &&

                            <SearchResultsDocumentsGrid
                                {...other}
                                parentClasses={this.props.classes}
                                searchResults={this.props.searchResults}
                                searchTotalCount={this.props.searchTotalCount}
                                searchLimit={this.props.searchLimit}
                                getNextResults={this.props.getNextResults}
                                getAllResults={this.props.getAllResults}
                                userDetails={this.props.userDetails}
                                actionsConfig={this.props.actionsConfig}
                                metadataConfig={this.props.metadataConfig}
                                optionsConfig={this.props.optionsConfig}
                                workspaceConfig={this.props.workspaceConfig}
                                selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                folderDetails={this.props.folderDetails}
                                isFetching={this.props.isFetching}
                                remountComponent={this.remountSearchResultsDocumentsGrid}
                                unmountComponent={this.unmountSearchResultsDocumentsGrid}
                                searchCriteria={this.props.searchCriteria}
                                searchCriteriaSimple={this.props.searchCriteriaSimple}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                nextMarker={this.props.nextMarker}
                                templatesConfig={this.props.templatesConfig}
                                workflowConfig={this.props.workflowConfig.documentActions}
                                showResultsAsTable={this.showDocumentResultsAsTable}
                                showResultsAsGrid={this.showDocumentResultsAsGrid}
                            />
                }
            </React.Fragment>
        );
    }
}

SearchResults.propTypes = {
    searchResults: PropTypes.array.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    searchLimit: PropTypes.number.isRequired,
    getNextResults: PropTypes.func.isRequired,
    getAllResults: PropTypes.func.isRequired,
    displaySearchResults: PropTypes.bool.isRequired,
    userDetails: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    selectedSearchConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    searchType: PropTypes.string.isRequired,
    openFolder: PropTypes.func.isRequired,
    folderDetails: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    searchCriteria: PropTypes.array.isRequired,
    searchCriteriaSimple: PropTypes.string.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    nextMarker: PropTypes.string,
    templatesConfig: PropTypes.object.isRequired,
    workflowConfig: PropTypes.object.isRequired,
    reloadWorkspace: PropTypes.func.isRequired
};

export default (withStyles(styles, { withTheme: true })(SearchResults));