/* eslint-disable no-unused-vars */
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import * as React from "react";
import CustomCell from "./CustomCell"
import {getOptions} from "../helper";
/* eslint-enable no-unused-vars */

export default class CustomGroupRow extends React.Component {
    rotateIconStyle = (isOpen) => ({
        transform: isOpen ? "rotate(90deg)" : "none",
    });

    render() {

        let colSpan = this.props.columns.filter((columnDef) => !columnDef.hidden)
            .length;
        this.props.options.selection && colSpan++;
        this.props.detailPanel && colSpan++;
        this.props.actions && this.props.actions.length > 0 && colSpan++;
        const column = this.props.groups[this.props.level];

        let detail;
        if (this.props.groupData.isExpanded) {
            if (this.props.groups.length > this.props.level + 1) {
                // Is there another group
                detail = this.props.groupData.groups.map((groupData, index) => (
                    <this.props.components.GroupRow
                        actions={this.props.actions}
                        key={groupData.value || "" + index}
                        columns={this.props.columns}
                        components={this.props.components}
                        detailPanel={this.props.detailPanel}
                        getFieldValue={this.props.getFieldValue}
                        groupData={groupData}
                        groups={this.props.groups}
                        icons={this.props.icons}
                        level={this.props.level + 1}
                        path={[...this.props.path, index]}
                        onGroupExpandChanged={this.props.onGroupExpandChanged}
                        onRowSelected={this.props.onRowSelected}
                        onRowClick={this.props.onRowClick}
                        onToggleDetailPanel={this.props.onToggleDetailPanel}
                        onTreeExpandChanged={this.props.onTreeExpandChanged}
                        onEditingCanceled={this.props.onEditingCanceled}
                        onEditingApproved={this.props.onEditingApproved}
                        options={this.props.options}
                        hasAnyEditingRow={this.props.hasAnyEditingRow}
                        isTreeData={this.props.isTreeData}
                        cellEditable={this.props.cellEditable}
                        onCellEditStarted={this.props.onCellEditStarted}
                        onCellEditFinished={this.props.onCellEditFinished}
                        scrollWidth={this.props.scrollWidth}
                    />
                ));
            } else {
                detail = this.props.groupData.data.map((rowData, index) => {
                    if (rowData.tableData.editing) {
                        return (
                            <this.props.components.EditRow
                                columns={this.props.columns}
                                components={this.props.components}
                                data={rowData}
                                icons={this.props.icons}
                                path={[...this.props.path, index]}
                                localization={this.props.localization}
                                key={index}
                                mode={rowData.tableData.editing}
                                options={this.props.options}
                                isTreeData={this.props.isTreeData}
                                detailPanel={this.props.detailPanel}
                                onEditingCanceled={this.props.onEditingCanceled}
                                onEditingApproved={this.props.onEditingApproved}
                                getFieldValue={this.props.getFieldValue}
                                onBulkEditRowChanged={this.props.onBulkEditRowChanged}
                                scrollWidth={this.props.scrollWidth}
                            />
                        );
                    } else {
                        return (
                            <this.props.components.Row
                                actions={this.props.actions}
                                key={index}
                                columns={this.props.columns}
                                components={this.props.components}
                                data={rowData}
                                detailPanel={this.props.detailPanel}
                                getFieldValue={this.props.getFieldValue}
                                icons={this.props.icons}
                                path={[...this.props.path, index]}
                                onRowSelected={this.props.onRowSelected}
                                onRowClick={this.props.onRowClick}
                                onToggleDetailPanel={this.props.onToggleDetailPanel}
                                options={this.props.options}
                                isTreeData={this.props.isTreeData}
                                onTreeExpandChanged={this.props.onTreeExpandChanged}
                                onEditingCanceled={this.props.onEditingCanceled}
                                onEditingApproved={this.props.onEditingApproved}
                                hasAnyEditingRow={this.props.hasAnyEditingRow}
                                cellEditable={this.props.cellEditable}
                                onCellEditStarted={this.props.onCellEditStarted}
                                onCellEditFinished={this.props.onCellEditFinished}
                                scrollWidth={this.props.scrollWidth}
                            />
                        );
                    }
                });
            }
        }

        const freeCells = [];
        for (let i = 0; i < this.props.level; i++) {
            freeCells.push(<TableCell padding="checkbox" key={i} />);
        }

        //set need to set options based on row data, use first row in group, needed for IQEQ months as values different from labels
        if (column.optionsDependencies && this.props.groupData.data && this.props.groupData.data.length > 0) {
            const options = getOptions(this.props.optionsConfig, column.templateKey, column.metadataKey, column.optionsDependencies, this.props.groupData.data[0], "edit");
            let lookup = {};
            for (let p = 0; p < options.length; p++) {
                const opt = options[p];
                lookup[opt.value] = opt.label;
            }
            column.lookup = lookup
        }

        let value = this.props.groupData.value;
        //overwrite value with label from lookup
        if (column.lookup && column.lookup[value]) {
            value = column.lookup[value];
        }

        let title = column.title;
        if (typeof this.props.options.groupTitle === "function") {
            title = this.props.options.groupTitle(this.props.groupData);
        } else if (typeof title !== "string") {
            title = React.cloneElement(title);
        }

        //Datum custom - remove colon
        let separator = this.props.options.groupRowSeparator || ": ";

        return (
            <>
                <TableRow>
                    {freeCells}

                    <CustomCell
                        colSpan={colSpan}
                        padding="none"
                        columnDef={column}
                        value={value}
                        icons={this.props.icons}
                        groupData={this.props.groupData}
                        onGroupExpandChanged={this.props.onGroupExpandChanged}
                        path={this.props.path}
                        iconColour={this.props.iconColour}
                        groupingIcon={this.props.groupingIcon}
                    >
                        <b>
                            {title}
                            {separator}
                        </b>
                    </CustomCell>
                </TableRow>
                {detail}
            </>
        );
    }
}

CustomGroupRow.defaultProps = {
    columns: [],
    groups: [],
    options: {},
    level: 0,
};

CustomGroupRow.propTypes = {
    actions: PropTypes.array,
    columns: PropTypes.arrayOf(PropTypes.object),
    components: PropTypes.object,
    detailPanel: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.object),
    ]),
    getFieldValue: PropTypes.func,
    groupData: PropTypes.object,
    groups: PropTypes.arrayOf(PropTypes.object),
    hasAnyEditingRow: PropTypes.bool,
    icons: PropTypes.object,
    isTreeData: PropTypes.bool.isRequired,
    level: PropTypes.number,
    localization: PropTypes.object,
    onGroupExpandChanged: PropTypes.func,
    onRowSelected: PropTypes.func,
    onRowClick: PropTypes.func,
    onToggleDetailPanel: PropTypes.func.isRequired,
    onTreeExpandChanged: PropTypes.func.isRequired,
    onEditingCanceled: PropTypes.func,
    onEditingApproved: PropTypes.func,
    options: PropTypes.object,
    path: PropTypes.arrayOf(PropTypes.number),
    scrollWidth: PropTypes.number.isRequired,
    cellEditable: PropTypes.object,
    onCellEditStarted: PropTypes.func,
    onCellEditFinished: PropTypes.func,
    onBulkEditRowChanged: PropTypes.func,
    iconColour: PropTypes.string,
    optionsConfig: PropTypes.object.isRequired,
    groupingIcon: PropTypes.object
};

