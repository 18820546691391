import React from 'react';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack'
import {getDataForSearchResultsTableMetadataSearch} from "../../../search/helper"
import {getErrorMessageFromResponse} from "../../../common/helper";

const INITIAL_STATE = {};

class GetTemplates extends React.Component {

    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;

        window.location.pathname.toLowerCase().includes("debug") && console.log ('GetTemplates props = ', props)

    }

    UNSAFE_componentWillMount() {

        let templateSearchConfig = this.props.templateSearchConfig;
        this.props.updateIsFetching(true);

        if (templateSearchConfig.box.queryParams.length === 0 ){
            //no filters in config so get all files in the folder
            window.location.pathname.toLowerCase().includes("debug") && console.log ('get all files in folder');
            this.getFolderDocuments()
        } else {
            //metadata search
            window.location.pathname.toLowerCase().includes("debug") && console.log ('metadata search for folder documents');
            this.searchFolderDocumentsMetadata()
        }

    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    getFolderId = async() => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('getFolderId function start');

        let templateSearchConfig = this.props.templateSearchConfig;
        let folderId = "";
        if (templateSearchConfig.folderName) {
            if (templateSearchConfig.folderName !== "") {

                //get folder by name (folder must have the same using parent folder id as the current folder)
                const getSubfoldersResponse = await this.getSubFolders(this.props.parentFolderId);
                const subfolders = await getSubfoldersResponse;
                const subfoldersArr = subfolders.entries;
                window.location.pathname.toLowerCase().includes("debug") && console.log ('***** subfolders = ', subfolders);

                //find subfolder with matching name
                if (subfoldersArr) {
                    for (let i=0; i<subfoldersArr.length; i++) {
                        if (subfoldersArr[i].name.toLowerCase() === templateSearchConfig.folderName.toLowerCase()) {
                            folderId = subfoldersArr[i].id
                            break;
                        }
                    }
                }

                folderId === "" && enqueueSnackbar("Unable to find subfolder: " + templateSearchConfig.folderName, {variant: 'error'});

            } else {
                enqueueSnackbar("Config error, either Folder ID or Name is required", {variant: 'error'});
            }
        } else {
            enqueueSnackbar("Config error, either Folder ID or Name is required", {variant: 'error'});
        }

        window.location.pathname.toLowerCase().includes("debug") && console.log ('getFolderId function end, returning folderId=' + folderId)
        return folderId;
    }

    getSubFolders = async (folderId) => {

        let request = {};
        let url = "";
        let pathVar = "/" + folderId + "/folders";

        //no need to pass a limit at the moment for Wallbrook but may be required for other implementations
        const queryString =  "?userId=" + this.props.userDetails.boxId + "&recursive=false&limit=&offset=0";

        url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + pathVar + queryString;


        request = {
            headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log('getSubfolders REQUEST.  url:', url, 'request: ', request);

        //update parent state with search status
        //const folderDetails = this.props.folderIdSearch !== "" ? this.props.folderDetails : {};
        let response = await (fetch(url,request));

        window.location.pathname.toLowerCase().includes("debug") && console.log ('getMetadata response = ', response);

        let responseCheck = await response.ok;

        if (responseCheck) {
            window.location.pathname.toLowerCase().includes("debug") && console.log('response ok - getSubFolders RESPONSE: ', response);
            return (response.json())
        } else {
            window.location.pathname.toLowerCase().includes("debug") && console.log('response not ok - getSubFolders RESPONSE: ', response);
            Promise.resolve(getErrorMessageFromResponse(response, 'getting subfolders'))
                .then(message => {
                    enqueueSnackbar(message, {variant: 'error'})
                })
            window.location.pathname.toLowerCase().includes("debug") && console.log("getSubfolders error. url:", url, "request: ", request, "response:", response);
            return {};
        }
    };

    getFolderDocuments = async () => {

        //Get all files in folder

        let templateSearchConfig = this.props.templateSearchConfig;

        let folderId = templateSearchConfig.folderId;
        if (folderId === "" ) {
            const getFolderIdResponse = await this.getFolderId();
            folderId= await getFolderIdResponse;
        }
        window.location.pathname.toLowerCase().includes("debug") && console.log ('getFolderDocuments folderId = ', folderId);


        if (folderId !== "") {

            //get template names required from search results columns config
            let templateNames = [];
            if (templateSearchConfig.resultsColumns) {
                templateSearchConfig.resultsColumns.forEach(col => {
                    if (col.templateKey !== "n/a") {
                        if (templateNames.indexOf(col.templateKey) === -1) {
                            templateNames.push(col.templateKey)
                        }
                    }
                })
            }

            let fieldsStr = "name,created_at,modified_at,version,size";
            if (templateNames.length > 0) {
                const templateNamesStr = templateNames.map (t => "metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + t).toString();
                fieldsStr = fieldsStr + "," + templateNamesStr
            }


            const pathVar = "/" + folderId + "/files";
            const queryString =  "?userId=" + this.props.userDetails.boxId + "&recursive="+ templateSearchConfig.recursive + "&limit=" + this.props.searchLimit + "&offset=" + this.props.searchOffset + "&fields=" + fieldsStr;
            const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_FOLDER_FOLDER + pathVar + queryString;

            const request = {
                headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
            };

            window.location.pathname.toLowerCase().includes("debug") && console.log('getFolderDocuments REQUEST.  url:', url, 'request: ', request);

            let rows = [];
            let totalCount = 0;
            this.setState({isFetching: true});

            await this.props.triggerRefreshAuthToken();
            fetch(url, request)
                .then(response => {
                    window.location.pathname.toLowerCase().includes("debug") && console.log ('getFolderDocuments response =', response);
                    if (response.ok) {
                        return (response.json())
                    } else {
                        window.location.pathname.toLowerCase().includes("debug") && console.log('response not ok - getFolderDocuments RESPONSE: ', response);
                        Promise.resolve(getErrorMessageFromResponse(response, 'retrieving search results'))
                            .then(message => {
                                enqueueSnackbar(message + (response.status === 404 ? " (Folder: " + folderId + ")": ""), {variant: 'error'});
                            })
                        window.location.pathname.toLowerCase().includes("debug") && console.log("getFolderDocuments error. url:", url, "request: ", request, "response:", response);
                        this.setState({isFetching: false});
                        return null
                    }
                })
                .then(resultA => {
                    window.location.pathname.toLowerCase().includes("debug") && console.log ('getFolderDocuments response.json: ', resultA);
                    if (resultA !== null) {
                        totalCount = resultA.total_count;
                        if (totalCount === 0) {
                            rows = [];
                        } else {
                            resultA.entries.forEach((item, i) => {
                                rows.push(getDataForSearchResultsTableMetadataSearch(item, templateSearchConfig.resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig))
                            })
                        }
                    }

                })
                .then(result => {
                    //updateSearchResults(searchResults, searchType, displaySearchResults, searchCriteria, searchCriteriaSimple, totalCount) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log ('rows = ', rows);
                    this.props.updateSearchResults(rows, totalCount, this.props.newSearch );
                })
                .catch(e => {
                    this.setState({isFetching: false});
                    window.location.pathname.toLowerCase().includes("debug") && console.log("getFolderDocuments Exception:", e, "url:", url, "request: ", request);
                })

        }

    };

    searchFolderDocumentsMetadata = async () => {

        try {

            window.location.pathname.toLowerCase().includes("debug") && console.log('searchFolderDocumentsMetadata.  props=', this.props);

            let templateSearchConfig = this.props.templateSearchConfig;

            let folderId = templateSearchConfig.folderId;
            if (folderId === "" ) {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('....get folderId from folderName...')
                const getFolderIdResponse = await this.getFolderId();
                folderId= await getFolderIdResponse;
            }

            let request = {};
            let url = "";
            let nextMarker = this.props.newSearch ? null : this.props.nextMarker;

            url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_SEARCH_METADATA;

            const searchQuery = this.props.templateSearchConfig.box;

            let fields = [];
            //add standard fields
            fields.push("created_by");
            fields.push("name"); //filename
            //get metadata list to query from config
            if (searchQuery.metadataKeys) {
                for (let i = 0; i < searchQuery.metadataKeys.length; i++) {
                    fields.push("metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + searchQuery.templateKey + "." + searchQuery.metadataKeys[i])
                }
            }

            let query = [];
            let query_params = {};
            let queryValid = true;
            let order_by= searchQuery.order_by ? searchQuery.order_by : [];
            let queryParams = searchQuery.queryParams;

            let n=0;
            for (let p = 0; p < queryParams.length; p++)  {
                let vals = [];
                for (let v = 0; v < queryParams[p].values.length; v++) {
                    vals.push(":val" + n);
                    query_params["val" + n] = queryParams[p].values[v];
                    n++
                }
                if (vals.length > 1 ){
                    query.push(queryParams[p].paramName + " " + queryParams[p].operation + " (" + vals.join() + ")")
                } else {
                    query.push(queryParams[p].paramName + " " + queryParams[p].operation + vals[0]);
                }
            }

            window.location.pathname.toLowerCase().includes("debug") && console.log ('query = ', query, 'query_params = ', query_params);


            if (!folderId  ){
                enqueueSnackbar("Folder ID required for document search", {variant: 'error'});
            }

            //Proceed if all required values are available
            if (queryValid && folderId ) {
                let body = {
                    "ancestor_folder_id": folderId,
                    "fields": fields,
                    "limit": window.REACT_APP_CONTENT_API_SEARCH_METADATA_LIMIT,
                    "from": "enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + searchQuery.templateKey,
                    "query": query.join(" AND "),
                    "query_params": query_params,
                    "order_by": order_by,
                    "marker": nextMarker
                }

                request = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + this.props.userDetails.accessToken
                    },
                    body: JSON.stringify(body)
                };

                window.location.pathname.toLowerCase().includes("debug") && console.log('searchFolderDocumentsMetadata REQUEST.  url:', url, 'BODY: ', body, 'request:', request);

                let rows = [];
                let totalCount = 0;
                this.setState({isFetching: true});

                await this.props.triggerRefreshAuthToken();
                fetch(url, request)
                    .then(response => {
                        window.location.pathname.toLowerCase().includes("debug") && console.log ('getFolderDocuments response =', response);
                        if (response.ok) {
                            return (response.json())
                        } else {
                            window.location.pathname.toLowerCase().includes("debug") && console.log('response not ok - searchFolderDocumentsMetadata RESPONSE: ', response);
                            Promise.resolve(getErrorMessageFromResponse(response, 'retrieving search results'))
                                .then(message => {
                                    enqueueSnackbar(message + (response.status === 404 ? " (Folder: " + folderId + ")": ""), {variant: 'error'});
                                })
                            window.location.pathname.toLowerCase().includes("debug") && console.log("searchFolderDocumentsMetadata error. url:", url, "request: ", request, "response:", response);
                            this.setState({isFetching: false});
                            return null
                        }
                    })
                    .then(resultA => {
                        window.location.pathname.toLowerCase().includes("debug") && console.log('searchFolderDocumentsMetadata response.json: ', resultA);
                        const resultsColumns = this.props.templateSearchConfig.resultsColumns;

                        window.location.pathname.toLowerCase().includes("debug") && console.log('with paging resultA = ', resultA);
                        //with paging
                        if (resultA !== null) {
                            //totalCount = resultA.total_count;
                            totalCount = null; //need to set to null as it is sometimes returning and if set it breaks the footer
                            nextMarker = resultA.next_marker;
                            window.location.pathname.toLowerCase().includes("debug") && console.log('nextMarker = ', nextMarker);
                            if (totalCount === 0 || !resultA.entries) {
                                rows = [];
                            } else {
                                resultA.entries.forEach((item, i) => {
                                    window.location.pathname.toLowerCase().includes("debug") && console.log(i, item);
                                    rows.push(getDataForSearchResultsTableMetadataSearch(item, resultsColumns, window.REACT_APP_ENTERPRISE_ID, this.props.metadataConfig))
                                })
                            }
                        }

                    })
                    .then(result => {
                        this.props.updateSearchResults(rows, totalCount, this.props.newSearch, nextMarker);
                    })
                    .catch(e => {
                        this.setState({isFetching: false});
                        window.location.pathname.toLowerCase().includes("debug") && console.log("searchFolderDocumentsMetadata Exception:", e, "url:", url, "request: ", request);
                    })
            } else {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('no folderId or query params incomplete')
                this.setState({isFetching: false});
                this.props.updateSearchResults([], 0, this.props.newSearch );
            }
        } catch (e) {
            console.error(e);
            this.setState({isFetching: false});
            this.props.updateSearchResults([], 0, this.props.newSearch );
        }
    };

    render() {

        window.location.pathname.toLowerCase().includes("debug") && console.log('*** GetTemplates props = ', this.props)

        return (
            <React.Fragment/>
        );
    }
}

GetTemplates.propTypes = {
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    searchLimit: PropTypes.number.isRequired,
    updateSearchResults: PropTypes.func.isRequired,
    newSearch: PropTypes.bool.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    nextMarker: PropTypes.string,
    templateSearchConfig: PropTypes.object.isRequired,
    parentFolderId: PropTypes.string,
    updateIsFetching: PropTypes.func.isRequired
};

export default ((GetTemplates));