import {hasAccess} from "../common/helper";
import {enqueueSnackbar} from "notistack";

export function checkHasMultiSelectActions(actionsConfig, userRoles){
    // console.log (actionsConfig, userRoles)
    let hasMultiSelectActions = false;

    //add checks here for all multi select actions, currently only one is downloadFileMulti
    if (actionsConfig.downloadFileMulti.enabled) {
        if (hasAccess(actionsConfig.downloadFileMulti, userRoles)) {
            hasMultiSelectActions = true
        }
    }
    return hasMultiSelectActions
}
//downloadFileMultiFolderDocs

export function checkHasMultiSelectActionsFolderDocs(actionsConfig, userRoles){
    // console.log (actionsConfig, userRoles)
    let hasMultiSelectActions = false;

    //add checks here for all multi select actions, currently only one is downloadFileMulti
    if (actionsConfig.downloadFileMultiFolderDocs && actionsConfig.downloadFileMultiFolderDocs.enabled) {
        if (hasAccess(actionsConfig.downloadFileMultiFolderDocs, userRoles)) {
            hasMultiSelectActions = true
        }
    }
    return hasMultiSelectActions
}

export async function downloadFileMulti(data,  accessToken, triggerRefreshAuthToken, updateIsProcessing, filename){

    //console.log('** evt=', evt);
    console.log ('downloadFileMulti, data=', data)

    const pathVar = "/download";
    const params =  ""; // "?userId=" + props.userDetails.boxId;
    const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params ;

    //Build array of ids from the selected files
    let files = [];
    data.map((file) => {
        files.push(file.id);
        return null;
    });

    const request = {
        method: 'POST',
        headers: {"Authorization": "Bearer " + accessToken},
        body: files
    };

    window.location.pathname.toLowerCase().includes("debug") && console.log ('url = ', url, 'request = ', request);

    await triggerRefreshAuthToken();
    updateIsProcessing(true, false);
    fetch(url , request )
        .then(response => {
            if (response.ok) {
                window.location.pathname.toLowerCase().includes("debug") && console.log('response.ok - download RESPONSE = ', response);
                return (response.blob())
            } else {
                return null
            }
        })
        .then(result => {

            const url = URL.createObjectURL(result);
            const fileName =  files.length === 1 ? data[0].name : filename ? filename + '.zip' :"download.zip" ;

            // Create a new anchor element
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            updateIsProcessing(false, true);
            enqueueSnackbar("Download of " + data.length + " files complete", {variant: 'success'});

        })
        .catch(e => {
            window.location.pathname.toLowerCase().includes("debug") && console.log("download Exception:", e, " url:", url, "request: ", request);
            updateIsProcessing(false, false)
            enqueueSnackbar("Error downloading file: " + e, {variant: 'error'});
        })

}


