import React, {Component} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import CommentsDialog from "./CommentsDialog";
import {Badge} from "@mui/material";

const styles = theme => ({});

const INITIAL_STATE = {
    showDialog: false,
};

class CommentsButton extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }


    handleClickIcon = (event) => {
        this.setState({
            showDialog: true
        });

    };

    handleCloseDialog = (commentAdded) => {
        this.setState({
            showDialog: false
        });
        if (commentAdded) {
            this.props.unmountSearchResults()
        }

    };

    render() {

        const { anchorEl } = this.state;

        //window.location.pathname.toLowerCase().includes("debug") && console.log ('CommentsButton render, this.props = ', this.props);

        const commentCount = this.props.comments && Array.isArray(this.props.comments) ? this.props.comments.length : 0;
        // const documentDetails = this.props.documentDetails;
        // let enableAddComment = true;
        // const disableWhen = actionConfig.disableAddCommentWhen;
        // let key = disableWhen && disableWhen.templateKey + "~" + disableWhen.metadataKey;
        // if (disableWhen && disableWhen.templateKey === "n/a") {
        //     key = disableWhen.metadataKey
        // }
        // if (disableWhen && Object.entries(disableWhen).length > 0 && documentDetails){
        //     if(documentDetails[key] && (disableWhen.values.indexOf(documentDetails[key]) >= 0)) {
        //          enableAddComment = false;
        //     }
        // }

        return (
            <div>
                <Tooltip title={this.props.actionsConfig.comments.label}>
                    <IconButton
                        aria-label="Menu"
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClickIcon}
                        color={"secondary"}
                        size="medium">
                        {
                            commentCount > 0 ?
                                <Badge badgeContent={commentCount} color="primary">
                                    <i className='material-icons'>comment</i>
                                </Badge> :

                                <i className='material-icons'>comment</i>
                        }

                    </IconButton>
                </Tooltip>

                {
                    this.state.showDialog &&
                    <CommentsDialog
                        boxDocID={this.props.boxDocID}
                        fileUrl={this.props.fileUrl}
                        userDetails={this.props.userDetails}
                        actionsConfig={this.props.actionsConfig}
                        handleCloseDialog = {this.handleCloseDialog}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        comments={this.props.comments}
                        documentDetails={this.props.documentDetails}
                    />
                }

            </div>
        );
    }
}

CommentsButton.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    fileUrl: PropTypes.string,
    userDetails: PropTypes.object.isRequired,
    calledFromDocumentMenu: PropTypes.bool,
    handleCloseMenu: PropTypes.func,
    actionsConfig: PropTypes.object.isRequired,
    comments: PropTypes.array, //when document and comment source is elastic
    unmountSearchResults: PropTypes.func, //to refresh counter in search results after adding comment (elastic only),
    documentDetails: PropTypes.object.isRequired
};

export default withStyles(styles)(CommentsButton);