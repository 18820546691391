import React from 'react';
import MaterialTable from "@material-table/core";
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography/Typography";
import {Paper} from '@mui/material';
import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import AddFolderButton from "../../folder/add/AddFolderButton";
import ColumnIcon from "./ColumnIcon";
import {getErrorMessageFromResponse, getFieldConfig, hasAccess, numberWithCommas} from "../../common/helper"
import FolderIcon from "./FolderIcon";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ActionButtons from "../../folder/workflow/ActionButtons"
import {getWorkflowActionsMulti} from "../../folder/workflow/helper";
import ActionDialogCase from "../../folder/workflow/ActionDialogCase";
import DisplayOptionButtons from "./DisplayOptionButtons";
import {withTranslation} from 'react-i18next'
import EditComponentMaterialTable from "../../common/EditComponentMaterialTable";
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { ReactComponent as ExportIcon } from '../../common/images/export.svg';

const styles = theme => ({
    appBarFoldersTable: {
        backgroundColor: "white",
        color: "black",
        boxShadow: "none",
        paddingLeft: theme.spacing(2)
    }
});

class SearchResultsFoldersTable extends React.Component {

    constructor(props) {

        super(props);

        window.location.pathname.toLowerCase().includes("debug") && console.log('SearchResultsFoldersTable props= ', props);

        this.deselectAll = this.deselectAll.bind(this);
        this.closeActionDialog = this.closeActionDialog.bind(this);

        let searchConfig = props.selectedWorkspaceConfig.searchConfig.folderSearch;

        let columns = [];
        let columnConfig = JSON.parse(JSON.stringify(searchConfig.resultsColumns)); //deep clone
        let folderIcon = searchConfig?.resultsDisplay?.tableFolderIcon

        //Add Folder Icon column
        columns.push({
            title: '',
            field: 'id',
            filtering: false,
            sorting: false,
            width: 48,
            cellStyle: {
                //backgroundColor: 'pink',
                // width: 48,
                // maxWidth: 48,
                padding: 0,
                // marginRight: 40
            },
            headerStyle: {
                // width: 48,
                // maxWidth: 48,
                padding: 0,
                // marginRight: 40
            },
            export: false,
            editComponent: rowData => {
                return (<span/>)
            },
            render: rowData => {
                return (
                    <FolderIcon icon={folderIcon}/>
                )
            }
        });

        let hasEditableColumns = false;

        //Add a column for each column in workspaceConfig
        columnConfig.forEach((column, index) => {

            let fieldConfig = getFieldConfig(props.metadataConfig, column.templateKey, column.metadataKey);

            if (column.displayAsIcon) {

                let iconConfig = fieldConfig.iconConfig;

                columns.push({
                    label: column.label,
                    metadataKey: column.metadataKey,
                    templateKey: column.templateKey,
                    filtering: false,
                    sorting: false,
                    width: 10,
                    cellStyle: {
                        // maxWidth: 10,
                        // width: 10,
                        padding: 0,
                        textAlign: "center"
                        // marginRight: 40
                    },
                    headerStyle: {
                        textAlign: "center",
                        // maxWidth: 10,
                        // width: 10,
                    },
                    editable: "never",
                    export: false,
                    render: rowData => {
                        return (
                            <ColumnIcon val={rowData[column.metadataKey]} iconConfig={iconConfig}/>
                        )
                    },
                });

            } else {

                if (!column.hide) {
                    columns.push(column)
                }

                if (column.editable) {
                    hasEditableColumns = true;
                }

            }
        });

        //MaterialTable component requires the following for each column: title, field
        columns.forEach((col) => {
            const fieldConfig = getFieldConfig(props.metadataConfig, col.templateKey, col.metadataKey);
            col.cellStyle = {
                fontSize: "13px",
                fontFamily: "roboto",
                paddingRight: ((col.type === "numeric" && (!col.defaultGroupOrder || col.defaultGroupOrder === 0)) || col.editable ==='always' ) ? "16px" : "8px"
            };
            col.title = col.label;
            if (col.templateKey === "n/a") {
                col.field = col.metadataKey;
            } else {
                col.field = col.templateKey + "~" + col.metadataKey;
            }
            col.editable = col.editable ? "always" : "never";
            col.editComponent = props => {
                const value = props.value;
                return (
                    <EditComponentMaterialTable
                        onChange={props.onChange} col={col} fieldConfig={fieldConfig} value={value} rowData={props.rowData}
                        metadataConfig={this.props.metadataConfig} optionsConfig={this.props.optionsConfig}/>
                )
            }
        });

        //TODO New for Sanctions
        //Add column for Folder Workflow Actions...
        if (searchConfig.showWorkflowActions) {

            //check if any actions enabled
            let workflowActionButtonsEnabled = false;
            props.workflowConfig.folderActions.forEach(action => {
                if (action.enabled) {
                    workflowActionButtonsEnabled = true
                }
            })
            if (workflowActionButtonsEnabled) {
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 48,
                    cellStyle: {
                        //width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    headerStyle: {
                        //width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    editComponent: rowData => {
                        return (<span/>)
                    },
                    render: rowData => {
                        if (this.state.mountActionButtons) {
                            return (
                                <ActionButtons
                                    folderDetails={rowData}
                                    userDetails={props.userDetails}
                                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                    workflowConfig={props.workflowConfig.folderActions}
                                    unmountComponent={this.unmountActionButtons}
                                    remountComponent={this.remountActionButtons}
                                />
                            )
                        } else {
                            return (
                                <span/>
                            )
                        }

                    },
                })

            }
        }


        //add data for each item in search results
        let data = [];
        props.searchResults.forEach(sr => {
            data.push(sr)
        });

        this.state = {
            data: data,
            columns: columns,
            hasEditableColumns: hasEditableColumns,
            //selectedRowsCount: 0,
            selectedRows: [],
            showActionDialog: false,
            mountActionButtons: true
        }

    }

    componentDidUpdate(prevProps) {

        if (JSON.stringify(prevProps.searchResults)!== JSON.stringify(this.props.searchResults)){
            window.location.pathname.toLowerCase().includes("debug") && console.log ('searchResults changed...' +
                'prevProps.searchResults=', prevProps.searchResults,
                'props.searchResults=' , this.props.searchResults);
            let data = [];
            this.props.searchResults.forEach(sr => {
                data.push(sr)
            });
            window.location.pathname.toLowerCase().includes("debug") && console.log ('!!! updated data: ', data);
            this.props.unmountComponent();
        }
    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    openFolder = (event,rowData) => {
        //call folderSearch on PortalApp.js
        this.props.openFolder(rowData);
    };

    deselectAll = () => {
        let updatedData = this.state.data;
        for (let i = 0; i < updatedData.length; i++) {
            updatedData[i].tableData.checked = false;
        }
        this.setState({data: updatedData })
    }

    openActionDialog = (action, data) => {
        this.setState({
            showActionDialog: true,
            selectedRows: data,
            selectedAction: action
        })
    }

    closeActionDialog = (deselectAll, reloadWorkspace) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResultsFoldersTable closeActionDialog deselectAll=', deselectAll, 'reloadWorkspace=', reloadWorkspace);

        this.setState({
            showActionDialog: false
        })

        if (reloadWorkspace) {
            window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResultsFoldersTable call props.reloadWorkspace, selectedWorkspaceConfig=', this.props.selectedWorkspaceConfig);
            this.props.reloadWorkspace(this.props.selectedWorkspaceConfig)
        } else if (deselectAll) {
            this.deselectAll()
        }
    }

    unmountActionButtons = () => {
        this.setState({mountActionButtons: false })
    }

    remountActionButtons = () => {
        this.setState({mountActionButtons: true })
    }

    render() {

        const { classes} = this.props;
        const actionsConfig = this.props.actionsConfig;
        const searchConfig = this.props.selectedWorkspaceConfig.searchConfig.folderSearch;

        const {t} = this.props;
        const translate = (val) => window.REACT_APP_ENABLE_TRANSLATION === "true" ? t(val) : val

        let columns = this.state.columns;
        columns.forEach(col => col.title = translate(col.label))

        const hasEditAccess = hasAccess(actionsConfig.editFolder, this.props.userDetails.userRoles);

        const showExport = actionsConfig.exportResults && actionsConfig.exportResults.enabled && hasAccess(actionsConfig.exportResults, this.props.userDetails.userRoles)
        const colsForExport = this.state.columns.filter((col) => col.field !== 'id');


        const actions = [];
        const workflowActionsMulti = searchConfig.showWorkflowActionsMulti ? getWorkflowActionsMulti(this.props.workflowConfig.folderActionsMulti, this.props.userDetails.userRoles) : [];
        if(searchConfig.showWorkflowActionsMulti) {
            if (workflowActionsMulti.length > 0) {
                workflowActionsMulti.forEach(action => {
                    actions.push({
                        tooltip: action.label,
                        icon: action.icon,
                        disabled: this.state.showActionDialog,
                        onClick: (evt, data) => {
                            this.openActionDialog(action, data)
                        }
                    })
                })
            }
        }

       // window.location.pathname.toLowerCase().includes("debug") && console.log ('actions =', actions);
        //window.location.pathname.toLowerCase().includes("debug") && console.log('SearchResultsFoldersTable render - props.searchResults: ', this.props.searchResults);

        let docMenuPosition = this.state.columns.length;

        //To cater for Metadata api which doesn't return searchTotalCount
        let totalCountDisp ;
        if ((!this.props.searchTotalCount || this.props.searchTotalCount === 0) || this.props.nextMarker) {
            totalCountDisp = this.props.searchResults.length
        } else {
            totalCountDisp = this.props.searchTotalCount;
        }

        let hasMore = false;
        if (this.props.nextMarker) {
            hasMore = true
        } else if (this.props.searchTotalCount !== 0 && (this.props.searchResults.length < this.props.searchTotalCount)) {
            hasMore = true
        }

        let tableTitle = numberWithCommas(totalCountDisp) + " " +
            (totalCountDisp === 1 ?
                translate(searchConfig.resultsTitleSingular) :
                translate(searchConfig.resultsTitlePlural));

        const iconColour = this.props.theme.palette.secondary.main; //default colour

        if (this.state.data.length === 0 ) {

            return (
                <AppBar position="sticky" className={classes.appBarFoldersTable}>
                    <Toolbar disableGutters>
                        <Typography variant={"h6"}>
                            {tableTitle}
                            <DisplayOptionButtons searchConfig = {searchConfig}
                                                  showResultsAsGrid={this.props.showResultsAsGrid}
                                                  showResultsAsList={this.props.showResultsAsList}
                                                  showResultsAsTable={this.props.showResultsAsTable}
                                                  currentDisplay={"table"}
                            />

                            {
                                this.props.selectedWorkspaceConfig.addFolderConfig.enabled &&
                                actionsConfig.addFolder.enabled &&
                                hasAccess(actionsConfig.addFolder, this.props.userDetails.userRoles) &&
                                <AddFolderButton
                                    parentClasses={this.props.classes}
                                    userDetails={this.props.userDetails}
                                    metadataConfig={this.props.metadataConfig}
                                    optionsConfig={this.props.optionsConfig}
                                    addFolderConfig={this.props.selectedWorkspaceConfig.addFolderConfig}
                                    parentFolderId={this.props.parentFolderId}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    openFolder={this.props.openFolder}
                                />
                            }
                        </Typography>
                    </Toolbar>
                </AppBar>

            )
        } else {

            return (
                <div style={{paddingLeft:'16px', paddingRight:'16px'}}>
                    {
                        this.state.showActionDialog &&
                            //multi select workflow actions only available for case currently, ActionDialog would need to be extended to handle multiples
                            window.REACT_APP_FOLDER_SOURCE === "elastic" &&
                            <ActionDialogCase
                                classes={classes}
                                userDetails={this.props.userDetails}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                actionConfig={this.state.selectedAction}
                                handleCloseDialog={this.closeActionDialog}
                                folders={this.state.selectedRows}/>
                    }

                    {/*<StyledEngineProvider injectFirst>*/}
                    {/*    <ThemeProvider theme={tableTheme}>*/}
                            <MaterialTable
                                onSelectionChange={(rowData) => {
                                    //TODO check selection causing extra rendering....
                                    //this.setState({selectedRowsCount: rowData.length})
                                }}
                                title={
                                    <React.Fragment>
                                        <Typography variant={"h6"}>
                                            {tableTitle}

                                            {
                                                hasMore &&
                                                <Tooltip title={this.props.isFetching ? "Retrieving more results..." : "Retrieve more results"}>
                                                    <span>
                                                        <Button onClick={this.props.getNextResults}
                                                                key={"ibNext"}
                                                                color={"secondary"}
                                                                disabled={this.props.isFetching}
                                                                style={{fontWeight: 'bold', minWidth: '0px',paddingLeft: '5px',paddingRight: '5px',paddingTop: '5px', fontSize: '1rem'}}>
                                                            {this.props.isFetching ?
                                                                <CircularProgress color="secondary" size={15}/> :
                                                                "..."}
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            }

                                            <DisplayOptionButtons searchConfig = {searchConfig}
                                                                  showResultsAsGrid={this.props.showResultsAsGrid}
                                                                  showResultsAsList={this.props.showResultsAsList}
                                                                  showResultsAsTable={this.props.showResultsAsTable}
                                                                  currentDisplay={"table"}
                                            />
                                            {
                                                this.props.selectedWorkspaceConfig.addFolderConfig.enabled &&
                                                actionsConfig.addFolder.enabled &&
                                                hasAccess(actionsConfig.addFolder, this.props.userDetails.userRoles) &&
                                                <AddFolderButton
                                                    parentClasses={this.props.classes}
                                                    userDetails={this.props.userDetails}
                                                    metadataConfig={this.props.metadataConfig}
                                                    optionsConfig={this.props.optionsConfig}
                                                    addFolderConfig={this.props.selectedWorkspaceConfig.addFolderConfig}
                                                    parentFolderId={this.props.parentFolderId}
                                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                    openFolder={this.props.openFolder}
                                                />
                                            }
                                        </Typography>
                                    </React.Fragment>
                                }
                                // remove shadow from paper container
                                components={{Container: props => <Paper {...props} elevation={0}/>}}
                                // toolbar={<React.Fragment></React.Fragment>}
                                columns={columns}
                                data={this.state.data}
                                icons={{
                                    DetailPanel: () => <i className={'material-icons'} style={{color: iconColour}}>chevron_right</i>,
                                    Edit: () => <i className={'material-icons'} style={{color: iconColour}}>edit</i>,
                                    Check: () => <i className={'material-icons'} style={{color: iconColour, padding: '3px'}}>check</i>,
                                    Clear: () => <i className={'material-icons'} style={{color: iconColour, padding: '3px'}}>clear</i>,
                                    Export: () => <ExportIcon style={{height: '24px', width: '24px', color: iconColour}}/>
                                    // Resize: props => <i {...props} className={'material-icons'} style={{color: iconColour, padding: '3px'}}>drag_indicator</i>
                                }}
                                options={{
                                    //exportButton: actionsConfig.exportResults && actionsConfig.exportResults.enabled && hasAccess(actionsConfig.exportResults, this.props.userDetails.userRoles),
                                    //exportFileName: this.props.selectedWorkspaceConfig.title,
                                    exportMenu: showExport ? [{
                                        label: 'Export PDF',
                                        exportFunc: (cols, datas) => ExportPdf(colsForExport, datas, this.props.selectedWorkspaceConfig.title)
                                    }, {
                                        label: 'Export CSV',
                                        exportFunc: (cols, datas) => ExportCsv(colsForExport, datas, this.props.selectedWorkspaceConfig.title)
                                    }]: [],
                                    actionsColumnIndex: docMenuPosition,
                                    paging: false,
                                    search: actionsConfig.folderFilterSearch && actionsConfig.folderFilterSearch.enabled,
                                    actionsCellStyle: {
                                        color: "rgba(0, 0, 0, 0.54)"
                                    },
                                    selection: searchConfig.showWorkflowActionsMulti && workflowActionsMulti.length > 0,
                                    //Note value in fixedColumns breaks onChange - newData paramater is returned the same as oldData in onChangeEvent
                                    // tableLayout: 'fixed',
                                    // columnResizable: true,
                                    // fixedColumns: {
                                    //     left: 0, //this.state.fixedColumnsLeft,
                                    //     right: -1 //this.state.fixedColumnsRight
                                    // }

                                }}
                                onRowClick={this.openFolder}
                                actions={actions}
                                editable={
                                    !actionsConfig.editFolder.enabled ?
                                        {} :
                                        !hasEditAccess ?
                                            {} :
                                            !this.state.hasEditableColumns ?
                                                {} :
                                                {
                                                    onRowUpdate: (newData, oldData) =>

                                                        new Promise(async (resolve, reject) => {

                                                            const useCaseAPI = window.REACT_APP_FOLDER_SOURCE === "elastic" ;

                                                            window.location.pathname.toLowerCase().includes("debug") && console.log((new Date()).toLocaleTimeString() + ' newData = ', newData);

                                                            if (useCaseAPI) {
                                                                window.location.pathname.toLowerCase().includes("debug") && console.log('edit case metadata');

                                                                let metadata = {};
                                                                Object.entries(newData).forEach(entry => {
                                                                    if (entry[0].indexOf("~") > -1) {
                                                                        let val = entry[1];
                                                                        if (val && typeof val === 'object') {
                                                                            let dateVal = new Date(val)
                                                                            dateVal.setUTCHours(0, 0, 0, 0);
                                                                            val = dateVal;
                                                                        }
                                                                        metadata[entry[0].split("~")[1]] = val;

                                                                    }
                                                                });

                                                                let body = {
                                                                    metadata: metadata,
                                                                    id: newData.id
                                                                };

                                                                window.location.pathname.toLowerCase().includes("debug") && console.log((new Date()).toLocaleTimeString() + ' body= ', body);

                                                                await this.props.triggerRefreshAuthToken();

                                                                const indexes = searchConfig.elastic.indexes;
                                                                const pathVar =  indexes.length > 0 ?  "/" + indexes.toString()  : "";
                                                                const url = window.REACT_APP_CASE_API_BASE_URL + pathVar + "/" ;
                                                                const request = {
                                                                    method: 'PUT',
                                                                    headers: {
                                                                        "Content-Type": "application/json",
                                                                        "case-token": this.props.userDetails.caseAccessToken,
                                                                        "Authorization": "Bearer " + this.props.userDetails.accessToken
                                                                    },
                                                                    body: JSON.stringify(body)
                                                                };

                                                                window.location.pathname.toLowerCase().includes("debug") && console.log((new Date()).toLocaleTimeString() + ' edit case metadata BODY=', body, 'request=', request);
                                                                this.setState({isFetching: true});

                                                                fetch(url, request)
                                                                    .then(response => {
                                                                        if (response.ok) {
                                                                            window.location.pathname.toLowerCase().includes("debug") && console.log((new Date()).toLocaleTimeString() + ' response=', response);
                                                                            enqueueSnackbar("Metadata successfully updated.", {variant: 'success'});
                                                                            this.setState({isFetching: false});

                                                                            //update state data so that table shows latest update
                                                                            const dataUpdate = [...this.state.data];
                                                                            const target = dataUpdate.find((el) => el.id === oldData.tableData.id);
                                                                            const index = dataUpdate.indexOf(target);
                                                                            dataUpdate[index] = newData;
                                                                            this.setState({...this.state, data: dataUpdate});

                                                                            resolve();

                                                                        } else {
                                                                            window.location.pathname.toLowerCase().includes("debug") && console.log((new Date()).toLocaleTimeString() + ' response=', response);
                                                                            Promise.resolve(getErrorMessageFromResponse(response, 'updating data'))
                                                                                .then(message => {
                                                                                    enqueueSnackbar(message, {variant: 'error'});
                                                                                    window.location.pathname.toLowerCase().includes("debug") && console.log((new Date()).toLocaleTimeString() + ' updateMetadata error.  url:', url, 'request: ', request);
                                                                                    this.setState({isFetching: false});
                                                                                    reject();
                                                                                })
                                                                        }
                                                                    })

                                                            } else {
                                                                enqueueSnackbar("Inline editing of box folder metadata not currently available", {variant: 'error'});
                                                                reject();
                                                            }
                                                        }),
                                                }}
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: '',
                                        editTooltip: 'Edit'
                                    },
                                    toolbar: {
                                        searchTooltip: 'Filter',
                                        searchPlaceholder: 'Filter',
                                        // nRowsSelected:
                                        //     " " + this.state.selectedRowsCount + " " +
                                        //     (this.state.selectedRowsCount === 1 ?
                                        //         searchConfig.rowsSelectedTextSingular ? searchConfig.rowsSelectedTextSingular : "rows selected" :
                                        //         searchConfig.rowsSelectedTextPlural ? searchConfig.rowsSelectedTextPlural : "row selected")
                                    },
                                    header:{
                                        actions: ""
                                    }
                                }}
                            />

                    {/*    </ThemeProvider>*/}
                    {/*</StyledEngineProvider>*/}

                </div>
            );
        }
    };
}

SearchResultsFoldersTable.propTypes = {
    classes: PropTypes.object.isRequired,
    searchResults: PropTypes.array.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    searchLimit: PropTypes.number.isRequired,
    getNextResults: PropTypes.func.isRequired,
    getAllResults: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    openFolder: PropTypes.func.isRequired,
    parentFolderId: PropTypes.string,
    showResultsAsList: PropTypes.func.isRequired,
    showResultsAsGrid: PropTypes.func.isRequired,
    showResultsAsTable: PropTypes.func.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    workflowConfig: PropTypes.object.isRequired,
    reloadWorkspace: PropTypes.func.isRequired,
    nextMarker: PropTypes.string
};

export default withTranslation()((withStyles(styles, { withTheme: true })(SearchResultsFoldersTable)));

