import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import {ContentExplorer} from "box-ui-elements";
import messages from "box-ui-elements/i18n/en-US";
import {addLocaleData} from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import 'box-ui-elements/dist/explorer.css';

addLocaleData(enLocaleData);

//const LANGUAGE = 'en_UK';
const LANGUAGE = "en-US";

class MyDocumentsDialog extends React.Component {
    state = {
        open: false,
        folderChosen: {}
    };

    componentDidMount(){
        this.setState({ open: true })
    }


    handleCloseDialog = () => {
        this.setState({ open: false });
        this.props.handleMyDocumentsClose()
    };

    folderPicker = (results) => {
        console.log ('folder chosen', results)
        if (results && Array.isArray(results)) {
            if (results.length > 1) {
                alert('Please choose only one folder')
            } else {
                this.setState({folderChosen: results[0]})
                alert("New Drawing Register folder will be created in the folder: " + results[0].name + '.  Please enter contract name and reference')
            }
        }


    }

    render() {
        return (

            <Dialog
                open={this.state.open}
                onClose={this.handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogContent>

                    <div style={{ border: 'border:1px solid #eee', height:'350px', width:'100%'}}>

                        <ContentExplorer
                            token={this.props.userDetails.boxAccessToken}
                            language={LANGUAGE}
                            messages={messages}
                            logoUrl = {window.location.origin + '/images/authlogo.png'}
                            currentFolderId={0}
                        />


                        {/*<IntlProvider locale="en">*/}
                        {/*    <ContentPicker*/}
                        {/*        token={this.props.userDetails.boxAccessToken}*/}
                        {/*        rootFolderId={"0"}*/}
                        {/*        logoUrl = {window.location.origin + '/images/authlogo.png'}*/}
                        {/*        type={"folder"}*/}
                        {/*        currentFolderId={"224040009260"}*/}
                        {/*        onChoose={this.folderPicker}*/}
                        {/*        maxSelectable={1}*/}
                        {/*        canUpload={false}*/}
                        {/*        canCreateNewFolder={false}*/}
                        {/*        canSetShareAccess={false}*/}
                        {/*        //responseInterceptor={this.folderPicker}*/}
                        {/*    />*/}
                        {/*</IntlProvider>*/}




                        {/*<IntlProvider locale="en">*/}
                        {/*    <ContentPicker*/}
                        {/*        features={FEATURES}*/}
                        {/*        rootFolderId={FOLDER_ID}*/}
                        {/*        token={this.props.userDetails.boxAccessToken}*/}
                        {/*        {...PROPS}*/}
                        {/*    />*/}
                        {/*</IntlProvider>*/}

                    </div>

                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={this.handleCloseDialog}
                        variant="contained"
                        color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

MyDocumentsDialog.propTypes = {
    userDetails: PropTypes.object.isRequired,
    handleMyDocumentsClose: PropTypes.func.isRequired,
    myDocumentsFolder: PropTypes.string.isRequired
};

export default MyDocumentsDialog;