import React, {Component} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from "prop-types";
import { enqueueSnackbar } from 'notistack'
import CircularProgress from '@mui/material/CircularProgress';

const styles = theme => ({});

const INITIAL_STATE = {
    isFetching: false
};

class DownloadFile extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;

        //window.location.pathname.toLowerCase().includes("debug") && console.log ('DownloadFile props=', props);
    }

    download = async () => {

        const actionConfig = this.props.downloadFileActionConfig;

        this.setState({isFetching: true});

        let pathVar = "";
        let params = "";
        let url = "";
        let request = {};

        //Build array of ids from the search results
        let files = [];
        files.push(this.props.boxDocID);

        let fileName = this.props.fileName;

        if (window.REACT_APP_DOCUMENT_SOURCE === "elastic") {
            if (actionConfig.preSignedUrl && actionConfig.preSignedUrl.enabled ) {
                url = this.props.fileUrl
                fileName = this.props.fileName ? this.props.fileName.replace(/\.[^/.]+$/, ".pdf") : ""
            } else {
                url = window.REACT_APP_CONTENT_API_BASE_URL + "/api/elastic/document/content/" + this.props.boxDocID;
                request = {
                    headers: {
                        "Authorization": "Bearer " + this.props.userDetails.accessToken
                    }
                }
                const caseTokenRequired = window.REACT_APP_CASE_TOKEN_REQUIRED === 'true';
                if (caseTokenRequired) {
                    request.headers["case-token"] = this.props.userDetails.caseAccessToken;
                }
            }
        } else {
            pathVar = "/download";
            params = "?userId=" + this.props.userDetails.boxId;
            url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params;
            request = {
                method: 'POST',
                headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken},
                body: files
            };
        }

        window.location.pathname.toLowerCase().includes("debug") && console.log ('url = ', url, 'request = ', request);

        await this.props.triggerRefreshAuthToken();
        fetch(url, request )
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('response.ok - download RESPONSE = ', response);
                    return (response.blob())
                } else {
                    return null
                }
            })
            .then(result => {

                const url = URL.createObjectURL(result);
                //const fileName = this.props.fileName ? x.replace(/\.[^/.]+$/, ".pdf") : "";

                // Create a new anchor element
                const a = document.createElement('a');
                a.href = url;
                a.target = "_blank"
                a.download = fileName || 'download';
                a.click();

                this.setState({isFetching: false});
                enqueueSnackbar("Download complete", {variant: 'success'});

            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("download Exception:", e, " url:", url, "request: ", request);
                this.setState({isFetching: false});
                enqueueSnackbar("Error downloading file: " + e, {variant: 'error'});
            })
    };

    render() {

        const {anchorEl} = this.state;
        const progressMessage = "Downloading " + this.props.fileName;
        const tooltipTitle = this.props.downloadFileActionConfig.label + " " + this.props.fileName;

        return (
            <React.Fragment>
                <Tooltip title={this.state.isFetching ? progressMessage : tooltipTitle}>
                    <span>

                        <IconButton
                            aria-label = "Menu"
                            color={"secondary"}
                            aria-owns = {anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup = "true"
                            onClick={this.download}
                            disabled = { this.state.isFetching }
                            size="medium"
                        >
                            {
                                this.state.isFetching ?

                                <CircularProgress color="secondary" size={20}/> :

                                <i className='material-icons md-36'>
                                    file_download
                                </i>
                            }

                        </IconButton>

                    </span>
                </Tooltip>
            </React.Fragment>
        );
    }
}

DownloadFile.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    downloadFileActionConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    fileUrl: PropTypes.string //for presigned url
};

export default (withStyles(styles)(DownloadFile));