import React, {Component} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ShareDialog from "../collaborate/ShareDialog";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import ViewLinkDialog from "../document/link/ViewFileLinkDialog";
import Tooltip from '@mui/material/Tooltip';
import {hasAccess} from "../common/helper"

const styles = (theme) => ({});

const menuTheme = (theme) => createTheme(({
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: theme.documentMenu.color,
                    fontSize: '.9rem'
                }
            }
        }
    }
}));

const INITIAL_STATE = {
    anchorEl: null,
    showView: false,
    showShare: false,
    showCommentsWithPreview: false,
    showViewLink: false,
};

class DocumentMenu extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.handleCloseMenu = this.handleCloseMenu.bind(this);
    }


    handleClickMenuIcon = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleCloseMenu = () => {

        //close menu and all dialogs [necessary to close dialogs here to allow them to be reopened]
        this.setState({
            anchorEl: null,
            //showRelatedDocuments: false,
            //showView: false,
            showShare: false,
            //showComments: false,
            //showCommentsWithPreview: false,
            showViewLink: false,
            //showPreview: false,
            //showTasks: false
        });
    };

    handleViewDialog = () => {
        this.setState({
            anchorEl: null,
            showView: true
        });
    };

    handleShareDialog = () => {
        this.setState({
            anchorEl: null,
            showShare: true
        });
    };

    handleViewLink = () => {
        this.setState({
            anchorEl: null,
            showViewLink: true
        });
    };

    render() {

        const { classes } = this.props;
        const { anchorEl } = this.state;

        return (
            <div>
            {
                this.props.actionsConfig.documentDropDownMenu.enabled &&

                hasAccess(this.props.actionsConfig.documentDropDownMenu, this.props.userDetails.userRoles) &&

                <Tooltip title={this.props.actionsConfig.documentDropDownMenu.label}>
                    <IconButton
                        aria-label="Menu"
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        color={"secondary"}
                        onClick={this.handleClickMenuIcon}
                        size="medium">
                        <i className='material-icons'>
                            more_horiz
                        </i>
                    </IconButton>
                </Tooltip>
            }
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={menuTheme}>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleCloseMenu}
                        >
                            {
                                this.props.actionsConfig.share.enabled &&

                                hasAccess(this.props.actionsConfig.share, this.props.userDetails.userRoles) &&

                                <MenuItem className={classes.documentMenu} onClick={this.handleShareDialog}>
                                    <i className={"material-icons"} style={{paddingRight: '10px'}}>folder_shared</i>
                                    {this.props.actionsConfig.share.label}
                                </MenuItem>
                            }

                            {
                                this.props.actionsConfig.viewLinkFile.enabled &&

                                hasAccess(this.props.actionsConfig.viewLinkFile, this.props.userDetails.userRoles) &&

                                <MenuItem className={classes.documentMenu} onClick={this.handleViewLink}>
                                    <i className={"material-icons"} style= {{paddingRight: '10px'}}>link</i>
                                    {this.props.actionsConfig.viewLinkFile.label}
                                </MenuItem>
                            }
                        </Menu>
                    </ThemeProvider>
                </StyledEngineProvider>

            {
                this.state.showShare &&
                <ShareDialog
                    boxDocIds={[this.props.boxDocID]}
                    userDetails={this.props.userDetails}
                    handleCloseMenu={this.handleCloseMenu}
                />
            }

            {
                this.state.showViewLink &&
                <ViewLinkDialog
                    boxDocID={this.props.boxDocID}
                    userDetails={this.props.userDetails}
                    handleCloseMenu={this.handleCloseMenu}
                />
            }
        </div>
        );
    }
}

DocumentMenu.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(DocumentMenu);