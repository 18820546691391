import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar/AppBar";
import Typography from "@mui/material/Typography/Typography";
import FormControl from "@mui/material/FormControl/FormControl";
import RenderMetadataField from "../common/RenderMetadataField";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {withStyles} from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import {hasAccess} from "../common/helper"

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

// this is injected as classes prop into the wrapping component
const styles = theme => ({

    searchTab: {
        minWidth: 80,
        alignItems: "right",
        color: 'red'
    },

    clearTab: {
        minWidth: 80,
    },

});

class SearchTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tabValue: (
                this.props.selectedSearchConfig.folderSearch.searchFields.length > 0 && this.props.advancedSearchFoldersActionConfig.enabled && hasAccess(this.props.advancedSearchFoldersActionConfig, this.props.userDetails.userRoles)) ?
                "folderSearch" :
                "documentSearch"};

        this.handleSearchClick = this.handleSearchClick.bind(this);
    }

    handleSearchClick() {
        if (this.state.tabValue === "folderSearch") {
            if (window.REACT_APP_FOLDER_SOURCE === "elastic"  ) {
                this.props.doCaseSearch(true);
            } else {
                this.props.doSearchMetadata("folder", true);
            }
        } else {
            this.props.doSearchMetadata("file", true)
        }
        this.props.handleClosePopover();
    }

    handleClearClick() {
        alert ('Clear values.... (to be configured)')
    }

    handleTabChange = (event, tabValue) => {
        if (tabValue === "search") {
            this.handleSearchClick()
        } else if (tabValue === "clear") {
            this.handleClearClick()
        } else {
            this.props.handleClearSearch();
            this.setState({ tabValue });
        }
    };

    render() {
        const { classes, ...other } = this.props;
        const { tabValue } = this.state;

       // window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchTemplate props = ', this.props);

        let formValues = {};
        Object.entries(this.props.searchCriteria).forEach(entry => {
            formValues[entry[0]] = entry[1];
        });

        return (
            <div style={{width: '400px'}}>
                <AppBar
                    position="relative"
                    color="secondary"
                >
                    <Tabs
                        value={tabValue}
                        onChange={this.handleTabChange}
                        variant="fullWidth"
                        indcatorcolor="primary"
                        textColor="inherit"
                    >

                        {/*<Tab label = ""*/}
                             {/*classes={{ root: classes.clearTab}}*/}
                             {/*value={"clear"}*/}
                             {/*icon={*/}
                                 {/*<Tooltip title={"Clear"}>*/}
                                     {/*<i className="material-icons md-light">clear</i>*/}
                                 {/*</Tooltip>*/}
                             {/*}*/}
                        {/*/>*/}

                        {
                            (this.props.advancedSearchFoldersActionConfig.enabled && hasAccess(this.props.advancedSearchFoldersActionConfig, this.props.userDetails.userRoles)) &&

                            this.props.selectedSearchConfig.folderSearch.searchFields.length > 0 &&

                                <Tab label = {this.props.selectedSearchConfig.folderSearch.title}
                                     disabled = {this.props.selectedSearchConfig.folderSearch.searchFields.length === 0}
                                     value={"folderSearch"}
                                />
                        }

                        {
                            (this.props.advancedSearchFilesActionConfig.enabled && hasAccess(this.props.advancedSearchFilesActionConfig, this.props.userDetails.userRoles))&&

                            this.props.selectedSearchConfig.documentSearch.searchFields.length > 0 &&

                                <Tab label={this.props.selectedSearchConfig.documentSearch.title}
                                     disabled = {this.props.selectedSearchConfig.documentSearch.searchFields.length === 0}
                                     value={"documentSearch"}/>
                        }

                        <Tab label = ""
                             classes={{ root: classes.searchTab}}
                             value={"search"}
                             icon={
                                 <Tooltip title={"Search"}>
                                     <i className="material-icons md-light">search</i>
                                 </Tooltip>
                             }
                        />

                    </Tabs>

                </AppBar>

                {/*Case (Folder) Search*/}
                {
                    tabValue === "folderSearch" &&
                    <TabContainer>
                        {this.props.selectedSearchConfig.folderSearch.searchFields.map((searchField, index) => {
                            return(
                                <FormControl variant="standard" fullWidth style={{paddingBottom: '10px'}}
                                             key={"fc" + searchField.templateKey + "~" + searchField.metadataKey}>
                                    <RenderMetadataField
                                        {...other}
                                        autoFocus ={index === 0}
                                        fieldValue={(this.props.searchCriteria[searchField.templateKey + "~" + searchField.metadataKey]) ? this.props.searchCriteria[searchField.templateKey + "~" + searchField.metadataKey] : null}
                                        handleOnChange={this.props.handleOnChangeSearchCriteria}
                                        metadataConfig={this.props.metadataConfig}
                                        optionsConfig={this.props.optionsConfig}
                                        metadataKey={searchField.metadataKey}
                                        templateKey={searchField.templateKey}
                                        forceEnable={true}
                                        usage={"search"}
                                        formValues={formValues}
                                        handleSearchClick={this.handleSearchClick}
                                    />

                                </FormControl>
                        )})}
                    </TabContainer>
                }


                {/*File Search*/}
                {
                    tabValue === "documentSearch" &&
                    <TabContainer>

                        {this.props.selectedSearchConfig.documentSearch.searchFields.map(searchField => { return(
                            <FormControl  variant="standard" fullWidth style={{paddingBottom: '10px'}} key={"fc" + searchField.templateKey + "~" + searchField.metadataKey}>
                                <RenderMetadataField
                                    fieldValue={(this.props.searchCriteria[searchField.templateKey + "~" + searchField.metadataKey]) ? this.props.searchCriteria[searchField.templateKey + "~" + searchField.metadataKey] : null}
                                    handleOnChange={this.props.handleOnChangeSearchCriteria}
                                    metadataConfig={this.props.metadataConfig}
                                    optionsConfig={this.props.optionsConfig}
                                    metadataKey={searchField.metadataKey}
                                    templateKey={searchField.templateKey}
                                    forceEnable={true}
                                    usage={"search"}
                                    formValues={formValues}
                                    handleSearchClick={this.handleSearchClick}
                                />
                            </FormControl>
                        )})}
                    </TabContainer>
                }

            </div>
        )
    }
}

SearchTemplate.propTypes = {
    classes: PropTypes.object.isRequired,
    handleOnChangeSearchCriteria: PropTypes.func.isRequired,
    handleClosePopover: PropTypes.func.isRequired,
    doSearchMetadata: PropTypes.func.isRequired,
    doCaseSearch: PropTypes.func.isRequired,
    searchCriteria: PropTypes.array.isRequired,
    selectedSearchConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    advancedSearchFilesActionConfig: PropTypes.object.isRequired,
    advancedSearchFoldersActionConfig: PropTypes.object.isRequired,
    handleClearSearch: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(SearchTemplate);