import React, {useState} from 'react'
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import AddFolderStructureDialog from "./AddFolderStructureDialog";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import ListItem from "@mui/material/ListItem/ListItem";
import Tooltip from "@mui/material/Tooltip/Tooltip";

const styles = theme => ({
    menuListItem: {
        color: theme.menuListItem.colour
    },
    listIcon: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },
});


function AddFolderStructureButton(props) {

    const [showDialog,setShowDialog] = useState(false)

    const closeDialog = () => {
       setShowDialog(false)
    };

    const openDialog = () => {
        setShowDialog(true)
    };

    const { classes, addFolderConfig, ...other } = props;

    return(

        <React.Fragment>
            <Tooltip title={addFolderConfig.tooltip}>
                <ListItem
                    button={true}
                    key={'addFolderStructure' + addFolderConfig.id}
                    id={'addFolderStructure'}
                    onClick={openDialog}
                >
                    <ListItemIcon  className={classes.listIcon}><i className='material-icons'>create_new_folder</i></ListItemIcon>
                    <ListItemText className={classes.menuListItem} primary={addFolderConfig.label}/>
                </ListItem>
            </Tooltip>

            {
                showDialog &&
                <AddFolderStructureDialog
                    {...other}
                    parentClasses={classes}
                    userDetails={props.userDetails}
                    metadataConfig={props.metadataConfig}
                    optionsConfig={props.optionsConfig}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    addFolderConfig={addFolderConfig}
                    closeDialog={closeDialog}
                />
            }

        </React.Fragment>
    )

}

AddFolderStructureButton.propTypes = {
    classes: PropTypes.object,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired,
    addFolderConfig: PropTypes.object.isRequired

};


export default (withStyles(styles, { withTheme: true })(AddFolderStructureButton));
