import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import ViewSharedLinkDialog from "./ViewSharedLinkDialog";

function ViewSharedLinkButton(props) {
    
    const [showDialog, setShowDialog] = useState()
    
    const handleClickIcon = (event) => {
        setShowDialog(true)
    };

    const handleCloseDialog = () => {
        setShowDialog(false)
    };

    const sharedLink = props.sharedLink;

    const url = sharedLink.substring(0,sharedLink.indexOf("/s/")) + "/embed_widget" + sharedLink.substring(sharedLink.indexOf("/s/"));

    return (
        <div>
            <Tooltip title={props.actionConfig.label}>
                <IconButton
                    aria-label="Menu"
                    aria-haspopup="true"
                    onClick={handleClickIcon}
                    color={"secondary"}
                    size="medium">
                    <i className='material-icons'>link</i>
                </IconButton>
            </Tooltip>

            {
                showDialog &&
                <ViewSharedLinkDialog
                    sharedLink={url}
                    handleCloseDialog={handleCloseDialog}
                />
            }

        </div>
    );
}

ViewSharedLinkButton.propTypes = {
    actionConfig: PropTypes.object.isRequired,
    sharedLink: PropTypes.string.isRequired
};

export default ViewSharedLinkButton;