import React, {useState} from "react";
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack'
import {withStyles} from '@mui/styles';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddSubfolderUserDialog from "./AddSubfolderUserDialog";

const styles = (theme) => ({
    disabled: {}, //required for the rule below to work
    root: {
        '&.Mui-disabled': {
            color: theme.disabledField.colour
        },
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    },
    list: {
        width: '100%',
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
    },

    listItem: {
        minWidth: 0,
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
    },
    listContainer: {
        overflow: 'auto',
    },
    listItemTextTransfer: {
        fontSize: theme.typography.pxToRem(16),
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
        //fontSize: '30px',
    },
    button: {
        margin: theme.spacing(0.5, 0),
        width: '100%',
        minWidth: 32
    },

    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

    rootRadio: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconRadio: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIconRadio: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

});

function AddSubfolderUserButton(props) {

    //const debug = window.location.pathname.toLowerCase().includes("debug");
    //debug && console.log ('AddSubfolderUserButton props = ', props);

    const [showDialog, setShowDialog] = useState(false)


    function handleClickIcon() {
        setShowDialog(true)
    }

    function handleCloseDialog() {
        setShowDialog(false)
    }

    //const {classes} = props;
    const actionConfig = props.actionConfig;

    return (
        <div style={{display: "inline"}}>
            <Tooltip title={'Add ' + props.title + ' user'}>
                <IconButton
                    aria-label="Menu"
                    aria-haspopup="true"
                    color = "secondary"
                    onClick={handleClickIcon}
                    size="medium">
                    <i className='material-icons'>
                        person_add
                    </i>
                </IconButton>
            </Tooltip>

            {
                showDialog &&
                <AddSubfolderUserDialog
                    handleCloseDialog={handleCloseDialog}
                    actionConfig={actionConfig}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    userDetails={props.userDetails}
                    folderDetails={props.folderDetails}
                    remountFolderGroupMembersDisplay={props.remountFolderGroupMembersDisplay}
                    groupName={props.groupName}
                />
            }

        </div>
    );
}

AddSubfolderUserButton.propTypes = {
    actionConfig: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    remountFolderGroupMembersDisplay: PropTypes.func.isRequired,
    groupName: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};


export default (withStyles(styles, { withTheme: true })(AddSubfolderUserButton));