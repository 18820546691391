import React from 'react';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import {Security, SecureRoute, LoginCallback} from '@okta/okta-react';
import { OktaAuth,toRelativeUrl } from '@okta/okta-auth-js';
import Home from './Home';
import CustomLoginComponent from '../auth/LoginPage';
import { Amplify , Auth} from 'aws-amplify';
import {  withAuthenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import HomeCognito from "./HomeCognito";
import HomeOkta from "./HomeOkta";

//Add script element for dynatrace
if (window.REACT_APP_DYNATRACE_URL) {
    const script = document.createElement("script");
    script.src = window.REACT_APP_DYNATRACE_URL;
    script.type = "text/javascript";
    script.crossOrigin = "anonymous";
    console.log('append script: ', script)
    document.body.appendChild(script);
} else {
    console.log('Dynatrace not enabled')
}

const authenticateWith = window.REACT_APP_AUTHENTICATE_WITH;
console.log ('authenticateWith=', authenticateWith);

console.log ("REACT_APP_CASE_TOKEN_REQUIRED=", window.REACT_APP_CASE_TOKEN_REQUIRED);
const caseTokenRequired = window.REACT_APP_CASE_TOKEN_REQUIRED === 'true';
console.log ('caseTokenRequired =', caseTokenRequired)

const boxTokenRequired = window.REACT_APP_FOLDER_SOURCE === "box" ||
    window.REACT_APP_DOCUMENT_SOURCE === "box" ||
    window.REACT_APP_TASK_SOURCE === "box" ||
    window.REACT_APP_COMMENT_SOURCE === "box"

console.log ('boxTokenRequired =', boxTokenRequired)
const noTokenRequired = !boxTokenRequired && window.REACT_APP_CASE_TOKEN_REQUIRED === 'false'

console.log ('noTokenRequired = ' , noTokenRequired)

console.log ('REACT_APP_COGNITO_IDENTITY_POOL_ID=', window.REACT_APP_COGNITO_IDENTITY_POOL_ID);
console.log ('REACT_APP_COGNITO_REGION=', window.REACT_APP_COGNITO_REGION);
console.log ('REACT_APP_COGNITO_USER_POOL_ID=', window.REACT_APP_COGNITO_USER_POOL_ID);
console.log ('REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID=', window.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID);

const HasAccessToRouter = () => {

    const history = useHistory(); // example from react-router
    const customAuthHandler = () => {
        // Redirect to the /login page that has a CustomLoginComponent
        history.push('/login');
    };

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    //OpenID Connect configuration
    const oidcConfig = {
        clientId: window.REACT_APP_OKTA_CLIENT_ID,
        issuer: window.REACT_APP_OKTA_BASE_URL + "/oauth2/default",
        redirectUri: window.location.origin + "/implicit/callback",
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: false,
        postLogoutRedirectUri: window.location.origin
    }

    const oktaAuth = new OktaAuth(oidcConfig);

    return (
        <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
        >
            {

                noTokenRequired ?
                    // Use HomeOkta if authenticating with Okta, but no tokens required from security api
                    <>
                        {/*<Route path="/implicit/callback" render={(props) => <LoginCallback {...props}  />} />*/}
                        <Route path="/login" component={CustomLoginComponent} />

                        <SecureRoute exact path="/" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                        <SecureRoute exact path="/debug" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                        <SecureRoute exact path="/dashboard" render={props => <HomeOkta {...props} launchDashboard={true} />} />
                        <SecureRoute path="/file/:id" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                        <SecureRoute path="/compare/:compareId" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                        <SecureRoute path="/upload/:workspace" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                        <SecureRoute path="/folder/:folderId" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                        <SecureRoute path="/search" render={props => <HomeOkta {...props} launchDashboard={false} />} />
                    </> :

                    // Use Home if authenticating with Okta, but tokens are required from security api
                    <>
                        <Route exact path="/" render={props => <Home {...props} launchDashboard={false} />} />
                        <Route path="/implicit/callback" render={(props) => <LoginCallback {...props}  />} />
                        <Route path="/login" component={CustomLoginComponent} />

                        <SecureRoute exact path="/debug" render={props => <Home {...props} launchDashboard={false} />} />
                        <SecureRoute exact path="/dashboard" render={props => <Home {...props} launchDashboard={true} />} />
                        <SecureRoute path="/file/:id" render={props => <Home {...props} launchDashboard={false} />} />
                        <SecureRoute path="/compare/:compareId" render={props => <Home {...props} launchDashboard={false} />} />
                        <SecureRoute path="/upload/:workspace" render={props => <Home {...props} launchDashboard={false} />} />
                        <SecureRoute path="/folder/:folderId" render={props => <Home {...props} launchDashboard={false} />} />
                        <SecureRoute path="/search" render={props => <Home {...props} launchDashboard={false} />} />
                    </>


            }

        </Security>
    );
};

if (authenticateWith === 'cognito') {
    const amplifyConfig = {
        Auth: {

            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
            identityPoolId:  window.REACT_APP_COGNITO_IDENTITY_POOL_ID, //'eu-west-2:4dbd7e4b-0a87-4c86-b68c-7b0b5d93a738',

            // REQUIRED - Amazon Cognito Region 'XX-XXXX-X',
            region: window.REACT_APP_COGNITO_REGION ,//'eu-west-2',

            // OPTIONAL - Amazon Cognito User Pool ID 'XX-XXXX-X_abcd1234',
            userPoolId: window.REACT_APP_COGNITO_USER_POOL_ID, //'eu-west-2_nGa4OByNv',

            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string) 'a1b2c3d4e5f6g7h8i9j0k1l2m3',
            userPoolWebClientId: window.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID, //'b1r2t8mrupe82sq8oi9om3tcu',

            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            //mandatorySignIn: false,

            // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
            // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
            // signUpVerificationMethod: 'code', // 'code' | 'link'

            // OPTIONAL - Configuration for cookie storage
            // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
            // cookieStorage: {
            //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            //     domain: '.yourdomain.com',
            //     // OPTIONAL - Cookie path
            //     path: '/',
            //     // OPTIONAL - Cookie expiration in days
            //     expires: 365,
            //     // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
            //     sameSite: "strict" | "lax",
            //     // OPTIONAL - Cookie secure flag
            //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            //     secure: true
            // },

            // OPTIONAL - customized storage object
            //storage: MyStorage,

            // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
            //authenticationFlowType: 'USER_PASSWORD_AUTH',

            // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
            //clientMetadata: { myCustomKey: 'myCustomValue' },

            // OPTIONAL - Hosted UI configuration
            oauth: {
                clientId: 'oidc',
                domain: window.REACT_APP_COGNITO_DOMAIN, //'https://sanctions-portal-poc-01.auth.eu-west-2.amazoncognito.com/',
                scope: ['openid'],
                redirectSignIn: window.location.href, //'http://localhost:3000/',
                redirectSignOut: window.location.origin, //'http://localhost:3000/',
                //responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
                responseType: 'ALLOW_CUSTOM_AUTH'
            }
        }
    }

    console.log ('old amplifyConfig=', amplifyConfig)
    const authConfig = {Auth:{
            region: window.REACT_APP_COGNITO_REGION,
            userPoolId: window.REACT_APP_COGNITO_USER_POOL_ID,
            userPoolWebClientId: window.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
            mandatorySignIn: false,
            oauth: {
                clientId: 'oidc',
                domain: window.REACT_APP_COGNITO_DOMAIN,
                scope: ['openid'], //'email, 'profile' needed too??

                redirectSignIn: window.location.origin,
                redirectSignOut: window.location.origin,
                //responseType: 'ALLOW_CUSTOM_AUTH', //code ??
                responseType: 'code',
                options: {AdvancedSecurityDataCollectionFlag: true}
            }

        }}
    console.log ('authConfig=', authConfig);
    //Amplify.configure(amplifyConfig);
    //alert ('debug')
    debugger;
    Auth.configure(authConfig);
    Auth.federatedSignIn({ customProvider: 'keycloak-oidc' })
}

function AppOkta() {
    return (
        <div>
            <Router>
                <HasAccessToRouter/>
            </Router>
        </div>
    )
}

function AppCognito({ signOut, user }) {

    console.log ('AppCognito user=', user)
    return (
        <div>
            <React.Fragment>
                <Router>
                    <Route exact path="/" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={false} />} />
                    <Route exact path="/debug" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={false} />} />
                    <Route exact path="/dashboard" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={true} />} />
                    <Route exact path="/file/:id" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={false} />} />
                    <Route exact path="/upload/:workspace" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={false} />} />
                    <Route exact path="/folder/:folderId" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={false}/>} />
                    <Route exact path="/search" render={props => <HomeCognito {...props} user={user} logout={signOut} launchDashboard={false}/>} />
                </Router>

            </React.Fragment>
        </div>
    )
}

export default authenticateWith === 'cognito' ? AppCognito : AppOkta;
