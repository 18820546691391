import React, { useReducer, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack'
import {withStyles} from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import {getErrorMessageFromResponse, getOptionsExternal} from "../../common/helper";
import LinearProgress from "@mui/material/LinearProgress";
import Autocomplete from '@mui/material/Autocomplete';

const styles = (theme) => ({
    disabled: {}, //required for the rule below to work
    root: {
        '&.Mui-disabled': {
            color: theme.disabledField.colour
        },
    },
    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    }

});

function reducer(state, action) {

    const debug = window.location.pathname.toLowerCase().includes("debug")
    debug && console.log ('reducer state =', state, "action =", action);

    switch (action.type) {
        case "INITIAL INPUT": {
            return {
                ...state,
                userInput: action.value,
                initUserInputDone: true
            }
        }

        case "CLOSE":
            return {
                ...state,
                open: false,
            };
        case "HANDLE CHANGE INPUT":
            return ({
                ...state,
                userInput: {
                    ...state.userInput,
                    [action.field] :{
                        ...state.userInput[action.field],
                        value: action.payload
                    }
                }
            })
        case "HANDLE CHANGE INPUT AUTOCOMPLETE":
            return ({
                ...state,
                userInput: {
                    ...state.userInput,
                    [action.field] :{
                        ...state.userInput[action.field],
                        value: action.payload ? action.payload.value : null,
                        valueDisp: action.payload
                    }
                }
            })
        case "PROCESSING":
            return ({
                ...state,
                isProcessing: true,
            })
        case "PROCESSING DONE":
            return ({
                ...state,
                isProcessing: false,
            })
        default:
            return state
    }
}

function ActionDialogCase(props) {

    const debug = window.location.pathname.toLowerCase().includes("debug")
    debug && console.log ('ActionDialogCase props = ', props);

    let initialState =  {
        open: true,
        isProcessing: false,
        userInput: {},
        initUserInputDone: false
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const userInputRequired = props.actionConfig.action.callService.userInput && Object.entries(props.actionConfig.action.callService.userInput).length > 0;

    useEffect(() => {

        async function initUserInput () {

            let userInput = {};
            let callServiceUserInputConfig = JSON.parse(JSON.stringify(props.actionConfig.action.callService.userInput)); //deep clone to prevent updating of config

            if (Object.entries(callServiceUserInputConfig).length > 0) {

                for (const entry of Object.entries(callServiceUserInputConfig)) {

                    //get options
                    let options = [];
                    if (entry[1].options && entry[1].options.length > 0) {
                        options = entry[1].options
                    } else if (entry[1].optionsExternal) {
                        if (Object.entries(entry[1].optionsExternal).length > 0) {
                            options = await getOptionsExternal(entry[1].optionsExternal, props.userDetails, props.triggerRefreshAuthToken)

                            if (options.length === 0) {
                                enqueueSnackbar("No options found for " + entry[0] , {variant: 'error'});
                                debug && console.log ('No options found for ', entry[0]);
                            }
                        }
                    }
                    userInput[entry[0]] = {
                        options: options,
                        value: entry.value ? entry.value : "",
                        label: entry[1].label,
                        type: entry[1].type,
                        placeholder: entry[1].placeholder
                    };
                }
            }

            return (
                dispatch({type: "INITIAL INPUT", value: userInput})
            )

        }

        if (props.actionConfig.action && props.actionConfig.action.callService &&
            props.actionConfig.action.callService.userInput && Object.entries(props.actionConfig.action.callService.userInput).length > 0) {
            initUserInput()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
        //[props.actionConfig, debug, props])

    const actionConfig = props.actionConfig;

    //validate all fields complete
    let allValsEntered = true;
    if (userInputRequired) {
        Object.entries(state.userInput).forEach(entry => {
            if (!entry[1].value || entry[1].value === "") {
                allValsEntered = false;
            }
        })
    }

    function handleCloseDialog(deselectAll, reloadWorkspace) {

        debug && console.log ('ActionDialogCase handleCloseDialog deselectAll=', deselectAll, 'reloadWorkspace=', reloadWorkspace);

        dispatch({type: "CLOSE"})
        props.handleCloseDialog(deselectAll, reloadWorkspace);
    }

    function handleChangeInput (event) {

        dispatch({
            type: "HANDLE CHANGE INPUT",
            field: event.target.name,
            payload: event.target.value,
        })
    }

    function handleChangeInputAutocomplete (newValue, fieldName) {

        //console.log ('***** TODO - handleChangeInputAutocomplete')

        dispatch({
            type: "HANDLE CHANGE INPUT AUTOCOMPLETE",
            field: fieldName,
            payload: newValue,
        })

    }


    function submitMulti (folders) {

        //Call submit for each of selected folders (when action triggered from search results)

        //async/await with forEach() - see https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
        //generic asyncForEach function - pass array and function to execute as parameters
        async function asyncForEach(
            array,
            callback
        ) {
            for (let index = 0; index < array.length; index++) {
                await callback(array[index], index, array);
            }
        }

        //wrap execution of asyncforEach in an async so that we can wait for all to be completed before showing confirmation
        const start = async () => {

            let successCount = 0
            let failCount = 0

            await asyncForEach(
                folders,
                async (folder) => {

                    await submit(folder.id)
                        .then(result => {
                            debug && console.log ('submit result = ', result);
                            if (result) {
                                successCount++
                            } else {
                                failCount++
                            }
                        })
                });

            debug && console.log ('folders.length=', folders.length, 'successCount = ', successCount, 'failCount = ', failCount)

            if (successCount === folders.length) {
                if (folders.length === 1) {
                    enqueueSnackbar('Action successfully completed on selected case', {variant: 'success'});
                } else {
                    enqueueSnackbar('Action successfully completed on all ' + successCount + ' selected cases', {variant: 'success'});
                }
            } else {
                if (failCount === folders.length) {
                    if (folders.length === 1) {
                        enqueueSnackbar('Action failed on selected case,  please retry.', {variant: 'error'});
                    } else {
                        enqueueSnackbar('Action failed on all ' + failCount + ' selected cases,  please retry.', {variant: 'error'});
                    }
                } else {
                    enqueueSnackbar('Action partially successful.  Failed to process ' + failCount + ' of ' + folders.length, {variant: 'error'});
                }
            }

            dispatch({type: "PROCESSING DONE"})

            //Unless all requests failed, close Dialog and refresh search results
            if (failCount !== folders.length) {
                handleCloseDialog(true, actionConfig.reloadWorkspaceOnComplete)
            }

        };

        //start upload
        start();

    }

    async function submit(id) {

        const action = actionConfig.action;

        debug && console.log('submit - action  = ', action);

        const url = window.REACT_APP_BASE_URL_WORKFLOW + action.callService.endpoint;
        const body = {
            case_id: (id)
        }

        if (userInputRequired) {
            Object.entries(state.userInput).forEach(entry => {
                body[entry[0]] = entry[1].value
            })
        }

        await props.triggerRefreshAuthToken();

        const request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + props.userDetails.accessToken,
                "case-token": props.userDetails.caseAccessToken
            },
            body: JSON.stringify(body)
        }

        debug && console.log('submit URL=', url, 'BODY=', body, 'REQUEST=', request);

        dispatch({type: "PROCESSING"})
        let success = false;

        await fetch(url, request)
            .then(response => {
                debug && console.log('submit response=', response);

                if (response.ok) {
                    success = true
                    return(response.json())
                } else {
                    //only show individual errors if single folder failed
                    if (response.status === 401 || props.folderDetails) {
                        Promise.resolve(getErrorMessageFromResponse(response, ''))
                            .then(message => {
                                enqueueSnackbar(message, {variant: 'error'})
                            })
                    }
                    debug && console.log("submit error.  url:", url, "request: ", request, "response.text:", response);
                    return null
                }
            })
            .then(data => {
                //if processing single folder (from case details screen)

                debug && console.log('*** checking if called from folder details screen props.folderDetails=', props.folderDetails);

                if (props.folderDetails) {
                    debug && console.log('*** submit response.json = ', data)
                    enqueueSnackbar("Action successfully completed", {variant: 'success'});
                    dispatch({type: "PROCESSING DONE"})

                    if (data) {

                        debug && console.log('data = ', data)
                        //debug && console.log('props.updateFolderDetails = ', props.updateFolderDetails)

                        //If successful, refresh folder details to reflect new status and any other metadata updates
                        if (props.updateFolderDetails) {
                            props.updateFolderDetails(data)
                        }
                        handleCloseDialog(false, false)
                    }
                }

            })
            .catch(e => {
                success = false;
                //if processing single folder
                if (props.folderDetails) {
                    debug && console.log("case workflow exception:", e);
                    dispatch({type: "PROCESSING DONE"})
                }
                //return success
            })
        return success;
    }

    const {classes} = props;

    return (
        <div>
            <Dialog
                open={state.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth= {actionConfig.dialogSize && actionConfig.dialogSize !== "" ? actionConfig.dialogSize : "md"}
                keepMounted={false}
            >
                <DialogContent style={{paddingTop: "8px"}}>
                    <DialogTitle style={{paddingLeft: "0px"}}>{actionConfig.dialogTitle}</DialogTitle>

                    {
                        (userInputRequired && state.initUserInputDone) || !userInputRequired ?

                            actionConfig.dialogContentText &&
                            <DialogContentText sx={(theme)=>({paddingBottom: theme.spacing(2)})}>{actionConfig.dialogContentText}</DialogContentText> :

                            <LinearProgress variant={"indeterminate"} color={"primary"}/>
                    }

                    {
                        userInputRequired ?

                            Object.entries(state.userInput).map(entry => {
                                const fieldId = entry[0];
                                const options = entry[1].options
                                const defaultProps = {
                                    options: options,
                                    getOptionLabel: (option) => option.label,
                                };
                                    return (
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            style={{paddingBottom: '10px'}}
                                            key={"fc" + fieldId}>
                                            <InputLabel shrink htmlFor={fieldId} className={classes.customLabel}>{entry[1].label}</InputLabel>

                                            {
                                                options && options.length > 0 ?

                                                    <Autocomplete
                                                        {...defaultProps}
                                                        id={fieldId}
                                                        name={fieldId}
                                                        debug
                                                        value={entry[1].valueDisp}
                                                        autoFocus={props.autoFocus}
                                                        onChange={(event, newValue) => {
                                                            handleChangeInputAutocomplete(newValue, fieldId);
                                                        }}
                                                        renderInput={(params) =>
                                                            <TextField variant="standard" {...params} margin="none" />
                                                        }
                                                    /> :

                                                    <TextField
                                                        variant="standard"
                                                        classes={{root: classes.root, disabled: classes.disabled}}
                                                        select
                                                        id={fieldId}
                                                        name={fieldId}
                                                        value={entry[1].value}
                                                        disabled={state.isProcessing}
                                                        type={entry[1].type}
                                                        native={true}
                                                        margin="none"
                                                        autoComplete=""
                                                        autoFocus={false}
                                                        onChange={handleChangeInput}
                                                        multiple={true} />
                                            }

                                        </FormControl>
                                    );
                            })
                            :
                            <React.Fragment/>
                    }

                </DialogContent>

                <DialogActions>
                    {
                        !state.isProcessing &&
                        <Button onClick={() => handleCloseDialog(false, false)} variant="contained" color={"grey"}>
                            Cancel
                        </Button>
                    }
                    <Button onClick={() => props.folderDetails ? submit(props.folderDetails.id) : submitMulti(props.folders)}
                            variant="contained" color="secondary"
                            disabled={!allValsEntered || state.isProcessing || (userInputRequired && !state.initUserInputDone )}>
                        {state.isProcessing ? "Processing..." : "Submit"}
                    </Button>

                </DialogActions>
                {
                    state.isProcessing &&
                    <LinearProgress variant={"indeterminate"} color={"primary"}/>
                }
            </Dialog>
        </div>
    );
}

ActionDialogCase.propTypes = {
    classes: PropTypes.object,
    folderDetails: PropTypes.object, //single actions, triggered from within case
    folders: PropTypes.array, //multi actions, triggered from results table selection
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired,
    updateFolderDetails: PropTypes.func.isRequired,
    searchConfig: PropTypes.object, //required for multi actions
};


export default (withStyles(styles, { withTheme: true })(ActionDialogCase));