import React, {Component} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import MLDialog from "./MLDialogDemo";

const styles = theme => ({
    menuItem: {
        'font-size': '12px',
    },
});

const INITIAL_STATE = {
    showDialog: false,
};

class MLButtonDemo extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.handleCloseMLDialog = this.handleCloseMLDialog.bind(this);
    }


    handleClickMLIcon = (event) => {
        this.setState({
            showDialog: true
        });

    };

    handleCloseMLDialog = (reloadDocuments) => {
        window.location.pathname.toLowerCase().includes("debug") && console.log('MLButtonDemo handleCloseMLDialog  reloadDocuments=', reloadDocuments)
        this.setState({
            showDialog: false
        });

        if (reloadDocuments) {
            if (this.props.unmountFolderDocumentsGet) {
                window.location.pathname.toLowerCase().includes("debug") && console.log('MLDialog closeDialog call this.props.unmountFolderDocumentsGet')
                this.props.unmountFolderDocumentsGet()
            }
            //Update folder details to ensure case actions refreshed
            if (this.props.folderDetails && this.props.updateFolderDetails) {
                this.props.updateFolderDetails(this.props.folderDetails.id)
            }
        }
    };

    render() {

        const { anchorEl } = this.state;

        return (
            <div>
                <Tooltip title={"AI"}>
                    <IconButton
                        aria-label="Menu"
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        color={"secondary"}
                        onClick={this.handleClickMLIcon}
                        size="medium">
                        <i className='material-icons'>
                            find_in_page
                        </i>
                    </IconButton>
                </Tooltip>

                {this.state.showDialog &&
                <MLDialog
                    boxDocID={this.props.boxDocID}
                    userDetails={this.props.userDetails}
                    calledFromDocumentMenu={false}
                    handleCloseMLDialog = {this.handleCloseMLDialog}
                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                    actionsConfig={this.props.actionsConfig}
                    documentDetails={this.props.documentDetails}
                    folderDetails={this.props.folderDetails}
                    disableApproveRejectSnippet={false}
                />}

            </div>
        );
    }
}

MLButtonDemo.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    unmountFolderDocumentsGet: PropTypes.func,
    folderDetails: PropTypes.object, //passed in if opened from SearchResultsDocumentsTableFolderDocs
    documentDetails: PropTypes.object.isRequired,
    updateFolderDetails: PropTypes.func //only if called from folder documents table
};

export default withStyles(styles)(MLButtonDemo);