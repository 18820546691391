import React, {Component} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import PreviewDialog from "./PreviewDialog";

const styles = theme => ({});

const INITIAL_STATE = {
    showDialog: false,
};

class PreviewButton extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }


    handleClickIcon = (event) => {
        this.setState({
            showDialog: true
        });

    };

    handleCloseDialog = (metadataUpdated) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('handleCloseDialog metadataUpdated= ', metadataUpdated);

        this.setState({
            showDialog: false
        });

        //force unmount to refresh folder documents if metadata has changed
        //TODO test it works with paging....
        if (this.props.refreshAfterEditMetadata && metadataUpdated) {
            if (this.props.unmountFolderDocumentsGet) {
                this.props.unmountFolderDocumentsGet();
            }
        }

    };

    render() {

        const { anchorEl } = this.state;

        return (
            <div>
                <Tooltip title={this.props.useEditIcon ? "Edit Metadata" : "Open"}>
                    <IconButton
                        aria-label="Menu"
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClickIcon}
                        color={"secondary"}
                        size="medium">
                        <i className='material-icons'>
                            {this.props.useEditIcon ? "edit" : "open_in_new"}
                        </i>
                    </IconButton>
                </Tooltip>

                {
                    this.state.showDialog &&
                    <PreviewDialog
                        parentClasses={this.props.classes}
                        boxDocID={this.props.boxDocID}
                        userDetails={this.props.userDetails}
                        handleCloseDialog={this.handleCloseDialog}
                        calledFromDocumentMenu={true}
                        metadataConfig={this.props.metadataConfig}
                        optionsConfig={this.props.optionsConfig}
                        workspaceConfig={this.props.workspaceConfig}
                        selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                        actionsConfig={this.props.actionsConfig}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        showMetadataOnOpen={this.props.showMetadataOnOpen}
                        documentDetails={this.props.documentDetails}
                        documentMetadataFields={this.props.documentMetadataFields}
                    />
                }
            </div>
        );
    }
}

PreviewButton.propTypes = {
    classes: PropTypes.object.isRequired,
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    unmountFolderDocumentsGet: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    showMetadataOnOpen: PropTypes.bool,
    useEditIcon: PropTypes.bool,
    refreshAfterEditMetadata: PropTypes.bool,
    documentDetails: PropTypes.object.isRequired, //used by commentsPanel
    documentMetadataFields: PropTypes.array.isRequired
};

export default withStyles(styles)(PreviewButton);