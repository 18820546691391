import React, {Component} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import TasksDialog from "./TasksDialog";

const styles = theme => ({
    menuItem: {
        'font-size': '12px',
    },
});

const INITIAL_STATE = {
    showTasksWithPreview: false,
};

class TasksButton extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }


    handleClickTasksIcon = (event) => {
        this.setState({
            showTasksWithPreview: true
        });

    };

    handleCloseDialog = () => {
        this.setState({
            showTasksWithPreview: false
        });

    };



    render() {

        const { anchorEl } = this.state;

        return (
            <div>
                <Tooltip title={"Tasks"}>
                    <IconButton
                        aria-label="Menu"
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        color={"secondary"}
                        onClick={this.handleClickTasksIcon}
                        size="medium">
                        <i className='material-icons'>
                            assignment
                        </i>
                    </IconButton>
                </Tooltip>

                {this.state.showTasksWithPreview &&
                <TasksDialog
                    parentClasses={this.props.classes}
                    boxDocID={this.props.boxDocID}
                    userDetails={this.props.userDetails}
                    calledFromDocumentMenu={false}
                    handleCloseDialog = {this.handleCloseDialog}
                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                    actionsConfig={this.props.actionsConfig}
                />}

            </div>
        );
    }
}

TasksButton.propTypes = {
    classes: PropTypes.object,
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionsConfig: PropTypes.object.isRequired
};

export default withStyles(styles, {withTheme: true})(TasksButton);