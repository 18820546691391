import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack'
import {withStyles} from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import {getErrorMessageFromResponse, getFieldConfig} from "../../common/helper";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import LinearProgress from "@mui/material/LinearProgress";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";


const styles = theme => ({
    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    },

    dialogContentText: {
        paddingBottom: theme.spacing(2)
    }

});

const INITIAL_STATE = {
    open: false,
    isProcessing: false,
    setMetadata: {},
    callServiceUserInput: {}
};

class ActionDialogDocument extends React.Component {

    constructor(props) {
        super(props);

        delete INITIAL_STATE.setMetadata;
        delete INITIAL_STATE.callServiceUserInput;

        INITIAL_STATE.setMetadata = {};
        if (props.actionConfig.action) {
            if(props.actionConfig.action.setMetadata) {
                props.actionConfig.action.setMetadata.forEach(entry => {
                    INITIAL_STATE.setMetadata[entry.templateKey+"~"+entry.metadataKey]=
                        {
                            options: entry.options,
                            value: entry.options.length > 0 ? "" : entry.value,
                            optionsMultiSelect: entry.optionsMultiSelect
                        };
                })
            }
        }

        INITIAL_STATE.callServiceUserInput = {};
        if (props.actionConfig.action) {
            if(props.actionConfig.action.callService) {
                if (props.actionConfig.action.callService.userInput) {
                    let callServiceUserInputConfig = JSON.parse(JSON.stringify(props.actionConfig.action.callService.userInput)); //deep clone to prevent updating of config
                    Object.entries(callServiceUserInputConfig).forEach(entry => {
                        if (entry[1].options && entry[1].options.length > 0) {
                            INITIAL_STATE.callServiceUserInput[entry[0]] =
                                {
                                    options: entry[1].options,
                                    value: entry[1].options.length > 0 ? (entry[1].optionsMultiSelect ? [] : "") : entry.value,
                                    optionsMultiSelect: entry[1].optionsMultiSelect,
                                    label: entry[1].label,
                                    type: entry[1].type,
                                    placeholder: entry[1].placeholder
                                };
                        } else {
                            INITIAL_STATE.callServiceUserInput[entry[0]] =
                                {
                                    value: entry[1].value,
                                    label: entry[1].label,
                                    type: entry[1].type,
                                    placeholder: entry[1].placeholder
                                };
                        }
                    })
                }
            }
        }


        this.state = INITIAL_STATE;
    }

    componentDidMount() {

        this.setState(
            {
                open: true,
            })
    }

    handleCloseDialog = () => {
        this.setState({
            open: false,
            setMetadata: {},
            callServiceUserInput: {}
        });

        //Added this to address issue of dialog not re-opening after initial open
        if (this.props.handleCloseDialog) {
            this.props.handleCloseDialog();
        }

        if (this.props.unmountSearchResults) {
            this.props.unmountSearchResults()
        }

        //New for Sanctions, need to refresh case details after document action

        if (this.props.actionConfig.refreshFolderDetailsOnComplete) {
            if (this.props.updateFolderDetails) {
                this.props.updateFolderDetails(this.props.folderId)
            }
        }

        console.log ('*** this.props.actionConfig.reloadWorkspaceOnComplete = ', this.props.actionConfig.reloadWorkspaceOnComplete)
        if (this.props.actionConfig.reloadWorkspaceOnComplete) {
            if (this.props.reloadWorkspace && this.props.selectedWorkspaceConfig) {
                console.log('*** proceed with reloading workspace')
                this.props.reloadWorkspace(this.props.selectedWorkspaceConfig)
            }
        }

    };

    handleCancel = () => {

        this.setState({open: false});

        //Added this to address issue of dialog not re-opening after initial open
        if (this.props.handleCloseDialog) {
            this.props.handleCloseDialog();
        }
    }

    handleChangeMetadata = (event) => {
        let amendedState = this.state.setMetadata;
        amendedState[event.target.name].value = event.target.value;
        this.setState({setMetadata: amendedState});
    }


    handleChangeCallServiceUserInput = (event) => {
        let amendedState = this.state.callServiceUserInput;
        amendedState[event.target.name].value = event.target.value;
        this.setState({callServiceUserInput: amendedState});
    }

    handleChangeMultiSelect = (entry, opt) => {

        //used for Ocado generateTranslate service - Need to submit labels and values for selected languages to service as labels used to generate filenames

        //get this option in state and set it to checked

        let amendedState = this.state.callServiceUserInput;
        for (let i=0; i < amendedState[entry[0]].options.length; i++) {
            if (amendedState[entry[0]].options[i].value === opt.value) {
                amendedState[entry[0]].options[i].checked = !amendedState[entry[0]].options[i].checked
                break;
            }
        }

        //set entry value to all checked values
        let newValArr = [];
        for (let i=0; i < amendedState[entry[0]].options.length; i++) {
            if ( amendedState[entry[0]].options[i].checked) {
                newValArr.push(amendedState[entry[0]].options[i])
            }
        }

        amendedState[entry[0]].value = newValArr;

        this.setState({callServiceUserInput: amendedState});

    }

    setMetadata = async (metadataArray) => {

        try {

            window.location.pathname.toLowerCase().includes("debug") && console.log ('setMetadata metadataArray = ' , metadataArray);

            let body = {
                fileName: this.props.documentDetails.name,
                metadata: metadataArray,
            };

            window.location.pathname.toLowerCase().includes("debug") && console.log ("body: " , body);

            await this.props.triggerRefreshAuthToken();
            const params = '?audit=false' ;
            const pathVar = "/" + this.props.documentDetails.id;
            const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params;
            let headers = {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
            }
            if (this.props.userDetails.caseAccessToken) {
                headers["case-token"] = this.props.userDetails.caseAccessToken
            }
            const request = {
                method: 'PATCH',
                headers: headers,
                body: JSON.stringify(body)
            };

            window.location.pathname.toLowerCase().includes("debug") && console.log ('setMetadata url:' , url, 'BODY:', body, 'request:' , request);

            //fetch
            let response = await (fetch(url,request));

            window.location.pathname.toLowerCase().includes("debug") && console.log ('setMetadata response = ', response);

            let response1 = await response.ok;

            if (!response1) {
                enqueueSnackbar("Error " + response.status + " setting metadata " + response.statusText, {variant: 'error'})
            } else {
                enqueueSnackbar("Metadata updated successfully", {variant: 'success'})
            }

            return response1;

        } catch(err) {
            window.location.pathname.toLowerCase().includes("debug") && console.log("setMetadata error: " + err);
            return false;
        }

    };

    callService = async (file) => {

        try {

            const serviceConfig = this.props.actionConfig.action.callService;

            window.location.pathname.toLowerCase().includes("debug") && console.log ('callService file = ' , file , "serviceConfig=", serviceConfig);

            const url= window.REACT_APP_BASE_URL_WORKFLOW + serviceConfig.endpoint;

            let body;

            //array required for translate api
            if (Array.isArray(serviceConfig.body)) {
                body = [];
                let bodyItem = {}
                //check if any values need to be updated with metadata values
                Object.entries(serviceConfig.body[0]).forEach (entry=>{
                    if (entry[1].templateKey && entry[1].metadataKey) {
                        //read value from file
                        bodyItem[entry[0]] = file[entry[1].templateKey +"~"+ entry[1].metadataKey]
                    } else {
                        bodyItem[entry[0]] = entry[1]
                    }
                })


                //add any userInput values, read values from state
                if (serviceConfig.userInput) {
                    Object.entries(serviceConfig.userInput).forEach (entry=>{
                        body[entry[0]] = this.state.callServiceUserInput[entry[0]].value
                    })
                }

                bodyItem.id = file.id;
                bodyItem.filename = file.name;
                bodyItem.folderId = this.props.folderId;
                bodyItem.scope = "enterprise_" + window.REACT_APP_ENTERPRISE_ID;
                body.push(bodyItem)
            } else {
                body={};
                //check if any values need to be updated with metadata values
                Object.entries(serviceConfig.body).forEach (entry=>{
                    if (entry[1].templateKey && entry[1].metadataKey) {
                        //read value from file
                        body[entry[0]] = file[entry[1].templateKey +"~"+ entry[1].metadataKey]
                    } else {
                        body[entry[0]] = entry[1]
                    }
                })

                //add any userInput values, read values from state
                if (serviceConfig.userInput) {
                    Object.entries(serviceConfig.userInput).forEach (entry=>{
                        body[entry[0]] = this.state.callServiceUserInput[entry[0]].value
                    })
                }

                body.id = file.id;
                body.filename = file.name;
                body.folderId = this.props.folderId;
                body.scope = "enterprise_" + window.REACT_APP_ENTERPRISE_ID;

            }

            await this.props.triggerRefreshAuthToken();

            const request = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.props.userDetails.accessToken,
                    "case-token": this.props.userDetails.caseAccessToken
                },
                body: JSON.stringify(body)
            };

            window.location.pathname.toLowerCase().includes("debug") && console.log ('Service url:' , url, 'BODY:', body, 'request:' , request);

            let response = await (fetch(url,request));

            window.location.pathname.toLowerCase().includes("debug") && console.log ('Service response = ', response);

            let response1 = null;
            try {
                response1=await response.json();
            } catch(err) {
                response1 = null
            }

            if (response.status.toString().substring(0,1) !== '2' ) {
                if (response1) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log ('response.json = ', response1);
                    if (response1.errorCode ) {
                        //translate response
                        enqueueSnackbar("Error " + response1.errorCode + " - " + response1.errorDescription, {variant: 'error'})
                    } else {
                        if (response1.message) {
                            //other ocado response
                            enqueueSnackbar("Error " + response1.status + " " + response1.message, {variant: 'error'})
                        } else {
                            enqueueSnackbar("Error " + response1.status + " " + response1.error, {variant: 'error'})
                        }
                    }
                } else {
                    //if no body returned
                    enqueueSnackbar("Error " + response.status + " " + response.statusText, {variant: 'error'})
                }

            } else {
                enqueueSnackbar(this.props.actionConfig.label + " successful", {variant: 'success'})
            }

            return response1;

        } catch(err) {
            window.location.pathname.toLowerCase().includes("debug") && console.log("Service error: " + err);
            enqueueSnackbar(this.props.actionConfig.label + " Error:" + err, {variant: 'error'})
            return false;
        }

    };

    moveDocument = async (destinationFolderId) => {


        console.log ('*** moveDocument to ', destinationFolderId)

        //TODO need to use different endpoint

        const pathVar =  "/" + this.props.documentDetails.id + "/move"
        const params =  "?newParentFolderId=" + destinationFolderId + '&audit=' + false +"&vehicleRegistration=&documentType=";
        const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params ;

        let request = {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            }
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ("moveDocument url:", url, 'request: ', request);

        await this.props.triggerRefreshAuthToken();
        fetch( url, request)
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('moveDocument RESPONSE ok: ', response);
                    return (response.json())
                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('moveDocument RESPONSE not ok: ', response);
                    Promise.resolve(getErrorMessageFromResponse(response, 'moving document'))
                        .then(message => {
                            enqueueSnackbar(message , {variant: 'error'});
                        })
                    this.setState({inProgress: false})
                    window.location.pathname.toLowerCase().includes("debug") && console.log("moveDocument error. url:", url, "request: ", request, "response:", response);
                    return (null)
                }
            })
            .then(result => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('result = ', result);

            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("moveDocument Exception:", e, "url:", url, "request: ", request);
            });

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        return true;
    }

    copyDocument = async (destinationFolderId) => {


        console.log ('*** copyDocument to ', destinationFolderId)

        let url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + "/" + this.props.documentDetails.id + "/copy";

        /*POST /api/box/document/{fileId}/copy, same details as box reference docs apart from the old "type"s needed in jsons*/

        let body = {
            type: "file",
            name: this.props.documentDetails.name,
            parent: {
                type: "folder",
                id: destinationFolderId
            }
        };

        let request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ("copyDocument url:", url, 'request: ', request);

        await this.props.triggerRefreshAuthToken();
        fetch( url, request)
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('copyDocument RESPONSE ok: ', response);
                    return (response.json())
                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('copyDocument RESPONSE not ok: ', response);
                    Promise.resolve(getErrorMessageFromResponse(response, 'moving document'))
                        .then(message => {
                            enqueueSnackbar(message , {variant: 'error'});
                        })
                    this.setState({inProgress: false})
                    window.location.pathname.toLowerCase().includes("debug") && console.log("copyDocument error. url:", url, "request: ", request, "response:", response);
                    return (null)
                }
            })
            .then(result => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('result = ', result);

            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("copyDocument Exception:", e, "url:", url, "request: ", request);
            });

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        return true;
    }

    submit = async () => {

        this.setState({isProcessing: true});
        const actionConfig = this.props.actionConfig.action;

        //Update metadata, if successful then proceed to call custom service if required
        const fieldsToUpdate = actionConfig.setMetadata;
        let vals = this.state.setMetadata;
        let metadataArray = [];
        fieldsToUpdate.forEach(fieldToUpdate => {
            metadataArray.push({
                templateKey: fieldToUpdate.templateKey,
                metadataKey: fieldToUpdate.metadataKey,
                value: vals[fieldToUpdate.templateKey + "~" + fieldToUpdate.metadataKey].value
            })
        })

        //Update Metadata if required
        let response1;
        if (metadataArray.length > 0) {
            let setMetadataResponse = await this.setMetadata(metadataArray);
            response1 = await setMetadataResponse;
        } else {
            response1 = true;
        }


        //Call custom service
        let response2;
        if (Object.entries(actionConfig.callService).length > 0) {
            if (actionConfig.callService.endpoint !== "") {
                let callServiceResponse = await this.callService(this.props.documentDetails);
                response2 = await callServiceResponse;
            } else {
                response2 = true;
            }
        } else {
            response2 = true;
        }

        console.log ('*** response1=', response1, 'response2=', response2)

        //Move or Copy to destination folder
        if (response1 && response2) {
            //If destination folder provided, move to folder
            if (actionConfig.moveDocument && actionConfig.moveDocument.destinationFolderId) {
                console.log ('*** now moveDocument')
                let moveDocumentResponse = await this.moveDocument(actionConfig.moveDocument.destinationFolderId);
                let response3 = await moveDocumentResponse;

                if (response3) {
                    if (response3.success) {
                        enqueueSnackbar("Document moved successfully " , {variant: 'success'});
                        //then close dialog
                        this.handleCloseDialog();
                    } else {
                        this.setState({isProcessing: false});
                    }
                }

            }

            else if (actionConfig.copyDocument && actionConfig.copyDocument.destinationFolderId) {
                console.log ('*** now copyDocument')
                let copyDocumentResponse = await this.copyDocument(actionConfig.copyDocument.destinationFolderId);
                let response3 = await copyDocumentResponse;

                if (response3) {
                    if (response3.success) {
                        enqueueSnackbar("Document copied successfully " , {variant: 'success'});
                        //then close dialog
                        this.handleCloseDialog();
                    } else {
                        this.setState({isProcessing: false});
                    }
                }

            }

            else {
                //no further action, close
                this.handleCloseDialog();
            }
        } else {
            this.setState({isProcessing: false});
            //no further action, close
            this.handleCloseDialog();
        }

    };

    render() {

        const { classes} = this.props;

        ///user input for setMetadata
        let userInputRequired = false;
        let allValsEntered = true;
        //check if user input required
        Object.entries(this.state.setMetadata).forEach(entry => {
            if (entry[1].options && entry[1].options.length > 0) {
                userInputRequired = true;
                if (!entry[1].value || entry[1].value === "") {
                    allValsEntered = false;
                }
            }
        })

        //userInput for callService
        const callServiceUserInput = this.state.callServiceUserInput && Object.entries(this.state.callServiceUserInput).length > 0;
        if (callServiceUserInput) {
            Object.entries(this.state.callServiceUserInput).forEach(entry => {
                if (!entry[1].value || entry[1].value === "" ) {
                    allValsEntered = false;
                }
            })
        }

        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth= {this.props.actionConfig.dialogSize && this.props.actionConfig.dialogSize !== "" ? this.props.actionConfig.dialogSize : "md"}
                    keepMounted={false}
                >
                    <DialogContent style={{paddingTop: "8px"}}>
                        <DialogTitle style={{paddingLeft: "0px"}}>{this.props.actionConfig.dialogTitle}</DialogTitle>

                        {
                            this.props.actionConfig.dialogContentText &&
                            <DialogContentText className={classes.dialogContentText} style={{whiteSpace: 'break-spaces'}}>
                                {
                                    this.state.isProcessing ?
                                        this.props.actionConfig.dialogContentTextProcessing ?
                                            this.props.actionConfig.dialogContentTextProcessing :
                                            "Processing, please wait..." :
                                        this.props.actionConfig.dialogContentText
                                }

                            </DialogContentText>
                        }

                        {/*Add a metadata field for each field specified in config that has options*/}
                        {
                            userInputRequired &&

                            Object.entries(this.state.setMetadata).map(entry => {
                                if (entry[1].options && entry[1].options.length > 0) {

                                    const fieldId = entry[0];
                                    let fieldConfig = getFieldConfig(this.props.metadataConfig, entry[0].substring(0, entry[0].indexOf("~")), entry[0].substring(entry[0].indexOf("~")+1));

                                    return (
                                        <FormControl
                                            variant="standard"
                                            fullWidth
                                            style={{paddingBottom: '10px'}}
                                            key={"fc" + fieldId}>
                                            <InputLabel shrink htmlFor={fieldId} className={classes.customLabel}>{fieldConfig.label}</InputLabel>
                                            <TextField
                                                variant="standard"
                                                classes={{root: classes.root, disabled: classes.disabled}}
                                                select
                                                id={fieldId}
                                                name={fieldId}
                                                value={entry[1].value}
                                                disabled={this.state.isProcessing}
                                                type={fieldConfig.type}
                                                native={true}
                                                margin="none"
                                                autoComplete=""
                                                autoFocus={false}
                                                onChange={this.handleChangeMetadata }
                                                multiple={true}>
                                                {entry[1].options.length > 0 && <MenuItem value="">{'- ' + fieldConfig.placeholder+ ' -'}</MenuItem>}
                                                {entry[1].options.map(opt => {
                                                    return (
                                                        <MenuItem id={opt.value} value={opt.value} key={opt.value}>
                                                            {opt.label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        </FormControl>
                                    );

                                    }
                                else {
                                    return (<React.Fragment/>)
                                }
                            })
                        }

                        {
                            callServiceUserInput ?
                                //e.g. generateTranslate for Ocado
                                Object.entries(this.state.callServiceUserInput).map(entry => {

                                    console.log ('entry = ', entry);

                                    const fieldId = entry[0];

                                    if (entry[1].options && entry[1].options.length > 0) {

                                        if (entry[1].optionsMultiSelect) {
                                            return (
                                                <FormControl variant="standard" component="fieldset">
                                                    <FormLabel component="legend" className={classes.dialogContentText}>{entry[1].label}</FormLabel>
                                                    <FormGroup>
                                                        {entry[1].options.map(opt => {
                                                            return (
                                                                <FormControlLabel
                                                                    control={<Checkbox  color="secondary"
                                                                                        checked={opt.checked}
                                                                                       onChange={() => this.handleChangeMultiSelect(entry, opt)}
                                                                                       name={opt.value}/>}
                                                                    label={opt.label}
                                                                />
                                                            )
                                                        })}
                                                    </FormGroup>
                                                </FormControl>
                                            );
                                        } else {
                                            return (
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    style={{paddingBottom: '10px'}}
                                                    key={"fc" + fieldId}>
                                                    <InputLabel shrink htmlFor={fieldId}
                                                                className={classes.customLabel}>{entry[1].label}</InputLabel>
                                                    <TextField
                                                        variant="standard"
                                                        classes={{root: classes.root, disabled: classes.disabled}}
                                                        select
                                                        id={fieldId}
                                                        name={fieldId}
                                                        value={entry[1].value}
                                                        disabled={this.state.isProcessing}
                                                        type={entry[1].type}
                                                        native={true}
                                                        margin="none"
                                                        autoComplete=""
                                                        autoFocus={false}
                                                        onChange={this.handleChangeCallServiceUserInput}
                                                        multiple={true}>
                                                        {entry[1].options.length > 0 && <MenuItem
                                                            value="">{'- ' + entry[1].placeholder + ' -'}</MenuItem>}
                                                        {entry[1].options.map(opt => {
                                                            return (
                                                                <MenuItem id={opt.value} value={opt.value}
                                                                          key={opt.value}>
                                                                    {opt.label}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </TextField>
                                                </FormControl>
                                            );
                                        }
                                    } else {

                                        return (
                                            <FormControl
                                                variant="standard"
                                                fullWidth
                                                style={{paddingBottom: '10px'}}
                                                key={"fc" + fieldId}>
                                                <InputLabel shrink htmlFor={fieldId}
                                                            className={classes.customLabel}>{entry[1].label}</InputLabel>
                                                <TextField
                                                    variant="standard"
                                                    classes={{root: classes.root, disabled: classes.disabled}}
                                                    id={fieldId}
                                                    name={fieldId}
                                                    value={entry[1].value}
                                                    disabled={this.state.isProcessing}
                                                    type={entry[1].type}
                                                    native={true}
                                                    margin="none"
                                                    autoComplete=""
                                                    autoFocus={false}
                                                    onChange={this.handleChangeCallServiceUserInput} />
                                            </FormControl>
                                        );
                                    }
                                })

                                :
                                <React.Fragment/>
                        }

                    </DialogContent>

                    <DialogActions>

                        {
                            !this.state.isProcessing &&
                            <Button onClick={this.handleCancel} variant="contained" color={"grey"}>
                                Cancel
                            </Button>
                        }

                        <Button onClick={this.submit} variant="contained" color="secondary"
                                disabled={((userInputRequired || callServiceUserInput ) && !allValsEntered) || this.state.isProcessing}>
                            {this.state.isProcessing ? "Processing..." : "Submit"}
                        </Button>

                    </DialogActions>
                    {
                        this.state.isProcessing &&
                        <LinearProgress variant={"indeterminate"} color={"primary"}/>
                    }
                </Dialog>
            </div>
        );
    }
}

ActionDialogDocument.propTypes = {
    classes: PropTypes.object,
    documentDetails: PropTypes.object.isRequired,
    folderId: PropTypes.string,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired,
    unmountSearchResults: PropTypes.func.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    updateFolderDetails: PropTypes.func, //only if called from folder documents table
    reloadWorkspace: PropTypes.func,
    selectedWorkspaceConfig: PropTypes.object
};


export default (withStyles(styles, { withTheme: true })(ActionDialogDocument));