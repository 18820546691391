import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Divider from '@mui/material/Divider';
import Moment from 'react-moment';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { enqueueSnackbar } from 'notistack'
import ListSubheader from "@mui/material/ListSubheader/ListSubheader";
import FormControl from "@mui/material/FormControl/FormControl";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Input from "@mui/material/Input/Input";
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {getInitials, getColour, getErrorMessageFromResponse} from "../common/helper";

function DisplayComments(props) {
    if (props.comments)  {
        return(
            props.comments.map(comment => {
                return(
                    <div key={"div" + comment.id}
                    >
                        <ListItem key = {"li" + comment.id}>
                            <ListItemIcon key = {"liIcon" + comment.id}>
                                <Avatar key = {"avatar" + comment.id} style={{backgroundColor: getColour(comment.created_by.name)}}>
                                    {getInitials(comment.created_by.name)}
                                </Avatar>
                            </ListItemIcon>
                            <ListItemText
                                key = {"liText" + comment.id}
                                primary={
                                    <React.Fragment key = {"fragment" + comment.id}>
                                        <Typography
                                            key = {"typog" + comment.id}
                                            component="span"
                                            variant="body2"
                                            //className={classes.inline}
                                            color="textPrimary">
                                            {comment.created_by.name}
                                        </Typography>
                                    </React.Fragment>}
                                secondary={
                                    <React.Fragment key = {"fragment1" + comment.id}>
                                        <Moment key = {"moment" + comment.id} fromNow>{comment.created_at}</Moment>
                                        <br/>
                                        {comment.message}
                                    </React.Fragment>}>
                            </ListItemText>

                        </ListItem>
                        <Divider key = {"divider" + comment.id} variant="inset" component="li" />
                    </div>
                )
            }))
    } else {
        //in case of error retrieving comments
        return(<span/>)
    }
}

DisplayComments.propTypes = {
    comments: PropTypes.array.isRequired
};

function InputComment(props) {
    return (
        <ListItem button={false}>
            <ListItemIcon>
                <Avatar style={{backgroundColor: getColour(props.userName)}}>{getInitials(props.userName)}</Avatar>
            </ListItemIcon>
            <ListItemText>
                <FormControl  variant="standard" style={{paddingTop: '40px', width: '100%'}}>
                    <InputLabel htmlFor="inputComment">Comment</InputLabel>
                    <Input
                        id="inputComment"
                        name="inputComment"
                        value={props.inputCommentValue}
                        placeholder="Write a comment"
                        type="text"
                        margin="none"
                        variant="outlined"
                        autoComplete=""
                        onChange={props.handleOnChangeComment}
                        style={{paddingTop: 16}}
                        autoFocus = {true}/>
                </FormControl>
            </ListItemText>
            <ListItemSecondaryAction>
                <Tooltip title={"Post"}>
                    <IconButton
                        aria-label="Post"
                        onClick={props.postComment}
                        disabled={props.inputCommentValue === ""}
                        color = "secondary"
                        size="medium">
                        <i className='material-icons'>
                            send
                        </i>
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
InputComment.propTypes = {
    inputCommentValue: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    handleOnChangeComment: PropTypes.func.isRequired,
    postComment: PropTypes.func.isRequired
};

const INITIAL_STATE = {
    comments: [],
    inputComment: ""
};

class FolderComments extends React.Component {

    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;
        this.getFolderComments = this.getFolderComments.bind(this);

    }

    UNSAFE_componentWillMount() {
        this.getFolderComments();
    }

    componentDidMount(){
        this.setState({ open: true });

        //auto-refresh comments at interval set in config
        this.interval = setInterval(() => this.getFolderComments(), this.props.folderCommentsConfig.pollingIntervalMilliseconds);

    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    getFolderComments = async () => {

        this.setState({isFetching: true});

        const pathVar = this.props.boxFolderID;
        const url = window.REACT_APP_COMMENT_API_BASE_URL + window.REACT_APP_COMMENT_API_FOLDER + "/" + pathVar;
        const request = {
            headers: {"Authorization": "Bearer " + this.props.userDetails.accessToken}
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ("getFolderComments:", url, 'request: ', request);

        await this.props.triggerRefreshAuthToken();
        fetch(url , request )
            .then(response => {
                window.location.pathname.toLowerCase().includes("debug") && console.log('getFolderComments RESPONSE: ', response);
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving comments'))
                        .then(message => {
                            enqueueSnackbar(message , {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("error.  url:", url, "request: ", request);
                    this.setState({isFetching: false});
                    return ([])
                }
            })
            .then(result => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('getFolderComments result = ', result);
                this.setState({comments: result, isFetching: false})
            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("getFolderComments Exception:", e, " url:", url, "request: ", request);
                this.setState({isFetching: false})
            })

    };

    handleOnChangeComment = event => {

        this.setState({inputComment : event.target.value});
    };

    postComment = async () => {

        const url = window.REACT_APP_COMMENT_API_BASE_URL + window.REACT_APP_COMMENT_API_FOLDER ;

        let body = {
            item : {
                "type" : "file",
                "id" : this.props.boxFolderID
            },
            message: this.state.inputComment,
            type: "comment"
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ('body: ', body);

        let request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ("postComment url:", url, 'request: ', request);

        await this.props.triggerRefreshAuthToken();
        fetch( url, request)
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('postComment RESPONSE ok: ', response);
                    return (response.json())
                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('postComment RESPONSE not ok: ', response);
                    Promise.resolve(getErrorMessageFromResponse(response, 'posting comment'))
                        .then(message => {
                            enqueueSnackbar(message , {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("postComment error. url:", url, "request: ", request, "response:", response);
                    return (null)
                }
            })
            .then(result => {
                if (result !== null){
                    this.setState({inputComment: ""});
                    //setTimeout(function(){ this.getFolderComments(); }, 5000);
                    this.getFolderComments(); //refresh comments
                }

            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("postComment Exception:", e, "url:", url, "request: ", request);
             });

    };


    render() {

        return (
            <div style={{width: "100%"}}>
                <Box border={1} borderColor="#FFF" borderRadius={1} boxShadow={1}>

                    <Typography variant={"h6"} color={"textPrimary"} style={{paddingLeft: "12px", paddingTop: "12px"}}>
                        Comments
                        <Tooltip title={"Refresh comments"}>
                            <IconButton
                                aria-label="Menu"
                                aria-haspopup="true"
                                onClick = {() => this.getFolderComments()}
                                // onClick = {() => this.props.folderSearch(this.props.folderDetails.name, this.props.folderDetails)}
                                color = "secondary"
                                size="medium">
                                <i className='material-icons md-36'>
                                    refresh
                                </i>

                            </IconButton>
                        </Tooltip>
                    </Typography>

                    <List>
                        {
                            this.state.isFetching ?
                                <React.Fragment>
                                    <div>
                                        <Typography variant="subtitle1">
                                            <ListSubheader disableSticky>
                                                Retrieving comments...
                                            </ListSubheader>
                                        </Typography>
                                    </div>
                                </React.Fragment> :

                                this.state.comments.length > 0 ?
                                <List>
                                    <div>
                                        <DisplayComments comments={this.state.comments}/>
                                    </div>
                                </List>:
                                <ListSubheader disableSticky>No Comments</ListSubheader>
                        }
                    </List>

                    <List>
                        <InputComment
                            inputCommentValue={this.state.inputComment}
                            userName={this.props.userDetails.userName}
                            handleOnChangeComment={this.handleOnChangeComment}
                            postComment={this.postComment}
                        />
                    </List>

                </Box>

            </div>
        );
    }
}

FolderComments.propTypes = {
    classes: PropTypes.object.isRequired,
    boxFolderID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    folderCommentsConfig: PropTypes.object.isRequired,
    remountComponent: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired
};

export default ((FolderComments));