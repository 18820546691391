import React from 'react';
import 'box-ui-elements/dist/preview.css';
import 'box-ui-elements/dist/explorer.css';
import PropTypes from 'prop-types';
import ListItem from "@mui/material/ListItem/ListItem";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from '@mui/material/Typography';
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from "@mui/material/Divider";
import LinearProgress from '@mui/material/LinearProgress';
import MLPage from "./MLPage";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";


const styles = theme => ({

    nestedLevel1: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0)
    },

    entityBox: {
        flexShrink: "0",
    },

    listIcon: {
        minWidth: theme.spacing(4),
        alignItems: "right"
    }

})

function ApprovalSummary(props) {

   // const progress = calculateEntityApprovalProgress(props.entity);

    return (
        <Box width="100%" display="flex" alignItems="center">
        {/* <Box display="flex" alignItems="center">*/}
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={props.progress} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(props.progress,)}%`}</Typography>
            </Box>
        </Box>
    );
}

ApprovalSummary.propTypes = {
    classes: PropTypes.object.isRequired,
    entity: PropTypes.object.isRequired,
    progress: PropTypes.number.isRequired
};

class MLEntity extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            isApproving: false,
            isRejecting: false,
            [props.entity.group]: false //collapse on opening
        };
        window.location.pathname.toLowerCase().includes("debug") && console.log('MLEntity props =', props)
    }

    handleExpandCollapse = item => (event) => {

        this.setState(prevState => ({
            [item]: !prevState[item]
        }))

    };

    autoCollapseEntity = () => {

        let entityApprovalComplete = true;
        this.props.entity.pages.forEach((page) => {
            page.snippets.forEach ((snippet) =>{

                const approved = snippet.review_status === "VALID MATCH"
                const rejected = snippet.review_status === "FALSE POSITIVE"
                const actioned = approved || rejected

                if (!actioned)  {
                    entityApprovalComplete = false
                }
            })
        })
        if (entityApprovalComplete && this.state[this.props.entity.group] === true) {
            this.setState({
                [this.props.entity.group] : false
            })
            this.props.autoCollapseType(this.props.typeIndex)
        }

    }

    approve = async (typeIndex, entityIndex) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('MLEntity approve ', "typeIndex:" , typeIndex, "entityIndex:", entityIndex)

        this.setState({isApproving: true})

        await this.props.actionEntity(typeIndex, entityIndex, 'approve');

        this.setState({isApproving: false})

    };

    reject = async (typeIndex, entityIndex) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('MLSnippet reject ', "typeIndex:" , typeIndex, "entityIndex:", entityIndex)

        this.setState({isRejecting: true})

        //let response =
        await this.props.actionEntity(typeIndex, entityIndex, 'reject');

        this.setState({isRejecting: false})

        this.props.autoCollapseType(typeIndex);

    };

    render () {

        const {classes} = this.props;
        const typeIndex = this.props.typeIndex;
        const entityIndex = this.props.entityIndex;
        const entity = this.props.entity;

        let entityApprovalComplete = true;
        let entityTotalCount = 0;
        let totalApproved = 0;
        let totalRejected = 0;
        let errorCount = 0;

        entity.pages.forEach((page) => {
            page.snippets.forEach ((snippet) =>{
                const approved = snippet.review_status === "VALID MATCH"
                const rejected = snippet.review_status === "FALSE POSITIVE"
                const actioned = approved || rejected

                entityTotalCount++
                if (actioned) {
                    if (approved) {
                        totalApproved ++
                    } else if (rejected) {
                        totalRejected ++
                    }
                } else {
                    entityApprovalComplete = false
                    if (snippet.approveError || snippet.rejectError) {
                        errorCount++
                    }
                }

            })
        })

        const progress = entityTotalCount === 0 ? 100 : (totalApproved + totalRejected) / entityTotalCount  * 100;
        const disableApprove = !this.props.enableApproveRejectSnippet;
        const disableReject = !this.props.enableApproveRejectSnippet;

        return (
            <React.Fragment key={"li_" + this.props.entity.group}>
                <Divider light variant="inset" component="li" />
                <ListItem button disableGutters={true} className={classes.nestedLevel1} key={"li_" + this.props.entity.group} onClick={this.handleExpandCollapse(this.props.entity.group)}>
                    <React.Fragment>
                        <Box width={250} className={classes.entityBox} alignItems="center">
                            <ListItemText className={classes.nestedLevel1}
                                  primary={this.props.entity.group}
                                  primaryTypographyProps={{ style: {fontWeight: "bold"} }}
                                  secondary={entityTotalCount  + " item" + (entityTotalCount !== 1 ? "s" : "")}
                            />
                        </Box>
                        <ApprovalSummary classes={classes} entity={this.props.entity} progress={progress}/>
                        {
                            !entityApprovalComplete &&
                                <React.Fragment>
                                    <Tooltip title={ disableApprove ? "" : "Valid Match"} >
                                        <span>
                                            <IconButton
                                                disabled={this.props.disableApproveRejectSnippet}
                                                className={classes.button}
                                                color="inherit"
                                                aria-label="Valid Match"
                                                onClick={() => this.approve(typeIndex,entityIndex)}
                                                size="medium">
                                                {this.state.isApproving ? <CircularProgress style={{paddingTop:"5px"}} color="primary" size={20}/> : <i className="material-icons">check_circle</i>}
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Tooltip title= {disableReject ? "" :"False Positive"}>
                                        <span>
                                        <IconButton
                                            disabled={this.props.disableApproveRejectSnippet}
                                            className={classes.button}
                                            color="inherit"
                                            aria-label="False Positive"
                                            onClick={() => this.reject(typeIndex, entityIndex)}
                                            size="medium">
                                        {this.state.isRejecting ? <CircularProgress style={{paddingTop:"5px"}} color="primary" size={20}/> : <i className="material-icons">cancel</i>}
                                        </IconButton>
                                        </span>
                                    </Tooltip>
                                </React.Fragment>
                        }

                        {
                            errorCount > 0 &&
                                <Tooltip title={errorCount + " unsuccessful updates"}>
                                    <IconButton
                                        className={classes.button}
                                        style={{color:"#FF0000"}}
                                        aria-label="Error"
                                        size="medium">
                                        <i className="material-icons">error</i>
                                    </IconButton>
                                </Tooltip>
                        }
                    </React.Fragment>
                </ListItem>
                <Collapse in={this.state[this.props.entity.group]} timeout="auto" unmountOnExit>
                    <List className = {classes.list}>
                        {
                            this.props.entity.pages.map((page, pageIndex) => {
                                return (
                                    <MLPage
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                        autoCollapseEntity={this.autoCollapseEntity}
                                        selectPage={this.props.selectPage}
                                        typeIndex={this.props.typeIndex}
                                        resultId={this.props.resultId}
                                        page={page}
                                        pageIndex={pageIndex}
                                        entityIndex={this.props.entityIndex}
                                        boxDocID={this.props.boxDocID}
                                        userDetails={this.props.userDetails}
                                        changePageNumber={this.props.changePageNumber}
                                        actionSnippet={this.props.actionSnippet}
                                        isApprovingEntity={this.state.isApproving}
                                        isRejectingEntity={this.state.isRejecting}
                                        enableApproveRejectSnippet={this.props.enableApproveRejectSnippet}
                                        disableApproveRejectSnippet={this.props.disableApproveRejectSnippet}
                                        enableAddComment={this.props.enableAddComment}
                                    />
                                )
                            })
                        }
                    </List>
                </Collapse>
            </React.Fragment>
        );

    };
}

MLEntity.propTypes = {
    actionSnippet: PropTypes.func.isRequired,
    actionEntity: PropTypes.func.isRequired,
    selectPage: PropTypes.func.isRequired,
    typeIndex: PropTypes.number.isRequired,
    typeProgress: PropTypes.number.isRequired,
    entity: PropTypes.object.isRequired,
    entityIndex: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    changePageNumber: PropTypes.func.isRequired,
    autoCollapseType: PropTypes.func.isRequired,
    enableApproveRejectSnippet: PropTypes.bool.isRequired,
    resultId: PropTypes.string.isRequired,
    enableAddComment: PropTypes.bool.isRequired
};

export default (withStyles(styles, { withTheme: true })(MLEntity));