import React, { useState, useEffect} from 'react';
import './App.css';
import PortalApp from "./PortalApp";
import {SnackbarProvider} from 'notistack';
import 'typeface-roboto';
import 'typeface-montserrat';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import PropTypes from "prop-types";
import {asyncForEach} from "../common/helper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import {getTheme, prepareTheme} from "./helper";
import { useOktaAuth } from '@okta/okta-react';
// import { MaterialDesignContent } from 'notistack'
// import { styled } from '@mui/system';

const HomeOkta = props => {

    const debug = window.location.pathname.toLowerCase().includes("debug");

    const {oktaAuth } = useOktaAuth();
    const authState = oktaAuth.authStateManager.getAuthState() //NEW
    const accessToken =  authState && authState.accessToken && authState.accessToken.accessToken;
    const theme = getTheme()
    const portalThemeOptions = prepareTheme(theme);
    const portalTheme = createTheme((portalThemeOptions));

    console.log ('portalTheme = ', portalTheme);
    const drawerWidth = theme.drawerWidth;

    const [workspaceInfo, setWorkspaceInfo] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [errorDetails, setErrorDetails] = useState(null);
    const [initDone, setInitDone] = useState(false);
    const [configFiles, setConfigFiles] = useState({
        actionsConfig: {},
        dashboardsConfig: [],
        metadataConfig: {},
        optionsConfig: {},
        publicLinksConfig: [],
        templatesConfig: {},
        workflowConfig: {folderActions:[],documentActions:[]},
        workspaces: [],
        generalConfig:{}
    })

    // const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    //     '&.notistack-MuiContent-success': {
    //         backgroundColor: '#2D7738',
    //
    //     },
    //     '&.notistack-MuiContent-error': {
    //         backgroundColor: '#970C0C',
    //     },
    // }));


    window.localStorage.setItem('windowUrl', window.location);

    useEffect(() => {

        console.log ('HomeOkta.js')

        window.localStorage.setItem('theme',"");

        async function init() {

            console.log ('init autState=', authState)


            console.log('init - get config then set user info')

            //get config then set user info
            let config = await getConfigFiles();
            debug && console.log('init, got config files config=', config)

            oktaAuth.getUser().then((info) => {

                debug && console.log('user info = ', info)

                let userRoles = []
                userRoles = info[window.REACT_APP_OKTA_GROUP_ATTRIBUTE];
                if (!userRoles) {
                    userRoles = info.groups;
                }

                debug && console.log('init, userRoles=', userRoles)

                const date = new Date();
                const currentTime = date.getTime();
                const expiresIn = currentTime + (1000 * 60 * parseInt(window.REACT_APP_OKTA_REFRESH));

                let workspaces = [];


                if (userRoles.length === 0) {
                    setErrorDetails("No roles assigned");
                } else {
                    //Loop through the Workspaces in config file, if the user has any of the accessroles listed for that workspace then add the Workspace to the list
                    config.workspaces.forEach(ws => {
                        for (let i = 0; i < ws.accessRoles.length; i++) {
                            if (userRoles.includes(ws.accessRoles[i])) {
                                workspaces.push(ws);
                                break;
                            }
                        }
                    })
                    if (workspaces.length === 0) {
                        setErrorDetails("You are not configured to use this application");
                    } else {
                        setWorkspaceInfo(workspaces);
                        setUserDetails({
                            isUserLoggedIn: true,
                            userLoggedOut: false,
                            userName: info.name,
                            userEmail: info.email,
                            expiresIn: expiresIn,
                            boxId: "",
                            userRoles: userRoles,
                            accessToken: accessToken,
                            boxAccessToken: "",
                            caseAccessToken: "",
                            //caseExpiresIn: data && data.expires_in,
                            //caseRefreshToken: data && data.refresh_token,
                            caseUsername: info.name,
                            otherInfo: "...."
                        })
                    }
                }
            })
        }


        init().then (r=>{
            setInitDone(true)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function getConfigFiles() {

        let config = {
            actionsConfig: {},
            dashboardsConfig: [],
            metadataConfig: {},
            optionsConfig: {},
            publicLinksConfig: [],
            templatesConfig: {},
            workflowConfig: {folderActions:[],documentActions:[]},
            workspaces: [],
        };

        // application config - either retrieve from api or pull from local config
        if (window.REACT_APP_CONFIG_API_REQUIRED === "true" || window.REACT_APP_CONFIG_API_REQUIRED === true) {
            const url = window.REACT_APP_CONFIG_API_BASE_URL;
            const request = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + accessToken
                }
            };
            debug && console.log('get config files from service..url: ', url, 'request:', request);

            fetch(url, request)
                .then(response => response.json())
                .then(data => {
                    debug && console.log('getConfigFiles - response.json', data);

                    config = {
                        workspaces: JSON.parse(data.workspace),
                        actionsConfig: JSON.parse(data.action),
                        metadataConfig: JSON.parse(data.metadata),
                        optionsConfig: JSON.parse(data.metadataoption),
                        publicLinksConfig: JSON.parse(data.link),
                        dashboardsConfig: JSON.parse(data.link),
                    };

                })
                .catch(e => {
                    console.log(e);
                    setErrorDetails("Config Retrieval Error" + e);
                })
        } else {

            debug && console.log ('get config from local config')

            const configFilePaths = {
                workspaces: 'config/configWorkspaces.json',
                actionsConfig: 'config/configActions.json',
                metadataConfig: 'config/configMetadata.json',
                optionsConfig: 'config/configMetadataOptions.json',
                publicLinksConfig: 'config/configPublicLinks.json',
                templatesConfig: 'config/configTemplates.json',
                workflowConfig: 'config/configWorkflow.json',
                dashboardsConfig: 'config/configDashboards.json',
                generalConfig: 'config/configGeneral.json'
            };

            const configPrefix =
                window.location.href.indexOf("file") > -1 || window.location.href.indexOf("folder") > -1 || window.location.href.indexOf("upload") > -1 ? "../../" :
                    window.location.href.indexOf("dashboard") > -1 ? "../" :
                        "";

            debug && console.log ('configPrefix=', configPrefix);

            await asyncForEach(Object.entries(configFilePaths), async (entry) => {

                let url = configPrefix + entry[1];

                await fetch(url)
                    .then(response => {
                        return(response.json())
                    })
                    .then(data => {
                        config[entry[0]] =  data;
                    })
                    .catch(e => {
                        debug && console.log("getConfigFiles exception:" , e, " entry: ", entry);
                    })
            })
        }
        debug && console.log('getConfigFiles done, config =  ', config);
        setConfigFiles(config);
        return config
    }

    const refreshAuthToken = async () => {
        //console.log ('refreshAuthToken')
    };

    const logout = async () => {

        window.localStorage.setItem('redirectUrlCode', null);
        window.localStorage.setItem('state', null);
        window.localStorage.setItem('nonce', null);
        window.localStorage.setItem('windowUrl', null);

        await oktaAuth.signOut()
    }



    debug && userDetails && console.log ('userDetails=', userDetails);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={portalTheme}>
                <div>
                    <SnackbarProvider maxSnack={1}>
                        {
                            errorDetails ?
                                <div style={{textAlign: "center", borderColor: '#ddd #ddd #d8d8d8', boxShadow: 'box-shadow: 0 2px 0 hsla(0,0%,68.6%,.12', width: "50%", height: "50%", overflow: 'visible', margin: 'auto', position: 'absolute', top: '0', left: '0', bottom: '0', right: '0'}}>
                                    <img src={window.location.origin + '/images/authlogo.png'} alt="logo"
                                         style={{display: "block", margin: "0 auto", maxWidth: "200px", maxHeight: "40px"}}/><br/><br/>
                                    <Typography variant="h6">{window.REACT_APP_APP_TITLE}</Typography><br/><br/>
                                    <Typography variant="subtitle1">An error has occurred, please retry.</Typography><br/><br/>
                                    <Typography variant="subtitle2">{errorDetails}</Typography><br/><br/>
                                    <Button variant="contained" color="secondary" onClick={logout} style={{width: "200px", marginTop: "20px"}}>Return to login</Button>
                                </div>
                            :
                            initDone && userDetails && workspaceInfo ?
                                <PortalApp
                                    {...props}
                                    userDetails={userDetails}
                                    logout={logout}
                                    triggerRefreshAuthToken = {refreshAuthToken}
                                    configFiles={configFiles}
                                    workspaceConfig={workspaceInfo}
                                    launchDashboard={props.launchDashboard}
                                    drawerWidth={drawerWidth}
                                /> :
                                <React.Fragment>
                                    <LinearProgress style={{backgroundColor: '#808080'}}/>
                                    <div style={{color: '#808080', fontSize: '0.75rem', fontWeight: '500', lineHeight: '24px', paddingLeft: '6px'}}>
                                        {"Loading..."}
                                    </div>
                                </React.Fragment>
                        }
                    </SnackbarProvider>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

HomeOkta.propTypes = {
    launchDashboard: PropTypes.bool.isRequired
};
export default HomeOkta;