import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar/Avatar";
import PropTypes from "prop-types";
import React from "react";
import { ReactComponent as CustomIcon } from './customIcon-1.svg';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

function MetadataValueIcon(props){

    //window.location.pathname.toLowerCase().includes("debug") && console.log ('MetadataValueIcon props = ', props);

    if (props.val && props.val !== "") {

        let valueMapping = {};
        if (props.iconConfig) {
            if (props.iconConfig.valueMapping) {
                valueMapping = props.iconConfig.valueMapping[props.val.toLowerCase()];
                if (!valueMapping) {
                    valueMapping = props.iconConfig.valueMapping[props.val];
                }
            }
        } else {
            window.location.pathname.toLowerCase().includes("debug") && console.log ('MetadataValueIcon props.iconConfig not available.  props = ', props);
        }

       // window.location.pathname.toLowerCase().includes("debug") && console.log ('MetadataValueIcon valueMapping = ', valueMapping);

        if (valueMapping) {
            if (valueMapping.colour ) {
                return (
                    <React.Fragment>

                        {
                            props.iconConfig.icon === "button" ?
                                <Button variant="contained" disabled style={{backgroundColor: valueMapping.colour, color: '#000000', opacity: '0.8'}}>
                                    {props.val}
                                </Button> :

                                <Tooltip
                                    title={(valueMapping.tooltipTitle === "" && props.iconConfig.icon !== "avatar") ? props.val : valueMapping.tooltipTitle}>
                                    {
                                        props.iconConfig.icon === "button" ?
                                            <span key={"avatar"} style={{
                                                backgroundColor: valueMapping.colour,
                                                height: 20,
                                                width: 20,
                                                fontSize: '12px',
                                                display: 'inline-flex'
                                            }}>
                                                {props.val}
                                            </span> :

                                            props.iconConfig.icon === "avatar" ?
                                                <Avatar key={"avatar"} style={{
                                                    backgroundColor: valueMapping.colour,
                                                    height: 20,
                                                    width: 20,
                                                    fontSize: '12px',
                                                    display: 'inline-flex'
                                                }}>
                                                    {props.val}
                                                </Avatar> :

                                                props.iconConfig.icon === "customIcon-1" ?
                                                    //Note, in svg file need fill="current" stroke="current"
                                                    //div required for tooltip to work as a child of Tooltip must be able to hold a ref
                                                    <div style={{display: "inline"}}>
                                                        <IconButton disabled size="medium">
                                                            <CustomIcon fill={valueMapping.colour} style={{
                                                                width: "24px",
                                                                height: "24px"
                                                            }}/>
                                                        </IconButton>
                                                    </div> :
                                                    <div style={{display: "inline"}}>
                                                        <IconButton disabled size="medium">
                                                            <i className='material-icons' style={{
                                                                color: valueMapping.colour,
                                                                fontSize: "inherit"
                                                            }}>
                                                                {props.iconConfig.icon}
                                                            </i>
                                                        </IconButton>
                                                    </div>
                                    }
                                </Tooltip>
                        }

                    </React.Fragment>
                );
            } else {
                return (<span/>)
            }
        } else {
            window.location.pathname.toLowerCase().includes("debug") && console.log ('No value mapping found for :' + props.val.toLowerCase());
            return(<span/>)
        }
    } else {
        return(<span/>)
    }
}

MetadataValueIcon.propTypes = {
    val: PropTypes.string,
    iconConfig: PropTypes.object.isRequired
};

export default (MetadataValueIcon)