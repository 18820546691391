import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import AuditDialog from "./AuditDialog";
import { enqueueSnackbar } from 'notistack'

const styles = theme => ({})

function AuditButton(props) {

    const [showDialog, setShowDialog] = useState(false);

    const handleClickIcon = () => {
        setShowDialog(true)
    };

    const handleCloseDialog = () => {
        setShowDialog(false)
    };

    return (
        <React.Fragment>

            <Tooltip title={"View audit history"}>
                <IconButton
                    className={props.classes.button}
                    color={"secondary"}
                    aria-label={"Menu"}
                    aria-haspopup="true"
                    onClick={handleClickIcon}
                    size="medium">
                    {
                        <i className='material-icons'>info</i>
                    }

                </IconButton>
            </Tooltip>

            {
                showDialog &&
                <AuditDialog
                    userDetails={props.userDetails}
                    handleCloseDialog = {handleCloseDialog}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    caseId={props.caseId}
                    docId={props.docId}
                    resultId={props.resultId}
                    snippetId={props.snippetId}
                    type={props.type}
                />
            }
        </React.Fragment>
    );

}

AuditButton.propTypes = {
    classes: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    unmountSnippet: PropTypes.func, //to refresh counter after adding comment
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    resultId: PropTypes.string,
    snippetId: PropTypes.string,
    docId: PropTypes.string,
    caseId: PropTypes.string,
    type: PropTypes.string.isRequired, //case, document, result, snippet

};

export default (withStyles(styles, { withTheme: true })(AuditButton));