import React, {Component} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import RelatedDocumentsDialog from "./RelatedDocumentsDialog"
import {getFieldConfig} from "../../common/helper";

const styles = theme => ({});

const INITIAL_STATE = {
    showDialog: false,
};

class RelatedDocumentsButton extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }

    handleClickIcon = (event) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('handleClickIcon - folderDetails=', this.props.folderDetails);

        const queryParams = this.props.searchConfig.box.queryParams.concat(this.props.actionsConfig.relatedDocuments.additionalQueryParams);

        //Check if value exists on document details, throw error if not
        let requiredSearchFieldsAvailable = true;
        for (let i = 0; i < queryParams.length; i++)  {
            let val = this.props.documentDetails[queryParams[i].templateKey + "~" + queryParams[i].metadataKey];

            if (!val) {
                if (queryParams[i].mandatory) {
                    requiredSearchFieldsAvailable = false
                    let fieldConfig = getFieldConfig(this.props.metadataConfig, queryParams[i].templateKey, queryParams[i].metadataKey)
                    enqueueSnackbar(fieldConfig.label + " value required for related documents search", {variant: 'error'});
                    window.location.pathname.toLowerCase().includes("debug") && console.log("Unable to find " + queryParams[i].templateKey + "~" + queryParams[i].metadataKey + " value required for document search")
                }
            }
        }

        if (requiredSearchFieldsAvailable) {
            this.setState({showDialog: true});
        }
    };

    handleCloseDialog = () => {
        this.setState({showDialog: false});
    };


    render() {

        const { anchorEl } = this.state;

        return (
            <React.Fragment>

                <Tooltip title={"Related Documents"}>
                    <IconButton
                        aria-label="Menu"
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClickIcon}
                        size="medium" color={"secondary"}
                    >
                        <i className='material-icons'>content_copy</i>
                    </IconButton>
                </Tooltip>

                {
                    this.state.showDialog &&
                    <RelatedDocumentsDialog
                        userDetails={this.props.userDetails}
                        actionsConfig={this.props.actionsConfig}
                        searchConfig= {this.props.searchConfig}
                        metadataConfig= {this.props.metadataConfig}
                        documentDetails={this.props.documentDetails}
                        folderDetails={this.props.folderDetails}
                        handleCloseDialog={this.handleCloseDialog}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                    />
                }

            </React.Fragment>
        );
    }
}

RelatedDocumentsButton.propTypes = {
    userDetails: PropTypes.object.isRequired,
    documentDetails: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    searchConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired
}
export default (withStyles(styles)(RelatedDocumentsButton));