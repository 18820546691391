import Input from "@mui/material/Input";
import React, {useEffect, useReducer, useState} from "react";
import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import { enqueueSnackbar } from 'notistack'
import {getFieldConfig, getObjectByKey, getOptions, getOptionsExternal, getYearOptions} from './helper'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {DatePicker} from "@mui/x-date-pickers";
import 'dayjs/locale/en-gb';
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {withStyles} from '@mui/styles';
import DayPicker, {DateUtils} from 'react-day-picker';
import "react-day-picker/lib/style.css";
import Helmet from 'react-helmet';
import Popover from "@mui/material/Popover";
import {bindPopover, bindTrigger, usePopupState,} from 'material-ui-popup-state/hooks'
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton"
import Button from '@mui/material/Button'
import Tooltip from "@mui/material/Tooltip";
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete, {createFilterOptions}  from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar'
import CardMedia from "@mui/material/CardMedia";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from "@mui/material/ListItemIcon";
import {Typography} from "@mui/material";
import {useTranslation} from 'react-i18next'
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

//MuiInputBase-input-MuiInput-input.Mui-disabled
const styles = (theme) => ({
    //https://material-ui.com/customization/components/
    //disabled: {}, //required for the rule below to work
    //root: {
        // '&.Mui-disabled': {
        //     color: theme.disabledField.colour,
        //     '-webkit-text-fill-color': '#000000',
        //     // '-webkit-text-fill-color': 'rgba(0, 0, 0, 1)'
        // },

        // "& .MuiInputBase-input-MuiInput-input": {
        //     fontSize: 'Inherit'
        // }
        // '& .MuiInputBase-input-MuiInput-input': {
        //     fontSize: '1px',
        //     color: 'red'
        // }
   // },

    // input: {
    //     "& input.Mui-disabled": {
    //         color: theme.disabledField.colour
    //     }
    // },

    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    }

});


const fieldTheme = theme => createTheme(({

    // to merge with parent theme
    ...theme,

    //https://material-ui.com/customization/globals/#css
    //note, overrides the overrides in the parent theme in app.css

    //to fix left padding on date picker toolbar
    components: {
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    paddingLeft: theme.spacing(3)
                },
                gutters: {
                    paddingLeft: theme.spacing(2)
                },
                root: {
                    paddingLeft: "0px"
                }
            }
        },
        //MuiButtonBase-root-MuiIconButton-root - to fix alighment of date input adornment
        MuiIconButton: {
            styleOverrides: {
                root: {
                    marginRight: 0
                }
            }
        }
    }
}));

const filter = createFilterOptions();

function RenderMetadataField(props){

    const debug = window.location.pathname.toLowerCase().includes("debug");
    //debug && console.log('RenderMetadataField ', props.metadataKey , ' props = ', props);

    const { t } = useTranslation();
    const translate = (val) => window.REACT_APP_ENABLE_TRANSLATION === "true" ? t(val) : val

    let fieldConfig = getFieldConfig(props.metadataConfig, props.templateKey, props.metadataKey);

    const useExternalOptions = Object.entries(fieldConfig).length > 0 && fieldConfig.optionsExternal && Object.entries(fieldConfig.optionsExternal).length > 0

    const useYearOptions = fieldConfig && Object.entries(fieldConfig).length > 0 && fieldConfig.useYearOptions ;

    let initialState =  {
        options: [],
        initOptionsDone: false
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    const [fieldValueDisp, setFieldValueDisp] = useState(null);
    const [notInList, setNotInList] = useState(false) //if value not in list


    useEffect(() => {

        debug && console.log (props.metadataKey  ,' - useEffect fieldValue=', props.fieldValue);

        //debug && console.log ('RenderMetadataField props = ', props);

        async function initExternalOptions () {

            let options = [];
            options = await getOptionsExternal(fieldConfig.optionsExternal, props.userDetails, props.triggerRefreshAuthToken, props.formValues )
            debug && console.log (props.metadataKey , ' options=', options)

            initFieldValueDisp(options)
            if (options.length === 0) {
                enqueueSnackbar("No options found for " + fieldConfig.label , {variant: 'error'});
                debug && console.log (props.metadataKey ,' No options found for fieldConfig', fieldConfig);
            }

            return (
                dispatch({type: "INIT OPTIONS", value: options})
            )
        }

        async function initYearOptions () {

            debug && console.log (props.metadataKey ,' initYearOptions')

            let options = [];
            options = await getYearOptions(fieldConfig.previousYears, fieldConfig.futureYears)
            initFieldValueDisp(options)
            debug && console.log (props.metadataKey ,' initYearOptions return dispatch options=', options)

            return (
                //dispatch({type: "YEAR OPTIONS", value: options})
                dispatch({type: "INIT OPTIONS", value: options})
            )

        }

        async function initOptionsWithDependencies () {

            debug && console.log (props.metadataKey , 'initOptionsWithDependencies');

            const dependencies = fieldConfig.optionsDependencies
            let options = getOptions(props.optionsConfig, props.templateKey, props.metadataKey, dependencies, props.formValues,props.usage)
            initFieldValueDisp(options)
            debug && console.log (props.metadataKey ,'initOptionsWithDependencies return dispatch options=', options)
            return (
                dispatch({type: "INIT OPTIONS", value: options})
            )

        }

        async function updateOptionsWithDependencies () {

            debug && console.log (props.metadataKey , 'updateOptionsWithDependencies');

            const dependencies = fieldConfig.optionsDependencies
            let options = getOptions(props.optionsConfig, props.templateKey, props.metadataKey, dependencies, props.formValues,props.usage)
            initFieldValueDisp(options)
            debug && console.log (props.metadataKey ,'updateOptionsWithDependencies return dispatch options=', options)
            return (
                dispatch({type: "UPDATE OPTIONS", value: options})
            )

        }

        function initFieldValueDisp(options) {

            debug && console.log (props.metadataKey , 'initFieldValueDisp fieldValue = ', props.fieldValue, 'options=', options);

            //set initial value for AutoComplete
            let selectedOption = {}
            if (options && options.length > 0) {
                if (props.fieldValue !== null && typeof props.fieldValue !== 'undefined' && props.fieldValue!=='') {
                    selectedOption = getObjectByKey("value", props.fieldValue, options)
                    debug && console.log (props.metadataKey , 'selectedOption = ', selectedOption);

                    if (!selectedOption) {
                        if (fieldConfig.allowValuesNotInList) {
                            selectedOption = {label: props.fieldValue, value: props.fieldValue}
                        } else {
                            debug && console.log (props.metadataKey ,' No option available for value - ', props.fieldValue)
                            setNotInList(true)
                        }

                    } else {
                        //fieldValueDisp
                        debug && console.log (props.metadataKey ,' Set selected option to ', selectedOption)
                        setNotInList(false)
                    }
                }
            }
            setFieldValueDisp( selectedOption ? selectedOption : null)
        }

        let disabled = props.forceDisable ? true : fieldConfig.disabled;
        disabled = props.forceEnable ? false : disabled;


        if (!state.initOptionsDone ){

            debug && console.log (props.metadataKey , 'useEffect initOptions not done ');

            if (!disabled && useExternalOptions) {
                //External Options (e.g. group members)
                initExternalOptions()

            } else if (!disabled && useYearOptions) {
                initYearOptions()

            } else if (!disabled && fieldConfig.optionsDependencies && fieldConfig.optionsDependencies.length > 0) {
                initOptionsWithDependencies()

            } else if (fieldConfig.options) {
                //standard options
                initFieldValueDisp(fieldConfig.options)

                dispatch({type: "INIT OPTIONS", value: fieldConfig.options})

            } else {
                dispatch({type: "INIT OPTIONS", value: []})
            }
        } else {

            debug && console.log (props.metadataKey , 'useEffect initOptions done so update if required for optionsDependencies');

            //updates to optionsWithDependencies (if formValues have changed)
            if (!disabled && fieldConfig.optionsDependencies && fieldConfig.optionsDependencies.length > 0) {
                updateOptionsWithDependencies()
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formValues]) //UPDATE DEPENDENCY OPTIONS IF FORM VALUES CHANGE
    //[ debug, fieldConfig, props])

    function reducer(state, action) {

        debug && console.log (props.metadataKey, ' reducer ', action.type)

        switch (action.type) {

            case "INIT OPTIONS": {

                debug && console.log (props.metadataKey, ' - INIT OPTIONS - ' , action.value)

                //if options have avatar or icons then don't use autoComplete
                let useAutocomplete = true;
                const options = action.value;
                for (const opt of options) {
                    if (opt.avatar || opt.icon) {
                        useAutocomplete = false
                        break;
                    }
                }

                return {
                    ...state,
                    options: options,
                    initOptionsDone: true,
                    useAutocomplete: useAutocomplete
                }
            }

            case "UPDATE OPTIONS": {
                console.log (props.metadataKey, ' - UPDATE OPTIONS - ' , action.value)
                return {
                    ...state,
                    options: action.value
                }
            }

            default:
                return state
        }
    }

    const { classes} = props;

    const handleChangeDate = (id) => (date) => {
        //need separate function to handle dates as the onChange event of the DatePicker doesn't pass the event
        props.handleOnChange( id, date);
    }

    const handleChange = (event) => {

        debug && console.log (props.metadataKey , ' handleChange event = ', event)

        const val =
            event.target.type === "checkbox" ? (event.target.checked ? 1 : 0) :
                event.target.type ==='number' ? parseFloat(event.target.value) :
                    event.target.value;

        props.handleOnChange( event.target.name, val );
    }

    const handleChangeAutocomplete = (newValue) => {
        debug && console.log (props.metadataKey ,' handleChangeAutocomplete newValue=', newValue)
        setNotInList(false)

        //set fieldValueDisp for AutoComplete
        if (typeof newValue === 'string') {
            setFieldValueDisp({
                label: newValue,
            });
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setFieldValueDisp({
                label: newValue.inputValue,
            });
        } else {
            setFieldValueDisp(newValue);
        }

        //update value in parent component

        let val = null;
        if (newValue) {
            val = newValue.inputValue ? newValue.inputValue : //newly added value
                isNaN(newValue.value) ? newValue.value : parseFloat(newValue.value)
            debug && console.log (props.metadataKey, 'val = ', val)
        }

        debug && console.log (props.metadataKey , ' call handleOnChange with val=', val)
        props.handleOnChange( fieldId, val );
    }

    const handleKeypress = (event) => {
        event.preventDefault();
        return false
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if(props.handleSearchClick != null) {
                props.handleSearchClick();
            }
        }
    }

    //=========================================DATE RANGE PICKER===================================

    const initialRange = {
        from: null,
        to: null,
        enteredTo: null
    }

    const [range, setRange] = useState(initialRange);
    const from = range.from;
    const to = range.to;
    const enteredTo = range.enteredTo;
    const disabledDays = !to ? { before: from } : {};
    const selectedDays = [from, { from, to: enteredTo }];
    const fromStr = from ? from.toLocaleDateString() : "";
    const toStr = to ? to.toLocaleDateString() : "";

    const selectedText =
        !to && !from ? "" :
            fromStr + " to " + toStr;

    const helperText = !from && !to ? '' :
        (to && !from) ? 'Please select the start date.':
            (from && !to) ? 'Please select the end date.': '';

    function isSelectingFirstDay(from, to, day) {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
    }

    function handleDayClick(day) {

        debug && console.log ('handleDayClick day=', day, 'range=', range);

        if (from && to && day >= from && day <= to) {
            handleResetClick();
            return;
        }
        if (isSelectingFirstDay(from, to, day)) {
            updateRange({from: day, to: null, enteredTo: null,});
        } else {
            updateRange({from: from, to: day, enteredTo: day,});
            popupState.close()
        }
    }

    function handleDayMouseEnter(day) {
        if (!isSelectingFirstDay(from, to, day)) {
            updateRange({from: from, to: to, enteredTo: day});
        }
    }

    function handleResetClick() {
        setRange(initialRange);
        props.handleOnChange( fieldId, "");
    }

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'datePopover',
    })

    function updateRange(newRange) {
        setRange(newRange);
        props.handleOnChange(fieldId, newRange)
    }

    //==================================================END DATE RANGE PICKER==================================

    let fieldId = props.templateKey + "~" + props.metadataKey;

    //append with row id if field being rendered in a table
    if (props.rowId) {
        fieldId = fieldId + "_" + props.rowId
    }

    if (Object.entries(fieldConfig).length > 0) {

        let disabled = props.forceDisable ? true : fieldConfig.disabled;
        disabled = props.forceEnable ? false : disabled;

        let fieldType = fieldConfig.fieldType;

        if (fieldType === "Input" && (fieldConfig.optionsDependencies || fieldConfig.useYearOptions ) ){
            //Note, need fieldType in metadata config to be Input for the optionsDependencies to show a value in read mode on the Material-Table component in search results
            fieldType = "Select"
        }

        if (disabled && fieldType === "Select") {
            fieldType = "Input"
        }
        const fieldLabel = translate(fieldConfig.label);

        switch (fieldType) {
            case "Date":
                if (props.usage === "search" && fieldConfig.searchRange) {
                    return (
                        <React.Fragment>

                            <InputLabel shrink htmlFor={fieldId} className={classes.customLabel}
                                        required={props.required}>{fieldLabel} Range</InputLabel>
                            <React.Fragment>
                                <Input
                                    classes={{root: classes.root, disabled: classes.disabled}}
                                    sx={(theme) => ({
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: theme.disabledField.colour,
                                        },
                                    })}
                                    id={fieldId}
                                    name={fieldId}
                                    value={selectedText}
                                    disabled={false}
                                    readOnly={true}
                                    type="Input"
                                    margin="none"
                                    variant="outlined"
                                    autoComplete=""
                                    // onChange={handleChangeDateRange}   - doesn't trigger as readOnly=true
                                    error={!helperText === ""}
                                    InputLabelProps={{shrink: true}} //Note: needed for date fields, disabling for now as generates warning in console

                                    endAdornment={
                                        <React.Fragment>
                                            {
                                                (to || from) &&
                                                <InputAdornment position={'start'}>
                                                    <Tooltip title={'Clear selection'}>
                                                        <IconButton aria-label={'clear date selection'}
                                                                    onClick={handleResetClick} size={"small"}>
                                                            <i className="material-icons">clear</i>
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            }
                                            <InputAdornment position="end">
                                                <Tooltip title={'Select date range'}>
                                                    <IconButton aria-label="date range picker"
                                                                size={"small"}{...bindTrigger(popupState)}>
                                                        <i className="material-icons">date_range</i>
                                                    </IconButton>
                                                </Tooltip>
                                                <Popover
                                                    {...bindPopover(popupState)}
                                                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                                                    transformOrigin={{vertical: 'top', horizontal: 'center'}}
                                                    style={{"width": "312px"}}
                                                >
                                                    <React.Fragment>
                                                        <DayPicker
                                                            modifiers={range}
                                                            onDayClick={handleDayClick}
                                                            className="Range"
                                                            numberOfMonths={1}
                                                            fromMonth={from}
                                                            selectedDays={selectedDays}
                                                            disabledDays={disabledDays}
                                                            onDayMouseEnter={handleDayMouseEnter}
                                                        />

                                                        <Button contained className="link"
                                                                disabled={!from && !to}
                                                                onClick={handleResetClick}
                                                                style={{"width": "100%"}}>
                                                            Clear selection
                                                        </Button>

                                                    </React.Fragment>
                                                </Popover>
                                            </InputAdornment>
                                        </React.Fragment>
                                    }
                                />
                                <FormHelperText id="component-helper-text"
                                                error={helperText !== ""}>{helperText}</FormHelperText>
                            </React.Fragment>

                            <Helmet>
                                <style>{`
                                      .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                                        background-color: #f0f8ff !important;
                                        color: #4a90e2;
                                      }
                                      .Range .DayPicker-Day {
                                        border-radius: 0 !important;
                                      }
                                    `}</style>
                            </Helmet>

                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            <InputLabel shrink htmlFor={fieldId} className={classes.customLabel} required={props.required}>{fieldLabel}</InputLabel>
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={fieldTheme}>
                                    <DatePicker
                                        // sx={{ fontSize: "2px" }}
                                        sx={(theme) => ({
                                            fontSize: "2px",
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: theme.disabledField.colour,
                                            },
                                        })}
                                        InputProps={{ classes: {root: classes.root, disabled: classes.disabled}}}
                                        onChangeRaw={handleKeypress} //required to prevent typing in field
                                        onKeyPress={handleKeypress}
                                        margin="none"
                                        id={fieldId}
                                        inputFormat="dd/MM/yyyy"
                                        value = {props.fieldValue === "" ? null : (props.fieldValue)}
                                        onChange = {!disabled ? handleChangeDate(fieldId) : null}
                                        onKeyDown={handleKeyDown}
                                        KeyboardButtonProps={{'aria-label': 'change date',}}
                                        disabled = {disabled }
                                        autoFocus={props.autoFocus}
                                        slotProps={{ textField: { variant: 'standard' }}}
                                        renderInput={props => <TextField
                                            variant="standard"
                                            label={fieldLabel}
                                            helperText={props.helperText ? props.helperText : ""} /> }
                                    />
                                </ThemeProvider>
                            </StyledEngineProvider>
                        </React.Fragment>
                    );
                }
            case "Input":
                return (
                    <React.Fragment>
                        <InputLabel shrink htmlFor={fieldId} className={classes.customLabel}  required={props.required}>{fieldLabel}</InputLabel>
                        <Input
                            classes={{root: classes.root, disabled: classes.disabled}}
                            sx={(theme) => ({
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: theme.disabledField.colour,
                                },
                            })}
                            id={fieldId}
                            name={fieldId}
                            value={props.fieldValue ? props.fieldValue : ""}
                            disabled={disabled}
                            type={fieldConfig.type}
                            margin="none"
                            autoComplete=""
                            onChange={!disabled ? handleChange : null}
                            onKeyDown={handleKeyDown}
                            multiline={fieldConfig.multiline}
                            autoFocus={props.autoFocus}
                            helperText={props.helperText ? props.helperText : ""}
                            //InputLabelProps={{shrink: true}} //Note: needed for date fields, disabling for now as generates warning in console
                            required={props.required}
                            startAdornment={
                                <React.Fragment>
                                    {
                                        (fieldConfig.currencySymbol && fieldConfig.currencySymbol !== "") &&
                                        <InputAdornment position={'start'}>
                                            {fieldConfig.currencySymbol}
                                        </InputAdornment>
                                    }
                                </React.Fragment>
                            }
                        />
                    </React.Fragment>);
            case "Select":

                let dependencies = fieldConfig.optionsDependencies;
                let options = state.options;

                // AG check if custom options are being passed in for folder seearch in the workspace config...
                if (props.selectedSearchConfig && props.selectedSearchConfig && props.selectedSearchConfig.folderSearch && props.selectedSearchConfig.folderSearch.searchFields){
                    let searchFieldsConfig = props.selectedSearchConfig.folderSearch.searchFields;
                    for (var i=0; i < searchFieldsConfig.length; i++) {
                        if (searchFieldsConfig[i].metadataKey === props.metadataKey && searchFieldsConfig[i].templateKey === props.templateKey) {
                            if(searchFieldsConfig[i].options){
                                options = searchFieldsConfig[i].options;
                            }
                        }
                    }
                }

            function validate(dependencies, metadataConfig, options){
                let missingValues = [];
                for (let i = 0; i < dependencies.length; i++) {
                    if (!dependencies[i].value && !dependencies[i].optional) {
                        let fieldConfig = getFieldConfig(metadataConfig, dependencies[i].templateKey, dependencies[i].metadataKey);
                        if (Object.entries(fieldConfig).length > 0) {
                            missingValues.push(fieldConfig.label);
                        }
                    }
                }
                if (missingValues.length > 0) {
                    if (missingValues.length === 1) {
                        enqueueSnackbar('Please select a value for ' + missingValues.slice(-1) + ' before selecting a ' + fieldConfig.label, {variant: 'info'});
                    } else {
                        enqueueSnackbar('Please select a value for ' + missingValues.slice(0, missingValues.length - 1).join(', ') + " and " + missingValues.slice(-1) + ' before selecting a ' + fieldConfig.label, {variant: 'info'});
                    }
                } else if (missingValues.length === 0 && options.length ===0 ){
                    //no required field values missing but options still 0
                    //enqueueSnackbar('No options available for ' + fieldConfig.label, {variant: 'info'});
                }
            }

                const defaultProps = {
                    options: options,

                    filterOptions: (options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (fieldConfig.allowValuesNotInList) {
                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    label: `Add "${params.inputValue}"`,
                                });
                            }
                        }

                        return filtered;
                    },

                    // getOptionLabel: (option) => option.label,

                    getOptionLabel: (option) => {

                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option

                        //return option.label;
                        return option.label || ""

                    },

                    //renderOption: (option) => option.label
                    renderOption: (props, option) => <li {...props}>{option.label}</li>



                };

                console.log (props.metadataKey, 'defaultProps=' , defaultProps)
                console.log (props.metadataKey, 'fieldValueDisp=' , fieldValueDisp)

                return (
                    <React.Fragment>
                        <InputLabel shrink htmlFor={fieldId} className={classes.customLabel} required={props.required}>{fieldLabel}</InputLabel>
                        {/*Changed Select with <option  to TextField with '<MenuItem select 'to improve styling, bringing it in line with inline editing in search results table*/}
                        {
                            (!state.initOptionsDone )  ?
                                //Show disabled field while retrieving options

                                // <LinearProgress variant={"indeterminate"} color={"secondary"}/>
                                //Show disabled field if external lookup not complete

                                <React.Fragment>
                                    <Input
                                        classes={{root: classes.root, disabled: classes.disabled}}
                                        sx={(theme) => ({
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: theme.disabledField.colour,
                                            },
                                        })}
                                        id={fieldId}
                                        name={fieldId}
                                        value={props.fieldValue}
                                        disabled={true}
                                        type="Input"
                                        margin="none"
                                        variant="outlined"
                                        autoComplete=""
                                        autoFocus={props.autoFocus}
                                        onChange={null}
                                        onKeyDown={handleKeyDown}
                                        // style={{paddingTop: 16}}
                                        multiline
                                        InputLabelProps={{shrink: true}} //Note: needed for date fields, disabling for now as generates warning in console
                                    />
                                </React.Fragment>

                                :
                                state.useAutocomplete ?

                                    <Autocomplete
                                        {...defaultProps}
                                        value={fieldValueDisp}
                                        onChange={(event, newValue) => {
                                            handleChangeAutocomplete(newValue);
                                        }}

                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys

                                        id={fieldId}
                                        name={fieldId}
                                        autoFocus={props.autoFocus}
                                        freeSolo = {fieldConfig.allowValuesNotInList}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                       variant={"standard"}
                                                       margin="none"
                                                       error={notInList}
                                                       helperText={notInList ? "'" + props.fieldValue + "' is not a valid option" : props.helperText ? props.helperText : ""}
                                            />
                                        }
                                    /> :
                                    <TextField
                                        variant="standard"
                                        classes={{root: classes.root, disabled: classes.disabled}}
                                        // style={hasAvatarOptions ?  {paddingBottom: '0px', paddingTop: '3px', color: 'red'}: {}}
                                        sx={(theme) => ({
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: theme.disabledField.colour,
                                            },
                                        })}
                                        select
                                        id={fieldId}
                                        name={fieldId}
                                        value={props.fieldValue}
                                        disabled={disabled}
                                        type={fieldConfig.type}
                                        native={true}
                                        margin="none"
                                        autoComplete=""
                                        autoFocus={props.autoFocus}
                                        onClick={ () => { if (options.length === 0) {validate(props.metadataConfig, dependencies,options)}}}
                                        onChange={!disabled ? handleChange : null}
                                        helperText={props.helperText ? props.helperText : ""}>
                                        {
                                            options.length > 0 &&
                                            <MenuItem value= {fieldConfig.type === "number" ? null : ""}>
                                                <Typography variant="inherit">{'- ' + fieldConfig.placeholder+ ' -'}</Typography>
                                            </MenuItem>
                                        }
                                        {options.map(opt => {
                                            return (
                                                <MenuItem id={opt.value} value={opt.value} key={opt.value}>
                                                    {opt.icon ?
                                                        <Tooltip title={opt.label ? opt.label : <i className={'material-icons'} style={{color: '#fff', fontSize: "36px"}}>{opt.icon}</i>}>
                                                            <span style={{width: '100%', display: 'block'}}>
                                                                <i className={'material-icons'} style={{color: '#646464', fontSize: "14px", verticalAlign: "middle", paddingRight: '4px'}}>{opt.icon}</i>
                                                                <Typography variant="inherit" style={{display: "inline"}}>{opt.label}</Typography>
                                                            </span>
                                                        </Tooltip>:
                                                        opt.avatar ?

                                                            <ListItem style={{padding: '0px'}}>
                                                                <Tooltip title={<CardMedia component="img" image={window.location.origin + '/images/' + opt.avatar}/>}>
                                                                    <ListItemIcon style={{minWidth: '32px'}}>
                                                                        <Avatar component={'span'} alt={opt.label} style={{ width: '18px', height: '18px' }} src={window.location.origin + '/images/' + opt.avatar} />
                                                                    </ListItemIcon>
                                                                </Tooltip>
                                                                <ListItemText primary={opt.label} style={{marginTop: '0px', marginBottom: '0px'}} />
                                                            </ListItem>
                                                            :
                                                            opt.label}
                                                </MenuItem>
                                            )
                                        })}
                                    </TextField>

                        }
                    </React.Fragment>
                );
            case "Checkbox":
                return (
                    <FormControlLabel
                        sx={(theme) => ({
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: theme.disabledField.colour,
                            },
                        })}
                        style={{paddingLeft: '16px'}}
                        value={props.fieldValue}
                        checked={props.fieldValue}
                        control={<Checkbox color="secondary" name= {fieldId} onChange={handleChange}/>}
                        label={fieldConfig.label}
                        labelPlacement="end"
                        readOnly={props.forceDisable}
                        disabled={props.forceDisable}
                    />
                )
            default:
                console.log ('Invalid field type:' , fieldType, fieldConfig)
                return (
                    <span>Invalid field type</span>
                )
        }
    } else {
        debug && console.log ("Error retrieving field config for " + props.templateKey + "." + props.metadataKey);
        //enqueueSnackbar("No field configuration found for " + props.templateKey + "." + props.metadataKey, {variant: 'info'});
        //return read only Input field if field config not available
        return (
            <React.Fragment>
                <InputLabel shrink htmlFor={fieldId} className={classes.customLabel} required={props.required}>{fieldId}</InputLabel>
                <Input
                    classes={{root: classes.root, disabled: classes.disabled}}
                    sx={(theme) => ({
                        "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: theme.disabledField.colour,
                        },
                    })}
                    id={fieldId}
                    name={fieldId}
                    value={props.fieldValue}
                    disabled={true}
                    type="Input"
                    margin="none"
                    variant="outlined"
                    autoComplete=""
                    autoFocus={props.autoFocus}
                    onChange={null}
                    onKeyDown={handleKeyDown}
                    // style={{paddingTop: 16}}
                    multiline
                    InputLabelProps={{shrink: true}} //Note: needed for date fields, disabling for now as generates warning in console
                />
            </React.Fragment>
        )
    }
}

RenderMetadataField.propTypes = {
    classes: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    templateKey: PropTypes.string.isRequired,
    metadataKey: PropTypes.string.isRequired,
    fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
    autoFocus: PropTypes.bool, //optional, set in searchTemplate
    rowId: PropTypes.number, //optional
    handleOnChange: PropTypes.func,//only required if field not disabled
    forceDisable: PropTypes.bool, //optional
    forceEnable: PropTypes.bool, //used when component rendered in SearchTemplate
    usage: PropTypes.string, //search / edit / upload - to be required when fully implemented
    formValues: PropTypes.object,  // include existing values - required to be able to implement option dependencies - to be required when fully implemented
    handleSearchClick: PropTypes.func, //optional, only passed from SearchTemplate to facilitate searching on enter
    helperText: PropTypes.string,
    required: PropTypes.bool,
    userDetails: PropTypes.object, //required for external lookups
    triggerRefreshAuthToken: PropTypes.func //required for external lookups
};

export default (withStyles(styles, { withTheme: true })(RenderMetadataField))