import React from 'react';
import 'box-ui-elements/dist/preview.css';
import 'box-ui-elements/dist/explorer.css';
import PropTypes from 'prop-types';
import ListItem from "@mui/material/ListItem/ListItem";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ExpandMore from '@mui/icons-material/ExpandMore';
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import ExpandLess from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from "@mui/material/Divider";
import MLSnippet from "./MLSnippet";

const styles = theme => ({

    nestedLevel2: {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0)
    },

    listIcon: {
        textAlign: "right",
        display:'flex',
        justifyContent:'flex-end'
    }

})

class MLPage extends React.Component {

    constructor(props) {

        super(props);
        this.state = {};
        //window.location.pathname.toLowerCase().includes("debug") && console.log('MLPage props =', props)
    }

    handleExpandCollapse = item => (index,event) => {
        this.setState(prevState => ({
            [item]: !prevState[item]
        }));
        this.props.selectPage(this.props.page.page_number);
    };


    autoCollapsePage = () => {

        let pageApprovalComplete = true;
        this.props.page.snippets.forEach ((snippet) =>{
            const approved = snippet.review_status === "VALID MATCH"
            const rejected = snippet.review_status === "FALSE POSITIVE"
            const actioned = approved || rejected

            if (!actioned)  {
                pageApprovalComplete = false
            }
        })

        if (pageApprovalComplete && this.state[this.props.pageIndex] === true) {
            this.setState({
                [this.props.pageIndex] : false
            })
            this.props.autoCollapseEntity()
        }

    }

    render () {

        const {classes} = this.props;

        return (
            <React.Fragment>
                <Divider light variant="inset" component="li" />
                <ListItem button className={classes.nestedLevel2} key={"li_" + this.props.entityIndex + "_" + this.props.pageIndex} onClick={this.handleExpandCollapse(this.props.pageIndex)}>
                    <ListItemText
                        className={classes.nestedLevel2}
                        primary={this.props.page ? "Page " + this.props.page.page_number : "Page"}
                        primaryTypographyProps={{ style: {fontWeight: "bold"} }}/>
                    <ListItemIcon className={classes.listIcon}>{this.state[this.props.pageIndex] ? <ExpandLess/> : <ExpandMore/>}</ListItemIcon>
                </ListItem>
                <Collapse in={this.state[this.props.pageIndex]}>
                    <List className={classes.list}>
                        {
                            this.props.page.snippets &&
                            this.props.page.snippets.map((snippet, index) => {
                                return (
                                    <MLSnippet
                                        userDetails={this.props.userDetails}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                        typeIndex={this.props.typeIndex}
                                        entityIndex={this.props.entityIndex}
                                        pageIndex={this.props.pageIndex}
                                        snippetIndex={index}
                                        actionSnippet={this.props.actionSnippet}
                                        autoCollapsePage={this.autoCollapsePage}
                                        selectPage={this.props.selectPage}
                                        page={this.props.page}
                                        snippet={snippet}
                                        isApprovingEntity={this.props.isApprovingEntity}
                                        isRejectingEntity={this.props.isRejectingEntity}
                                        actionConfig={this.props.actionConfig}
                                        enableApproveRejectSnippet={this.props.enableApproveRejectSnippet}
                                        resultId={this.props.resultId}
                                        disableApproveRejectSnippet={this.props.disableApproveRejectSnippet}
                                        enableAddComment={this.props.enableAddComment}
                                    />
                                )
                            })
                        }
                    </List>
                </Collapse>
            </React.Fragment>
        )
    }
}

MLPage.propTypes = {
    actionSnippet: PropTypes.func.isRequired,
    selectPage: PropTypes.func.isRequired,
    typeIndex: PropTypes.number.isRequired,
    resultId: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    pageIndex: PropTypes.number.isRequired,
    entityIndex: PropTypes.number.isRequired,
    entityKey: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    isApprovingEntity: PropTypes.bool.isRequired,
    isRejectingEntity: PropTypes.bool.isRequired,
    autoCollapseEntity: PropTypes.func.isRequired,
    enableApproveRejectSnippet: PropTypes.bool.isRequired,
    enableAddComment: PropTypes.bool.isRequired
};

export default (withStyles(styles, { withTheme: true })(MLPage));