import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import {Grid} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Input from "@mui/material/Input/Input";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {getErrorMessageFromResponse} from "../../common/helper";

const styles = theme => ({});

function SignRequestDialog (props)  {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    const [open, setOpen] = useState(true);
    const [prepare, setPrepare] = useState(false)
    const [prepareUrl, setPrepareUrl] = useState("")
    const [signers, setSigners] = useState([{
        id: 1,
        email: "",
        role: "signer"
    }]);
    const [isPosting, setIsPosting] = useState(false);
    const [created, setCreated] = useState(false)
    const showPrepareOption = props.actionConfig && props.actionConfig.showPrepareOption
    const signatories = props.actionConfig && props.actionConfig.signatories && Array.isArray(props.actionConfig.signatories) ? props.actionConfig.signatories : [] ;
    const hasFixedSignatories = signatories.length > 0;

    console.log ('*** signatories=', signatories)
    console.log ('*** hasFixedSignatories=', hasFixedSignatories)

    useEffect(() => {

        console.log ('*** useEffect props=', props)

        if (hasFixedSignatories ) {
            let signerList = [];
            signatories.forEach(signer =>{
                const newSigner = {
                    id: signerList.length + 1,
                    email: signer,
                    role: "signer"
                };
                signerList.push(newSigner);
            })
            setSigners(signerList)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseDialog = (signSubmitted) => {

        //clear values
        setOpen(false);

        //Added this to address issue of dialog not re-opening after initial open
        if (props.handleCloseDialog) {
            props.handleCloseDialog(signSubmitted)
        }
    };

    const handleChangeSigner = (event) => {

        let updatedSignerList = [...signers];
        const whichSigner = Number(event.target.name.substring(event.target.name.indexOf("_") + 1)) - 1;
        const fieldName = event.target.name.substring(0, event.target.name.indexOf("_"));
        updatedSignerList[whichSigner][fieldName] = event.target.value;

        //add a new row if editing final row email
        if (fieldName === "email" && event.target.value !== "") {
            if (whichSigner + 1 === updatedSignerList.length)  {
                let newSigner = {
                    id: updatedSignerList.length + 1,
                    email: "",
                    role: "signer"
                };
                updatedSignerList.push(newSigner);
            }
        }

        setSigners(updatedSignerList)
    };

    const prepareSignRequest = async () => {
        window.open(prepareUrl,"_blank");
        props.handleCloseDialog();
    };

    // const copyToClipboard = (e) => {
    //     textArea.select();
    //     document.execCommand('copy');
    //     e.target.focus();
    //     enqueueSnackbar("URL copied to clipboard", {variant: 'success'})
    // };

    const postSignRequest = async () => {

        console.log ('start postSignRequest')

        setIsPosting(true)

        console.log ('refresh token userDetails:', props.userDetails)
        await props.triggerRefreshAuthToken();
        console.log ('userDetails: ' , props.userDetails)
        
        //const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + "/sign/request/" ;
        const url = "https://api.howden.dev.aifusion.io/content/api/box/document/sign"

        let signersToSubmit = signers.map(({id, ...rest})=> rest);

        console.log(signersToSubmit);
        signersToSubmit.forEach((item, index, arr) => {
            if (item.email === "") {
                arr.splice(index, 1);
            }
        });
        console.log('signersToSubmit = ', signersToSubmit);

        const body = {
            signers: signersToSubmit,
            //signers: [{"email": "dee.marsh@datumsolutions.net"}],
            source_files: [{
                id: props.boxDocID,
                type: "file"
            }],
            parent_folder: {
                "id": "231589257240", //sign requests folder - have relay process to move to signed folder
                "type": "folder",
            },
            //is_document_preparation_needed: false,
            is_document_preparation_needed: prepare,
            //redirect_url: window.location.origin + "/folder/" + props.folderDetails.id
            redirect_url: window.location.origin,
            // email_message: "Please sign this contract",
            // email_subject: "Sonnidex contract for signing"
        };

        console.log ('body=', body);

        const request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };

        debug && console.log ("postSignRequest url=", url, "BODY=", body, 'request: ', request);
        let signRequestSuccess = false;
        fetch( url, request)
            .then(response => {
                if (response.ok) {
                    debug && console.log('postSignRequest RESPONSE ok: ', response);
                    signRequestSuccess = true;
                    try {
                        return response.json();
                    } catch(err) {
                        enqueueSnackbar("Error " + response.status + " posting sign request " + response.statusText, {variant: 'error'});
                        return null
                    }
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'posting sign request'))
                        .then(message => {
                            enqueueSnackbar(message , {variant: 'error'});
                        })
                    debug && console.log("postSignRequest error. url:", url, "request: ", request, "response:", response);
                    return null
                }
            })
            .then(result => {
                debug && console.log('postSignRequest RESPONSE.json: ', result);

                if (signRequestSuccess) {

                    setCreated(true);

                    enqueueSnackbar("Sign request submitted successfully", {variant: 'success'})

                    const prepare_url = result.prepare_url ? result.prepare_url : ""
                    if (showPrepareOption) {
                        setPrepareUrl(prepare_url);
                    }

                    //openPrepareURL(prepare_url)
                    //window.open(prepare_url, "_blank")
                    //
                    // //todo set signRequestPrepareUrl and hasSignRequest on source document
                    let metadataArray = [];
                    const templateKey = "clm"
                    if (showPrepareOption) {
                        metadataArray.push({
                            templateKey: "clm",
                            metadataKey: "signRequestPrepareUrl",
                            value: prepare_url
                        })
                    }
                    metadataArray.push({
                        templateKey: "clm",
                        metadataKey: "hasSignRequest",
                        value: "true"
                    })

                    metadataArray.push({
                        templateKey: "clm",
                        metadataKey: "contractStatus",
                        value: "Execution"
                    })

                    debug && console.log ('setMetadata templateKey = ', templateKey, ' metadata = ' , metadataArray);

                    //construct body from metadata
                    let body = {
                        fileName: props.documentDetails.name,
                        metadata: metadataArray,
                    };

                    window.location.pathname.toLowerCase().includes("debug") && console.log ("body: " , body);

                    const pathVar = "/" + props.boxDocID;
                    const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar;
                    const request = {
                        method: 'PATCH',
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + props.userDetails.accessToken,
                            "case-token": props.userDetails.caseAccessToken
                        },
                        body: JSON.stringify(body)
                    };
                    window.location.pathname.toLowerCase().includes("debug") && console.log ('setMetadata url:' , url, 'BODY:', body, 'request:' , request);

                    fetch( url, request)
                        .then(response => {
                            if (response.ok) {
                                debug && console.log('setMetadata RESPONSE ok: ', response);
                                signRequestSuccess = true

                                setIsPosting(false);
                                if (showPrepareOption && prepareUrl) {
                                    window.open(prepareUrl,"_blank")
                                } else {
                                    handleCloseDialog(true)
                                }
                                try {
                                    return response.json();
                                } catch(err) {
                                    enqueueSnackbar("Error " + response.status + " setting metadata " + response.statusText, {variant: 'error'});
                                    return null
                                }
                            } else {
                                debug && console.log('setMetadata RESPONSE not ok: ', response);
                                Promise.resolve(getErrorMessageFromResponse(response, 'setting metadata'))
                                    .then(message => {
                                        enqueueSnackbar(message , {variant: 'error'});
                                    })
                                debug && console.log("setMetadata error. url:", url, "request: ", request, "response:", response);
                                return null
                            }
                        })
                        .then(result => {
                            debug && console.log('setMetadata RESPONSE.json: ', result);
                        })
                }

            })
            .catch(e => {
                setIsPosting(false)
                debug && console.log("postSignRequest Exception:", e, "url:", url, "request: ", request);
                enqueueSnackbar("Error creating sign request " + e , {variant: 'error'});
            });

    };

    const {classes} = props;

    return (
        <Dialog
            open={open}
            //onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle>{ hasFixedSignatories && signers.length === 1 ? "Request Signing of Contract" : "Request Signature(s)"}</DialogTitle>
            <DialogContent>
                <Grid container style={ {height: "150px"}}>
                    <Grid item xs={12} style={{height: "100%", overflowY: "auto"}}>

                        <DialogContentText sx={(theme)=>({paddingBottom: theme.spacing(2)})}>
                            {created ?
                                prepare ?
                                "Signature request ready to prepare" :
                                    "":
                                hasFixedSignatories ?
                                    "" :
                                "List signatures required"}
                        </DialogContentText>

                        {
                            !created &&

                            <Paper className={classes.root}>

                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Email</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {signers.map(signer => {
                                            return (
                                                <TableRow key={signer.id}>
                                                    <TableCell component="th" scope="row">
                                                        <Input
                                                            id={"email_" + signer.id}
                                                            name={"email_" + signer.id}
                                                            value={signer.email}
                                                            placeholder="Enter email address"
                                                            type="email"
                                                            autoComplete="email"
                                                            size="small"
                                                            variant="outlined"
                                                            onChange={handleChangeSigner}
                                                            disabled={isPosting || hasFixedSignatories}
                                                            style={{paddingLeft: "7px", paddingTop: "5px", width: "100%"}}
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <AccountCircle/>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )})}
                                    </TableBody>
                                </Table>


                                {/*{*/}
                                {/*    prepareUrl &&*/}
                                {/*        <Tooltip title = {"Prepare"}>*/}
                                {/*            <Link href={prepareUrl} target="_blank">*/}
                                {/*                {prepareUrl}*/}
                                {/*            </Link>*/}
                                {/*        </Tooltip>*/}
                                {/*}*/}



                                {/*<Input*/}
                                {/*    id = "fileLink"*/}
                                {/*    name = "fileLink"*/}
                                {/*    value = {window.location.origin + "/file/" + this.props.boxDocID}*/}
                                {/*    inputRef = {(textarea) => this.textArea = textarea}*/}
                                {/*    type = "text"*/}
                                {/*    margin="none"*/}
                                {/*    variant="outlined"*/}
                                {/*    readOnly={true}*/}
                                {/*    style={{width: "100%"}}*/}
                                {/*    autoFocus={true}*/}
                                {/*    onFocus={event => {event.target.select()}}*/}
                                {/*/>*/}

                            </Paper>

                        }

                    </Grid>
                </Grid>
            </DialogContent>

            {
                showPrepareOption && !created &&

                <FormControlLabel
                    style={{paddingLeft: '16px'}}
                    value="prepare"
                    checked={prepare}
                    control={<Checkbox color="secondary" onChange={(event) => {setPrepare(event.target.checked)}} />}
                    label="Prepare with Box Sign"
                    labelPlacement="end"
                    readOnly={isPosting}
                    disabled={isPosting}
                />
            }

            {
                isPosting && <LinearProgress color = "primary"/>
            }
            <DialogActions>
                <Button onClick={() => handleCloseDialog(false)} color="primary">Close</Button>
                {
                    prepareUrl ?
                        <Button onClick={prepareSignRequest} color="primary" disabled={isPosting}>Prepare Sign Request</Button> :
                        !created && <Button onClick={postSignRequest} color="primary" disabled={isPosting}>Submit Sign Request</Button>
                }
            </DialogActions>
        </Dialog>
    );

}

SignRequestDialog.propTypes = {
    userDetails: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    boxDocID: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    folderDetails: PropTypes.object.isRequired,
    documentDetails: PropTypes.object.isRequired,
    actionConfig: PropTypes.object.isRequired
};

export default (withStyles(styles)(SignRequestDialog));