import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack'
import {withStyles} from '@mui/styles';
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import DialogTitle from "@mui/material/DialogTitle";

const styles = theme => ({});

const INITIAL_STATE = {
    open: false,
    value: ""
};

class AddDocOptionsDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        window.location.pathname.toLowerCase().includes("debug") && console.log ('AddDocOptionsDialog props= ' , props);
    }

    componentDidMount(){
        this.setState({ open: true })
    }

    handleCancel = () => {
        this.setState({ open: false });
        this.props.handleCloseDialog("")
    }

    handleConfirm = () => {
        this.setState({ open: false });
        this.props.handleCloseDialog(this.state.value)
    }


    handleChange = (event) => {
        this.setState({value: event.target.value})
    }

    render() {

        const templateOptions = this.props.templateOptions;
        const uploadConfig = this.props.uploadConfig;
        const dialogTitle = uploadConfig.dialogTitle ? uploadConfig.dialogTitle : "Add document";

        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={(theme)=>({paddingBottom: theme.spacing(2)})}>
                            Confirm file source and click continue
                        </DialogContentText>

                        <FormControl variant="standard" component="fieldset" style={{paddingLeft: "24px"}}>
                            <RadioGroup aria-label="decision" name="decision" value={this.state.value} onChange={this.handleChange}>
                                {
                                    //for each templating option listed in templates, give the option to select
                                    templateOptions.map(templateOption => {
                                        let templateConfig = this.props.templatesConfig ? this.props.templatesConfig[templateOption] : {};
                                        window.location.pathname.toLowerCase().includes("debug") && console.log ('templateConfig =' , templateConfig);
                                        if (templateConfig) {
                                            return (<FormControlLabel value= {templateOption} control={<Radio/>} label={templateConfig.label} />)
                                        } else {
                                            window.location.pathname.toLowerCase().includes("debug") && console.log ('Template Config not found for: ', templateOption)
                                            enqueueSnackbar("Template config not found for: " + templateOption, {variant: 'error'})
                                            return(<React.Fragment/>)
                                        }
                                    })
                                }
                                {
                                    this.props.showUploadOption &&
                                    <FormControlLabel value={"upload"} control={<Radio/>} label={"Upload"}/>
                                }
                            </RadioGroup>
                        </FormControl>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleCancel} variant="contained" color="grey">Cancel</Button>
                        <Button onClick={this.handleConfirm} variant="contained" color="secondary" disabled={this.state.value === ""}>Continue</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

AddDocOptionsDialog.propTypes = {
    classes: PropTypes.object,
    handleCloseDialog: PropTypes.func.isRequired,
    templateOptions: PropTypes.array.isRequired,
    templatesConfig: PropTypes.object.isRequired,
    showUploadOption: PropTypes.bool.isRequired,
    uploadConfig: PropTypes.object.isRequired
};

export default (withStyles(styles, { withTheme: true })(AddDocOptionsDialog));