import React from 'react';
import PropTypes from 'prop-types';

export default class Dashboard extends React.Component{

    render() {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('DashboardFolderList.js render Dashboard, props=', this.props);
        return(

            <div align="center" style={{marginTop: "0px", marginRight: "0px"}}>
                <iframe
                    title={"Dashboard"}
                    src={this.props.dashboardUrl}
                    frameBorder={0}
                    scrolling={'auto'}
                    style={{ display:'block', width:'100%', height: '800px'}}
                />
            </div>
        )
    }
};

Dashboard.propTypes = {
    dashboardUrl: PropTypes.string.isRequired
};