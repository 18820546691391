import React, {Component} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import CompareCustomDialog from "./CompareCustomDialog";

const styles = theme => ({});

const INITIAL_STATE = {
    showDialog: false,
};

class CompareCustomButton extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }


    handleClickIcon = (event) => {
        this.setState({
            showDialog: true
        });

    };

    handleCloseDialog = () => {
        this.setState({
            showDialog: false
        });

    };

    render() {

        const { anchorEl } = this.state;

        return (
            <div>
                <Tooltip title={this.props.actionConfig.label}>
                    <IconButton
                        aria-label="Menu"
                        color={"secondary"}
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleClickIcon}
                        size="medium">
                        <i className='material-icons'>compare</i>
                    </IconButton>
                </Tooltip>

                {
                    (this.state.showDialog) &&
                    <CompareCustomDialog
                        parentClasses={this.props.classes}
                        id={this.props.id}
                        parentFileId={this.props.parentFileId}
                        handleCloseDialog={this.handleCloseDialog}
                        userDetails={this.props.userDetails}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        actionConfig={this.props.actionConfig}
                    />
                }

            </div>
        );
    }
}

CompareCustomButton.propTypes = {
    classes: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    parentFileId: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.func.isRequired
};

export default withStyles(styles)(CompareCustomButton);