import React, {useState} from 'react';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu"
import {IconButton} from "@mui/material";
import {Tooltip} from "@mui/material";

const styles = theme => ({});

function WorkspaceFilter (props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelect = (event) => {
        //console.log ('handleSelect event=', event, 'selected value = ', event.currentTarget.dataset.myValue);
        props.updateWorkspaceFilter(event.currentTarget.dataset.myValue)
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let filters = [];
    filters.push ({label: "All", value: ""});
    if (props.filterList) {
        props.filterList.forEach(f => {
            filters.push({label: f, value: f})
        })
    }
    // IconButton colour options
    // nothing gives light grey
    // 'inherit'
    // | 'default'
    // | 'primary' -
    // | 'secondary' - red
    // | 'error'
    // | 'info'
    // | 'success'
    // | 'warning'
    // | string

    return (
        <React.Fragment>
            <Tooltip title={"Filter" + (props.workspaceFilter !== "" ? (" (showing " + props.workspaceFilter + ")") : "")}>
                <IconButton
                    id={"filterIconButton"}
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="Menu"
                    onClick={handleClick}
                    value={props.workspaceFilter}
                    color='secondary'
                    key={"filterTooltip"}
                    size="medium">
                    <i className='material-icons md-36' key="filterListIcon">filter_list</i>
                </IconButton>
            </Tooltip>

            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                key={"filterMenu"}
            >

                {<MenuItem data-my-value={""} key={"selFilter"}>{'- Select Filter -'}</MenuItem>}
                {
                    filters.map(opt => {
                        return (
                            <MenuItem
                                id={opt.value}
                                value={opt.value}
                                data-my-value={opt.value}
                                key={opt.value}
                                onClick={handleSelect}
                            >
                                {opt.label}
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </React.Fragment>
    );
}

WorkspaceFilter.propTypes = {
    updateWorkspaceFilter: PropTypes.func.isRequired,
    workspaceFilter: PropTypes.string.isRequired,
    filterList: PropTypes.array.isRequired
};

export default withStyles(styles, { withTheme: true })(WorkspaceFilter);