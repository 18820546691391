import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from "@mui/material/Typography/Typography";
import PublicLinks from "./PublicLinks";
import Metadata from "./Metadata";

const styles = theme => ({});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const INITIAL_STATE = {
    tabValue: 0
};

class Admin extends React.Component {

    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;

    }

    handleTabChange = (event, tabValue) => {
        this.setState({ tabValue });
    };

    render() {

        const { classes } = this.props;
        const { tabValue } = this.state;

        return(

            <React.Fragment>
                <div>
                    <AppBar
                        position={"sticky"}
                        color="primary"
                    >

                    <Tabs
                        //centered
                        value={tabValue}
                        onChange={this.handleTabChange}
                    >
                        <Tab label = "Metadata"/>
                        <Tab label = "Public Links"/>

                    </Tabs>

                    </AppBar>

                    {tabValue === 0 &&
                        <TabContainer>
                            <Metadata parentClasses={classes}/>
                        </TabContainer>}

                    {tabValue === 1 &&
                        <TabContainer>
                            <PublicLinks parentClasses={classes}/>
                        </TabContainer>}

                </div>

            </React.Fragment>
        )
    }
}

export default withStyles(styles, { withTheme: true })(Admin);

Admin.propTypes = {
    classes: PropTypes.object.isRequired,
    drawerOpen: PropTypes.bool.isRequired
};
