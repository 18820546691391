import React from 'react';
import {withStyles} from '@mui/styles';
import IconButton from "@mui/material/IconButton/IconButton";
import PropTypes from "prop-types";

const styles = theme => ({});

function FolderIcon(props){

    return (
        <div>
            <IconButton color="secondary" style={{ backgroundColor: 'transparent' }} size="medium">
                <i className='material-icons'> {props.icon ? props.icon : "folder_open"}</i>
            </IconButton>

        </div>
    );

}

FolderIcon.propTypes = {
    icon: PropTypes.string
}

export default withStyles(styles)(FolderIcon);