import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import { enqueueSnackbar } from 'notistack'
import {Grid} from "@mui/material";
import FormControl from "@mui/material/FormControl/FormControl";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Input from "@mui/material/Input/Input";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import {formatDate, getErrorMessageFromResponse, getOptionsExternal} from "../../common/helper";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from "@mui/x-date-pickers";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";


const styles = (theme) => ({
    // root: {
    //     height: '75vh',
    //     // overflow: 'hidden',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     flexWrap: 'wrap',
    //     justifyContent: 'space-around',
    //     overflow: 'hidden',
    // },

    //https://material-ui.com/customization/components/
    disabled: {}, //required for the rule below to work
    rootInput: {
        '&.Mui-disabled': {
            color: theme.disabledField.colour
        },

    },

    // left: {
    //     float: 'left',
    //     width: '50%'
    // },
    //
    // right: {
    //     float: 'right',
    //     width: '50%'
    // },

    // smallAvatar: {
    //     margin: 10,
    //     width: 30,
    //     height: 30,
    // },

    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    },

    // optional: {
    //     color: theme.disabledField.colour
    // }
});


const INITIAL_STATE = {
    open: false,
    inputComment: "",
    inputDescription: "",
    inputAssignee: "",
    inputDueDate: null,
    tasks: [],
    isProcessing: false,
    assigneeOptions: [],
    initAssigneeOptionsDone: false,
    recurring: false,
    frequency: "month"

};

class AddFolderTaskDialog extends React.Component {

    constructor(props) {

        super(props);
        if (props.defaultTaskDesc) {
            INITIAL_STATE.inputDescription = props.defaultTaskDesc
        }
        if (props.defaultFrequency) {
            INITIAL_STATE.frequency = props.defaultFrequency
        }
        if (props.defaultDueDate) {
            INITIAL_STATE.inputDueDate = props.defaultDueDate
        }

        this.state = INITIAL_STATE;

        this.handleOnChangeComment = this.handleOnChangeComment.bind(this);
        this.handleOnChangeAssignee = this.handleOnChangeAssignee.bind(this);

        console.log ('AddFolderTaskDialog props = ', props)

    }

    UNSAFE_componentWillMount() {

        const actionConfig = this.props.folderTasksConfig;
        const assigneeConfig = actionConfig.assignee;

        //const assigneeUseExternalOptions = assigneeConfig && assigneeConfig.optionsExternal && Object.entries(assigneeConfig.optionsExternal).length > 0

        getOptionsExternal(assigneeConfig.optionsExternal, this.props.userDetails, this.props.triggerRefreshAuthToken)
            .then(response => {
                this.setState({
                    assigneeOptions: response,
                    initAssigneeOptionsDone: true
                })
            })
    }

    componentDidMount(){

        this.setState({ open: true });
        window.location.pathname.toLowerCase().includes("debug") && console.log('boxDocId:', this.props.boxDocID);

    }


    closeDialog = () => {

        //clear state
        const END_STATE = {
            open: false,
            tasks: []
        };

        this.setState(END_STATE);

        //Added this to address issue of dialog not re-opening after initial open
        if (this.props.handleCloseDialog) {
            this.props.handleCloseDialog()
        }

    };


    handleOnChangeComment = event => {
        this.setState({inputComment : event.target.value});
    };

    handleOnChangeAssignee = event => {
        this.setState({inputAssignee : event.target.value});
    };

    handleChangeDate = (id) => (date) => {
        //need separate function to handle dates as the onChange event of the DatePicker doesn't pass the event
        this.setState({inputDueDate : date});
    }

    handleOnChangeDescription = event => {
        this.setState({inputDescription : event.target.value});
    };


    handleOnChangeRecurring = event => {
        this.setState({recurring : event.target.value});
    };

    handleKeypress = (event) => {
        event.preventDefault();
        return false
    }


    postTask = async () => {

        const url = window.REACT_APP_TASK_API_BASE_URL + "/api/elastic/tasks";

        let body = {
            item : {
                "id" : this.props.boxDocID ? this.props.boxDocID : "158980912142", //TEMP for Britishvolt adding task from snippet
                "type" : "folder",
            },
            type: "task",
            action: "complete",  //review or complete
            message: this.state.inputComment, //label
            description: this.state.inputDescription,
            completion_rule:"any_assignee",
        };
        const selectedAssignee = this.state.inputAssignee;
        if (selectedAssignee) {
            body.assign_to = {
                id: selectedAssignee.user.boxUserId,
                type: "user",
                login: selectedAssignee.user.login
            }
        }

        if (this.state.inputDueDate) {
            const dateVal = new Date(this.state.inputDueDate);
            let month = dateVal.getUTCMonth()
            month++
            body.due_at = dateVal.getUTCFullYear() + "-" + month + "-" + dateVal.getUTCDate() + "T00:00:00-00:00"
        }

        await this.props.triggerRefreshAuthToken();

        let request = {
            method: 'POST',
            headers: {
                "case-token": this.props.userDetails.caseAccessToken,
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ("url:", url, "postTask ", 'BODY: ', body,'request: ', request);

        this.setState({isProcessing: true});

        fetch( url, request)
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('postTask RESPONSE ok: ', response);
                    return (response.json())
                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('postTask RESPONSE not ok: ', response);
                    Promise.resolve(getErrorMessageFromResponse(response, 'posting task'))
                        .then(message => {
                            enqueueSnackbar(message, {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("postTask error. url:", url, "request: ", request, "response:", response);
                    this.setState({isProcessing: false});
                    return null
                }
            })
            .then(result => {
                if (result && result.id) {
                    enqueueSnackbar("Task created.  Assigning task...", {variant: 'success'});
                    this.postAssignment(result.id); //proceed with assignment if task creation successful
                }
            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log("postTask Exception:", e, "url:", url, "request: ", request);
                enqueueSnackbar("postTask Exception:" + e.toString(), {variant: 'error'});
                this.setState({isProcessing: false});
            });

    };

    postAssignment = async (taskid) => {

        const url = window.REACT_APP_TASK_API_BASE_URL + "/api/elastic/taskassignment";

        let body = {
            task : {
                "id" : taskid,
                "type" : "task",
            },
            // assign_to: {
            //     type: "user",
            //     login: this.state.inputAssignee
            // }
        };
        const selectedAssignee = this.state.inputAssignee;
        if (selectedAssignee) {
            body.assign_to = {
                id: selectedAssignee.user.boxUserId,
                type: "user",
                login: selectedAssignee.user.login
            }
        }

        window.location.pathname.toLowerCase().includes("debug") && console.log ('body: ', body);

        await this.props.triggerRefreshAuthToken();

        let request = {
            method: 'POST',
            headers: {
                "case-token": this.props.userDetails.caseAccessToken,
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken
            },
            body: JSON.stringify(body)
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ("postAssignment url:", url, 'request: ', request);

        fetch( url, request)
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('postAssignment RESPONSE ok: ', response);
                    enqueueSnackbar("Task creation and assignment complete", {variant: 'success'});
                    return (response.json())
                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('postAssignment RESPONSE not ok: ', response);
                    Promise.resolve(getErrorMessageFromResponse(response, 'posting task'))
                        .then(message => {
                            enqueueSnackbar(message, {variant: 'error'});
                        })
                    window.location.pathname.toLowerCase().includes("debug") && console.log("postAssignment error. url:", url, "request: ", request, "response:", response);
                    return null
                }
            })
            .then(result => {
                this.setState({
                    inputComment: "",
                    inputAssignee: "",
                    isProcessing: false
                });
                this.closeDialog();
               // this.props.getFolderTasks(); //refresh tasks
            })
            .catch(e => {
                this.setState({isProcessing: false});
                window.location.pathname.toLowerCase().includes("debug") && console.log("postAssignment Exception:", e, "url:", url, "request: ", request);
            });

    };

    render() {

        const classes = this.props.classes;

        const actionConfig = this.props.folderTasksConfig;
        const assigneeConfig = actionConfig.assignee;

        let headerStr = "";
        if (this.props.folderDetails) {
           if (this.props.folderDetails.supplierName) {
               headerStr = " to " + this.props.folderDetails.supplierName
           }
        }

        let subHeaderStr = "";
        if (this.props.folderDetails) {
            if (this.props.folderDetails.buyerName) {
                subHeaderStr = "Contract Manager: " + this.props.folderDetails.buyerName
            }
            if (this.props.folderDetails.contractStartDate) {
                subHeaderStr = subHeaderStr === "" ? subHeaderStr : subHeaderStr + " | "
                subHeaderStr = subHeaderStr + "Contract start: " + formatDate(this.props.folderDetails.contractStartDate, "dd-mm-yyyy")
            }
            if (this.props.folderDetails.reviewDueDate) {
                subHeaderStr = subHeaderStr === "" ? subHeaderStr : subHeaderStr + " | "
                subHeaderStr = subHeaderStr + "Review date: " + formatDate(this.props.folderDetails.reviewDueDate, "dd-mm-yyyy")
            }
        }
        //secondaryVal = secondaryVal + col.label + " " + sr[col.metadataKey] + " | "


        return (
            <Dialog
                open={this.state.open}
                onClose={this.closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={false}
                maxWidth="md"
            >

                <DialogContent>

                    <Grid container spacing={1} style={ {height: "450px"}}>
                        <Grid item  xs={12}>
                            <Typography variant={"h6"} color={"textPrimary"} style={{paddingTop: "0px"}}>
                                {"Add Task" + headerStr}
                            </Typography>
                            {
                                subHeaderStr !== "" &&
                                    <Typography variant={"subtitle1"} style={{paddingTop: "0px", color: "#808080"}}>
                                        {subHeaderStr}
                                    </Typography>
                            }
                        </Grid>
                        <Grid item  xs={12}>
                            <FormControl  variant="standard" fullWidth style={{paddingBottom: '10px'}}>
                                <InputLabel shrink htmlFor="inputComment" className={classes.customLabel}>Title</InputLabel>
                                <Input
                                    id="inputComment"
                                    name="inputComment"
                                    value={this.state.inputCommentValue}
                                    placeholder="Enter task title"
                                    type="text"
                                    margin="none"
                                    variant="outlined"
                                    autoComplete=""
                                    onChange={this.handleOnChangeComment}
                                    style={{paddingTop: 16}}
                                    autoFocus = {true}
                                    disabled={this.state.isProcessing}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item  xs={12}>
                            <FormControl  variant="standard" fullWidth style={{paddingBottom: '10px'}}>
                                <InputLabel shrink htmlFor="inputDescription" className={classes.customLabel}>Description (optional)</InputLabel>
                                <Input
                                    id="inputDescription"
                                    name="inputComment"
                                    value={this.state.inputDescription}
                                    placeholder="Enter task description"
                                    type="text"
                                    margin="none"
                                    variant="outlined"
                                    autoComplete=""
                                    onChange={this.handleOnChangeDescription}
                                    style={{paddingTop: 16}}
                                    autoFocus = {false}
                                    disabled={this.state.isProcessing}
                                    multiline={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item  xs={6}>
                            <FormControl  variant="standard" fullWidth style={{paddingBottom: '10px', paddingTop: '16px'}}>
                                <InputLabel shrink htmlFor="inputDueDate" className={classes.customLabel} >Due date</InputLabel>
                                    <DatePicker
                                        InputProps={{ classes: {root: classes.rootInput, disabled: classes.disabled}}}
                                        onChangeRaw={this.handleKeypress} //required to prevent typing in field
                                        onKeyPress={this.handleKeypress}
                                        margin="none"
                                        id="inputDueDate"
                                        format="dd/MM/yyyy"
                                        value = {this.state.inputDueDate === "" ? null : (this.state.inputDueDate)}
                                        onChange = {this.handleChangeDate("inputDueDate")}
                                        onKeyDown={this.handleKeyDown}
                                        KeyboardButtonProps={{'aria-label': 'change date',}}
                                        disabled = {false }
                                        autoFocus={false}
                                        renderInput={props => <TextField variant="standard" label="Due date" helperText="" /> }
                                    />
                            </FormControl>
                        </Grid>
                        <Grid item  xs={6}>
                            {
                                this.state.initAssigneeOptionsDone  &&

                                <FormControl  variant="standard" fullWidth style={{paddingBottom: '10px'}}>
                                    <InputLabel shrink htmlFor="inputAssignee" className={classes.customLabel}>{assigneeConfig.label}</InputLabel>
                                    <TextField
                                        classes={{root: classes.rootInput, disabled: classes.disabled}}
                                        id="inputAssignee"
                                        name="inputAssignee"
                                        value={this.state.inputAssigneeValue}
                                        placeholder={assigneeConfig.label + " email"}
                                        type="Select"
                                        margin="none"
                                        variant="outlined"
                                        autoComplete=""
                                        onChange={this.handleOnChangeAssignee}
                                        style={{paddingTop: 16}}
                                        autoFocus = {false}
                                        disabled = {this.state.isProcessing}
                                        select
                                        native={true}
                                        helperText=""
                                    >
                                        {this.state.assigneeOptions.length > 0 && <MenuItem value="">{'- ' + assigneeConfig.label + ' -'}</MenuItem>}
                                        {this.state.assigneeOptions.map(opt => {
                                            return (
                                                <MenuItem id={opt.value} value={opt} key={opt.value}>{opt.label}</MenuItem>
                                            )
                                        })}
                                    </TextField>
                                </FormControl>
                            }
                        </Grid>
                        {/*<Grid item  xs={6}>*/}
                        {/*    <FormControlLabel*/}
                        {/*        // value={recurring}*/}
                        {/*        checked={this.state.recurring}*/}
                        {/*        control={<Checkbox color="secondary" onChange={this.handleOnChangeRecurring} />}*/}
                        {/*        label="Recurring"*/}
                        {/*        labelPlacement="end"*/}
                        {/*        readOnly={this.state.isProcessing}*/}
                        {/*    />*/}
                        {/*</Grid>*/}
                        <Grid item  xs={6}>
                            {
                                this.state.recurring  &&

                                <FormControl  variant="standard" fullWidth style={{paddingBottom: '10px'}}>
                                    <InputLabel shrink htmlFor="frequency" className={classes.customLabel}>Frequency</InputLabel>
                                    <TextField
                                        classes={{root: classes.rootInput, disabled: classes.disabled}}
                                        id="inputFrequency"
                                        name="inputFrequency"
                                        value={this.state.frequency}
                                        placeholder={"frequency"}
                                        type="Select"
                                        margin="none"
                                        variant="outlined"
                                        autoComplete=""
                                        onChange={this.handleOnChangeFrequency}
                                        style={{paddingTop: 16}}
                                        autoFocus = {false}
                                        disabled = {this.state.isProcessing}
                                        select
                                        native={true}
                                        helperText=""
                                    >
                                        <MenuItem value="">{'- Select Frequency -'}</MenuItem>
                                        <MenuItem id={"Weekly"} value={"week"} key={"Weekly"}>Weekly</MenuItem>
                                        <MenuItem id={"Monthly"} value={"month"} key={"Monthly"}>Monthly</MenuItem>
                                        <MenuItem id={"Annually"} value={"year"} key={"Annually"}>Annually</MenuItem>

                                    </TextField>
                                </FormControl>
                            }
                        </Grid>
                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={this.closeDialog} color="primary">
                        Close
                    </Button>
                    <Button onClick={this.postTask}
                            variant="contained"
                            color="secondary"
                            disabled={this.state.inputComment === "" || this.state.isProcessing}>
                        Add Task
                    </Button>
                </DialogActions>
                {
                    this.state.isProcessing &&
                    <LinearProgress variant={"indeterminate"} color={"primary"}/>
                }
            </Dialog>
        );
    }
}

AddFolderTaskDialog.propTypes = {
    classes: PropTypes.object,
    boxDocID: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    calledFromDocumentMenu: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    folderTasksConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object,
    defaultTaskDesc: PropTypes.string,
    defaultFrequency: PropTypes.string,
    defaultDueDate: PropTypes.string,
    snippet: PropTypes.object

    //getFolderTasks: PropTypes.func.isRequired
};

export default (withStyles(styles, { withTheme: true })(AddFolderTaskDialog));