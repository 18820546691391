import React, {Component} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import DashboardDialog from "./DashboardDialog";

const styles = theme => ({});

const INITIAL_STATE = {
    showDialog: false,
};

class DashboardButton extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
    }


    handleClickIcon = (event) => {
        this.setState({
            showDialog: true
        });

    };

    handleCloseDialog = () => {
        this.setState({
            showDialog: false
        });

    };

    render() {

        const {anchorEl} = this.state;

        return (
            <React.Fragment>
                <Tooltip title={this.props.actionConfig.label}>
                    <IconButton
                        aria-label="Menu"
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        color = "secondary"
                        onClick={this.handleClickIcon}
                        size="medium">
                        <i className='material-icons md-36'>
                            insert_chart
                        </i>
                    </IconButton>
                </Tooltip>

                {
                    this.state.showDialog &&
                    <DashboardDialog
                        //dashboardUrl={window.REACT_APP_DASHBOARD_URL}
                        dashboardUrl={"https://c45015652e994742b31820b5270c8a20.europe-west2.gcp.elastic-cloud.com:9243/app/dashboards#/view/b61b81c0-5b1c-11eb-9d40-bb60cef393d3?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-15m%2Cto%3Anow))"}
                        handleCloseDialog={this.handleCloseDialog}
                    />
                }

            </React.Fragment>
        );
    }

}

DashboardButton.propTypes = {
    //dashboardUrl: PropTypes.string.isRequired,
    actionConfig: PropTypes.object.isRequired
};

export default withStyles(styles)(DashboardButton);