import React from 'react';
import MaterialTable, {MTableToolbar} from "@material-table/core";
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import DocumentMenu from "../../menus/DocumentMenu";
import TasksButton from "../../document/tasks/TasksButton";
import MLButton from "../../document/ml/MLButton";
import PreviewButton from "../../document/preview/PreviewButton";
import PreviewDialog from "../../document/preview/PreviewDialog";
import Preview from "../../document/preview/Preview";
import { enqueueSnackbar } from 'notistack'
// import RelatedDocumentsButton from "../../document/relatedDocuments/RelatedDocumentsButton";
import DeleteFile from "../../document/DeleteFile";
import ViewFileLinkButton from "../../document/link/ViewFileLinkButton";
import DownloadFile from "../../document/download/DownloadFile";
import CommentsButton from "../../document/comments/CommentsButton";
import DownloadResults from "../../document/download/DownloadResults";
import SearchCriteriaTooltip from "./../SearchCriteriaTooltip";
import ColumnIcon from "./../results/ColumnIcon";
import {
    getErrorMessageFromResponse,
    getFieldConfig,
    hasAccess,
    numberWithCommas,
    validateDate
} from "../../common/helper"
import {dynamicSort} from "../helper";
import {checkHasMultiSelectActions, downloadFileMulti} from "../../document/multiSelectActions";
import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography/Typography";
import AddDocButton from "../../document/addDocument/AddDocButton";
import {Paper} from "@mui/material";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {ContentOpenWith} from "box-ui-elements";
import ActionButtonsDocument from "../../document/workflow/ActionButtonsDocument";
import ViewSharedLinkButton from "../../document/link/ViewSharedLinkButton";
import CompareParentButton from "../../document/compare/CompareParentButton";
import ComparePreviousButton from "../../document/compare/ComparePreviousButton";
import CompareCustomButton from "../../document/compare/CompareCustomButton";
import DisplayOptionButtons from "./DisplayOptionButtons";
import FileIcon from "../../common/FileIcon";
import FileIconThumbnail from "../../common/FileIconThumbnail";
import MLButtonDemo from "../../document/ml_demo/MLButtonDemo";
import SignRequestButton from "../../document/sign/SignRequestButton";
import AuditButton from "../../audit/AuditButton";
import PreviewSignSourceDocButton from "../../document/sign/PreviewSignSourceDocButton";
import EditComponentMaterialTable from "../../common/EditComponentMaterialTable";
import DownloadFilenameDialog from "../../document/download/DownloadFilenameDialog";
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { ReactComponent as ExportIcon } from '../../common/images/export.svg';

const styles = theme => ({
    appBarDocsTable: {
        backgroundColor: "white",
        color: "black",
        boxShadow: "none",
        // paddingLeft: theme.spacing(2)
    }
});
// const tableTheme = theme => createTheme(({
//
//     // to merge with parent theme
//     ...theme,
//
//     //https://material-ui.com/customization/globals/#css
//     //note, overrides the overrides in the parent theme in app.css
//     components: {
//         MuiToolbar: {
//             styleOverrides: {
//                 gutters: {
//                     paddingLeft: "0px"
//                 },
//                 root: {
//                     paddingLeft: "0px"
//                 },
//                 regular: {
//                     paddingLeft: "0px"
//                 }
//             }
//         },
//         MTableToolbar: {
//             styleOverrides: {
//                 root: {
//                     paddingLeft: "0px"
//                 }
//             }
//         },
//         //moved to prepareTheme
//         MuiTableCell: {
//             styleOverrides: {
//                 root: {
//                     padding: '0px',
//                 }
//             }
//         },
//
//         // MuiIconButton: {
//         //     styleOverrides: {
//         //         root: {
//         //             color: theme.palette.secondary.main,
//         //         },
//         //         colorInherit: {
//         //             color: theme.palette.secondary.main
//         //         }
//         //     }
//         // },
//
//         MuiAvatar: {
//             styleOverrides: {
//                 colorDefault: {
//                     backgroundColor: theme.palette.secondary.main
//                 }
//             }
//         },
//
//         //to fix spacing issue on add document dialog
//         MuiInput: {
//             styleOverrides: {
//                 formControl: {
//                     "label + &": {
//                         marginTop: "0px"
//                     }
//                 }
//             }
//         }
//     }
// }));

class SearchResultsDocumentsTable extends React.Component {

    constructor(props) {

        super(props);

        const searchConfig = props.selectedWorkspaceConfig.searchConfig.documentSearch;

        //console.log ('searchConfig = ', searchConfig);
        console.log ('SearchResultsDocumentsTable props =', props);

        this.updateIsProcessing = this.updateIsProcessing.bind(this);
        this.closeDownloadFilenameDialog = this.closeDownloadFilenameDialog.bind(this);

        //Add a column for each column in workspaceConfig
        let columns = [];
        let hasEditableColumns = false;

        const tableConfig = searchConfig && searchConfig.resultsDisplay && searchConfig.resultsDisplay.table;
        const showFileIcon = tableConfig && tableConfig.showFileIcon;
        const showThumbnail = tableConfig && tableConfig.showThumbnail;
        const showDeleteAction =  props.actionsConfig.delete.enabled && hasAccess(props.actionsConfig.delete, props.userDetails.userRoles)

        if (showFileIcon) {
            columns.push({
                label: "",
                field: 'name',
                filtering: false,
                sorting: false,
                width: 30,
                cellStyle: {
                    padding: 0,
                    textAlign: "center",
                    // maxWidth: 30,
                    //width: 30,
                    paddingRight: '8px'
                },
                headerStyle: {
                    textAlign: "center",
                    // maxWidth: 30,
                    // width: 30,
                },
                editable: "never",
                grouping: false,
                editComponent: rowData => {return (<span/>)}, //even though editable set to 'never' this is required to prevent editable field from appearing for icons
                render: rowData => {
                    return (
                        <React.Fragment>
                            {
                                showThumbnail ?
                                    <FileIconThumbnail height={'24px'} width={'24px'} file={rowData} userDetails={this.props.userDetails} triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}/> :
                                    <Tooltip title={rowData.name}><div><FileIcon height={'24px'} width={'24px'} fileName={rowData.name}/></div></Tooltip>
                            }
                        </React.Fragment>
                    )
                }
            });
        }

        searchConfig.resultsColumns.forEach((column) => {

            let fieldConfig = getFieldConfig(props.metadataConfig, column.templateKey, column.metadataKey);

            if (!column.hide) {

                if (column.displayAsIcon) {

                    let iconConfig = fieldConfig.iconConfig;
                    if (!iconConfig) {
                        window.location.pathname.toLowerCase().includes("debug") && console.log('iconConfig not available for ' + column.templateKey + "-" + column.metadataKey);
                    }

                    columns.push({
                        title: "",
                        field: column.templateKey + "~" + column.metadataKey,
                        metadataKey: column.metadataKey,
                        templateKey: column.templateKey,
                        filtering: false,
                        sorting: false,
                        cellStyle: {
                            // maxWidth: 10,
                            // width: 10,
                            padding: 0,
                            textAlign: "center",
                            // fontSize: "13px"
                            // marginRight: 40
                        },
                        headerStyle: {
                            textAlign: "center",
                            maxWidth: 10,
                            width: 10,
                        },
                        type: column.type,
                        editable: "never",
                        editComponent: rowData => {return (<span/>)}, //even though editable set to 'never' this is required to prevent editable field from appearing for icons
                        render: rowData => {
                            return (
                                <ColumnIcon val={rowData[column.metadataKey]} iconConfig={iconConfig}/>
                            )
                        }
                    });
                } else {

                    //Metadata field
                    //TODO use custom edit component for all (was using default previously but issue with dates)

                    let col = JSON.parse(JSON.stringify(column)); //deep clone
                    col.editable = col.editable ? "always" : "never";
                    //NB need at least one column with no width set, otherwise the detailPanel icon and action icons are resized
                    //col.width = column.width ? column.width : column.type ==='date' ? '200px' : '150px' //date columns need to be wider to allow for clear icon in edit mode
                    //col.width = column.width ? column.width : column.type ==='date' && '170px' //remove default of 150
                    //tableLayout needs to be fixed for width to take effect.  Setting width of date columns when tableLayout != fixed results in the date field being too narrow
                    //col.width = column.width ? column.width : (column.editable && column.type ==='date') && '170px' //only set width if it is specified, or if an editable date column
                    col.resizable = column.resizable === true //only make resizable if specified in config
                    col.cellStyle = {
                        fontSize: "13px",
                        fontFamily: "roboto",
                        paddingRight: ((col.type === "numeric" && (!col.defaultGroupOrder || col.defaultGroupOrder === 0)) || col.editable ==='always' ) ? "16px" : "8px"
                    };
                    col.headerStyle = {paddingRight: (col.type === "numeric" && (!col.defaultGroupOrder  || col.defaultGroupOrder === 0)) ? "16px" : "0px"}
                    col.title = col.label;
                    col.field = col.templateKey === "n/a" ? col.metadataKey : col.templateKey + "~" + col.metadataKey;

                    col.editComponent = props => {
                        const value = props.value;
                        return (
                            <EditComponentMaterialTable
                                onChange={props.onChange} col={col} fieldConfig={fieldConfig} value={value} rowData={props.rowData}
                                metadataConfig={this.props.metadataConfig} optionsConfig={this.props.optionsConfig}/>
                        )
                    }

                    columns.push(col)

                    if (column.editable) {
                        hasEditableColumns = true;
                    }
                }
            }
        });

        //append a column for ContentOpenWith component
        //if action is enabled and user role listed in config then add a column action
        if (props.actionsConfig.openWithFromTable && props.actionsConfig.openWithFromTable.enabled) {
            if (hasAccess(props.actionsConfig.openWithFromTable, props.userDetails.userRoles)) {
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width:110,
                    cellStyle: {
                        // width: 110,
                        // maxWidth: 110,
                        padding: 0,
                        paddingLeft: "8px"
                        // marginRight: 40
                    },
                    headerStyle: {
                        // width: 110,
                        // maxWidth: 110,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    editComponent: rowData => {return (<span/>)},
                    render: rowData => {
                        const usePreviewFor = props.actionsConfig.openWithFromTable.usePreviewFor;
                        const fileType = rowData.name.substring(rowData.name.lastIndexOf("."));
                        let usePreview = false;
                        if (usePreviewFor && usePreviewFor.length > 0) {
                            if (usePreviewFor.indexOf(fileType) > -1) {
                                usePreview = true
                            }
                        }

                        const usePreviewWhen = props.actionsConfig.openWithFromTable.usePreviewWhen;

                        if (usePreviewWhen && Object.entries(usePreviewWhen).length > 0) {
                            if(rowData[usePreviewWhen.templateKey + "~" + usePreviewWhen.metadataKey] && usePreviewWhen.values.indexOf(rowData[usePreviewWhen.templateKey + "~" + usePreviewWhen.metadataKey]) !== -1){
                                usePreview = true
                            }
                        }

                        let hide = false;
                        const hideWhen = props.actionsConfig.openWithFromTable.hideWhen;
                        if (hideWhen && Object.entries(hideWhen).length > 0){
                            if(rowData[hideWhen.templateKey + "~" + hideWhen.metadataKey] && (rowData[hideWhen.templateKey + "~" + hideWhen.metadataKey] === hideWhen.value)) {
                                hide = true;
                            }
                        }

                        return (
                            hide ?
                                <span/> :
                                usePreview ?
                                    <PreviewButton
                                        parentClasses={this.props.classes}
                                        boxDocID={rowData.id}
                                        userDetails={this.props.userDetails}
                                        metadataConfig={this.props.metadataConfig}
                                        optionsConfig={this.props.optionsConfig}
                                        workspaceConfig={this.props.workspaceConfig}
                                        selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                        actionsConfig={this.props.actionsConfig}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                        unmountFolderDocumentsGet={this.props.unmountFolderDocumentsGet}
                                        documentDetails={rowData}
                                        documentMetadataFields={searchConfig && searchConfig.documentMetadataFields}
                                    /> :
                                    <ContentOpenWith
                                        fileId={rowData.id}
                                        token={this.props.userDetails.boxAccessToken}
                                    />
                        )
                    },
                })
            }
        }

        //append a column for PreviewDocumentWithProperties component
        //if action is enabled and user role listed in config then add a column action
        //openOnRowClickOnly
        if (!props.actionsConfig.previewDocument.openOnRowClickOnly) {
            if (props.actionsConfig.previewDocument.enabled) {
                if (hasAccess(props.actionsConfig.previewDocument, props.userDetails.userRoles)) {
                    columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            width: 48,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            width: 48,
                            //maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {
                            return (<span/>)
                        },
                        render: rowData => {
                            return (
                                <PreviewButton
                                    parentClasses={this.props.classes}
                                    boxDocID={rowData.id}
                                    userDetails={this.props.userDetails}
                                    metadataConfig={this.props.metadataConfig}
                                    optionsConfig={this.props.optionsConfig}
                                    workspaceConfig={this.props.workspaceConfig}
                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                    actionsConfig={this.props.actionsConfig}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    documentDetails={rowData}
                                    documentMetadataFields={searchConfig && searchConfig.documentMetadataFields}
                                />
                            )
                        },
                    })
                }
            }
        }

        //Add column for each File Workflow Action....button to display if the doc meets the criteria specified in config
        if (searchConfig.showWorkflowActions) {
            //File Workflow actions
            if (props.workflowConfig && props.workflowConfig.length > 0) {
                // Build list of enabled file workflow actions that the user has access to
                let enabledActions = [];
                for (let i = 0; i < props.workflowConfig.length; i++) {
                    if (props.workflowConfig[i].enabled && hasAccess(props.workflowConfig[i], props.userDetails.userRoles)) {
                        enabledActions.push(
                            props.workflowConfig[i]
                        )
                    }
                }
                //If user has access to any enabled workflow actions then add a single column to display all the available actions
                if (enabledActions.length > 0) {
                    columns.push({
                        title: 'Workflow',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        disableClick: true,
                        editComponent: rowData => {return(<></>)},
                        render: rowData => {
                            return(
                                <ActionButtonsDocument
                                    documentDetails={rowData}
                                    actions={enabledActions}
                                    folderId={props.folderDetails.id}
                                    userDetails={props.userDetails}
                                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                    unmountSearchResults={props.unmountComponent}
                                    metadataConfig={props.metadataConfig}
                                    updateFolderDetails={props.updateFolderDetails}
                                    unmountFolderDocumentsGet={props.unmountFolderDocumentsGet}
                                    reloadWorkspace={props.reloadWorkspace}
                                    selectedWorkspaceConfig={props.selectedWorkspaceConfig}
                                />
                            )
                        },
                    });
                }
            }
        }

        if (props.actionsConfig.signRequest && props.actionsConfig.signRequest.enabled) {
            if (hasAccess(props.actionsConfig.signRequest, props.userDetails.userRoles)) {
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 48,
                    cellStyle: {
                        width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    headerStyle: {
                        width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    export: false,
                    editComponent: rowData => {
                        return (<span/>)
                    },
                    render: rowData => {
                        //if (rowData.objectType === "document") {
                        const displayWhen = props.actionsConfig.signRequest && props.actionsConfig.signRequest.displayWhen;
                        let display;
                        if (searchConfig.showingSignDocuments) {
                            display = false
                        } else if (displayWhen) {
                            if (Object.entries(displayWhen).length === 0) {
                                display = true;
                            } else {
                                display = rowData[displayWhen.metadataKey] === displayWhen.value;
                            }
                        } else {
                            display = true
                        }

                        return (
                            display ?
                                <SignRequestButton
                                    parentClasses={this.props.classes}
                                    boxDocID={rowData.id}
                                    fileName={rowData.name}
                                    userDetails={this.props.userDetails}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    reloadWorkspace={props.reloadWorkspace}
                                    folderDetails={this.props.folderDetails}
                                    documentDetails={rowData}
                                    actionConfig={props.actionsConfig.signRequest}
                                    selectedWorkspaceConfig={props.selectedWorkspaceConfig}
                                /> :
                                <span/>
                        )
                        //}
                    },
                });
            }
        }

        //append a column for PreviewDocumentWithProperties component
        //if action is enabled and user role listed in config then add a column action
        //openOnRowClickOnly{
        if (searchConfig.showingSignDocuments && props.actionsConfig.previewSourceSignDoc.enabled) {
            if (hasAccess(props.actionsConfig.previewSourceSignDoc, props.userDetails.userRoles)) {
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 48,
                    cellStyle: {
                        width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    headerStyle: {
                        width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    editComponent: rowData => {
                        return (<span/>)
                    },
                    render: rowData => {
                        const actionConfig = this.props.actionsConfig.previewSourceSignDoc;
                        let sourceDocId = ""
                        if (actionConfig && actionConfig.sourceFileDocId ){
                            const templateKey = actionConfig.sourceFileDocId.templateKey
                            const metadataKey = actionConfig.sourceFileDocId.metadataKey
                            if (templateKey && metadataKey && rowData[templateKey + "~" + metadataKey] ) {
                                sourceDocId = rowData[templateKey + "~" + metadataKey]
                                console.log ('rowData=', rowData)
                                console.log ('rowDate.id=', rowData.id, 'sourceDocId=', sourceDocId)
                            }
                        }
                        return (
                            <PreviewSignSourceDocButton
                                parentClasses={this.props.classes}
                                boxDocID={sourceDocId}
                                userDetails={this.props.userDetails}
                                metadataConfig={this.props.metadataConfig}
                                optionsConfig={this.props.optionsConfig}
                                workspaceConfig={this.props.workspaceConfig}
                                selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                actionsConfig={this.props.actionsConfig}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                documentDetails={rowData}
                                documentMetadataFields={searchConfig && searchConfig.documentMetadataFields}
                            />
                        )
                    },
                })

            }
        }

        //append a column for Download File component
        //if action is enabled and user role listed in config then add a column action
        if (props.actionsConfig.downloadFile.enabled) {
            if (hasAccess(props.actionsConfig.downloadFile, props.userDetails.userRoles)) {
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 48,
                    cellStyle: {
                        width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    headerStyle: {
                        width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    editComponent: rowData => {return (<span/>)},
                    render: rowData => {
                        return (
                            <DownloadFile
                                boxDocID={rowData.id}
                                fileName={rowData.name}
                                userDetails={this.props.userDetails}
                                downloadFileActionConfig={this.props.actionsConfig.downloadFile}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            />
                        )
                    },
                })
            }
        }

        //append a column for Download File component
        //if action is enabled and user role listed in config then add a column action
        if (props.actionsConfig.viewLinkFile.enabled) {
            if (hasAccess(props.actionsConfig.viewLinkFile, props.userDetails.userRoles)) {
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 48,
                    cellStyle: {
                        width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    headerStyle: {
                        width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    editComponent: rowData => {return (<span/>)},
                    render: rowData => {
                        return (
                            <ViewFileLinkButton
                                boxDocID={rowData.id}
                                actionConfig={this.props.actionsConfig.viewLinkFile}
                            />
                        )
                    },
                })
            }
        }

        //append a column for compareParent button component
        //if action is enabled and user role listed in config then add a column action
        if (props.actionsConfig.compareParent && props.actionsConfig.compareParent.enabled) {
            if (hasAccess(props.actionsConfig.compareParent, props.userDetails.userRoles)) {
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 48,
                    cellStyle: {
                        width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    headerStyle: {
                        width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    editComponent: rowData => {return (<span/>)},
                    render: rowData => {
                        const actionConfig = props.actionsConfig.compareParent;
                        return (
                            rowData[actionConfig.compareToIdMetadataKey] && rowData[actionConfig.compareToIdMetadataKey] !== "" ?
                                <CompareParentButton
                                    parentClasses={this.props.classes}
                                    id={rowData.id}
                                    parentFileId={rowData[actionConfig.compareToIdMetadataKey]}
                                    actionConfig={actionConfig}
                                    userDetails={this.props.userDetails}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}/> :
                                <span/>
                        )
                    },
                })
            }
        }

        //append a column for comparePrevious button component
        //if action is enabled and user role listed in config then add a column action
        if (props.actionsConfig.comparePrevious && props.actionsConfig.comparePrevious.enabled) {
            if (hasAccess(props.actionsConfig.comparePrevious, props.userDetails.userRoles)) {
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 48,
                    cellStyle: {
                        width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    headerStyle: {
                        width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    editComponent: rowData => {return (<span/>)},
                    render: rowData => {

                        const displayWhen = props.actionsConfig.comparePrevious.displayWhen;
                        let display;
                        if (displayWhen) {
                            if (Object.entries(displayWhen).length === 0) {
                                display = true;
                            } else {
                                display = rowData[displayWhen.metadataKey] === displayWhen.value;
                            }
                        } else {
                            display = true
                        }

                        const displayAlways = props.actionsConfig.comparePrevious.displayAlways;
                        return (
                            (displayAlways || (display && rowData.sequence_id && rowData.sequence_id !== "" && parseInt(rowData.sequence_id) > 1)) ?
                                <ComparePreviousButton
                                    parentClasses={this.props.classes}
                                    id={rowData.id}
                                    actionConfig={this.props.actionsConfig.comparePrevious}
                                    userDetails={this.props.userDetails}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}/> :
                                <span/>
                        )
                    },
                })
            }
        }

        //append a column for compareCustom button component
        //if action is enabled and user role listed in config then add a column action
        if (props.actionsConfig.compareCustom && props.actionsConfig.compareCustom.enabled) {
            if (hasAccess(props.actionsConfig.compareCustom, props.userDetails.userRoles)) {
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 48,
                    cellStyle: {
                        // width: 48,
                        // maxWidth: 48,
                        padding: 0},
                    headerStyle: {
                        // width: 48,
                        // maxWidth: 48,
                        padding: 0},
                    disableClick: true,

                    editComponent: rowData => {return (<span/>)},
                    render: rowData => {
                        const displayWhen = props.actionsConfig.compareCustom.displayWhen;
                        let display;
                        if (displayWhen) {
                            if (Object.entries(displayWhen).length === 0) {
                                //no criteria specified so display
                                display = true;
                            } else {
                                display = rowData[displayWhen.metadataKey] === displayWhen.value;
                            }
                        } else {
                            display = true
                        }

                        return (
                            display ?
                                <CompareCustomButton
                                    parentClasses={this.props.classes}
                                    id={rowData.id}
                                    parentFileId={rowData.parentFileId}
                                    actionConfig={this.props.actionsConfig.compareCustom}
                                    userDetails={this.props.userDetails}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}/> :
                                <span/>
                        )
                    },
                })
            }
        }


        //append a column for Comments Button component
        //if action is enabled and user role listed in config then add a column action
        if (!searchConfig.showingSignDocuments && props.actionsConfig.comments.enabled) {
            if (hasAccess(props.actionsConfig.comments, props.userDetails.userRoles)) {
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 48,
                    cellStyle: {
                        // width: 48,
                        // maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    headerStyle: {
                        // width: 48,
                        // maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    editComponent: rowData => {return (<span/>)},
                    render: rowData => {
                        return (
                            <CommentsButton
                                boxDocID={rowData.id}
                                userDetails={this.props.userDetails}
                                actionsConfig={this.props.actionsConfig}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                documentDetails={rowData}
                            />
                        )
                    },
                });
            }
        }




        //append a column for Shared Link button component
        //if action is enabled and user role listed in config then add a column action
        if (props.actionsConfig.viewSharedLinkFile && props.actionsConfig.viewSharedLinkFile.enabled) {
            if (hasAccess(props.actionsConfig.viewSharedLinkFile, props.userDetails.userRoles)) {
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 48,
                    cellStyle: {
                        // width: 48,
                        // maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    headerStyle: {
                        // width: 48,
                        // maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    editComponent: rowData => {return (<span/>)},
                    render: rowData => {
                        return (
                            rowData.shared_link && rowData.shared_link !== "" ?
                                <ViewSharedLinkButton
                                    sharedLink={rowData.shared_link}
                                    actionConfig={this.props.actionsConfig.viewSharedLinkFile}/> :
                                <span/>)

                    },
                })
            }
        }

        if (props.actionsConfig.watson.enabled) {
            if (hasAccess(props.actionsConfig.watson, props.userDetails.userRoles)) {
                //create a json with watson results so that we can use them
                //append a column for Watson component
                columns.push({
                        title: '',
                        field: 'id',
                        filtering: false,
                        sorting: false,
                        width: 48,
                        cellStyle: {
                            // width: 48,
                            // maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        headerStyle: {
                            // width: 48,
                            // maxWidth: 48,
                            padding: 0,
                            // marginRight: 40
                        },
                        disableClick: true,
                        editComponent: rowData => {return (<span/>)},
                        render: rowData => {
                            const actionConfig = props.actionsConfig.watson;
                            if (window.REACT_APP_ENV === "dev" && actionConfig.useDemoVersion) {

                                console.log ('*** show MLDemo')

                                const displayWhen = actionConfig.displayWhen;
                                let display;
                                if (displayWhen) {
                                    if (Object.entries(displayWhen).length === 0) {
                                        display = true;
                                    } else {
                                        display = rowData[displayWhen.metadataKey] === displayWhen.value;
                                    }
                                } else {
                                    display = true
                                }

                                return (
                                    display ?
                                        <MLButtonDemo
                                            boxDocID={rowData.id}
                                            userDetails={this.props.userDetails}
                                            metadataConfig={this.props.metadataConfig}
                                            uploadConfig={this.props.uploadConfig}
                                            actionsConfig={this.props.actionsConfig}
                                            workspaceConfig={this.props.workspaceConfig}
                                            selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                            documentDetails={rowData}
                                        /> :
                                        <span/>
                                )
                            } else {
                                return (
                                    <MLButton
                                        boxDocID={rowData.id}
                                        userDetails={this.props.userDetails}
                                        metadataConfig={this.props.metadataConfig}
                                        uploadConfig={this.props.uploadConfig}
                                        actionsConfig={this.props.actionsConfig}
                                        workspaceConfig={this.props.workspaceConfig}
                                        selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                        documentDetails={rowData}
                                    />
                                )
                            }
                        },
                    }
                );
            }
        }

        if (props.actionsConfig.tasks.enabled) {
            if (hasAccess(props.actionsConfig.tasks, props.userDetails.userRoles)) {

                //append a column for TasksButton component
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 48,
                    cellStyle: {
                        // width: 48,
                        // maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    headerStyle: {
                        // width: 48,
                        // maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    editComponent: rowData => {return (<span/>)},
                    render: rowData => {
                        if (window.REACT_APP_APP_TITLE !== "Pearson Education Portal" || rowData["pearson~documentType"] === "Assignment") {
                            return (
                                <TasksButton
                                    boxDocID={rowData.id}
                                    userDetails={this.props.userDetails}
                                    metadataConfig={this.props.metadataConfig}
                                    uploadConfig={this.props.uploadConfig}
                                    workspaceConfig={this.props.workspaceConfig}
                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    actionsConfig={this.props.actionsConfig}
                                />

                            )
                        }
                    },
                });
            }
        }



        //append a column for Related Documents component
        // if (props.actionsConfig.relatedDocuments.enabled) {
        //     if (hasAccess(props.actionsConfig.relatedDocuments, props.userDetails.userRoles)) {
        //         columns.push({
        //             title: '',
        //             field: 'id',
        //             filtering: false,
        //             sorting: false,
        //             width: 48,
        //             cellStyle: {
        //                 width: 48,
        //                 maxWidth: 48,
        //                 padding: 0,
        //                 // marginRight: 40
        //             },
        //             headerStyle: {
        //                 width: 48,
        //                 maxWidth: 48,
        //                 padding: 0,
        //                 // marginRight: 40
        //             },
        //             disableClick: true,
        //             editComponent: rowData => {return (<span/>)},
        //             render: rowData => {
        //                 return (
        //                     <RelatedDocumentsButton
        //                         userDetails={this.props.userDetails}
        //                         actionsConfig={this.props.actionsConfig}
        //                         searchConfig= {this.props.selectedWorkspaceConfig.searchConfig.folderDocuments}
        //                         metadataConfig={this.props.metadataConfig}
        //                         folderDetails = {this.props.folderDetails}
        //                         documentDetails ={rowData}
        //                         triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
        //                     />
        //                 )
        //             },
        //         })
        //     }
        // }

        //append a column for Delete
        if (showDeleteAction) {
            columns.push({
                title: '',
                field: 'id',
                filtering: false,
                sorting: false,
                width: 48,
                cellStyle: {
                    // width: 48,
                    // maxWidth: 48,
                    padding: 0,
                    // marginRight: 40
                },
                headerStyle: {
                    // width: 48,
                    // maxWidth: 48,
                    padding: 0,
                    // marginRight: 40
                },
                disableClick: true,
                editComponent: rowData => {return (<span/>)},
                render: rowData => {
                    return (
                        <DeleteFile
                            boxDocID={rowData.id}
                            userDetails={this.props.userDetails}
                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            actionConfig={props.actionsConfig.delete}
                            reloadWorkspace={props.reloadWorkspace}
                            selectedWorkspaceConfig={props.selectedWorkspaceConfig}
                            fileName={rowData.name ? rowData.name : rowData.original_name}
                        />
                    )
                },
            })
        }

        //append a column for Audit History
        if (props.actionsConfig.audit && props.actionsConfig.audit.document && props.actionsConfig.audit.document.enabled) {
            columns.push({
                title: '',
                field: 'id',
                filtering: false,
                sorting: false,
                width: 48,
                cellStyle: {
                    // width: 48,
                    // maxWidth: 48,
                    padding: 0,
                    // marginRight: 40
                },
                headerStyle: {
                    // width: 48,
                    // maxWidth: 48,
                    padding: 0,
                    // marginRight: 40
                },
                disableClick: true,
                export: false,
                editComponent: rowData => {return (<span/>)},
                render: rowData => {
                    return (
                        <AuditButton
                            parentClasses={this.props.classes}
                            userDetails={this.props.userDetails}
                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            docId={rowData.id}
                            type={"document"}
                        />
                    )
                },
            })

        }

        if (props.actionsConfig.documentDropDownMenu.enabled) {
            if (hasAccess(props.actionsConfig.documentDropDownMenu, props.userDetails.userRoles)) {

                //append a column to render the document menu
                columns.push({
                    title: '',
                    field: 'id',
                    filtering: false,
                    sorting: false,
                    width: 48,
                    cellStyle: {
                        //width: 48,
                        //maxWidth: 48,
                        paddingRight: 15,
                        paddingLeft: 0,
                        marginRight: 40
                    },
                    headerStyle: {
                        //width: 48,
                        //maxWidth: 48,
                        padding: 0,
                        // marginRight: 40
                    },
                    disableClick: true,
                    editComponent: rowData => {return (<span/>)},
                    render: rowData => {
                        return (
                            <DocumentMenu
                                boxDocID={rowData.id}
                                userDetails={this.props.userDetails}
                                metadataConfig={this.props.metadataConfig}
                                uploadConfig={this.props.uploadConfig}
                                workspaceConfig={this.props.workspaceConfig}
                                selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                actionsConfig={this.props.actionsConfig}
                                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            />
                        )
                    },
                });
            }
        }

        const hasMultiSelectActions = checkHasMultiSelectActions(props.actionsConfig, props.userDetails.userRoles);

        //add data for each item in search results
        let data = [];

        props.searchResults.forEach(sr => {
            data.push(sr)
        });


        if (this.props.selectedWorkspaceConfig.searchConfig.documentSearch.sortResultsByName) {
            data.sort( dynamicSort("name") );
        }

        console.log ('*** columns=', columns)


        this.state = {
            searchResults: props.searchResults,
            data: data,
            columns: columns,
            hasMultiSelectActions: hasMultiSelectActions,
            hasEditableColumns: hasEditableColumns,
            showPreview: false,
            isProcessing: false,
            showPreviewDialog: false,
            showDeleteAction: showDeleteAction,
            tableRef: React.createRef(),
            showDownloadFilenameDialog: false,
            selectedFilesForDownload: []
        }

    }

    componentDidUpdate(prevProps) {

        if (JSON.stringify(this.state.searchResults)!== JSON.stringify(this.props.searchResults)){
            this.props.unmountComponent();
         }
        //else {
            //window.location.pathname.toLowerCase().includes("debug") && console.log ('no change to search Results')
        //}

    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    showDownloadFilenameDialog = (data) => {
        this.setState({
            showDownloadFilenameDialog: true,
            selectedFilesForDownload: data
        });
    }

    closeDownloadFilenameDialog = () => {
        console.log ('closeDownloadFilenameDialog')
        this.setState({
            showDownloadFilenameDialog: false,
            selectedFilesForDownload: []
        });
    }

    updateIsProcessing =(isProcessing, deselect) => {
        this.setState({
            isProcessing: isProcessing
        });

        if (deselect) {
            this.state.tableRef.current.onAllSelected(false)
        }

    }
    rowClickNoAction = (event,rowData) => {
        //dummy action to make row go grey when hovered over
    };

    rowClickOpen = (event, rowData) => {
        this.setState({
            showPreviewDialog: true,
            showPreviewDocId: rowData.id,
            showPreviewDocumentDetails: rowData
        });
    };

    handleClosePreviewDialog = (metadataUpdated) => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('handleClosePreviewDialog metadataUpdated= ', metadataUpdated);

        this.setState({
            showPreviewDialog: false,
            showPreviewDocId: "",
            showPreview: {}
        });
    }

    render() {

        const actionsConfig = this.props.actionsConfig;
        const {classes, ...other } = this.props;

        const isProcessing = this.state.isProcessing;

        window.location.pathname.toLowerCase().includes("debug") && console.log ('SearchResultsDocumentsTable props = ' , this.props);

        const hasEditAccess = hasAccess(this.props.actionsConfig.edit, this.props.userDetails.userRoles);

        let actionsColumnIndex = this.state.showDeleteAction ? this.state.columns.length-1 : -1;

        //To cater for Metadata api which doesn't return searchTotalCount
        let totalCountDisp ;
        if ((!this.props.searchTotalCount || this.props.searchTotalCount === 0) || this.props.nextMarker) {
            totalCountDisp = this.props.searchResults.length
        } else {
            totalCountDisp = this.props.searchTotalCount
        }

        let hasMore = false;
        if (this.props.nextMarker) {
            hasMore = true
        } else if (this.props.searchTotalCount !== 0 && (this.props.searchResults.length < this.props.searchTotalCount)) {
            hasMore = true
        }

        let tableTitle = numberWithCommas(totalCountDisp) + " " +
            (totalCountDisp === 1 ?
                this.props.selectedWorkspaceConfig.searchConfig.documentSearch.resultsTitleSingular :
                this.props.selectedWorkspaceConfig.searchConfig.documentSearch.resultsTitlePlural);

        const searchConfig = this.props.selectedWorkspaceConfig.searchConfig.documentSearch;

        //Access to Add Doc button now further restricted by roles listed in workspaceConfig.uploadConfig (if available)
        const workspaceUploadRoles =
            this.props.selectedWorkspaceConfig.uploadConfig.roles &&
            Array.isArray(this.props.selectedWorkspaceConfig.uploadConfig.roles) ?
                this.props.selectedWorkspaceConfig.uploadConfig.roles :
                [];

        const showAddDocButton =
            (this.props.actionsConfig.addDocumentMain.enabled && hasAccess(this.props.actionsConfig.addDocumentMain, this.props.userDetails.userRoles)) &&
            this.props.selectedWorkspaceConfig.uploadConfig.enabled &&
            ((workspaceUploadRoles.length > 0 && hasAccess(this.props.selectedWorkspaceConfig.uploadConfig, this.props.userDetails.userRoles)) ||
                workspaceUploadRoles.length === 0)

        const iconColour = this.props.theme.palette.secondary.main; //default colour

        const showExport = actionsConfig.exportResults && actionsConfig.exportResults.enabled && hasAccess(actionsConfig.exportResults, this.props.userDetails.userRoles)
        const colsForExport = this.state.columns.filter((col) => col.field !== 'id');


        if (this.state.data.length === 0 ) {
            return (
                <AppBar position="sticky" className={classes.appBarDocsTable}>
                    <Toolbar disableGutters={false}>
                        <Typography variant={"h6"} color={"textPrimary"}>
                            {tableTitle}
                            {
                                showAddDocButton  &&
                                <AddDocButton
                                    {...other}
                                    parentClasses={this.props.classes}
                                    userDetails={this.props.userDetails}
                                    uploadConfig={this.props.selectedWorkspaceConfig.uploadConfig}
                                    metadataConfig={this.props.metadataConfig}
                                    optionsConfig={this.props.optionsConfig}
                                    iconClass={""}
                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                    actionConfig={this.props.actionsConfig.addDocumentMain}
                                    workspaceConfig={this.props.workspaceConfig}
                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                    templatesConfig={this.props.templatesConfig}
                                    actionsConfig={this.props.actionsConfig}
                                    reloadWorkspace={this.props.reloadWorkspace}
                                />
                            }
                        </Typography>
                    </Toolbar>
                </AppBar>
            )
        } else {
            return (
                <div style={{paddingLeft:'16px', paddingRight:'16px'}}>

                    {/*<StyledEngineProvider injectFirst>*/}
                    {/*    <ThemeProvider theme={tableTheme}>*/}
                            <MaterialTable
                                tableRef={this.state.tableRef}
                                title={
                                    <React.Fragment>
                                        <Typography variant={"h6"}>
                                            {tableTitle}
                                            {
                                                hasMore &&
                                                <Tooltip title={this.props.isFetching ? "Retrieving more results..." : "Retrieve more results"}>
                                                    <span>
                                                        <Button onClick={this.props.getNextResults}
                                                                key={"ibNext"}
                                                                color={"secondary"}
                                                                disabled={this.props.isFetching}
                                                                style={{fontWeight: 'bold', minWidth: '0px',paddingLeft: '5px',paddingRight: '5px',paddingTop: '5px', fontSize: '1rem'}}>
                                                            {this.props.isFetching ?
                                                                <CircularProgress color="secondary" size={15}/> :
                                                                "..."}
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            }

                                            <DisplayOptionButtons searchConfig = {searchConfig}
                                                                  showResultsAsGrid={this.props.showResultsAsGrid}
                                                                  showResultsAsTable={this.props.showResultsAsTable}
                                                                  currentDisplay={"table"}
                                            />

                                            {
                                                this.props.actionsConfig.searchCriteriaTooltip.enabled &&
                                                <SearchCriteriaTooltip
                                                    parentClasses={this.props.classes}
                                                    metadataConfig={this.props.metadataConfig}
                                                    searchCriteriaSimple={this.props.searchCriteriaSimple}
                                                    searchCriteria={this.props.searchCriteria}
                                                    iconClass={""}
                                                />
                                            }
                                            {
                                                this.props.searchResults.length > 0 &&
                                                this.props.actionsConfig.downloadResults.enabled &&
                                                hasAccess(this.props.actionsConfig.downloadResults, this.props.userDetails.userRoles) &&
                                                <DownloadResults
                                                    userDetails={this.props.userDetails}
                                                    downloadResultsActionConfig={this.props.actionsConfig.downloadResults}
                                                    searchResults={this.props.searchResults}
                                                    fileName={this.props.folderDetails.name + ".zip"}
                                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                />
                                            }
                                            {
                                                showAddDocButton &&
                                                <AddDocButton
                                                    parentClasses={this.props.classes}
                                                    userDetails={this.props.userDetails}
                                                    uploadConfig={this.props.selectedWorkspaceConfig.uploadConfig}
                                                    metadataConfig={this.props.metadataConfig}
                                                    optionsConfig={this.props.optionsConfig}
                                                    iconClass={""}
                                                    triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                    actionConfig={this.props.actionsConfig.addDocumentMain}
                                                    workspaceConfig={this.props.workspaceConfig}
                                                    selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                                                    templatesConfig={this.props.templatesConfig}
                                                    actionsConfig={this.props.actionsConfig}
                                                    reloadWorkspace={this.props.reloadWorkspace}
                                                />
                                            }
                                        </Typography>
                                    </React.Fragment>
                                }
                                // remove shadow from paper container
                                components={{
                                    Container: props => <Paper {...props} elevation={0}/>,
                                    // Toolbar: (props) => {
                                    //     return (
                                    //         <div style={{ paddingLeft: "0" }}>
                                    //             <MTableToolbar {...props} />
                                    //         </div>)
                                    Toolbar: props => {
                                        const propsCopy = { ...props };
                                        propsCopy.disableGutters = true;
                                        return (<MTableToolbar {...propsCopy} />);
                                    }
                                }}
                                columns={this.state.columns}
                                data={this.state.data}
                                icons={{
                                    DetailPanel: () => <i className={'material-icons'} style={{color: iconColour}}>chevron_right</i>,
                                    Edit: () => <i className={'material-icons'} style={{color: iconColour}}>edit</i>,
                                    Check: () => <i className={'material-icons'} style={{color: iconColour, padding: '3px'}}>check</i>,
                                    Clear: () => <i className={'material-icons'} style={{color: iconColour, padding: '3px'}}>clear</i>,
                                    Export: () => <ExportIcon style={{height: '24px', width: '24px', color: iconColour}}/>
                                }}
                                options={{
                                    //exportButton: actionsConfig.exportResults && actionsConfig.exportResults.enabled && hasAccess(actionsConfig.exportResults, this.props.userDetails.userRoles),
                                    //exportFileName: this.props.selectedWorkspaceConfig.title,
                                    exportAll: true,

                                    exportMenu: showExport ? [{
                                        label: 'Export PDF',
                                        exportFunc: (cols, datas) => ExportPdf(colsForExport, datas, this.props.selectedWorkspaceConfig.title)
                                    }, {
                                        label: 'Export CSV',
                                        exportFunc: (cols, datas) => ExportCsv(colsForExport, datas, this.props.selectedWorkspaceConfig.title)
                                    }]: [],
                                    actionsColumnIndex: actionsColumnIndex,
                                    paging: false,
                                    actionsCellStyle: {
                                        color: "rgba(0, 0, 0, 0.54)",
                                        width: 32,
                                        maxWidth: 32,
                                        paddingLeft: 0,
                                        paddingRight: 0
                                    },
                                    headerStyle: {
                                        width: 32, //NB required to control width of detail panel expansion icon
                                        //maxWidth: 96, //setting this overrides any columns set to width>96
                                        paddingLeft: 0,
                                        paddingRight: 0
                                    },
                                    selection: this.state.hasMultiSelectActions,
                                    selectionProps: rowData => ({
                                        size: 'medium'
                                    })
                                    //Note value in fixedColumns breaks onChange - newData paramater is returned the same as oldData in onChangeEvent

                                    //tableLayout: 'fixed',// - causes X cancel button for inline editin to appear off screen in edit mode, unless there is an action to the right of it
                                    //tableLayout: 'fixed', //needs to be fixed, with at least one column  with no size specified to prevent detail panel icon columns from being auto widened
                                    //note fixed columns not available when grouping https://material-table.com/#/docs/features/fixed-columns
                                    //columnResizable: true, //NB, requires width to be set for each column
                                   // fixedColumns: {
                                        //left: this.state.fixedColumnsLeft,
                                        //right: this.state.fixedColumnsRight
                                        //left: -1,
                                        //right: -1
                                   // }

                                }}
                                actions={ this.state.hasMultiSelectActions ?
                                    [
                                        this.props.actionsConfig.downloadFileMulti?.enabled &&
                                        {
                                            // tooltip: isProcessing ? "Downloading..." : this.props.actionsConfig.downloadFileMulti.label,
                                            tooltip: !isProcessing && this.props.actionsConfig.downloadFileMulti.label,
                                            icon: 'file_download',
                                            iconProps: {  color: isProcessing? "grey" : "secondary"  },
                                            disabled: isProcessing,
                                            onClick: (evt, data) => {
                                                if (data.length > 1 && this.props.actionsConfig.downloadFileMulti.promptForFilename) {
                                                    this.showDownloadFilenameDialog(data);
                                                } else {
                                                    downloadFileMulti( data, this.props.userDetails.accessToken,this.props.triggerRefreshAuthToken, this.updateIsProcessing)
                                                }

                                            }
                                        },
                                    ]: []
                                }

                                onRowClick={
                                    this.props.actionsConfig.previewDocument.openOnRowClick && hasAccess(this.props.actionsConfig.previewDocument, this.props.userDetails.userRoles)?
                                        this.rowClickOpen :
                                        this.rowClickNoAction
                                }
                                editable={
                                    !this.props.actionsConfig.edit.enabled || searchConfig.showingSignDocuments ?
                                        {} :
                                        !hasEditAccess ?
                                            {} :
                                            !this.state.hasEditableColumns ?
                                                {} :
                                                {
                                                    onRowUpdate: (newData, oldData) =>

                                                        //TODO if field value not in list of options then set it to blank

                                                        new Promise(async (resolve, reject) => {

                                                            window.location.pathname.toLowerCase().includes("debug") && console.log ('oldData=', oldData, 'newData = ', newData);

                                                            let allFieldsValid = true;
                                                            let invalidDate = false;
                                                            let metadataArray = [];
                                                            Object.entries(newData).forEach(entry => {
                                                                if (entry[0].indexOf("~") > -1) {
                                                                    let val = entry[1];
                                                                    if (val && typeof val === 'object') {
                                                                        if (validateDate(val)) {
                                                                            let dateVal = new Date(val)
                                                                            val = dateVal;
                                                                            dateVal.setUTCHours(0,0,0,0);
                                                                        } else {
                                                                            invalidDate = true;
                                                                            allFieldsValid = false;
                                                                        }
                                                                    }

                                                                    metadataArray.push({
                                                                        templateKey: entry[0].split("~")[0],
                                                                        metadataKey: entry[0].split("~")[1],
                                                                        value: val
                                                                    });
                                                                }
                                                            });

                                                            if (!allFieldsValid) {
                                                                const message = (invalidDate ? "Invalid date.  " : "") + "Please ensure all fields are valid before saving."
                                                                enqueueSnackbar(message, {variant: 'info'});
                                                                reject();

                                                            } else {

                                                                let body = {
                                                                    fileName: newData.name,
                                                                    metadata: metadataArray,
                                                                };

                                                                const pathVar = "/" + newData.id;
                                                                const params = '?audit=' + this.props.actionsConfig.edit.audit;
                                                                const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params;
                                                                const request = {
                                                                    method: 'PATCH',
                                                                    headers: {
                                                                        "Content-Type": "application/json",
                                                                        "Authorization": "Bearer " + this.props.userDetails.accessToken
                                                                    },
                                                                    body: JSON.stringify(body)
                                                                };

                                                                window.location.pathname.toLowerCase().includes("debug") && console.log('edit metadata BODY=', body, 'request=', request);
                                                                this.setState({isFetching: true});

                                                                await this.props.triggerRefreshAuthToken();
                                                                fetch(url, request)
                                                                    .then(response => {
                                                                        if (response.ok) {
                                                                            window.location.pathname.toLowerCase().includes("debug") && console.log('response=', response);
                                                                            enqueueSnackbar("Metadata on " + body.fileName + " successfully updated.", {variant: 'success'});
                                                                            this.setState({isFetching: false});

                                                                            //update state data so that table shows latest update
                                                                            const dataUpdate = [...this.state.data];
                                                                            const target = dataUpdate.find((el) => el.id === oldData.tableData.id);
                                                                            const index = dataUpdate.indexOf(target);
                                                                            dataUpdate[index] = newData;
                                                                            this.setState({
                                                                                ...this.state,
                                                                                data: dataUpdate
                                                                            });

                                                                            resolve();

                                                                        } else {
                                                                            window.location.pathname.toLowerCase().includes("debug") && console.log('response=', response);
                                                                            Promise.resolve(getErrorMessageFromResponse(response, 'updating data'))
                                                                                .then(message => {
                                                                                    enqueueSnackbar(message, {variant: 'error'});
                                                                                    window.location.pathname.toLowerCase().includes("debug") && console.log('updateMetadata error.  url:', url, 'request: ', request);
                                                                                    this.setState({isFetching: false});
                                                                                    reject();
                                                                                })
                                                                        }
                                                                    })
                                                            }
                                                            // .catch(e => {
                                                            //     enqueueSnackbar("Error updating data.  Exception: " + e);
                                                            //     window.location.pathname.toLowerCase().includes("debug") && console.log ('"updateMetadata Exception:", e, " url:", url, "request: ", request, );
                                                            //     this.setState({isFetching: false})});
                                                            //     reject();
                                                        }),
                                            }}
                                detailPanel={
                                    [
                                        {
                                            tooltip: 'Preview',
                                            disabled: false,
                                            //removing as no impact
                                            // width: 48,
                                            // cellStyle: {
                                            //     width: '48px',
                                            //     maxWidth: '50px',
                                            //     padding: 0,
                                            //     // marginRight: 40
                                            // },
                                            // headerStyle: {
                                            //     width: '48px',
                                            //     maxWidth: '50px',
                                            //     padding: 0,
                                            //     // marginRight: 40
                                            // },

                                            render:  rowData  => {
                                                return (
                                                    <div style={{height: "500px"}}>
                                                        <Preview
                                                            boxDocID={rowData.rowData.id}
                                                            userDetails={this.props.userDetails}
                                                            showHeader={false}
                                                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                                                            actionsConfig={this.props.actionsConfig}
                                                        />
                                                    </div>
                                                )
                                            }
                                        }
                                    ]
                                }
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: '',
                                        editTooltip: this.props.actionsConfig.edit.label,
                                        editRow: {
                                            cancelTooltip: 'Cancel Edit'
                                        }
                                    },
                                    toolbar: {
                                        searchTooltip: 'Filter results',
                                        searchPlaceholder: 'Filter results'
                                    }
                                }}
                            />

                    {/*    </ThemeProvider>*/}
                    {/*</StyledEngineProvider>*/}

                    {
                        (this.state.showPreviewDialog) &&
                        <PreviewDialog
                            parentClasses={this.props.classes}
                            boxDocID={this.state.showPreviewDocId}
                            userDetails={this.props.userDetails}
                            handleCloseDialog={this.handleClosePreviewDialog}
                            calledFromDocumentMenu={true}
                            metadataConfig={this.props.metadataConfig}
                            optionsConfig={this.props.optionsConfig}
                            workspaceConfig={this.props.workspaceConfig}
                            selectedWorkspaceConfig={this.props.selectedWorkspaceConfig}
                            actionsConfig={this.props.actionsConfig}
                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            showMetadataOnOpen={this.props.showMetadataOnOpen}
                            documentDetails={this.state.showPreviewDocumentDetails}
                            documentMetadataFields={searchConfig && searchConfig.documentMetadataFields}
                        />
                    }

                    {
                        this.state.showDownloadFilenameDialog &&
                        <DownloadFilenameDialog
                            files={this.state.selectedFilesForDownload}
                            accessToken={this.props.userDetails.accessToken}
                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            updateIsProcessing={this.updateIsProcessing}
                            handleCloseDialog={this.closeDownloadFilenameDialog}
                        />
                    }
                </div>
            );
        }
    }
}

SearchResultsDocumentsTable.propTypes = {
    classes: PropTypes.object.isRequired,
    searchResults: PropTypes.array.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    searchLimit: PropTypes.number.isRequired,
    getNextResults: PropTypes.func.isRequired,
    getAllResults: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    workspaceConfig: PropTypes.array.isRequired,
    selectedWorkspaceConfig: PropTypes.object.isRequired,
    folderDetails: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    searchCriteria: PropTypes.array.isRequired,
    searchCriteriaSimple: PropTypes.string.isRequired,
    toggleFilterFolderDocuments: PropTypes.func,
    filterFolderDocuments: PropTypes.bool,
    isFilteringFolderDocuments: PropTypes.bool,
    toggleFilterFolderDocumentsByMetadata: PropTypes.func,
    filterFolderDocumentsByMetadata: PropTypes.bool,
    isFilteringFolderDocumentsByMetadata: PropTypes.bool,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    folderDocuments: PropTypes.bool,
    nextMarker: PropTypes.string,
    templatesConfig: PropTypes.object.isRequired,
    workflowConfig: PropTypes.array.isRequired,
    showResultsAsGrid: PropTypes.func.isRequired,
    showResultsAsTable: PropTypes.func.isRequired,
    showMetadataOnOpen: PropTypes.bool,
    reloadWorkspace: PropTypes.func.isRequired
};

export default (withStyles(styles, { withTheme: true })(SearchResultsDocumentsTable));

