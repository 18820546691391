import {formatBytes, getFieldConfig} from "../common/helper";

export function getUserValue(val, userDetails) {
    switch (val) {
        case "$USER_NAME":
            return userDetails.userName
        case "$USER_BOXID":
            return userDetails.boxId
        case "$USER_EMAIL":
            return userDetails.userEmail
        default:
            return val
    }
}

export function getDataForSearchResultsTableElastic(item, resultsColumns, enterpriseId, metadataConfig, counter){

    //Preparing data for Search Results Table, return row
    let row = {
        rowId: counter,
        id: item.id,
        original_name: item.original_name,
        created_at: item.created_at ? new Date(item.created_at): null,
        modified_at: item.modified_at ? new Date(item.modified_at) : null,
        size: item.size ? formatBytes(item.size) : null,
        version: item.version,
        box_id: item.box_id,
        case_type: item.case_type,
        type: "case", //??
        archived: item.archived,
        vision_annotated_url: item.vision_annotated_url,
        converted_url: item.converted_url,
        original_url: item.original_url
    };

    let templateKey = "", metadataKey = "";

    //Get the metadata specified in resultsColumns for the selected search config
    for (let i = 0; i < resultsColumns.length; i++) {

        templateKey = resultsColumns[i].templateKey;
        metadataKey = resultsColumns[i].metadataKey;

        let fieldConfig = getFieldConfig(metadataConfig, templateKey, metadataKey);
        let val = "";
        if (fieldConfig) {
            if (fieldConfig.elasticField) {
                if (item.metadata) {
                    if (item.metadata[fieldConfig.elasticField] || item.metadata[fieldConfig.elasticField] === "" || item.metadata[fieldConfig.elasticField] === false){
                        val = item.metadata[fieldConfig.elasticField];
                        if (fieldConfig.type === "date") {
                            val = (val !== 0 && val !== "0" && val !== null) ? new Date(val) : val;
                        }
                        row[metadataKey] = val;
                        row[templateKey + "~" + metadataKey] = val;
                    // } else {
                    //    window.location.pathname.toLowerCase().includes("debug") && console.log('Unable to find metadata value for ' + fieldConfig.elasticField, "item=", item)
                    }
                }
            }
        // } else {
        //     window.location.pathname.toLowerCase().includes("debug") && console.log ('Unable to find value fieldConfig for', 'templateKey=', templateKey, 'metadataKey=', metadataKey);
        }
    }

    //Need all Case Data so that when we edit case we set all fields
    row.metadata = item.metadata;

    return row;
}

export function getDataForSearchResultsTableMetadataSearch(item, resultsColumns, enterpriseId, metadataConfig, counter){

    // const debug = window.location.pathname.toLowerCase().includes("debug")
    // debug && console.log ('getDataForSearchResultsTableMetadataSearch item = ', item, 'resultsColumns=', resultsColumns)

    //Format results from metadata search
    //Preparing data for Search Results Table, return row

    //check if created by app user
    const createdByAppUser = item.created_by && item.created_by.name ? item.created_by.name.includes("@") : false
    //console.log (item.name, 'createdByAppUser=', createdByAppUser)

    //let metadata = {};
    let row = {
        rowId: counter,
        name: item.name,
        created_at: item.created_at,
        created_by_name: item.created_by ?
            createdByAppUser ? item.created_by.name :  item.created_by.login :
            "",
        modified_at: item.modified_at,
        size: item.size ? formatBytes(item.size) : null,
        version: item.version,
        id: item.id,
        box_id: item.box_id, // required for compatibility when getting case documents
        type: item.type,
        sequence_id: (item.sequence_id && parseInt(item.sequence_id) + 1),
        file_extension: item.name ? item.name.split('.').pop() : ""
        //permissions: item.permissions
    };

    if (item.shared_link && item.shared_link.url) {
        row["shared_link"] = item.shared_link.url
    }

    //New - to prevent folderDetails from remounting unnecessarily - loop through metadata returned and add all values, even if not specified in resultsColumns
    if (item.metadata) {
        if (item.metadata["enterprise_"+enterpriseId]) {
            const templates = Object.entries(item.metadata["enterprise_"+enterpriseId])
            if (Array.isArray(templates)) {
                templates.forEach(metadataTemplate => {
                    let templateName = metadataTemplate[0];
                    Object.entries(metadataTemplate[1]).forEach(property => {
                        if (property[0].substring(0, 1) !== "$") {

                            const fieldConfig = getFieldConfig(metadataConfig, templateName, property[0]);
                            //convert dates to date format, strings returned from api.  Need to be dates for export functionality
                            let val = property[1]
                            if (fieldConfig && fieldConfig.type === "date") {
                                val = (val !== 0 && val !== "0" && val !== null) ? new Date(val) : val;
                            }
                            row[property[0]] = val;
                            row[templateName + "~" + property[0]] = val;
                        }
                    })
                })
            }
        }
    }

    //debug && console.log ('*** getDataForSearchResultsTableMetadataSearch row = ', row)

    //Loop through resultsColumns to ensure an entry added for each item (if metadata property is blank Box doesn't return it so won't be picked up above)
    let templateKey = "";
    let metadataKey = "";

    for (let i = 0; i < resultsColumns.length; i++) {
        templateKey = resultsColumns[i].templateKey;
        if (templateKey !== "n/a") {
            metadataKey = resultsColumns[i].metadataKey;
            if (!row.hasOwnProperty(templateKey + "~" + metadataKey)) {
                row[templateKey + "~" + metadataKey] = null;
            }
            if (!row.hasOwnProperty(metadataKey)) {
                row[metadataKey] = null;
            }
        }
    }

    templateKey = "";
    metadataKey = "";

    //Get the metadata specified in resultsColumns for the selected search config

    for (let i = 0; i < resultsColumns.length; i++) {

        templateKey = resultsColumns[i].templateKey;
        metadataKey = resultsColumns[i].metadataKey;

        //window.location.pathname.toLowerCase().includes("debug") && console.log ('templateKey = ', templateKey, "metadataKey = ", metadataKey);

        let val = row[templateKey + "~" + metadataKey]

        //Populate options for editable table if available
        let fieldConfig = getFieldConfig(metadataConfig, templateKey, metadataKey);
        if (fieldConfig) {
            if (fieldConfig.fieldType === "Select") {
                let options = fieldConfig.options;

                let lookup = {};
                let valInLookup = false;
                for (let p = 0; p < options.length; p++) {
                    lookup[options[p].label] = options[p].value
                    if (val === options[p].value) {
                        valInLookup = true;
                    }
                }

                //if value not in lookup list then append an option for it
                if (!valInLookup && val !== "") {
                    lookup[val] = val;
                }

                if (Object.entries(lookup).length > 0) {
                    //window.location.pathname.toLowerCase().includes("debug") && console.log ('set lookup to be ', lookup);
                    resultsColumns[i].lookup = lookup;
                }

            }
        }
    }

    return row;
}

export function dynamicSort(property) {

    //Sorts an array of objectes by the property name specified in the property parameter

    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers*/
        var result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
        return result * sortOrder;
    }
}

export async function setMetadataSelectedFolders(evt, data,  props, updateIsSettingMetadata){

    const pathVar = "/download";
    const params =  "?userId=" + props.userDetails.boxId;
    const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params ;

    //Build array of ids from the selected files
    let folders = [];
    data.map((folder) => {
        folders.push(folder.id);
        return null;
    });

    const request = {
        method: 'POST',
        headers: {"Authorization": "Bearer " + props.userDetails.accessToken},
        body: folders
    };

    window.location.pathname.toLowerCase().includes("debug") && console.log ('url = ', url, 'request = ', request);


    //await props.triggerRefreshAuthToken();
    //updateIsSettingMetadata(true, false);
    // fetch(url , request )
    //     .then(response => {
    //         if (response.ok) {
    //             window.location.pathname.toLowerCase().includes("debug") && console.log('response.ok - download RESPONSE = ', response);
    //             return (response.blob())
    //         } else {
    //             return null
    //         }
    //     })
    //     .then(result => {
    //
    //         const url = URL.createObjectURL(result);
    //         const fileName = files.length === 1 ? data[0].name : "download.zip" ;
    //
    //         // Create a new anchor element
    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = fileName;
    //         a.click();
    //         updateIsSettingMetadata(false, true);
    //         enqueueSnackbar("Update complete", {variant: 'success'});
    //
    //     })
    //     .catch(e => {
    //         window.location.pathname.toLowerCase().includes("debug") && console.log("download Exception:", e, " url:", url, "request: ", request);
    //         updateIsSettingMetadata(false, false)
    //         enqueueSnackbar("Error setting metadata: " + e, {variant: 'error'});
    //     })

}

// export function getWorkflowActions(evt, data, props,){
//     console.log ('getWorkflowActions', evt, data, props);
//     return (
//         [
//             {
//                 tooltip: "tooltip from config",
//                 icon: <span>react element here</span>,
//                 disabled: false,
//                 // onClick: (evt, data) => {
//                 //     console.log (evt, data)
//                 //     alert('on click action')
//                 // }
//             }
//         ]
//     )
// }