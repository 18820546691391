import React from "react";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import RenderMetadataField from "../../../../common/RenderMetadataField";
import PropTypes from "prop-types";

function GenericMetadata(props) {

    console.log ('GenericMetadata props =', props);
    const gridSize = 12;

    return (
        <React.Fragment>
            {
                props.uploadConfig.genericFields.length > 0 &&

                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle2"}  className={props.classes.header}>Select values common to all the files being uploaded</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={props.classes.paper}
                               style={{
                                   //width: "100%",
                                   paddingLeft: "20px",
                                   paddingTop: "20px",
                                   paddingRight: "20px",
                                   textAlign: "left"
                               }}>
                            <Grid container spacing={2}>
                                {
                                    props.uploadConfig.genericFields.map(genericField => {
                                        const fieldValue = (props.genericMetadata[genericField.templateKey + "~" + genericField.metadataKey])? props.genericMetadata[genericField.templateKey + "~" + genericField.metadataKey] : "";
                                        return (
                                            <Grid item xs={gridSize}>
                                                <FormControl
                                                    variant="standard"
                                                    fullWidth
                                                    style={{paddingBottom: '10px', margin: '0px'}}
                                                    key={"fc" + genericField.templateKey + "~" + genericField.metadataKey}>
                                                    <RenderMetadataField
                                                        parentClasses={props.classes}
                                                        fieldValue={fieldValue}
                                                        handleOnChange={props.handleOnChangeGenericMetadata}
                                                        metadataConfig={props.metadataConfig}
                                                        optionsConfig={props.optionsConfig}
                                                        metadataKey={genericField.metadataKey}
                                                        templateKey={genericField.templateKey}
                                                        usage={"upload"}
                                                        required={genericField.required}
                                                        // helperText={(genericField.required && (!fieldValue || fieldValue === "")) ? "Value required" : ""}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        );})
                                }
                            </Grid>
                        </Paper>
                    </Grid>

                </React.Fragment>
            }


        </React.Fragment>
    );
}

GenericMetadata.propTypes = {
    classes: PropTypes.object.isRequired,
    updateFileList: PropTypes.func.isRequired,
    files: PropTypes.array.isRequired,
    fixedMetadata: PropTypes.object.isRequired,
    genericMetadata: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    //selectFolderField: PropTypes.object.isRequired,
    handleOnChangeGenericMetadata: PropTypes.func.isRequired,
    handleOnChangeDocumentMetadata: PropTypes.func.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    uploadConfig: PropTypes.object.isRequired,
    maxWidth: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired
};
export default (GenericMetadata);
