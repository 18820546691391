import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import ViewFolderLinkDialog from "./ViewFolderLinkDialog";

function ViewFolderLinkButton(props) {

    const [showDialog, setShowDialog] = useState(false)

    const handleClickIcon = () => {
        setShowDialog(true)
    };

   const handleCloseDialog = () => {
        setShowDialog(false)
    };

    return (
        <div style={{display: "inline"}}>
            <Tooltip title={props.actionConfig.label}>
                <IconButton
                    aria-label="Menu"
                    aria-haspopup="true"
                    color = "secondary"
                    onClick={handleClickIcon}
                    size="medium">
                    <i className='material-icons'>
                        link
                    </i>
                </IconButton>
            </Tooltip>

            {
                showDialog &&
                <ViewFolderLinkDialog
                    folderId={props.folderId}
                    handleCloseDialog={handleCloseDialog}
                    actionConfig={props.actionConfig}
                />
            }

        </div>
    );

}

ViewFolderLinkButton.propTypes = {
    folderId: PropTypes.string.isRequired,
    actionConfig: PropTypes.object.isRequired
};

export default ViewFolderLinkButton;