import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { enqueueSnackbar } from 'notistack'
import RelatedCaseDialog from "./RelatedCaseDialog";

const styles = theme => ({})

function RelatedCaseSearchButtons(props) {

    const [showDialog, setShowDialog] = useState(false);
    const [searchConfig, setSearchConfig] = useState(false);
    const debug = window.location.pathname.toLowerCase().includes("debug");

    const handleClickIcon = (searchConfig) => {
        setShowDialog(true)
        setSearchConfig(searchConfig)
    };

    const handleCloseDialog = () => {
        setShowDialog(false)
    };

    return (
        <React.Fragment>
            {
                props.actionConfig && Array.isArray(props.actionConfig) &&

                props.actionConfig.map(action => {
                    debug && console.log ('RelatedCaseSearch action=', action)

                    //check displayWhen
                    let display = false;
                    if (action.displayWhen ) {
                        if (action.displayWhen.templateKey && action.displayWhen.metadataKey) {
                            let metadataValue;
                            if (props.folderDetails[action.displayWhen.templateKey + "~" + action.displayWhen.metadataKey]) {
                                metadataValue = props.folderDetails[action.displayWhen.templateKey + "~" + action.displayWhen.metadataKey]
                                display = action.displayWhen.value === metadataValue
                            }
                        }
                    }

                    return (
                        <React.Fragment>
                            {
                                display &&
                                <Tooltip title={action.label}>
                                    <IconButton
                                        colour={"inherit"}
                                        aria-label={"Menu"}
                                        aria-haspopup="true"
                                        color={"secondary"}
                                        onClick={() => handleClickIcon(action.searchConfig)}
                                        size="medium">
                                        <i className='material-icons'>{action.icon}</i>
                                    </IconButton>
                                </Tooltip>
                            }
                        </React.Fragment>
                    );}
                )

            }

            {
                showDialog &&
                <RelatedCaseDialog
                    userDetails={props.userDetails}
                    handleCloseDialog={handleCloseDialog}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    searchConfig={searchConfig}
                    metadataConfig={props.metadataConfig}
                    optionsConfig={props.optionsConfig}
                    folderDetails={props.folderDetails}
                />
            }

        </React.Fragment>
    );
}

RelatedCaseSearchButtons.propTypes = {
    classes: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    folderDetails: PropTypes.object.isRequired,
    actionConfig: PropTypes.array.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired
};

export default (withStyles(styles, { withTheme: true })(RelatedCaseSearchButtons));