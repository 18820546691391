import React, {useEffect, useReducer} from "react";
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Divider from "@mui/material/Divider/Divider";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import {getErrorMessageFromResponse, hasAccess} from "../common/helper"
import { enqueueSnackbar } from 'notistack'
import {Badge} from "@mui/material";
import {useTranslation} from "react-i18next";

const styles = (theme) => ({
    listIcon: {
        color: theme.menuListItem.colour
    },
    menuListItem: {
        color: theme.menuListItem.colour
    },
});

function TaskMenu(props) {

    const {classes} = props;
    const showAllTasks = props.actionsConfig.tasksWorkspaceAll && props.actionsConfig.tasksWorkspaceAll.enabled && hasAccess(props.actionsConfig.tasksWorkspaceAll, props.userDetails.userRoles)
    const [, setIsFetching] = React.useState(false);

    const { t } = useTranslation();
    const translate = (val) => window.REACT_APP_ENABLE_TRANSLATION === "true" ? t(val) : val

    async function getTasks() {

        const debug = window.location.pathname.toLowerCase().includes("debug");
        const url = window.REACT_APP_TASK_API_BASE_URL + "/api/elastic/tasks/incomplete";
        const request = {
            headers: {
                "Authorization": "Bearer " + props.userDetails.accessToken,
                "case-token": props.userDetails.caseAccessToken
            },
        };

        debug && console.log ("getTasks url = ", url, "request = ", request);

        let tasks=[]
        setIsFetching(true)

        await props.triggerRefreshAuthToken();
        await fetch(url , request )
            .then(response => {
                debug && console.log('getTasks RESPONSE: ', response);
                if (response.ok) {
                    return (response.json()).then(result => {
                        debug && console.log ('getTasks response.json = ' , result);
                        const todayDate = new Date();
                        result.forEach(task => {
                            task.statusDisp = task.completed ? "Complete" : "Not started"
                            task.assignee = task.assign_to && task.assign_to.login
                            if (!task.completed && task.due_at) {
                                const dueDate = new Date(task.due_at)
                                task.overdue = dueDate <= todayDate;
                            }
                        })
                        tasks = result
                    })
                } else {
                    //response not ok
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving tasks'))
                        .then(message => {
                            enqueueSnackbar(message, {variant: 'error'});
                        })
                }
            })
            .catch(e => {
                enqueueSnackbar("Error retrieving folder tasks: " + e.toString(), {variant: 'error'});
                debug && console.log("getTasks Exception:", e, " url:", url, "request: ", request);
            })

        return tasks
    }
    
    let initialState =  {
        isFetching: false,
        tasks: [],
        tasksOverdue: []
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {

        async function initTasks() {

            setIsFetching(true);
            
            let tasks = await getTasks();

            return (
                dispatch({type: "TASKS", value: tasks})
            )
           
        }
        initTasks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[] )

    function reducer(state, action) {

        switch (action.type) {
            case "TASKS": {

                let filteredOverdue = action.value.filter(task => task.overdue);

                setIsFetching(false);
                return {
                    ...state,
                    tasks: action.value,
                    tasksOverdue: filteredOverdue
                }
            }

            default:
                return state
        }
    }

    // function updateTasksBadge(tasks) {
    //     dispatch({type: "TASKS", value: tasks})
    // }


    return (
        <React.Fragment>
            {
                showAllTasks &&
                    <React.Fragment>

                        {/*<ListSubheader disableSticky color={'primary'}>Tasks</ListSubheader>*/}
                        {/**/}
                        {
                            showAllTasks &&

                            <ListItem
                                button={true}
                                key={'tasks'}
                                id={'tasks'}
                                onClick={props.openTasks}
                            >

                                <ListItemIcon className={classes.listIcon}>
                                    <Badge badgeContent={state.tasksOverdue.length} color="error" >
                                    <i className='material-icons'>playlist_add_check</i>
                                    </Badge>
                                </ListItemIcon>

                                <ListItemText
                                    className={classes.menuListItem}
                                    primary={translate('Tasks')}
                                />
                            </ListItem>

                        }
                        <Divider/>
                    </React.Fragment>

            }
        </React.Fragment>
    );
}


TaskMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    openTasks: PropTypes.func.isRequired
};

export default (withStyles(styles, { withTheme: true })(TaskMenu));