import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import Button from "@mui/material/Button/Button";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import List from "@mui/material/List/List";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ListSubheader from "@mui/material/ListSubheader/ListSubheader";
import ReactJson from 'react-json-view'

const styles = theme => ({

    buttons: {
        textAlign: "right"
    },

    formControl : {
        width: "100%"
    },

    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        fontWeight: 'bold'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },

});

const INITIAL_STATE = {
    settings: {},
    settingsChanged: false,
    expanded: {},
};

class Metadata extends React.Component {


    constructor(props) {

        super(props);

        this.state = INITIAL_STATE;

        this.handleEdit = this.handleEdit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);

    }

    UNSAFE_componentWillMount() {
        this.getSettings();

    }

    handleEdit() {
        this.setState(
            {editMode: true}
        )
    }

    getSettings() {
        //let response = require("../../resources/stubs/configMetadata.json");
        //fetch from public folder as we do in home.jsx - not tested
        fetch('config/configMetadata.json')
            .then(response => response.json())
            .then(data => {
                this.setState({settings: data});
            })
    }

    handleOnChange = event => {

        //need this temporary object to ensure all values are maintained
        let newSettings = this.state.settings;

        switch(event.target.type) {
            case "number":
                newSettings[event.target.name] = event.target.valueAsNumber;
                break;
            case "date":
                newSettings[event.target.name] = event.target.valueAsDate;
                break;
            default:
                newSettings[event.target.name] = event.target.value;
        }

        this.setState({"settings" : newSettings});
        this.setState({"settingsChanged": true});

    };

    handleExpandCollapse = panel => (event) => {

        this.setState({panel : true } )
    };

    render() {

        const { classes} = this.props;

        let metadataTemplateArray = []; //to get list of template headers

        Object.entries( this.state.settings).forEach(entry => {
            //window.location.pathname.toLowerCase().includes("debug") && console.log ('entry: ', entry);
            metadataTemplateArray.push(entry);
        });


        return(

            <div className={classes.root}>

                {metadataTemplateArray.map(
                    m => {
                        let metadataPropertyArray = [];
                        Object.entries(m[1].metadata).forEach(entry => {
                            metadataPropertyArray.push(entry);
                        });
                        return(
                            <Accordion
                                expanded = {this.state[m[0]]}
                                onChange={this.handleExpandCollapse(m[0])}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className={classes.heading}>{m[1].templateLabel}</Typography>
                                    <Typography className={classes.secondaryHeading}>{m[0]}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        <ListSubheader disableSticky>Metadata</ListSubheader>
                                        {metadataPropertyArray.map(
                                            p => {
                                                return(
                                                    <ListItem>
                                                    <ListItemText
                                                        primary= {p[0]}
                                                        secondary=<ReactJson src={p[1]} name ={p[0]} collapsed ={true} enableClipboard={false}/>
                                                        />
                                                    </ListItem>
                                                )
                                            })
                                        }

                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        )},
                    this,
                )}

                    <br/><br/>

                <div className={classes.buttons}>
                    {
                        this.state.editMode ?
                            <Button variant="contained"
                                    color="secondary"
                                    disabled={!this.state.settingsChanged}>Submit</Button> :
                            <Button variant="contained"
                                    color="secondary"
                                    onClick={this.handleEdit}>Edit</Button>
                    }
                </div>


            </div>

        )
    }
}

export default withStyles(styles, { withTheme: true })(Metadata);

Metadata.propTypes = {
    classes: PropTypes.object.isRequired,
};
