import React, {useEffect} from 'react';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Divider from "@mui/material/Divider/Divider";
import ListSubheader from "@mui/material/ListSubheader/ListSubheader";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import {hasAccess} from "../common/helper"

const styles = (theme) => ({
    listIcon: {
        color: theme.menuListItem.colour
    },
    menuListItem: {
        color: theme.menuListItem.colour
    },
});

function DashboardMenu(props) {

   // const debug = window.location.pathname.toLowerCase().includes("debug");

    useEffect(() => {
        //debug && console.log('DashboardMenu props=', props)
        },
        [])

    const classes = props.classes;


    let userDashboards = [];
    if (props.dashboardsConfig.length > 0 && window.REACT_APP_ENV !== "") {
        props.dashboardsConfig.forEach(dashboard => {
            if (dashboard.enabled && hasAccess(dashboard, props.userDetails.userRoles)) {
                userDashboards.push(dashboard)
            }
        })

    }

    //debug && console.log ('userDashboards=', userDashboards)

    const showMainDashboard = props.actionConfig.enabled && hasAccess(props.actionConfig, props.userDetails.userRoles);

    return (

        <React.Fragment>

            {/*Only show Reporting header if the user has access to at least one dashboard*/}
            {
                (showMainDashboard || userDashboards.length > 0) &&
                <ListSubheader disableSticky color={'primary'}>Reporting</ListSubheader>
            }

            {
                //    Main Dashboard
                showMainDashboard &&

                <React.Fragment>
                    <ListItem
                        className={classes.listItemText}
                        button={true} key={'dashboardMain'} id={'dashboardMain'} onClick={() => props.handleShowDashboard()}
                    >
                        <ListItemIcon className={classes.listIcon}><i className='material-icons'>dashboard</i></ListItemIcon>
                        <ListItemText className={classes.menuListItem} primary={'Dashboard'}/>
                    </ListItem>

                </React.Fragment>
            }

            {

                //additional user dashboards from dashboardsConfig

                userDashboards.length > 0 && window.REACT_APP_ENV !== "" &&

                <React.Fragment>

                    {userDashboards.map(
                        (entry, index) => {
                            return (
                                <ListItem button={true} key={'dash' + index} id={'dash' + index}
                                          className={classes.nestedLevel1}
                                          onClick={() => props.handleShowDashboard(entry.url[window.REACT_APP_ENV], entry.label)}>
                                    <ListItemIcon className={classes.listIcon}><i
                                        className='material-icons'>{entry.icon}</i></ListItemIcon>
                                    <ListItemText primary={entry.label}/>
                                </ListItem>
                            )
                        })
                    }

                    <Divider/>

                </React.Fragment>
            }

            {
                (showMainDashboard || userDashboards.length > 0) &&
                <Divider/>
            }

        </React.Fragment>
    )
}


DashboardMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    actionConfig: PropTypes.object.isRequired,
    dashboardsConfig: PropTypes.array.isRequired,
    handleShowDashboard: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(DashboardMenu);