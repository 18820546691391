import React, {useEffect, useState} from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack'
import {withStyles} from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import {Grid} from "@mui/material";
import Input from "@mui/material/Input/Input";
import { getErrorMessageFromResponse, hasAccess, validateEmail} from "../../common/helper";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";

const styles = (theme) => ({
    disabled: {}, //required for the rule below to work
    root: {
        '&.Mui-disabled': {
            color: theme.disabledField.colour
        },
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    },
    list: {
        width: '100%',
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
    },

    listItem: {
        minWidth: 0,
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
    },
    listContainer: {
        overflow: 'auto',
    },
    listItemTextTransfer: {
        fontSize: theme.typography.pxToRem(16),
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
        //fontSize: '30px',
    },
    button: {
        margin: theme.spacing(0.5, 0),
        width: '100%',
        minWidth: 32
    },

    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

    rootRadio: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconRadio: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIconRadio: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

});

function EditFolderUserDialog(props) {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    //debug && console.log ('EditFolderUserDialog props = ', props);

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [status, setStatus] = useState("");
    const [groups, setGroups] = useState([])
    const [userName, setUserName] = useState("")
    const [deactivate, setDeactivate] = useState(false);
    const [retrieveSuccess, setRetrieveSuccess] = useState(false);
    const [isRetrieving, setIsRetrieving] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {

        async function retrieve(user) {

            setIsRetrieving(true)

            const url = window.REACT_APP_SECURITY_API_BASE_URL + window.REACT_APP_SECURITY_API_USER_ENDPOINT + "/" + encodeURIComponent(user);

            const request = {
                method: 'GET',
                headers: {
                    "Authorization": "Bearer " + props.userDetails.accessToken,
                    "Content-Type": "application/json",
                },
            }

            debug && console.log('retrieveUser url=', url, 'request=', request);

            await props.triggerRefreshAuthToken();
            await fetch(url, request)
                .then(response => {
                    debug && console.log('retrieveUser response=', response);
                    if (response.ok) {
                        return(response.json())
                    } else {
                        Promise.resolve(getErrorMessageFromResponse(response, 'retrieving user'))
                            .then(message => {
                                enqueueSnackbar(message, {variant: 'error'});
                            })
                        debug && console.log("retrieve user error.  url:", url, "request: ", request);
                        return null
                    }
                })
                .then(data => {
                    debug && console.log('editUser response.json = ', data)

                    if (data) {

                        setEmail(data.email)
                        setFirstName(data.firstName)
                        setLastName(data.lastName)
                        setStatus(data.status)
                        setUserName(data.username)
                        setGroups(data.groups)
                        setIsRetrieving(false)
                        setRetrieveSuccess(true)

                    } else {
                        setIsRetrieving(false)
                        setRetrieveSuccess(false)
                        handleCloseDialog()
                    }



                })
                .catch(e => {
                    enqueueSnackbar("Error retrieving user details (exception: " + e.message + ")" , {variant: 'error'});
                    debug && console.log("editUser  exception:" , e);
                    setIsRetrieving(false)
                })
        }

        debug && console.log ('EditFolderUserDialog useEffect props=', props)

        retrieve(props.user)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[] )


    function handleCloseDialog() {
        props.handleCloseDialog();
    }

    function validateChanges() {

        //check all fields complete and email format is valid
        let isValid = false;

        const allFieldsComplete = email && firstName && lastName ;

        if (allFieldsComplete) {
            if (validateEmail(email)) {
                isValid = true
            } else {
                enqueueSnackbar("Please enter a valid email address" , {variant: 'error'});
            }
        } else {
            enqueueSnackbar("Please complete all fields before submitting" , {variant: 'error'});
        }
        return isValid
    }

    function handleSaveChangesClick() {
        if (validateChanges()) {
            saveChanges()
        }
    }

    async function saveChanges() {

        setIsSaving(true)

        const url = window.REACT_APP_SECURITY_API_BASE_URL + window.REACT_APP_SECURITY_API_USER_ENDPOINT +  "/" + encodeURIComponent(userName);
        const body = {
            "email": email.toLowerCase(),
            "firstName": firstName,
            "lastName": lastName,
            "groups": groups,
            "deactivate": deactivate
        }

        const request = {
            method: 'PUT',
            headers: {
                "Authorization": "Bearer " + props.userDetails.accessToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }

        debug && console.log('editUser url=', url, 'BODY', body, 'request=', request);

        await props.triggerRefreshAuthToken();

        await fetch(url, request)
            .then(response => {
                debug && console.log('saveChanges response=', response);
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'saving changes to user details'))
                        .then(message => {
                            enqueueSnackbar(message, {variant: 'error'});
                        })
                    debug && console.log("save user error.  url:", url, "request: ", request);
                    return null
                }

            })
            .then(data => {
                debug && console.log('saveChanges response.json = ', data)

                if (data) {
                    enqueueSnackbar("User details updated successfully" , {variant: 'success'});
                    handleCloseDialog();
                }

                setIsSaving(false)

            })
            .catch(e => {
                enqueueSnackbar("Error saving user details (exception: " + e.message + ")" , {variant: 'error'});
                debug && console.log("saveChanges  exception:" , e);
                setIsSaving(false)
            })
    }


    const {classes} = props;
    const activeUser =  status !== "DEPROVISIONED"
    const editEnabled = props.actionConfig && props.actionConfig.enabled && hasAccess(props.actionConfig,props.userDetails.userRoles)

    console.log ('editEnabled =', editEnabled);
    console.log ('retrieveSuccess =', retrieveSuccess);
    console.log ('status = ', status);
    console.log ('  editEnabled && retrieveSuccess && status = ', editEnabled && retrieveSuccess && status)

    return (
        <div>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth= {retrieveSuccess ? "md" : "sm"}
                keepMounted={false}
            >
                <DialogTitle>{editEnabled ? 'Edit User' : 'User Details'}</DialogTitle>
                <DialogContent>
                    {
                        isRetrieving ?

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel shrink htmlFor="userName" className={classes.customLabel}>Retrieving user details for </InputLabel>
                                        <Input
                                            id="userName" name="userName" value={props.user}
                                            type="text" margin="none" variant="outlined"
                                            readOnly={true} disabled={true}
                                        />
                                    </FormControl>

                                </Grid>
                            </Grid> :

                            retrieveSuccess &&

                                <Grid container spacing={2}>

                                    <Grid item xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel shrink htmlFor="email" className={classes.customLabel}>Email</InputLabel>
                                            <Input
                                                id="email" name="email" value={email}
                                                type="text" margin="none" variant="outlined"
                                                placeholder="Enter email" autoFocus={true}
                                                onChange={e => setEmail(e.target.value)}
                                                readOnly={!editEnabled || !activeUser || isSaving} disabled={!editEnabled || !activeUser || isSaving}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel shrink htmlFor="userName" className={classes.customLabel}>Username</InputLabel>
                                            <Input
                                                id="userName" name="userName" value={userName}
                                                type="text" margin="none" variant="outlined"
                                                readOnly={true} disabled={true}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel shrink htmlFor="firstName" className={classes.customLabel}>First Name</InputLabel>
                                            <Input
                                                id="firstName" name="firstName" value={firstName}
                                                type="text" margin="none" variant="outlined"
                                                placeholder="Enter first name"
                                                onChange={e => setFirstName(e.target.value)}
                                                readOnly={!editEnabled || !activeUser || isSaving} disabled={!editEnabled || !activeUser || isSaving}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel shrink htmlFor="status" className={classes.customLabel}>Status</InputLabel>
                                            <Input
                                                id="status" name="status" value={status}
                                                type="text" margin="none" variant="outlined"
                                                readOnly={true} disabled={true}
                                                style={!activeUser ? {color: 'red'}: {}}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel shrink htmlFor="lastName" className={classes.customLabel}>Last Name</InputLabel>
                                            <Input
                                                id="lastName" name="lastName" value={lastName}
                                                type="text" margin="none" variant="outlined"
                                                placeholder="Enter last name"
                                                onChange={e => setLastName(e.target.value)}
                                                readOnly={!editEnabled || !activeUser || isSaving} disabled={!editEnabled || !activeUser || isSaving}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel shrink htmlFor="groups" className={classes.customLabel}>Groups</InputLabel>
                                            <Input
                                                id="groups" name="groups" value={groups.join('; ')}
                                                type="text" margin="none" variant="outlined"
                                                readOnly={true} disabled={true}
                                            />

                                        </FormControl>
                                    </Grid>

                                    {
                                        activeUser &&

                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                value="deactivate"
                                                checked={deactivate}
                                                control={<Checkbox color="secondary" onChange={(event) => {setDeactivate(event.target.checked)}} />}
                                                label= {props.actionConfig.deactivateLabel ? props.actionConfig.deactivateLabel : "Deactivate User"}
                                                labelPlacement="end"
                                                readOnly={!editEnabled || !activeUser || isSaving} disabled={!editEnabled || !activeUser || isSaving}
                                            />
                                        </Grid>
                                    }

                                </Grid>
                    }

                </DialogContent>

                <DialogActions>
                    <React.Fragment>
                        {
                            !isRetrieving && !isSaving &&

                            <Button onClick={() => handleCloseDialog()} variant="contained" color= { retrieveSuccess ? "secondary"  : "grey"} >
                                { retrieveSuccess ? "Close"  : "Cancel"}
                            </Button>
                        }
                        {
                            editEnabled && retrieveSuccess && status !== "DEPROVISIONED" &&
                            <Button color="secondary" onClick={() => handleSaveChangesClick()} variant={"contained"} disabled={isSaving}>
                                {isSaving ? "Saving..." : "Save Changes"}
                            </Button>
                        }
                    </React.Fragment>


                </DialogActions>
                {
                    (isRetrieving || isSaving) &&
                    <LinearProgress variant={"contained"} color={"primary"}/>
                }

            </Dialog>
        </div>
    );
}


EditFolderUserDialog.propTypes = {
    actionConfig: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired
};


export default (withStyles(styles, { withTheme: true })(EditFolderUserDialog));