import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import List from "@mui/material/List/List";
import { enqueueSnackbar } from 'notistack'
import ListSubheader from "@mui/material/ListSubheader/ListSubheader";
import IconButton from "@mui/material/IconButton/IconButton";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Button from "@mui/material/Button/Button";
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {withStyles} from '@mui/styles';
import Paper from "@mui/material/Paper";
import {Grid} from "@mui/material";
import MaterialTable from"@material-table/core"
import {getErrorMessageFromResponse} from "../../common/helper";

const styles = theme => ({
    paper: {
        textAlign: 'left',
        color: theme.palette.text.secondary,
        paddingTop: '0px',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
});

const tableTheme = theme => createTheme(({

    // to merge with parent theme
    ...theme,

    //https://material-ui.com/customization/globals/#css
    //note, overrides the overrides in the parent theme in app.css
    components: {
        MuiToolbar: {
            styleOverrides: {
                gutters: {
                    paddingLeft: "0px"
                },
                root: {
                    paddingLeft: "0px"
                },
                regular: {
                    // paddingLeft: "0px"
                }
            }
        },
        MTableToolbar: {
            styleOverrides: {
                root: {
                    // paddingLeft: "0px"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '0px',
                    //paddingLeft: "24px"
                }
            }
        },

        // MuiIconButton: {
        //     styleOverrides: {
        //         root: {
        //             color: theme.palette.secondary.main,
        //         },
        //         colorInherit: {
        //             color: theme.palette.secondary.main
        //         }
        //     }
        // },

        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: theme.palette.secondary.main
                }
            }
        }
    }
}));


function TaskDescription(props) {
    return(
        <div style={{width: '100%'}}>
            {props.description}
        </div>
    )
}
TaskDescription.propTypes = {
    description: PropTypes.string.isRequired
};


function CompleteTaskButton(props) {

    //window.location.pathname.toLowerCase().includes("debug") && console.log ('CompleteTaskButton props=', props)

    if (props.task && props.task.assign_to && props.task.assign_to.login && props.task.assign_to.login === props.userDetails.userEmail && !props.task.completed)  {
        return (
            <Tooltip title={"Mark as Complete"}>
                <IconButton
                    aria-label={"Mark as Complete"}
                    aria-haspopup="true"
                    color ="secondary"
                    onClick={() => props.completeFolderTask(props.task.task_assignment_collection.entries[0])}
                    size="medium">
                    <i className='material-icons' style ={{color: "secondary"}}>
                        <i className="material-icons">done</i>
                    </i>
                </IconButton>
            </Tooltip>
        );
    } else {
        //in case of error retrieving tasks
        return(<span/>)
    }
}

CompleteTaskButton.propTypes = {
    task: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    completeFolderTask: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired
};

function TaskStatusButton(props) {

    const statusConfig = {
        "complete": {
            "colour": "#4caf50"
        },
        "in progress": {
            "colour": "#33B2FF"
        },
        "not started": {
            "colour": "#FFDD33"
        },
        "overdue": {
            "colour": "#FF3339"
        }
    }

    //window.location.pathname.toLowerCase().includes("debug") && console.log ('TaskStatusButton props=', props)

    if (props.status)  {
        const config = statusConfig[props.status.toLowerCase()]

        return(
            <Button variant="contained" disableElevation disableRipple
                    style={{backgroundColor: props.overdue ? statusConfig.overdue.colour : (config ? config.colour : "black") , width: '120px', height:"16px", borderRadius: '8px'}}>
                {props.status}
            </Button>
        )
    } else {
        return(<span/>)
    }
}

TaskStatusButton.propTypes = {
    status: PropTypes.string.isRequired,
    overdue: PropTypes.bool.isRequired
};


const INITIAL_STATE = {
    tasks: [],
    filter: ""
};

class AllTasks extends React.Component {

    constructor(props) {

        super(props);

        this.completeFolderTask = this.completeFolderTask.bind(this);

        INITIAL_STATE.columns = [
            {
                title: 'Task', field: 'message', width:300,
                cellStyle: {width: 300, maxWidth: 300, padding: 0, fontSize: "13px", fontFamily: "roboto"},
                headerStyle: {width: 300, maxWidth: 300, padding: 0},
            },
            { title: 'Owner', field: 'assignee', cellStyle: { fontSize: "13px", fontFamily: "roboto"}},
            {
                title: '', field: 'id',
                filtering: false,
                sorting: false,
                cellStyle: {padding: 0,fontSize: "13px", fontFamily: "roboto"},
                headerStyle: {padding: 0},
                disableClick: true,
                editComponent: rowData => {return (<span/>)},
                render: rowData => {return (
                    rowData.overdue ?
                        <Tooltip title={"Overdue"}><i className="material-icons" style={{color: "#FF3339"}}>alarm</i></Tooltip> :
                        <span/>
                )}
            },
            { title: 'Due date', field: 'due_at', type: 'date', cellStyle: { fontSize: "13px", fontFamily: "roboto"}},
            {
                title: 'Status',
                field: 'id',
                filtering: false,
                sorting: false,
                cellStyle: {padding: 0,},
                headerStyle: {padding: 0},
                disableClick: true,
                editComponent: rowData => {return (<span/>)},
                render: rowData => {return (<TaskStatusButton status={rowData.statusDisp} overdue={rowData.overdue}/>)}
            },
            {
                title: 'Actions',
                field: 'id',
                filtering: false,
                sorting: false,
                cellStyle: {padding: 0,fontSize: "13px", fontFamily: "roboto"},
                headerStyle: {padding: 0, fontSize: "13px", fontFamily: "roboto"},
                disableClick: true,
                editComponent: rowData => {return (<span/>)},
                render: rowData => {return (<CompleteTaskButton userDetails={props.userDetails} classes={props.classes} completeFolderTask={this.completeFolderTask} task={rowData}/>)}
            }]

        this.state = INITIAL_STATE;

    }

    UNSAFE_componentWillMount() {
        //window.location.pathname.toLowerCase().includes("debug") && console.log ('AllTasks props=', this.props);
        //if (this.props.boxFolderID !== 0) {
            this.getAllTasks();
        //}
    }

    componentDidMount(){

       // window.location.pathname.toLowerCase().includes("debug") && console.log('AllTasks componentDidMount boxFolderID:', this.props.boxFolderID);

        //auto-refresh comments at interval set in config
        this.interval = setInterval(() => this.getAllTasks(), this.props.folderTasksConfig.pollingIntervalMilliseconds);

    }

    componentWillUnmount() {
        this.props.remountComponent();
    }

    filterMy() {
        this.setState({filter: "my"})
    }

    filterOverdue() {
        this.setState({filter: "overdue"})
    }

    filterClear() {
        this.setState({filter: ""})
    }

    getAllTasks = async () => {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        const url = window.REACT_APP_TASK_API_BASE_URL + "/api/elastic/tasks/incomplete"
        await this.props.triggerRefreshAuthToken();
        const request = {
            headers: {
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                "case-token": this.props.userDetails.caseAccessToken
            },
        };
        debug && console.log ("getAllTasks url = ", url, "request = ", request);

        this.setState({isFetching: true});

        fetch(url , request )
            .then(response => {
                debug && console.log('getAllTasks RESPONSE: ', response);
                if (response.ok) {
                    return(response.json())
                        .then(result => {
                            debug && console.log ('getAllTasks response.json = ' , result);
                            const todayDate = new Date();
                            result.forEach(task => {
                                task.statusDisp = task.completed ? "Complete" : "Not started"
                                task.assignee = task.assign_to && task.assign_to.login
                                if (!task.completed && task.due_at) {
                                    const dueDate = new Date(task.due_at)
                                    task.overdue = dueDate <= todayDate;
                                }
                            })
                            this.setState({
                                tasks: result,
                                isFetching: false})

                            if (this.props.updateTasksBadge) {
                                this.props.updateTasksBadge(result)
                            }
                        })
                } else {
                    //response not ok
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving tasks'))
                        .then(message => {
                            enqueueSnackbar(message, {variant: 'error'});
                        })
                    this.setState({isFetching: false})
                }
            })

            .catch(e => {
                enqueueSnackbar("Error retrieving tasks: " + e.toString(), {variant: 'error'});
                debug && console.log("getAllTasks Exception:", e, " url:", url, "request: ", request);
                this.setState({isFetching: false})
            })
    };

    completeFolderTask = assignment => {

        const debug = window.location.pathname.toLowerCase().includes("debug");

        debug && console.log (' - Complete Folder Task - ');

        this.setState({isFetching: true});

        //let assignment = this.state.tasks[0].task_assignment_collection.entries[0];
        debug && console.log ("id = ", this.props.userDetails.boxId);

        const queryStr =  "?id=" + this.props.userDetails.boxId;
        const url = window.REACT_APP_TASK_API_BASE_URL + window.REACT_APP_TASK_API_FOLDER_ASSIGNMENT + queryStr;
        const request = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                "case-token": this.props.userDetails.caseAccessToken
            },
            body: JSON.stringify(assignment)
        };

        debug && console.log ("completeFolderTask url:", url, "BODY=",assignment, "request=", request);

        fetch(url , request )
            .then(response => {
                debug && console.log('completeFolderTask RESPONSE: ', response);
                if (response.ok) {
                    debug && console.log ("completeFolderTask response status = OK ");
                    enqueueSnackbar("Task completed successfully", {variant: 'success'});
                    return(response.json())
                } else {
                    debug && console.log ("completeFolderTask response status not OK ");
                    Promise.resolve(getErrorMessageFromResponse(response, 'completing task assignment'))
                        .then(message => {
                            enqueueSnackbar(message, {variant: 'error'})
                        })

                    debug && console.log("error.  url:", url, "request: ", request);
                    return null
                }
            })
            .then(result => {
                debug && console.log ('result json = ' , result);
                //refresh tasks
                this.getAllTasks();
            })
            .catch(e => {
                enqueueSnackbar("Error retrieving tasks: " + e.toString(), {variant: 'error'});
                debug && console.log("completeFolderTask Exception:", e, " url:", url, "request: ", request);
                this.setState({isFetching: false})
            })
    };

    render() {
        
        //const classes = this.props.classes;

        return (
            <div style={{width: "100%"}}>
                <Grid container spacing={0} style={{overflowY:'auto'}}>
                    <Grid item xs={12}>
                        <Paper>

                            <Typography variant={"h6"} color={"textPrimary"} style={{paddingLeft: "12px", paddingTop: "0px"}}>
                                {this.state.tasks.length > 0 ? "All Tasks (" + this.state.tasks.length + ")" : "Tasks"}

                                <Tooltip title={"Refresh tasks"}>
                                    <IconButton
                                        aria-label="Menu"
                                        aria-haspopup="true"
                                        onClick = {() => this.getAllTasks()}
                                        color = "secondary"
                                        size="medium">
                                        <i className='material-icons md-36'>refresh</i>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={this.state.filter === "my" ? "Show all" : "Show my tasks"}>
                                    <IconButton
                                        aria-label="Menu"
                                        aria-haspopup="true"
                                        onClick = {() => this.state.filter === "my" ? this.filterClear() : this.filterMy()}
                                        color = {this.state.filter === "my" ? "primary" : "secondary"}
                                        size="medium">
                                        <i className='material-icons md-36'>{this.state.filter === "my" ? "person" : "people"}</i>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title={this.state.filter === "overdue" ? "Show all" : "Show overdue"}>
                                    <IconButton
                                        aria-label="Menu"
                                        aria-haspopup="true"
                                        onClick = {() => this.state.filter === "overdue"? this.filterClear() : this.filterOverdue()}
                                        color = {this.state.filter === "overdue" ? "primary" : "secondary"}
                                        size="medium">
                                        <i className='material-icons md-36'>{this.state.filter === "overdue" ? "alarm" : "access_time"}</i>
                                    </IconButton>
                                </Tooltip>

                            </Typography>



                            <div style={{width: "100%"}}>

                                <List>
                                    {
                                        this.state.isFetching ?
                                            <React.Fragment>
                                                <div>
                                                    <Typography variant="subtitle1">
                                                        <ListSubheader disableSticky>
                                                            Retrieving tasks...
                                                        </ListSubheader>
                                                    </Typography>
                                                </div>
                                            </React.Fragment> :

                                            this.state.tasks && this.state.tasks.length > 0 ?

                                                 <StyledEngineProvider injectFirst>
                                                     <ThemeProvider theme={tableTheme}>

                                                        <MaterialTable
                                                            title={""}
                                                            columns={this.state.columns}
                                                            data={
                                                                this.state.filter === "overdue" ?
                                                                    this.state.tasks.filter(task => task.overdue):
                                                                    this.state.filter === "my" ?
                                                                        this.state.tasks.filter(task => task.assignee === this.props.userDetails.userEmail):
                                                                        this.state.tasks}
                                                            options={{
                                                                pageSize: 200,
                                                                pageSizeOptions: [],
                                                                toolbar: false,
                                                                paging: false
                                                            }}
                                                            localization={{
                                                                body: {
                                                                    emptyDataSourceMessage: '',
                                                                    editTooltip: 'Edit'
                                                                },
                                                                toolbar: {
                                                                    searchTooltip: 'Filter',
                                                                    searchPlaceholder: 'Filter'
                                                                },
                                                                header:{
                                                                    actions: ""
                                                                }
                                                            }}
                                                            detailPanel={
                                                                [
                                                                    {
                                                                        tooltip: 'Description',
                                                                        disabled: false,
                                                                        width: 48,
                                                                        cellStyle: {
                                                                            width: '48px',
                                                                            maxWidth: '50px',
                                                                            padding: 0,
                                                                            // marginRight: 40
                                                                        },
                                                                        headerStyle: {
                                                                            width: '48px',
                                                                            maxWidth: '50px',
                                                                            padding: 0,
                                                                            // marginRight: 40
                                                                        },
                                                                        render: rowData => {
                                                                            return (
                                                                                <div style={{height: "80px", paddingLeft: "42px", border: "solid", backgroundColor: "lightgrey", borderColor: "lightgrey"}}>
                                                                                    <TaskDescription description={rowData?.rowData?.description}/>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        />
                                                     </ThemeProvider>
                                                 </StyledEngineProvider>
                                                    :
                                                <ListSubheader disableSticky>No Tasks</ListSubheader>
                                    }
                                </List>
                            </div>
                        </Paper>

                    </Grid>
                </Grid>

            </div>
        );
    }
}

AllTasks.propTypes = {
    classes: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    folderTasksConfig: PropTypes.object.isRequired,
    remountComponent: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    displayInOwnTab: PropTypes.bool,
    updateTasksBadge: PropTypes.func
};

export default (withStyles(styles, { withTheme: true })(AllTasks));