import React, {Component} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from "prop-types";

const styles = theme => ({});

const INITIAL_STATE = {
};

class FolderDocumentsRefresh extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    render() {

        const {anchorEl} = this.state;

        return (
            <React.Fragment>
                <Tooltip title={"Refresh"}>
                    <IconButton
                        aria-label="Menu"
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        color = "secondary"
                        onClick = {() => this.props.unmountFolderDocumentsGet()}
                        size="medium">
                        <i className='material-icons md-36'>
                            refresh
                        </i>

                    </IconButton>
                </Tooltip>

            </React.Fragment>
        );
    }

}

FolderDocumentsRefresh.propTypes = {
    unmountFolderDocumentsGet: PropTypes.func.isRequired,
    isFetching: PropTypes.bool
};

export default withStyles(styles)(FolderDocumentsRefresh);