import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import Input from "@mui/material/Input/Input";
import { enqueueSnackbar } from 'notistack'

function ViewUrlSearchLinkDialog (props) {

    const [open, setOpen] = useState(true)
    const [textArea, setTextArea] = useState()

    const handleCloseDialog = () => {

        setOpen(false)
        props.handleCloseDialog && props.handleCloseDialog()
    }

    const copyToClipboard = (e) => {
        textArea.select();
        document.execCommand('copy');
        e.target.focus();
        enqueueSnackbar("URL copied to clipboard", {variant: 'success'})
    };

    //prepare url from config
    let url = window.location.origin +'/search?';
    let p = 0;
    props.actionConfig.button.fields && Array.isArray(Object.entries(props.actionConfig.button.fields)) &&
        Object.entries(props.actionConfig.button.fields).forEach((field, i) => {
            if (props.folderDetails[field[1]]) { //value available
                url += (p > 0 ? "&" : "") + field[0] + '=' + props.folderDetails[field[1]]
                p++
            }
        })


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogContent>
                    <DialogContentText sx={(theme)=>({paddingBottom: theme.spacing(2)})}>
                        {props.actionConfig.button.dialogText}
                    </DialogContentText>
                    <br/>

                    <Input
                        id = "fileLink"
                        name = "fileLink"
                        value = {url}
                        inputRef = {(textarea) => setTextArea(textarea)}
                        type = "text"
                        margin="none"
                        variant="outlined"
                        readOnly={true}
                        style={{width: "100%"}}
                        autoFocus={true}
                        onFocus={event => {event.target.select()}}
                    />

                </DialogContent>

                <DialogActions>
                    <Button onClick={copyToClipboard}
                            variant="contained"
                            color="secondary">
                        Copy to Clipboard
                    </Button>
                    <Button onClick={handleCloseDialog}
                            variant="contained"
                            color="secondary">
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}

ViewUrlSearchLinkDialog.propTypes = {
    classes: PropTypes.object,
    folderDetails: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    handleCloseMenu: PropTypes.func,
    actionConfig: PropTypes.object.isRequired
};


export default (ViewUrlSearchLinkDialog);