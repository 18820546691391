import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import SignRequestDialog from './SignRequestDialog';
import { ReactComponent as SignIcon } from './file-sign.svg';

const styles = theme => ({
    signIcon: {
        fill: theme.palette.secondary.main
    }
})

function SignRequestButton(props) {

    const [showDialog, setShowDialog] = useState(false);

    const handleClickIcon = () => {
        setShowDialog(true)
    };

    const handleCloseDialog = (signSubmitted) => {
        setShowDialog(false)

        //force unmount to refresh folder documents
        if (signSubmitted && props.unmountFolderDocumentsGet) {
            props.unmountFolderDocumentsGet();
        }

        console.log ('*** signRequestButton handleCloseDialog  props=', props)
        if (signSubmitted && props.reloadWorkspace && props.selectedWorkspaceConfig) {
            console.log ('*** signRequestButton - DO RELOAD WORKSPACE')
            props.reloadWorkspace(props.selectedWorkspaceConfig)
        }


    };

    return (
        <React.Fragment>

            <Tooltip title={"Request Signature(s)..."}>
                <div style={{display: "inline"}}>
                    <IconButton onClick={handleClickIcon} color={"secondary"} size="medium">
                        <SignIcon
                            fill = {props.theme.palette.primary.main}
                            style={{width: "24px", height: "24px"}}
                        />
                    </IconButton>
                </div>
            </Tooltip>

            {
                showDialog &&
                <SignRequestDialog
                    boxDocID={props.boxDocID}
                    folderDetails={props.folderDetails}
                    userDetails={props.userDetails}
                    handleCloseDialog = {handleCloseDialog}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    documentDetails={props.documentDetails}
                    actionConfig={props.actionConfig}
                />
            }
        </React.Fragment>
    );

}

SignRequestButton.propTypes = {
    classes: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    boxDocID: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    folderDetails: PropTypes.object.isRequired,
    unmountFolderDocumentsGet: PropTypes.func,
    reloadWorkspace: PropTypes.func,
    documentDetails: PropTypes.object.isRequired,
    actionConfig: PropTypes.object.isRequired,
    selectedWorkspaceConfig: PropTypes.object
};

export default withStyles(styles, {withTheme: true} )(SignRequestButton);