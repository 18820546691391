import React, {useState} from 'react';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input/Input";
import FormControl from "@mui/material/FormControl";
import isValidFilename from 'valid-filename';
import {enqueueSnackbar} from "notistack";
import {downloadFileMulti} from "../multiSelectActions";
import InputAdornment from "@mui/material/InputAdornment";

const styles = theme => ({});

function DownloadFilenameDialog (props) {

    const [open, setOpen] = useState(true)
    const [filename, setFilename] = useState("");
    const [error, setError] = useState(false)

    function confirm() {
        if (isValidFilename(filename)) {
            downloadFileMulti(props.files, props.accessToken, props.triggerRefreshAuthToken, props.updateIsProcessing, filename)
            handleCloseDialog()
        } else {
            enqueueSnackbar("Please enter a valid filename")
        }
    }

    function updateFilename(val) {
        setError(isValidFilename(val));
        setFilename(val)
    }

    const handleCloseDialog = () => {
        setOpen(false)
        props.handleCloseDialog && props.handleCloseDialog()
    };

    return (

        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Filename for download"}</DialogTitle>
            <DialogContent>

                <Box width={400} sx={{typography: 'body1' , pt: (theme) => theme.spacing(2), pb: (theme) => theme.spacing(2)}} >
                    Please specify the filename for the download
                </Box>

                <FormControl variant="standard" sx={{ width: '25ch' }}>
                    <Input
                        id="filename"
                        name="filename"
                        value={filename}
                        placeholder="Filename"
                        type="text"
                        margin="none"
                        variant="outlined"
                        autoComplete=""
                        onChange={e => updateFilename(e.target.value)}
                        autoFocus = {true}
                        error = {error}
                        //helperText = {error? "Enter valid filename" : ""}
                        endAdornment={<InputAdornment position="end">.zip</InputAdornment>}
                    />
                </FormControl>

            </DialogContent>
            <DialogActions>

                <Button color="grey" variant="contained" onClick={handleCloseDialog}>
                    {"Cancel"}
                </Button>

                <Button color="secondary" variant="contained" onClick={confirm} autoFocus disabled = {!filename}>
                    { "Confirm"}
                </Button>


            </DialogActions>
        </Dialog>

    );
}

DownloadFilenameDialog.propTypes = {
    files: PropTypes.array, //files to be downloaded - list in info?
    accessToken: PropTypes.string.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    updateIsProcessing: PropTypes.func.isRequired,
    handleCloseDialog: PropTypes.func.isRequired
};

export default (withStyles(styles)(DownloadFilenameDialog));