import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import Input from "@mui/material/Input/Input";
import { enqueueSnackbar } from 'notistack'
import {withStyles} from '@mui/styles';
import {getErrorMessageFromResponse} from "../../common/helper";

const styles = theme => ({});

const INITIAL_STATE = {
    open: false,
    caseId: "",
    isFetching: false
};

class MoveDocumentToCaseDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;

        this.moveDocument = this.moveDocument.bind(this);
    }

    componentDidMount(){
        this.setState({ open: true })
    }

    handleCloseDialog = () => {
        this.setState({ open: false });

        if (this.props.handleCloseMenu) {
            this.props.handleCloseMenu()
        }
        //Added this to address issue of dialog not re-opening after initial open
        if (this.props.handleCloseDialog) {
            this.props.handleCloseDialog()
        }
    };

    handleOnChangeCaseId = event => {
        this.setState({caseId : event.target.value});
    };

    doCaseSearch = async (newSearch) => {

        // AG int counter...
        //let counter = 0
        window.location.pathname.toLowerCase().includes("debug") && console.log ('doCaseSearch newSearch = ', newSearch);

        let searchConfig =  this.props.actionConfig.folderSearch;
        let searchCriteriaArray = [];
        let indexes = searchConfig.elastic.indexes;
        //let rows=[];
        let totalCount = 0;

        this.setState({isFetching : "true"});

        //Populate Search Criteria Array, adjust it to use case fields

        //Added this if statement to cater for folder results paging
        // if (newSearch) {

        window.location.pathname.toLowerCase().includes("debug") && console.log('enter new search...');
        //add fixed search criteria
        if (searchConfig.fixedSearchCriteria) {
            const fixedSearchCriteria = searchConfig.fixedSearchCriteria;
            let newSearchCriteria = this.state.searchCriteria;
            for (let i = 0; i < fixedSearchCriteria.length; ++i) {
                newSearchCriteria[fixedSearchCriteria[i].templateKey + "~" + fixedSearchCriteria[i].metadataKey] = fixedSearchCriteria[i].value;
            }
            this.setState({"searchCriteria": newSearchCriteria});
            window.location.pathname.toLowerCase().includes("debug") && console.log ('newSearchCriteia=', newSearchCriteria)
        }

        //extract templateKey & metadataKey and populate search criteria array
        // Object.entries(this.state.searchCriteria).forEach(entry => {
        //     //get field config
        //     const templateKey = entry[0].split("~")[0];
        //     const metadataKey = entry[0].split("~")[1];
        //     let fieldConfig = getFieldConfig(this.props.metadataConfig, templateKey,metadataKey);
        //     if (fieldConfig.caseField) {
        //         searchCriteriaArray.push({
        //             metadata_key: fieldConfig.caseField,
        //             value: entry[1],
        //             type: fieldConfig.operator ? fieldConfig.operator : "equals"
        //         })
        //     } else {
        //         window.location.pathname.toLowerCase().includes("debug") && console.log ('Unable to find caseField for ' +  templateKey + ' - '+ metadataKey);
        //     }
        // });
        // } else {
        //     searchCriteriaArray = this.state.searchCriteria;
        // }

        window.location.pathname.toLowerCase().includes("debug") && console.log('add case type...')
        //Add caseType to searchCriteriaArray
        if (searchConfig.elastic.caseType) {
            if (searchConfig.elastic.caseType !== "") {
                searchCriteriaArray.push({
                    raw_key: "case_type",
                    value: searchConfig.elastic.caseType
                },)
                searchCriteriaArray.push({
                    metadata_key: "business_unit",
                    value: this.props.folderDetails["dawsongroup~businessUnit"]
                },)
                searchCriteriaArray.push({
                    metadata_key: "asset_id",
                    value: this.state.caseId.toUpperCase(),
                    type: "equals"
                },)
            }
        }

        // TODO AG remove page and page size from request...
        const pathVar = (indexes.length > 0 ?  "/" + indexes.toString()  : "") + window.REACT_APP_CASE_API_SEARCH ;
        // let searchOffset = newSearch ? 0 : this.props.searchOffset;
        // const page = newSearch ? 0 : (this.props.searchOffset/this.props.searchLimit)
        // const params =  "?page=" + page + "&pageSize=" + this.props.searchLimit;

        await this.props.triggerRefreshAuthToken();

        // const url = window.REACT_APP_CASE_API_BASE_URL + pathVar + params;
        const url = window.REACT_APP_CASE_API_BASE_URL + pathVar;

        const request = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                "case-token": this.props.userDetails.caseAccessToken
            },
            body: JSON.stringify(searchCriteriaArray)
        };

        window.location.pathname.toLowerCase().includes("debug") && console.log ('doCaseSearch REQUEST.  url:', url, 'body: ', searchCriteriaArray, 'request: ', request);

        fetch(url, request)
            .then(response => {
                if (response.ok) {
                    window.location.pathname.toLowerCase().includes("debug") && console.log ('response.ok - doCaseSearch RESPONSE: ', response);
                    return (response.json())
                    //return sampleResponse
                } else {
                    window.location.pathname.toLowerCase().includes("debug") && console.log ('response not ok - doCaseSearch RESPONSE: ', response);
                    Promise.resolve(getErrorMessageFromResponse(response, 'retrieving search results'))
                        .then(message => {
                            enqueueSnackbar(message, {variant: 'error'})
                        })

                    window.location.pathname.toLowerCase().includes("debug") && console.log ("doCaseSearch error. url:", url, "request: ", request, "response:", response);
                    this.setState({isFetching : "false"});
                    return null
                    //return sampleResponse
                }
            })
            .then(resultA => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ('doCaseSearch response.json: ', resultA);

                //TODO NEXT - populate search results table - note paging changes as per Dave's slack
                //counter = resultA.offset;
                //counter = 0 //TODO counter =0 temporarily
                totalCount = resultA.total_hits;

                if (totalCount > 1) {
                    enqueueSnackbar("Error - More than 1 result found, please check and try again.", {variant: 'error'});
                    this.setState({isFetching : "false"});
                    return null
                }

                if(totalCount === "0" || totalCount === 0){
                    enqueueSnackbar("Error - No results found, please check and try again.", {variant: 'error'});
                    this.setState({isFetching : "false"});
                    return null
                }

                // AG Call Move Request...

                // POST https://api.dawson.aifusion.io/content//api/box/document/{fileId}/move?

//                /api/box/document/{fileId}/move?
//                newParentFolderId=...
//                vehicleRegistration=...
//                &documentType=..
                const pathVar = "/api/box/document/"+ this.props.boxDocID+
                    "/move?newParentFolderId="+resultA.results[0].box_id+
                    "&vehicleRegistration="+this.state.caseId+
                    "&documentType="+this.props.rowData["dawsongroup~documentType"] +
                    '&audit=' + this.props.actionConfig.audit;

                const content_url = window.REACT_APP_CONTENT_API_BASE_URL + pathVar;

                const request = {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + this.props.userDetails.accessToken,
                    }
                };

                window.location.pathname.toLowerCase().includes("debug") && console.log ('doCaseSearch REQUEST.  url:', url, 'body: ', searchCriteriaArray, 'request: ', request);

                fetch(content_url, request)
                    .then(response => {
                        if (response.ok) {
                            window.location.pathname.toLowerCase().includes("debug") && console.log ('response.ok - doCaseSearch RESPONSE: ', response);
                            enqueueSnackbar("Document Moved.", {variant: 'success'});
                            this.props.unmountFolderDocumentsGet();
                            this.handleCloseDialog();
                            this.setState({isFetching : "false"});
                            return (response.json());
                        } else {
                            window.location.pathname.toLowerCase().includes("debug") && console.log ('response not ok - doCaseSearch RESPONSE: ', response);
                            Promise.resolve(getErrorMessageFromResponse(response, 'moving document'))
                                .then(message => {
                                    enqueueSnackbar(message , {variant: 'error'});
                                })
                            window.location.pathname.toLowerCase().includes("debug") && console.log ((new Date()).toLocaleTimeString() + " Move Document error. url:", url, "request: ", request, "response:", response);
                            this.setState({isFetching : "false"});
                            return null
                            //return sampleResponse
                        }
                    })
                    .catch(e => {
                        window.location.pathname.toLowerCase().includes("debug") && console.log ((new Date()).toLocaleTimeString() + " Move Document Exception:", e, "url:", url, "request: ", request);
                        this.setState({isFetching : "false"});
                        // this.props.updateIsFetching(false, {}, true, searchOffset);
                        // //if showing folder details and the search errors then hide the search results
                        // const displaySearchResults = !Object.entries(folderDetails).length;
                        // this.props.updateSearchResults(rows, searchType, displaySearchResults, searchCriteriaArray, "", totalCount);
                    })

            })
            .catch(e => {
                window.location.pathname.toLowerCase().includes("debug") && console.log ((new Date()).toLocaleTimeString() + " doCaseSearch Exception:", e, "url:", url, "request: ", request);
                this.setState({isFetching : "false"});
                // this.props.updateIsFetching(false, {}, true, searchOffset);
                // //if showing folder details and the search errors then hide the search results
                // const displaySearchResults = !Object.entries(folderDetails).length;
                // this.props.updateSearchResults(rows, searchType, displaySearchResults, searchCriteriaArray, "", totalCount);
            })
    };

    moveDocument = (e) => {
        if (window.REACT_APP_FOLDER_SOURCE === "elastic"  ) {
            window.location.pathname.toLowerCase().includes("debug") && console.log ('useCaseAPI so doCaseSearch')
            this.doCaseSearch(this.props.newSearch);
        } else {
            enqueueSnackbar("Box Folder Search not configured.", {variant: 'error'})
        }
    };

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.moveDocument()
        }
    }

    render() {

        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <DialogContent>
                        <DialogContentText sx={(theme)=>({paddingBottom: theme.spacing(2)})}>
                            {this.props.actionConfig.promptText}
                        </DialogContentText>
                        <br/>

                        <Input
                            id = "caseId"
                            name = "caseId"
                            value = {this.state.caseId}
                            type = "text"
                            margin="none"
                            variant="outlined"
                            style={{width: "100%"}}
                            autoFocus={true}
                            onChange={this.handleOnChangeCaseId}
                            onKeyDown={this.handleKeyDown}
                        />

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.moveDocument}
                                variant="contained"
                                color="secondary"
                                disabled={(this.state.caseId === "") || (this.state.isFetching === "true")}>
                            Move Document
                        </Button>
                        <Button onClick={this.handleCloseDialog}
                                variant="contained"
                                color="secondary">
                            Close
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

MoveDocumentToCaseDialog.propTypes = {
    classes: PropTypes.object,
    boxDocID: PropTypes.string.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    handleCloseMenu: PropTypes.func,
    actionConfig: PropTypes.object.isRequired
};


export default (withStyles(styles, { withTheme: true })(MoveDocumentToCaseDialog));