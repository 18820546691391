import React, {useState, useEffect} from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { searchPlugin} from "@react-pdf-viewer/search";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import { toolbarPlugin } from '@react-pdf-viewer/toolbar';


// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
// Custom search css
// import '@react-pdf-viewer/search/lib/styles/index.css';
import './PDFViewer.css';

import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack'
import LinearProgress from "@mui/material/LinearProgress";
import ListSubheader from "@mui/material/ListSubheader/ListSubheader";
import {getErrorMessageFromResponse} from "../../common/helper";

// used for local testing... add document to component and local dir - to use this...
// import samplePDF from './SlipSample.pdf';

const PDFViewer = (props) => {

    const debug = window.location.pathname.toLowerCase().includes("debug")

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar} = toolbarPluginInstance;

    const transform = (slot) => ({
        ...slot,
        Download: () => <></>
    });

    const renderToolbar = (Toolbar) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );

    // Reference, the different elements on the viewer...
    // <Toolbar>
    //     {(slots) => {
    //         const {
    //             CurrentPageInput,
    //             Download,
    //             EnterFullScreen,
    //             GoToNextPage,
    //             GoToPreviousPage,
    //             NumberOfPages,
    //             Print,
    //             ShowSearchPopover,
    //             Zoom,
    //             ZoomIn,
    //             ZoomOut,
    //         }

    // const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
    });

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToPage } = pageNavigationPluginInstance;
    const searchPluginInstance = searchPlugin();
    const { highlight } = searchPluginInstance;

    const [isFetching, setIsFetching] = useState(null);
    const [docContent, setDocContent] = useState(null);
    const [highlightKeyword, setHighlightKeyword] = useState(null);

    if(props.pageNumber && props.snippet && props.snippet !== highlightKeyword) {
        setHighlightKeyword(props.snippet);
        highlight({
            "keyword": props.snippet,
            "matchCase": false,
            "wholeWords": false
            }).then(response => {
                jumpToPage(props.pageNumber - 1)
            });
    } else {
        jumpToPage(props.pageNumber-1);
    }

    useEffect(() => {

        debug && console.log('PDF Viewer props = ', props);

        const getDocContent = async () => {

            let pathVar = "";
            let url = "";

            await props.triggerRefreshAuthToken();

            if (window.REACT_APP_DOCUMENT_SOURCE === "elastic") {
                url = window.REACT_APP_CONTENT_API_BASE_URL + "/api/elastic/document/content/" + props.boxDocID + "/vision";
            } else {
                pathVar = "/" + props.boxDocID;
                url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + "/content";
            }

            let request = {
                headers: {
                    "Authorization": "Bearer " + props.userDetails.accessToken,
                    "Accept": "application/pdf,application/octet-stream"
                },
            };

            const caseTokenRequired = window.REACT_APP_CASE_TOKEN_REQUIRED === 'true';
            if (caseTokenRequired) {
                request.headers["case-token"] = props.userDetails.caseAccessToken;
            }

            window.location.pathname.toLowerCase().includes("debug") && console.log("getDocContent url :", url);

            await fetch(url, request)
                .then(response => {
                    if (response.ok) {
                        return (response.blob())
                    } else {
                        // AG check error code... if not 500... then show error snackbar...
                        if(response.status !== 404 && response.status !== 500) {
                            Promise.resolve(getErrorMessageFromResponse(response, 'retrieving content'))
                                .then(message => {
                                    enqueueSnackbar(message, {variant: 'error'})
                                })
                        }
                        window.location.pathname.toLowerCase().includes("debug") && console.log("getDocContent error.  url:", url, "request: ", request, "response.text:", response);
                        return null
                    }
                })
                .then(result => {

                    window.location.pathname.toLowerCase().includes("debug") && console.log('getDocContent, result=', result);

                    if (result) {
                        result = result.slice(0, result.size, "application/pdf")

                        const reader = new FileReader();
                        reader.readAsDataURL(result);
                        reader.onload = () => {
                            setIsFetching(false);
                            setDocContent(reader.result);
                        }
                    } else {
                        setIsFetching(false);
                        // AG check error code... if not 500... then show error snackbar...
                        // enqueueSnackbar("Error retrieving content", {variant: 'error'});
                    }
                })
                .catch(e => {
                    window.location.pathname.toLowerCase().includes("debug") && console.log("getDocContent Exception:", e, " url:", url, "request: ", request);
                    enqueueSnackbar("Error retrieving content", {variant: 'error'});
                    setIsFetching(false);
                })
        };

        //only get content if getContent set to true in actionConfig
        if (!props.getContent && props.fileUrl) {
            setDocContent(props.fileUrl)
        } else {
            if (!props.fileContent) {
                debug && console.log('PDFViewer.js: no content passed in need to get content')
                if (isFetching == null && props.boxDocID) {
                    setIsFetching(true);
                    getDocContent();
                }
            } else {
                if (props.fileContent !== "") {
                    setDocContent(props.fileContent)
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Update if authState changes

    return (

        !docContent ?
            <React.Fragment>
                {
                    isFetching ?
                        <LinearProgress style={{marginTop: '0px'}} color="primary"/> :
                        <ListSubheader disableSticky>File content not available.</ListSubheader>
                }
            </React.Fragment>
            :
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        <Viewer
                            fileUrl={docContent}
                            plugins={[
                                defaultLayoutPluginInstance,
                                pageNavigationPluginInstance,
                                searchPluginInstance
                            ]}
                        />
            </Worker>
    );
};

PDFViewer.propTypes = {
    boxDocID: PropTypes.string,
    fileUrl: PropTypes.string,
    fileContent: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    pageNumber: PropTypes.number.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired
};

export default  (PDFViewer);

// ---- back up old react-pdf PDF Viewer ----------------------------------------------------

// import React from 'react';
// import PropTypes from 'prop-types';
// import {Document, Page, pdfjs } from "react-pdf";
// import { enqueueSnackbar } from 'notistack'
// import LinearProgress from "@mui/material/LinearProgress";
//
// // using ES6 modules
// // import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// // using CommonJS modules
// import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
//
// // used for local testing... add document to component and local dir - to use this...
// // import samplePDF from './SlipSample.pdf';
//
// class PDFViewer extends React.Component {
//     constructor(props) {
//
//         super(props);
//
//         window.location.pathname.toLowerCase().includes("debug") && console.log ('PDFViewer props: ' ,this.props);
//     }
//
//     UNSAFE_componentWillMount() {
//         window.location.pathname.toLowerCase().includes("debug") && console.log('UNSAFE_componentWillMount');
//
//         if (!this.props.fileContent) {
//             window.location.pathname.toLowerCase().includes("debug") && console.log('Preview.js: no content passed in need to get content from box')
//             if (this.props.boxDocID) {
//                 this.getDocContent();
//             }
//         }
//     }
//
//     componentDidMount() {
//         window.location.pathname.toLowerCase().includes("debug") && console.log ('component did mount');
//
//     }
//
//     getDocContent = async () => {
//
//         let pathVar = "";
//         let params =  "";
//         let url = "";
//
//         this.setState({isFetching: true});
//         if(window.REACT_APP_DOCUMENT_SOURCE == "elastic"){
//             url = window.REACT_APP_CONTENT_API_BASE_URL + "/api/elastic/document/content/" + this.props.boxDocID + "/converted";
//         } else {
//             pathVar = "/" + this.props.boxDocID;
//             params =  "?userId=" + this.props.userDetails.boxId + "&content=true&metadata=false";
//             url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + pathVar + params ;
//         }
//
//         const request = {
//             headers: {
//                 "Authorization": "Bearer " + this.props.userDetails.accessToken,
//                 "case-token": this.props.userDetails.caseAccessToken
//             },
//         };
//
//         window.location.pathname.toLowerCase().includes("debug") && console.log ("getDocContent url :", url);
//
//         await this.props.triggerRefreshAuthToken();
//         fetch(url , request )
//             .then(response => {
//                 if (response.ok) {
//                     return(response.blob())
//                 } else {
//                     if (response.status === 401) {
//                         enqueueSnackbar("Error " + response.status + ".  Please log in again. " + response, {variant: 'error'});
//                     } else {
//                         enqueueSnackbar("Error " + response.status + " retrieving metadata.  " + response, {variant: 'error'});
//                     }
//                     window.location.pathname.toLowerCase().includes("debug") && console.log("getDocContent error.  url:", url, "request: ", request, "response.text:", response);
//                     return null
//
//                 }
//             })
//             .then(result => {
//
//                 window.location.pathname.toLowerCase().includes("debug") && console.log('getDocContent, result=', result);
//                 window.location.pathname.toLowerCase().includes("debug") && console.log('getDocContent, result.content=', result.content);
//
//                 result = result.slice(0, result.size, "application/pdf")
//
//                 const reader = new FileReader();
//                 reader.readAsDataURL(result);
//
//                 reader.onload = () => this.setState({
//                     doc: reader.result,
//                     isFetching: false}
//                 )
//             })
//             .catch(e => {
//                 window.location.pathname.toLowerCase().includes("debug") && console.log("getDocContent Exception:", e, " url:", url, "request: ", request);
//                 enqueueSnackbar("Error retrieving metadata - " + e, {variant: 'error'});
//                 this.setState({isFetching: false})
//
//             })
//     };
//
//     state = { numPages: null, pageNumber: 1 };
//
//     onDocumentLoadSuccess = ({ numPages }) => {
//         this.setState({ numPages });
//     };
//
//     render() {
//
//         window.location.pathname.toLowerCase().includes("debug") && console.log ('render PDFViewer, props=', this.props);
//
//         let fileContent = "";
//         if (this.props.fileContent) {
//             if (this.props.fileContent !== "") {
//                 fileContent = this.props.fileContent
//             }
//         }
//         if (fileContent === "") {
//             //check if getDocContent retrieved content on mounting
//             if (this.state.doc) {
//                 fileContent = this.state.doc
//             }
//         }
//
//         let fileContentBinary = "";
//         fileContentBinary=fileContent;
//
//         pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//
//         if (fileContentBinary === "" ) {
//             return(
//                 <React.Fragment>
//                     {
//                         this.state.isFetching ?
//                             <LinearProgress style={{marginTop: '0px'}} color="primary"/> :
//                             <span>File content not available.</span>
//                     }
//                 </React.Fragment>
//             )
//         } else {
//             const { numPages } = this.state;
//
//             return (
//                 <Document
//                     // file={samplePDF}
//                     // // file={`data:application/pdf;base64,${fileContentBinary}`}
//                     file={fileContentBinary}
//                     onLoadSuccess={this.onDocumentLoadSuccess}
//                     onLoadError={console.error}
//                 >
//                     {Array.from(
//                         new Array(numPages),
//                         (el, index) => (
//                             <Page
//                                 key={`page_${index + 1}`}
//                                 pageNumber={index + 1}
//                                 renderAnnotationLayer={false}
//                                 renderTextLayer={false}
//                                 scale="1.5"
//                             />
//                         ),
//                     )}
//                 </Document>
//             )
//         }
//     }
// }
//
// PDFViewer.propTypes = {
//     boxDocID: PropTypes.string,
//     fileContent: PropTypes.string.isRequired,
//     userDetails: PropTypes.object.isRequired,
//     pageNumber: PropTypes.number.isRequired,
//     triggerRefreshAuthToken: PropTypes.func.isRequired
// };
//
// export default  (PDFViewer);

// ---- end ... back up old react-pdf PDF Viewer ----------------------------------------------
