import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { enqueueSnackbar } from 'notistack'
import {green, grey, red} from '@mui/material/colors';
import {ConfirmationMessage} from "./steps/ConfirmationMessage";
import {
    asyncForEach,
    fetch_retry,
    getErrorMessageFromResponse,
    getErrorMessageFromResponseAsync,
    getFieldConfig,
    getReadableFileSizeString
} from "../../../common/helper";
import {buf2Base64, readFileAsyncArrayBuffer, readFileAsyncBase64} from "../helper";
import GenericMetadata from "./steps/GenericMetadata";
import FileTable from "./steps/FileTable";
import {Grid} from "@mui/material";
import FileUploadDropzone from "../FileUploadDropzone";
import DialogContentText from "@mui/material/DialogContentText";

const styles = theme => ({

    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    redAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: red[500],
    },

    greenAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: green[500],
    },
    greyAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: grey[500],
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    header: {
        paddingBottom : theme.spacing(3),
    }

});

let counterMetadataTable = 0;
function addRowIDToFile (file) {
    counterMetadataTable += 1;
    file.rowId = counterMetadataTable;
    file.metadata = {};
    file.id = ""; //box id placeholder
    file.statusMessage = "";
    file.partProgress = "";
    file.useChunkApi= false;
    file.statusIcon = "file_upload";
    file.statusIconClass = "greyAvatar";
    return file
}

const INITIAL_STATE = {
    maxWidth: "sm",
    open: false,
    activeStep: 0,
    // skipped: new Set(),
    genericMetadata: {},
    files: [],
    response: {
        success: true,
        message: ""
    },
    uploadProgress: 0,
    isUploadComplete: false,
    folders: {},
    errorCounter: 0
};

class BulkUploadDialog extends React.Component {

    constructor(props) {

        const debug = window.location.pathname.toLowerCase().includes("debug")

        super(props);

        this.state = INITIAL_STATE;

        this.handleOnChangeDocumentMetadata = this.handleOnChangeDocumentMetadata.bind(this);
        this.updateFileList = this.updateFileList.bind(this);
        this.updateFolders = this.updateFolders.bind(this);
        this.handleOnChangeGenericMetadata = this.handleOnChangeGenericMetadata.bind(this);
        this.doUploadAsync = this.doUploadAsync.bind(this);

        debug && console.log ('BulkUploadDialog props: ', this.props);
    }

    componentDidMount(){
        this.setState({ open: true });
    }

    validateGenericFields = () => {

        let fields = this.props.uploadConfig.genericFields;
        let missingValues = [];

        if (fields && fields.length > 0) {
            fields.forEach(field => {
                if (field.required) {
                    if (!this.state.genericMetadata[field.templateKey + "~" + field.metadataKey]  || this.state.genericMetadata[field.templateKey + "~" + field.metadataKey].toString() === "" ) {
                        let fieldConfig = getFieldConfig(this.props.metadataConfig, field.templateKey, field.metadataKey);
                        if (Object.entries(fieldConfig).length > 0) {
                            missingValues.push(fieldConfig.label);
                        } else {
                            missingValues.push(field.metadataKey)
                        }
                    }
                }
            })
        }

        if (missingValues.length > 0) {
            if (missingValues.length === 1) {
                enqueueSnackbar('Please select a value for ' + missingValues.slice(-1) , {variant: 'error'});
            } else {
                enqueueSnackbar('Please select a value for ' + missingValues.slice(0, missingValues.length - 1).join(', ') + " and " + missingValues.slice(-1), {variant: 'error'});
            }
            return false;
        } else {
            return true
        }
    }

    validateDocumentSpecificFields = () => {

        const debug = window.location.pathname.toLowerCase().includes("debug")

        let missingValues = [];
        //loop through files
        let files = this.state.files;
        let fields = this.props.uploadConfig.documentSpecificFields;
        if (fields && fields.length > 0 && files && files.length > 0) {
            fields.forEach(field => {
                if (field.required) {

                    for(let i=0; i<files.length; i++) {
                        if (!files[i].metadata || Object.entries(files[i].metadata) === 0 || !files[i].metadata[field.templateKey + "~" + field.metadataKey] || files[i].metadata[field.templateKey + "~" + field.metadataKey].toString() === "") {
                            let fieldConfig = getFieldConfig(this.props.metadataConfig, field.templateKey, field.metadataKey);
                            if (Object.entries(fieldConfig).length > 0) {
                                missingValues.push(fieldConfig.label);
                            } else {
                                missingValues.push(field.metadataKey)
                            }
                            break;// skip to next field
                        }
                    }
                }
            })
        }

        debug && console.log ('validateDocumentSpecificFields - missingValues check done, missingValues=', missingValues);

        let missingFolders = [];
        if (missingValues.length > 0) {
            if (missingValues.length === 1) {
                enqueueSnackbar('Please select a value for ' + missingValues.slice(-1) + (files.length === 1 ? '.' : ' for each file.'), {variant: 'error'});
            } else {
                enqueueSnackbar('Please select a value for ' + missingValues.slice(0, missingValues.length - 1).join(', ') + " and " + missingValues.slice(-1) + (files.length === 1 ? '.' : ' for each file.'), {variant: 'error'});
            }
           // return false;
        } else {
            debug && console.log ('validateDocumentSpecificFields - no missing values, now check for folders.  folers=', this.state.folders);
            //no missing values, now check that a folder exists for each file
            files.forEach(file => {
                const metadata = file.metadata;
                const spv = metadata['howdenConstruction~spvEntity'];
                const folderFound = this.state.folders[spv.toUpperCase()];
                debug && console.log ('validateDocumentSpecificFields - folder check for spv = ' + spv, folderFound);
                if (!folderFound) {
                    missingFolders.push(spv)
                }
            })
            debug && console.log ('validateDocumentSpecificFields - missing folder check done, missingFolders =' , missingFolders);
            let uniqueMissingFolders = [...new Set(missingFolders)];
            if (uniqueMissingFolders.length > 0) {
                if (uniqueMissingFolders.length === 1) {
                    enqueueSnackbar('No SPV found for ' + uniqueMissingFolders[0] + '.  Please correct the SPV value or create a new SPV if required', {variant: 'error'});
                } else {
                    enqueueSnackbar('No SPV found for ' + uniqueMissingFolders.slice(0, uniqueMissingFolders.length - 1).join(', ') + " and " + uniqueMissingFolders.slice(-1) + '   Please check the SPV values and create new SPVs if required,', {variant: 'error'});
                }
                debug && console.log ('we are missing folders, return false')
                //return false;
            } else {
                debug && console.log ('all good - return true')
                //return true
            }
        }

        debug && console.log ('validateDocumentSpecificFields returning ' , !(missingFolders.length > 0 || missingValues.length > 0));
        return !(missingFolders.length > 0 || missingValues.length > 0);
    }

    updateFolders = (folders) => {
        console.log ('*** updateFolders START: ', folders)
        this.setState({folders: folders});
        console.log ('*** updateFolders END: ', folders)
    }

    async processFile(file) {
        const debug = window.location.pathname.toLowerCase().includes("debug");
        try {

            //append generic metadata
            let genericMetadata = this.state.genericMetadata;
            let metadata = file.metadata;
            const spv = metadata['howdenConstruction~spvEntity']
            debug && console.log ('spv=', spv, "file.metadata:" , file.metadata);

            for (let propertyName in genericMetadata) {
                metadata[propertyName] = genericMetadata[propertyName];
            }

            //add any fixed metadata from config
            const fixedMetadata = this.props.uploadConfig.fixedMetadata;
            if (fixedMetadata) {
                for (let i = 0; i < fixedMetadata.length; ++i) {
                    metadata[fixedMetadata[i].templateKey + "~" + fixedMetadata[i].metadataKey] = fixedMetadata[i].value;
                }
            }

            //extract templateKey & metadataKey and populate array
            let metadataArray = [];
            Object.entries(metadata).forEach(entry => {
                metadataArray.push({
                    templateKey: entry[0].split("~")[0],
                    metadataKey: entry[0].split("~")[1],
                    value: entry[1]
                });
            });

            debug && console.log ('metadataArray=' , metadataArray, 'uploadConfig: ', this.props.uploadConfig );

            // if (props.uploadConfig.setMetadataFromParentFolder &&
            //     props.uploadConfig.setMetadataFromParentFolder.length > 0) {
            //     props.uploadConfig.setMetadataFromParentFolder.for
            // }
            const parentFolder = this.state.folders[spv.toUpperCase()]
            const setMetadataFromParentFolder = this.props.uploadConfig.setMetadataFromParentFolder;

            if (setMetadataFromParentFolder && setMetadataFromParentFolder.length > 0) {
                for (let i=0; i<setMetadataFromParentFolder.length; i++) {
                    setMetadataFromParentFolder[i].metadataKeys.forEach(metadataKey => {

                        console.log ('metadataKey = ', metadataKey)
                        //"metadata.enterprise_" + window.REACT_APP_ENTERPRISE_ID + "." + templateKey + "." + metadataKeys[i])
                        if (parentFolder.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][setMetadataFromParentFolder[i].templateKey][metadataKey]) {
                            metadataArray.push({
                                templateKey: setMetadataFromParentFolder[i].templateKey,
                                metadataKey: metadataKey,
                                value: parentFolder.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID][setMetadataFromParentFolder[i].templateKey][metadataKey]
                            })
                        }
                    })
                }
            }

            let subFolders = [];

            const folderId=this.state.folders[spv.toUpperCase()].id;

            let queryStr = "?folderId=" + folderId;

            if (this.props.uploadConfig.convert) {
                queryStr = queryStr + "&convert=true"
            }

            if (this.props.uploadConfig.ocr) {
                queryStr = queryStr + "&ocr=true"
            }


            if (this.props.actionConfig.audit) {
                queryStr = queryStr + "&audit=true"
            }

            if (this.props.uploadConfig.newVersionOnConflict) {
                queryStr = queryStr + "&newVersionOnConflict=true"
            }
            const baseUrl = this.props.uploadConfig.watson ? window.REACT_APP_CONTENT_API_BASE_URL_AI : window.REACT_APP_CONTENT_API_BASE_URL;
            const endpoint = this.props.actionConfig.customEndpoint ? this.props.actionConfig.customEndpoint : window.REACT_APP_CONTENT_API_DOCUMENT;
            const url = baseUrl + endpoint + queryStr;

            //If large file need to chunk content and upload in chunks

            const useChunkApiFrom = this.props.actionConfig.useChunkApiFrom;
            const useChunkApi = useChunkApiFrom && useChunkApiFrom > 0 && file.size > useChunkApiFrom;

            debug && console.log ('useChunkApiFrom = ', useChunkApiFrom, 'useChunkApi=', useChunkApi);

            // The direct file upload API supports files up to 50MB in size and sends all the binary data to the Box API in 1 API request.
            // The chunked upload APIs support files from 20MB in size and allow an application to upload the file in parts,
            // allowing for more control to catch any errors and retry parts individually.

            if (useChunkApi) {

                debug && console.log (' use chunked upload API, file.size=', file.size);

                let updatedFiles = this.state.files;
                file.useChunkApi = true ;
                this.setState({files: updatedFiles});

                let sessionError = false;

                //1. Create Upload Session
                let body={
                    folder_id: folderId,
                    file_size: file.size,
                    file_name: file.name
                }
                const sessionRequest = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + this.props.userDetails.accessToken
                    },
                    body: JSON.stringify(body)
                };
                const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + '/chunked/upload_sessions';
                debug && console.log ('Create session url= ', url, 'BODY=', body, 'request=', sessionRequest);

                await this.props.triggerRefreshAuthToken();
                let sessionResponse = await (fetch_retry(url,sessionRequest,3));
                debug && console.log ('Create session response = ', sessionResponse);
                let session;
                if (sessionResponse.ok) {
                    session = await sessionResponse.json();
                    debug && console.log ('Create session response.json = ', session);
                } else {
                    sessionError = true
                    //response not ok
                    Promise.resolve(getErrorMessageFromResponse(sessionResponse, 'creating upload session'))
                        .then(message => {
                            enqueueSnackbar(message, {variant: 'error'});
                        })
                }

                if (sessionError) {
                    console.log ('sessionError.... session = ', session, 'sessionResponse=', sessionResponse);
                    if (session) {
                        debug && console.log ('return session', session)
                        return session
                    } else {
                        debug && console.log ('return sessionResponse', sessionResponse)
                        return sessionResponse
                    }

                } else {
                    //2. Upload each part
                    debug && console.log ('Upload ' + session.total_parts +' parts....');

                    //Read file
                    //const allBytes = await readFileAsyncBinary(file); //for digest
                    const bodyFull = await readFileAsyncArrayBuffer(file);  //for body
                    debug && console.log ('file.size=', file.size);

                    //initialise rangeEnd and contentRange then loop through and upload each part
                    let rangeStart = 0;
                    let rangeEnd = session.part_size-1
                    const partURL = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + '/chunked/upload_sessions/' + session.id;

                    const partsArray = []
                    let partError = false

                    for (let i = 0; i < session.total_parts; i++) {

                        debug && console.log (file.name ,' upload part: ', i+1, ' of ', session.total_parts);

                        let updatedFiles = this.state.files;
                        let progress = i+1
                        file.partProgress =  '  Large file, uploading part ' + progress + ' of ' + session.total_parts + ', please wait...' ;
                        this.setState({files: updatedFiles});

                        const firstPart = i === 0;
                        const finalPart = i === (session.total_parts-1);

                        if (!firstPart) {
                            rangeStart = rangeEnd + 1
                            rangeEnd = rangeEnd + session.part_size
                            if (finalPart){
                                rangeEnd = file.size -1
                            }
                        }

                        //const partBytes = finalPart ? allBytes.slice(rangeStart) : allBytes.slice(rangeStart, rangeStart + session.part_size); //for digest
                        const bodyPart = bodyFull.slice(rangeStart, rangeStart + session.part_size); //for body

                        const partArrayBuffer = await window.crypto.subtle.digest("SHA-1", bodyPart);
                        const partDigest = 'sha=' + buf2Base64(partArrayBuffer)
                        debug && console.log ('part ', i+1 , 'partDigest: ', partDigest)

                        const contentRange = "bytes " + rangeStart + "-" + rangeEnd + "/" + file.size; //<unit> <range-start>-<range-end>/<size>

                        debug && console.log ('contentRange: ', contentRange)
                        debug && console.log ('digest: ', partDigest)

                        /* Note re. headers:
                        "content-range": "bytes 8388608-16777215/445856194", //The byte range of the chunk.  Must not overlap with the range of a part already uploaded this session.
                        "digest": "" //example sha=fpRyg5eVQletdZqEKaFlqwBXJzM=     The RFC3230 message digest of the chunk uploaded.  Only SHA1 is supported. The SHA1 digest must be Base64 encoded. The format of this header is as sha=BASE64_ENCODED_DIGEST.
                        */

                        const partRequest = {
                            method: 'PUT',
                            headers: {
                                "Content-Type": "application/octet-stream",
                                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                                "Content-Range": contentRange,
                                "Digest": partDigest
                            },
                            body: bodyPart
                        };

                        debug && console.log ('Request for part ' + (i+1) + ' = ', partURL, 'partRequest=', partRequest);
                        const partResponse = await (fetch_retry(partURL,partRequest,3));
                        debug && console.log ('add part response = ', partResponse);
                        let partResponseJSON
                        if (partResponse.ok) {
                            partResponseJSON = await partResponse.json();
                            debug && console.log ('addPartResponseJSON = ', partResponseJSON);
                            partsArray.push(partResponseJSON.part)
                        } else {
                            partError = true
                            Promise.resolve(getErrorMessageFromResponse(partResponse, "uploading part " + progress + ' of ' + session.total_parts))
                                .then(message => {
                                    enqueueSnackbar(message, {variant: 'error'});
                                })
                        }

                        if (partError) {
                            break
                        }
                    }

                    if (partError) {
                        return null
                    } else {

                        debug && console.log('==========all chunks done for ' + file.name, "now commit then return file id...");

                        debug && console.log('partsArray=', partsArray);

                        const commitArrayBuffer = await window.crypto.subtle.digest("SHA-1", bodyFull);
                        const commitDigest = 'sha=' + buf2Base64(commitArrayBuffer);

                        debug && console.log ('digest: ', commitDigest)
                        const body = {
                            parts: partsArray,
                            metadata: metadataArray
                        }

                        const commitURL = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + '/chunked/upload_sessions/' + session.id ;

                        const commitRequest = {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + this.props.userDetails.accessToken,
                                "Digest": commitDigest
                            },
                            body: JSON.stringify(body)
                        };

                        debug && console.log('Commit session url = ', commitURL, 'BODY=', body, 'request=', commitRequest);

                        const commitResponse = await (fetch_retry(commitURL, commitRequest, 3));
                        debug && console.log('Commit session response = ', commitResponse);

                        try {
                            const commitResponseJSON = await commitResponse.json();
                            debug && console.log('commitResponseJSON = ', commitResponseJSON);
                            return commitResponseJSON
                        } catch (err) {
                            if (commitResponse) {
                                return commitResponse
                            } else {
                                //e.g. out of memory error
                                const errorMessage = err
                                enqueueSnackbar(errorMessage, {variant: 'error'});
                                return {message: err}
                            }

                        }
                    }
                }

            } else {

                //Upload via standard upload api (no chunking)

                //call readFileAsync to get content');
                let content = await readFileAsyncBase64(file);

                let body = {
                    content: content,
                    fileName: file.name,
                    metadata: metadataArray,
                    subFolders: subFolders
                };

                if (this.props.uploadConfig.watson) {
                    body.discoveryRequest = {
                        apiKey: window.REACT_APP_WATSON_APIKEY,
                        instanceId: window.REACT_APP_WATSON_INSTANCEID,
                        collectionId: window.REACT_APP_WATSON_COLLECTIONID,
                        environmentId: window.REACT_APP_WATSON_ENVIRONMENTID,
                    }
                }

                debug && console.log ("body: " , body);

                const request = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + this.props.userDetails.accessToken
                    },
                    body: JSON.stringify(body)
                };

                debug && console.log ('Upload url= ', url, 'BODY=', body, 'request=', request);

                //upload to box
                await this.props.triggerRefreshAuthToken();
                let response = await (fetch(url,request));

                try {
                    if (response.ok) {
                        let data = await response.json();
                        debug && console.log ('response.json: ', data);
                        return data
                    } else {
                        //get error message and return that??
                        let errorDetails = {error: "placeholder"};
                        await getErrorMessageFromResponseAsync(response, '')
                            .then(message => {
                                console.log ('message = ', message)
                                errorDetails.message = message;
                            })
                        return (errorDetails)
                    }

                } catch(err) {
                    console.log ('exception', err)
                    enqueueSnackbar("Error " + response.status + " uploading file " + response.statusText, {variant: 'error'});
                    return {message: err}
                }
            }

        } catch(err) {
            console.log("processFile error: " , err);
            return {message: err};
        }
    }

    doUploadAsync = () => {

        //wrap execution of asyncforEach in an async so that we can wait for all to be completed before showing confirmation
        const start = async () => {

            const debug = window.location.pathname.toLowerCase().includes("debug");


            let progressCounter = 0;
            let errorCounter = 0
            let updatedFiles = this.state.files;

            await this.props.triggerRefreshAuthToken();
            await asyncForEach(
                updatedFiles,
                async (file) => {

                    const fileSizeStr = getReadableFileSizeString(file.size);

                    progressCounter = progressCounter + 1;
                    this.setState({uploadProgress: progressCounter});
                    file.statusMessage = "Uploading " + fileSizeStr  ;
                    file.statusIconClass = "greenAvatar";
                    this.setState({files: updatedFiles});

                    await this.processFile(file)
                        .then(result => {
                            debug && console.log ('processFile result = ', result);
                            if (result) {
                                if (result.id) {
                                    file.statusIconClass = "greenAvatar";
                                    if (result.sequence_id && result.sequence_id !== "" && parseInt(result.sequence_id) > 0 ) {
                                        file.statusIcon = "done_all";
                                        file.statusMessage = "Successfully uploaded " + fileSizeStr + ") as NEW VERSION of file, ID=" + result.id;
                                    } else {
                                        file.statusIcon = "done";
                                        file.statusMessage = "Successfully uploaded " + fileSizeStr + " file, ID = " + result.id;
                                    }

                                    return result.id
                                } else {
                                    file.statusIcon = "error";
                                    file.statusIconClass = "redAvatar";
                                    errorCounter = errorCounter + 1;
                                    this.setState({errorCounter: errorCounter});

                                    Promise.resolve(getErrorMessageFromResponse(result, "uploading " + getReadableFileSizeString(file.size) + " file"))
                                        .then(message => {
                                            file.statusMessage = message
                                            enqueueSnackbar(message , {variant: 'error'});
                                            return "0";
                                    })
                                }
                            } else {
                                debug && console.log ("processFile result is null");
                                file.statusIcon = "error";
                                file.statusIconClass = "redAvatar";
                                file.statusMessage = "Error uploading " + fileSizeStr + " file" ;
                                errorCounter = errorCounter + 1;
                                this.setState({errorCounter: errorCounter});
                                return "0";
                            }
                        })
                        .then(id => {
                            file.id = id;
                        });

                    this.setState({files: updatedFiles});

                });

            // All Done, update state with updatedfiles
            this.setState({isUploadComplete: true});
            this.setState({response: {success: true}});

        };

                // this.handleNext();
                // start();

        //start upload
        //Validate first

        if (this.validateDocumentSpecificFields()) {
            //show confirmation step showing progress
            this.handleNext();
            start();
         }


    };

    updateFileList(files) {

        //Add rowID to prep data for adding to metadata table

        counterMetadataTable = 0;
        let filesWithRowID = [];

        files.forEach((item, i) => {
            filesWithRowID.push(addRowIDToFile(item));
        });

        this.setState({
            files: filesWithRowID
        });

    }

    handleClose = () => {
        const END_STATE = {
            //dialog/stepper
            open: false,
            activeStep: 0,
            // skipped: new Set(),

            genericMetadata: {},
            files: [],

            //service
            response: {
                success: true,
                message: ""
            },

            uploadProgress: 0,
            isUploadComplete: false

        };
        this.setState(END_STATE);

        this.props.closeDialog();
    };

    handleCancel = () => {
        const END_STATE = {
            //dialog/stepper
            open: false,
            activeStep: 0,
            // skipped: new Set(),

            genericMetadata: {},
            files: [],

            //service
            response: {
                success: true,
                message: ""
            },

            uploadProgress: 0,
            isUploadComplete: false

        };
        this.setState(END_STATE);

        this.props.cancelDialog();
    };

    handleOnChangeGenericMetadata = (id, newValue) => {

        const genericMetadata = this.state.genericMetadata;

        let val = newValue;
        if (val && typeof val === 'object') {
            let dateVal = new Date(val)
            dateVal.setUTCHours(0,0,0,0);
            val = dateVal;
        }

        genericMetadata[id] = val;

        this.setState({genericMetadata: genericMetadata});

    };

    handleOnChangeDocumentMetadata = (id, newValue) => {

        const debug = window.location.pathname.toLowerCase().includes("debug")

        //input fields are named the same as the state properties so use this generic function to update the state properties
        //fields suffixed with _index

        let whichFile = Number(id.substring(id.indexOf("_")+1))-1;
        let stateProp = id.substring(0 , id.indexOf("_"));

        debug && console.log ('stateProp = ', stateProp);

        const files1 = this.state.files;

        let val = newValue;
        if (val && typeof val === 'object') {
            let dateVal = new Date(val)
            dateVal.setUTCHours(0,0,0,0);
            val = dateVal;
        }

        files1[whichFile].metadata[stateProp] = val;

        this.setState({files: files1});

    };

    handleNext = () => {

        const { activeStep } = this.state;

        console.log ('*** activeStep =' , activeStep)

        let maxWidth = "lg"


        //Validate if generic fields have been input
        if (activeStep === 0) {
            if (this.validateGenericFields()) {
                this.setState({
                    maxWidth: maxWidth,
                    activeStep: activeStep + 1,
                });
            }
        } else if (activeStep === 1) {
            this.setState({
                maxWidth: 'xl', //file table step wider
                activeStep: activeStep + 1,
            });
        } else {
            this.setState({
                maxWidth: maxWidth, //file table step wider
                activeStep: activeStep + 1,
            });
        }

    };


    render() {

        const { classes} = this.props;
        const { activeStep } = this.state;

        let steps = [], stepComponents = [];

        steps.push("Enter generic metadata");
        stepComponents.push(
            <GenericMetadata
                classes={this.props.classes}
                updateFileList={this.updateFileList}
                files={this.state.files}
                fixedMetadata={this.props.uploadConfig.fixedMetadata}
                genericMetadata={this.state.genericMetadata}
                fields={this.props.uploadConfig.documentSpecificFields}
                //selectFolderField={this.props.uploadConfig.selectFolderField}
                handleOnChangeDocumentMetadata={this.handleOnChangeDocumentMetadata}
                uploadProgress={this.state.uploadProgress}
                metadataConfig={this.props.metadataConfig}
                optionsConfig={this.props.optionsConfig}
                uploadConfig={this.props.uploadConfig}
                handleOnChangeGenericMetadata={this.handleOnChangeGenericMetadata}
                maxWidth={this.state.maxWidth}
                userDetails={this.props.userDetails}
                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
            />
        );

        steps.push("Select files to upload");
        stepComponents.push(
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <FileUploadDropzone
                        parentClasses={this.props.classes}
                        triggerParentUpdateFileList={this.updateFileList}
                        actionConfig={this.props.actionConfig}
                    />
                </Grid>
            </Grid>
        );

        steps.push("Confirm SPV");
        stepComponents.push(
            <FileTable
                classes={this.props.classes}
                updateFileList={this.updateFileList}
                files={this.state.files}
                fixedMetadata={this.props.uploadConfig.fixedMetadata}
                genericMetadata={this.state.genericMetadata}
                fields={this.props.uploadConfig.documentSpecificFields}
                //selectFolderField={this.props.uploadConfig.selectFolderField}
                handleOnChangeDocumentMetadata={this.handleOnChangeDocumentMetadata}
                uploadProgress={this.state.uploadProgress}
                metadataConfig={this.props.metadataConfig}
                optionsConfig={this.props.optionsConfig}
                handleOnChangeGenericMetadata={this.handleOnChangeGenericMetadata}
                maxWidth={this.state.maxWidth}
                userDetails={this.props.userDetails}
                triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                addFolderConfig={this.props.actionConfig.addFolderConfig}
                folders={this.state.folders}
                updateFolders={this.updateFolders}
                searchConfig={this.props.actionConfig.folderSearch}
            />
        );

        if(this.state.response.success) {
            stepComponents.push(
                <ConfirmationMessage classes={classes} files={this.state.files} uploadProgress={this.state.uploadProgress}
                                     isUploadComplete={this.state.isUploadComplete} actionConfig={this.props.actionConfig}/>)
        } else {
            stepComponents.push(
                <Typography>Error message here</Typography>
            );
        }

        let dialogTitle = "Bulk upload";
        const files = this.state.files;

        let dialogContentText = ""
        if (files && Array.isArray(files) && files.length > 0) {
            if (files.length === 1){
                dialogContentText = dialogContentText + " (" + files.length + " file)"
            } else {
                dialogContentText = dialogContentText + " (" + files.length + " files)"
            }
            if ( (this.state.uploadProgress > 0 && !this.state.isUploadComplete)) {
                dialogContentText = dialogContentText + '.  Uploading ' + this.state.uploadProgress + ' of ' + files.length + '.'
            }
            if ( this.state.isUploadComplete) {

                console.log ('*** errorCounter = ', this.state.errorCounter)
                console.log ('*** isUploadComplete = ', this.state.isUploadComplete)

                if (this.state.errorCounter === 0 ) {
                    dialogContentText = dialogContentText + '.  Upload complete.'
                } else if (this.state.errorCounter === 1)  {
                    dialogContentText = dialogContentText + '.  Upload complete with ' + this.state.errorCounter + ' unsuccessful, see error below.'
                } else if (this.state.errorCounter > 1) {
                    dialogContentText = dialogContentText + '.  Upload complete with ' + this.state.errorCounter + ' unsuccessful, see errors below.'
                }


            }

        }
        let genericVals = [];
        Object.entries(this.state.genericMetadata).forEach(entry => {
            genericVals.push(entry[1])
        })
        const genericValsStr = genericVals && genericVals.length > 0 ? genericVals.join(" > ") : ""

        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth={this.state.maxWidth}
                height={"90vh"}
                disableEscapeKeyDown
                //to prevent caching of values
                keepMounted={false}>

                <DialogTitle id="form-dialog-title">
                    <React.Fragment>
                        {dialogTitle}
                        {
                            activeStep > 0 &&
                            <DialogContentText sx={(theme)=>({paddingBottom: theme.spacing(2)})}>{activeStep > 0 && genericValsStr + dialogContentText }</DialogContentText>
                        }
                    </React.Fragment>

                </DialogTitle>


                <DialogContent>


                    <div style={{width: '100%', display: 'block'}}>

                        <Stepper
                            activeStep={activeStep}
                            orientation={"horizontal"}>
                            {steps.map((label, index) => {
                                const props = {};
                                const labelProps = {};

                                return (
                                    <Step key={label} {...props}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>

                        {stepComponents[activeStep] || <div>Error: Step not configured...</div>}
                    </div>
                </DialogContent>

                <DialogActions>
                    <React.Fragment>
                        {
                            activeStep === steps.length ?
                                <Button onClick={this.handleClose}
                                        variant="contained"
                                        disabled = {!this.state.isUploadComplete}
                                        color="secondary">
                                    {this.state.isUploadComplete ? "Close" : "Uploading..."}
                                </Button> :
                                <React.Fragment>
                                    <Button onClick={this.handleCancel} variant="contained" color="grey">Cancel</Button>&nbsp; &nbsp;
                                    &nbsp;&nbsp;
                                    <Button variant="contained" color="secondary"
                                            disabled = {activeStep === 1 && this.state.files.length === 0} //disable Next/Submit button if no files selected
                                            onClick={activeStep === steps.length - 1 ? this.doUploadAsync : this.handleNext}>
                                        {(activeStep === steps.length - 1 )? 'Submit' : 'Next'}
                                    </Button>
                                </React.Fragment>
                        }
                    </React.Fragment>
                </DialogActions>
            </Dialog>
        );
    }
}

BulkUploadDialog.propTypes = {
    classes: PropTypes.object,
    userDetails: PropTypes.object.isRequired,
    uploadConfig: PropTypes.object.isRequired,
    metadataConfig: PropTypes.object.isRequired,
    optionsConfig: PropTypes.object.isRequired,
    cancelDialog: PropTypes.func.isRequired,
    closeDialog: PropTypes.func.isRequired,
    folderDetails: PropTypes.object,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired
};


export default (withStyles(styles, { withTheme: true })(BulkUploadDialog));
