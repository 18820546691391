import React, { useState, useEffect} from 'react';
import './App.css';
import PortalApp from "./PortalApp";
import {SnackbarProvider} from 'notistack';
import 'typeface-roboto';
import 'typeface-montserrat';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import PropTypes from "prop-types";
import {asyncForEach, chunkArray, getErrorMessageFromResponse} from "../common/helper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import {getTheme, prepareTheme} from "./helper";
import { Auth } from 'aws-amplify';
import CustomSnackbar from "./CustomSnackbar";

const HomeCognito = props => {

    const debug = window.location.pathname.toLowerCase().includes("debug");

    const accessToken = props.user.signInUserSession.idToken.jwtToken;

    const theme = getTheme()
    const portalThemeOptions = prepareTheme(theme);
    const portalTheme = createTheme((portalThemeOptions));
    const drawerWidth = theme.drawerWidth;

    const [workspaceInfo, setWorkspaceInfo] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [errorDetails, setErrorDetails] = useState(null);
    const [initDone, setInitDone] = useState(false);
    const [configFiles, setConfigFiles] = useState({
        actionsConfig: {},
        dashboardsConfig: [],
        metadataConfig: {},
        optionsConfig: {},
        publicLinksConfig: [],
        templatesConfig: {},
        workflowConfig: {folderActions:[],documentActions:[]},
        workspaces: [],
        generalConfig:{}
    })

    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [snackbarSeverity, setSnackbarSeverity] = useState("")

    function showSnackbar(message, messageSeverity)  {
        setSnackbarMessage(message)
        setSnackbarSeverity(messageSeverity)
        setOpenSnackbar(true)
    }

    function hideSnackbar()  {
        setSnackbarMessage("")
        setOpenSnackbar(false)
    }

    window.localStorage.setItem('windowUrl', window.location);

    useEffect(() => {

        window.localStorage.setItem('theme',"");

        async function init() {

            debug && console.log('init user = ', props.user)

            //get config then set user info
            let config = await getConfigFiles();
            debug && console.log('init, got config files config=', config)

            const allRoles = config && config.generalConfig && config.generalConfig.allRoles;
            let userRoles = []
            let rolesError = ""

            if (allRoles) {
                const userRolesResponse = await getUserRoles(allRoles)
                userRoles = userRolesResponse.roles
                if (userRolesResponse.error) {
                    rolesError = userRolesResponse.error
                }
            }

            debug && console.log('init, userRoles=', userRoles)

            let workspaces = [];

            if (rolesError) {
                setErrorDetails(rolesError)
            } else {
                if (userRoles.length === 0) {
                    setErrorDetails("No roles assigned");
                } else {
                    //Loop through the Workspaces in config file, if the user has any of the accessroles listed for that workspace then add the Workspace to the list
                    config.workspaces.forEach(ws => {
                        for (let i = 0; i < ws.accessRoles.length; i++) {
                            if (userRoles.includes(ws.accessRoles[i])) {
                                workspaces.push(ws);
                                break;
                            }
                        }
                    })
                    if (workspaces.length === 0) {
                        setErrorDetails("You are not configured to use this application");
                    } else {
                        setWorkspaceInfo(workspaces);
                        setUserDetails({
                            isUserLoggedIn: true,
                            userLoggedOut: false,
                            userName: props.user.username,
                            userEmail: props.user.username,
                            boxId: "",
                            userRoles: userRoles,
                            accessToken: accessToken,
                            boxAccessToken: "",
                            caseAccessToken: "",
                            caseUsername: props.user.username,
                            otherInfo: "...."
                        })
                    }
                }
            }
        }

        async function getUserRoles(allRoles) {

            //Loop through each role listed in configGeneral.json
            //return list of current user's roles

            let userRoles = [];
            const chunkedRoles = chunkArray(allRoles, 3) //need to process in groups of 3
            let errorGettingUserRoles = ""

            await asyncForEach(chunkedRoles, async (chunk) => {

                //sample url "https://aoidpr.lm-tst101.com/api/v1/role/portal.Parties?role2=portal.IPOS&role3=portal.ICOS"

                //add first role as path variable

                let url = window.REACT_APP_COGNITO_ROLES_ENDPOINT + encodeURIComponent(chunk[0]).replace(/\./g, '%2E');

                //add any additional roles as query parameters
                if (chunk.length > 1) {
                    let queryParams = []
                    for (let i = 1; i < chunk.length; i++) {
                        queryParams.push('role' + (i+1) + '=' + encodeURIComponent(chunk[i]).replace(/\./g, '%2E'))
                    }
                    url = url + '?'+ queryParams.join('&')
                }

                const request = {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + accessToken
                    }
                };

                debug && console.log ('checkRoles url=', url)

                //await mockFetch (url) // - temp solution until endpoint confirmed
                await fetch(url , request )
                    .then(response => {
                        debug && console.log('checkRoles response for chunk ', chunk , '=', response);
                        if (response.ok) {
                            return(response.json())
                        } else {
                            Promise.resolve(getErrorMessageFromResponse(response, 'checking roles'))
                                .then(message => {
                                    errorGettingUserRoles = message;
                                })
                            return null
                        }
                    })
                    .then(result => {
                        debug && console.log ('check roles response.json = ' , result);
                        //loop through chunk array and check if true returned for that role
                        if (result ){
                            chunk.forEach(role => {
                                if (result[role]  && result[role] === true) {
                                    userRoles.push(role)
                                }
                            })
                        }
                    })
            })

            //return list of user's roles
            return {roles: userRoles, error: errorGettingUserRoles};
        }

        init().then (r=>{
            setInitDone(true)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // mockFetch to simulate roles when roles endpoint not available
    // async function mockFetch(url) {
    //
    //     let roles = []
    //     const theUrl = new URL(url)
    //     const path = theUrl.pathname;
    //
    //     //get role1 from path
    //     const role1 = path.substring(path.lastIndexOf('/') + 1)
    //     roles.push (role1)
    //     //check for roles 2 & 3 in query params
    //     const queryParams = new URLSearchParams(theUrl.search)
    //     queryParams.has("role2") && roles.push(queryParams.get("role2"))
    //     queryParams.has("role3") && roles.push(queryParams.get("role3"))
    //
    //     let response = {};
    //     roles.forEach(role=>{
    //         response[role] = true
    //     })
    //     debug && console.log ('mockFetch response=', response)
    //     return {
    //         ok: true,
    //         status: 200,
    //         json: async () => (response),
    //     }
    // }

    async function getConfigFiles() {

        let config = {
            actionsConfig: {},
            dashboardsConfig: [],
            metadataConfig: {},
            optionsConfig: {},
            publicLinksConfig: [],
            templatesConfig: {},
            workflowConfig: {folderActions:[],documentActions:[]},
            workspaces: [],
        };

        // application config - either retrieve from api or pull from local config
        if (window.REACT_APP_CONFIG_API_REQUIRED === "true" || window.REACT_APP_CONFIG_API_REQUIRED === true) {
            const url = window.REACT_APP_CONFIG_API_BASE_URL;
            const request = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + accessToken
                }
            };
            debug && console.log('get config files from service..url: ', url, 'request:', request);

            fetch(url, request)
                .then(response => response.json())
                .then(data => {
                    debug && console.log('getConfigFiles - response.json', data);

                    config = {
                        workspaces: JSON.parse(data.workspace),
                        actionsConfig: JSON.parse(data.action),
                        metadataConfig: JSON.parse(data.metadata),
                        optionsConfig: JSON.parse(data.metadataoption),
                        publicLinksConfig: JSON.parse(data.link),
                        dashboardsConfig: JSON.parse(data.link),
                    };

                })
                .catch(e => {
                    console.log(e);
                    setErrorDetails("Config Retrieval Error" + e);
                })
        } else {

            debug && console.log ('get config from local config')

            const configFilePaths = {
                workspaces: 'config/configWorkspaces.json',
                actionsConfig: 'config/configActions.json',
                metadataConfig: 'config/configMetadata.json',
                optionsConfig: 'config/configMetadataOptions.json',
                publicLinksConfig: 'config/configPublicLinks.json',
                templatesConfig: 'config/configTemplates.json',
                workflowConfig: 'config/configWorkflow.json',
                dashboardsConfig: 'config/configDashboards.json',
                generalConfig: 'config/configGeneral.json'
            };

            const configPrefix =
                window.location.href.indexOf("file") > -1 || window.location.href.indexOf("folder") > -1 || window.location.href.indexOf("upload") > -1 ? "../../" :
                    window.location.href.indexOf("dashboard") > -1 ? "../" :
                        "";

            debug && console.log ('configPrefix=', configPrefix);

            await asyncForEach(Object.entries(configFilePaths), async (entry) => {

                let url = configPrefix + entry[1];

                await fetch(url)
                    .then(response => {
                        return(response.json())
                    })
                    .then(data => {
                        config[entry[0]] =  data;
                    })
                    .catch(e => {
                        debug && console.log("getConfigFiles exception:" , e, " entry: ", entry);
                    })
            })
        }
        debug && console.log('getConfigFiles done, config =  ', config);
        setConfigFiles(config);
        return config
    }

    const refreshAuthToken = async () => {

        // https://docs.amplify.aws/lib/auth/manage-session/q/platform/js/#retrieve-a-user-session
        // Note: The Auth.currentSession() method will automatically refresh the accessToken and
        // idToken if tokens are expired and a valid refreshToken presented. So
        // you can use this method to refresh the session if needed.

        try {
            const previousAccessToken = props.user.signInUserSession.idToken.jwtToken;
            let response;
            try {
                response = await Auth.currentSession();
            } catch (err) {
                //show session expiry message and redirect to log in screen
                console.log('refreshAuthToken - Error refreshing token - ', err, ' - Redirect to login screen');
                showSnackbar("Session expired, please log in again", "error")
                await new Promise(resolve => setTimeout(() => {props.logout()}, "1500"));
            }

            if (response) {
                //if the access token has changed then update it in the userDetails
                const newAccessToken = response.idToken.jwtToken;
                if (newAccessToken !== previousAccessToken) {
                    debug && console.log('refreshAuthToken - access token has changed so update userDetails with latest token')
                    setUserDetails({
                        ...userDetails,
                        accessToken: newAccessToken
                    })
                } else {
                    debug && console.log('refreshAuthToken - no change to accessToken')
                }
        }
        } catch (err) {
            console.log("refreshAuthToken exception - Error refreshing token:  " , err)
        }
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={portalTheme}>
                <div>
                    {/*enqueueSnackbar not available here so use custom snackbar*/}
                    <CustomSnackbar message={snackbarMessage} open={openSnackbar} severity={snackbarSeverity} handleClose={hideSnackbar}/>
                    <SnackbarProvider maxSnack={1}>
                        {
                            errorDetails ?
                                <div style={{textAlign: "center", borderColor: '#ddd #ddd #d8d8d8', boxShadow: 'box-shadow: 0 2px 0 hsla(0,0%,68.6%,.12', width: "50%", height: "50%", overflow: 'visible', margin: 'auto', position: 'absolute', top: '0', left: '0', bottom: '0', right: '0'}}>
                                    <img src={window.location.origin + '/images/authlogo.png'} alt="logo"
                                         style={{display: "block", margin: "0 auto", maxWidth: "200px", maxHeight: "40px"}}/><br/><br/>
                                    <Typography variant="h6">{window.REACT_APP_APP_TITLE}</Typography><br/><br/>
                                    <Typography variant="subtitle1">An error has occurred, please retry.</Typography><br/><br/>
                                    <Typography variant="subtitle2">{errorDetails}</Typography><br/><br/>
                                    <Button variant="contained" color="secondary" onClick={props.logout} style={{width: "200px", marginTop: "20px"}}>Return to login</Button>
                                </div>
                            :
                            initDone && userDetails && workspaceInfo ?
                                <PortalApp
                                    {...props}
                                    userDetails={userDetails}
                                    logout={props.logout}
                                    triggerRefreshAuthToken = {refreshAuthToken}
                                    configFiles={configFiles}
                                    workspaceConfig={workspaceInfo}
                                    launchDashboard={props.launchDashboard}
                                    drawerWidth={drawerWidth}
                                /> :
                                <React.Fragment>
                                    <LinearProgress style={{backgroundColor: '#808080'}}/>
                                    <div style={{color: '#808080', fontSize: '0.75rem', fontWeight: '500', lineHeight: '24px', paddingLeft: '6px'}}>
                                        {"Loading..."}
                                    </div>
                                </React.Fragment>
                        }
                    </SnackbarProvider>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

HomeCognito.propTypes = {
    user: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    launchDashboard: PropTypes.bool.isRequired
};
export default HomeCognito;