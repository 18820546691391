import React from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { enqueueSnackbar } from 'notistack'

const styles = theme => ({})

function RefreshDashboard(props) {
    return (
        <Tooltip title={"Reload Dashboard"}>
            <IconButton
                color={"inherit"}
                aria-label={"Reload Dashboard"}
                aria-haspopup="true"
                onClick={props.unmountDashboard}
                size="medium">
                {
                    <i className='material-icons'>refresh</i>
                }
            </IconButton>
        </Tooltip>
    );
}

RefreshDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    unmountDashboard: PropTypes.func.isRequired
};

export default (withStyles(styles, { withTheme: true })(RefreshDashboard));