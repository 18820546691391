import React from "react";
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Divider from "@mui/material/Divider/Divider";
import ListSubheader from "@mui/material/ListSubheader/ListSubheader";

import {hasAccess} from "../common/helper"

import BulkUploadButton from "../document/addDocument/bulkUpload/BulkUploadButton";
import AddFolderStructureButton from "../folder/add/AddFolderStructureButton";
import CustomMenu from "../custom/CustomMenu";

const styles = (theme) => ({
    listIcon: {
        color: theme.menuListItem.colour
    },
    menuListItem: {
        color: theme.menuListItem.colour
    },
});


function MainMenuActions(props) {

    const {classes, actionsConfig, userDetails, ...other} = props;
    const userRoles = userDetails.userRoles;
    const createSanctionsCaseActionConfig = actionsConfig && actionsConfig.createSanctionsCase;
    const showSanctionsCreateCase = createSanctionsCaseActionConfig && createSanctionsCaseActionConfig.enabled && hasAccess(createSanctionsCaseActionConfig, userRoles);
    const showBulkUpload = actionsConfig.bulkUpload && actionsConfig.bulkUpload.enabled && hasAccess(actionsConfig.bulkUpload, userRoles)

    const addFolderStructureEnabled = actionsConfig.addFolderStructure && actionsConfig.addFolderStructure.enabled;
    const folderStructures = []

    if (addFolderStructureEnabled) {
        if (actionsConfig.addFolderStructure.folderStructures && Array.isArray(actionsConfig.addFolderStructure.folderStructures)){
            //Build array of 'Add Folder Structure' configs that the user has access to
            actionsConfig.addFolderStructure.folderStructures.forEach(f => {
                if (f.enabled && hasAccess(f, userRoles)) {
                    folderStructures.push(f);
                }
            })
        }
    }

    const anyActionsEnabled = showBulkUpload || showSanctionsCreateCase || (addFolderStructureEnabled && folderStructures.length > 0) ;

        return (
            <React.Fragment>
                {
                    anyActionsEnabled &&
                        <React.Fragment>

                            <ListSubheader disableSticky color={'primary'}>Actions</ListSubheader>

                            {/*Sanctions Button*/}
                            {
                                showSanctionsCreateCase &&
                                <CustomMenu
                                    parentClasses={classes}
                                    actionsConfig={actionsConfig}
                                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                    handleShowAdminGeneral={props.handleShowAdminGeneral}
                                    userDetails={userDetails}
                                />
                            }

                            {/*Bulk upload button*/}
                            {
                                showBulkUpload &&
                                <BulkUploadButton
                                    {...other}
                                    parentClasses={classes}
                                    userDetails={userDetails}
                                    uploadConfig={actionsConfig.bulkUpload}
                                    metadataConfig={props.metadataConfig}
                                    optionsConfig={props.optionsConfig}
                                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                    actionConfig={actionsConfig.bulkUpload}
                                />
                            }

                            {/*Add folder structure button(s) depending on what's enabled / user has access to*/}
                            {
                                addFolderStructureEnabled && folderStructures.map(f => {
                                    return (
                                        <AddFolderStructureButton
                                            {...other}
                                            parentClasses={classes}
                                            userDetails={userDetails}
                                            metadataConfig={props.metadataConfig}
                                            optionsConfig={props.optionsConfig}
                                            triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                            addFolderConfig={f}
                                        />
                                    )})
                            }


                            <Divider/>
                        </React.Fragment>
                }
            </React.Fragment>
        );
}


MainMenuActions.propTypes = {
    classes: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    handleShowAdminGeneral: PropTypes.func.isRequired,
    actionsConfig: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MainMenuActions);