import React, {useState} from 'react'
import {DropzoneArea} from "react-mui-dropzone";
import PropTypes from 'prop-types'
import {withStyles} from "@mui/styles";
import { AttachFile, Description, PictureAsPdf, Theaters } from '@mui/icons-material';
import {formatBytes} from "../../common/helper";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem"
import {ListItemIcon} from "@mui/material";

const styles = theme => ({
    dropzoneParagraph: {
        fontFamily: "roboto",
        fontSize: '16px',
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    infoIcon: {
        color: theme.palette.secondary.main
    }
});

function FileUploadDropzone(props) {

    const debug = window.location.pathname.toLowerCase().includes("debug");

    const [files, setFiles] = useState([]);
    const [showFileSizeTips, setShowFileSizeTips] = useState(false)
    const {classes, actionConfig} = props;
    const singleFile = !!(actionConfig && actionConfig.filesLimit && actionConfig.filesLimit === 1);

    const handlePreviewIcon = (fileObject, classes) => {
        const {type} = fileObject.file
        const iconProps = {
            className : classes.image,
        }

        if (type.startsWith("video/")) return <Theaters {...iconProps} />

        switch (type) {
            case "application/msword":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return <Description {...iconProps} />
            case "application/pdf":
                return <PictureAsPdf {...iconProps} />
            default:
                return <AttachFile {...iconProps} />
        }
    }

    const handleChange = (files) => {
        setFiles(files)

        //update parent state
        props.triggerParentUpdateFileList(files);
    }

    debug && console.log('FileUploadDropzone props = ', props);

    const maxFileSize = props.actionConfig.maxFileSize;

    const FileSizeTips = () => {
        return (
            <React.Fragment>
                <List>
                    <ListItem style={{paddingTop: '0px', paddingBottom: '0px'}}>
                        <ListItemIcon className={classes.infoIcon}><i className='material-icons'>info</i></ListItemIcon>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <span>{`Please note the maximum file size permitted is ${formatBytes(maxFileSize)}.`}</span><br/>
                                </React.Fragment>}
                            secondary={
                                <React.Fragment>
                                    <span>Scan in black and white, not colour.</span><br/>
                                    <span>Reduce the resolution of the scan.</span><br/>
                                    <span>Use Adobe’s free compression service: </span>
                                    <a href={"https://www.adobe.com/acrobat/online/compress-pdf.html"}
                                       rel="noreferrer"
                                       target={"_blank"}>https://www.adobe.com/acrobat/online/compress-pdf.html</a><br/>
                                </React.Fragment>}
                        />
                    </ListItem>
                </List>
            </React.Fragment>
        )
    }

    //works but not used, using alternative solution displaying message on screen, keeping for future reference
    // const customGetDropRejectMessage = (rejectedFile, acceptedFiles, maxFileSize) => {
    //
    //     // (rejectedFile: File, acceptedFiles: string[], maxFileSize: number) => string;
    //
    //     const debug = window.location.pathname.toLowerCase().includes("debug");
    //     debug && console.log ('customRejectMessage', rejectedFile, acceptedFiles, maxFileSize)
    //
    //     let message = `File ${rejectedFile.name} was rejected.`
    //     if(rejectedFile.size > maxFileSize) {
    //         message = `${message}  File ${rejectedFile.name} was rejected. File is too big. Size limit is ${formatBytes(maxFileSize)}.`
    //         setMessage(message)
    //         setShowMessageDialog(true)
    //     }
    //     return (message)
    // }

    const customOnDropRejected = (files, event) => {
        // (rejectedFile: File, acceptedFiles: string[], maxFileSize: number) => string;
        const debug = window.location.pathname.toLowerCase().includes("debug");
        debug && console.log ('customOnDropRejected', files, event)
        props.actionConfig.showFileReductionTips && setShowFileSizeTips(true)
    }

        return (
            <>
                    <DropzoneArea
                        style={{paddingBottom: "24px"}}
                        dropzoneText={singleFile ? "Drag & drop file or click to browse" : "Drag & drop file(s) or click to browse"}
                        acceptedFiles={props.actionConfig.acceptedFiles}
                        filesLimit={props.actionConfig.filesLimit}
                        maxFileSize={maxFileSize}
                        onChange={handleChange.bind(this)}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        showAlerts={['error']}
                        showFileNames={true}
                        dropzoneParagraphClass={classes.dropzoneParagraph}
                        fileObjects={files}
                        getPreviewIcon={handlePreviewIcon}
                        useChipsForPreview
                        previewGridProps={{container: { spacing: 1, direction: 'row' , paddingTop: '16px'}}}
                        previewChipProps={{classes: { root: classes.previewChip } }}
                        previewText=""
                        //getDropRejectMessage={customGetDropRejectMessage}
                        onDropRejected={customOnDropRejected}
                    />

                    {
                        showFileSizeTips &&
                        <FileSizeTips/>
                    }
            </>
        );
}

FileUploadDropzone.propTypes = {
    classes: PropTypes.object.isRequired,
    triggerParentUpdateFileList: PropTypes.func.isRequired,
    actionConfig: PropTypes.number.isRequired
};

export default withStyles(styles, { withTheme: true })(FileUploadDropzone);
