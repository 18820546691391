import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import FileIcon from "../../common/FileIcon";

function DocumentCardContent(props) {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    const [thumbnail,setThumbnail] = useState();

    useEffect(() => {

        const getThumbnail = async (file)  => {

            debug && console.log('getThumbnail for file id: ', file.id);

            const url = window.REACT_APP_CONTENT_API_BASE_URL + window.REACT_APP_CONTENT_API_DOCUMENT + '/' + file.id + '/thumbnail?width=1024&height=1024';
            const request = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + props.userDetails.accessToken
                }
            };
            await props.triggerRefreshAuthToken();
            fetch(url,request)
                .then(response => {
                    debug && console.log('thumbnail response = ', response)
                    if (response.ok) {
                        return (response.blob())
                    } else {
                        // Promise.resolve(getErrorMessageFromResponse(response, 'retrieving thumbnail'))
                        //     .then(message => {
                        //         enqueueSnackbar(message, {variant: 'error'});
                        //     })
                        debug && console.log("thumbnail error. url:", url, "request: ", request, "response:", response);
                        return null
                    }
                }).then(resultA => {
                    if (resultA) {
                        const thumbnailUrl = URL.createObjectURL(resultA);
                        setThumbnail(thumbnailUrl)
                    }
            })
        }

        if (props.showThumbnail) {
            getThumbnail(props.file)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.file]);

    //debug && console.log ('thumbnail = ', thumbnail)

    return (
        <React.Fragment>
            {
                props.showThumbnail  ?

                    <React.Fragment>
                        {
                            thumbnail ?
                                <CardMedia
                                    style={{objectFit: "contain", paddingTop: '8px'}}
                                    component="img"
                                    height="94"
                                    width="94"
                                    image={thumbnail}
                                    alt={props.file.name}
                                /> :

                                <CardMedia
                                    style={{objectFit:"contain", paddingTop:'8px', display: 'grid', alignItems: "center",justifyContent: 'center'}}
                                    component={'component'}
                                    width="94" height="94"
                                    alt={props.file.name}
                                >
                                    <FileIcon height={'94px'} width={'94px'} fileName={props.file.name}/>
                                </CardMedia>
                        }
                        <CardContent style={{alignItems: "center", justifyContent: 'center'}}>
                            <Typography variant="body2" color="text.secondary" align={'center'}
                                        style={{
                                            overflow: "hidden",
                                            display: "-webkit-box",
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: "vertical"}}
                            >
                                {props.file.documentTitle ? props.file.documentTitle : props.file.name}
                            </Typography>
                        </CardContent>
                    </React.Fragment> :
                    <CardContent
                        style={{
                            height: '200px',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: 'center',
                            backgroundImage: "url('" + props.image + "')",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: 'top right',
                            margin: '0px 0px 0px 0px',
                            paddingRight: '50px'
                        }}>
                        <Typography variant="h6" align="center">
                                {props.file.documentTitle ? props.file.documentTitle : props.file.name}
                        </Typography>
                    </CardContent>
            }

        </React.Fragment>


    )
}

DocumentCardContent.propTypes = {
    file: PropTypes.object.isRequired,
    image: PropTypes.string.isRequired,
    userDetails: PropTypes.object.isRequired,
    showThumbnail: PropTypes.bool.isRequired,
    triggerRefreshAuthToken: PropTypes.bool.isRequired
}

export default DocumentCardContent;