import React, {Component} from 'react';
import IconButton from '@mui/material/IconButton';
import {withStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import ActionDialogCase from "./ActionDialogCase";
import ActionDialogBox from "./ActionDialogBox";

const styles = theme => ({});

const INITIAL_STATE = {
    showDialog: false,
};

class ActionButton extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    };

    handleClickIcon = (event) => {
        this.setState({
            showDialog: true
        });

    };

    handleCloseDialog = () => {

        window.location.pathname.toLowerCase().includes("debug") && console.log ('ActionButton handleCloseDialog');

        this.setState({
            showDialog: false
        });
    }

    actionButtonDisabled = () => {

        const action = this.props.actionConfig;
        let folderDetails = this.props.folderDetails

        let disabled = true;

        //get metadata value on folder
        let metadataValue;
        if (action.enableWhen) {
            if (folderDetails[action.enableWhen.templateKey + "~" + action.enableWhen.metadataKey]) {
                metadataValue = folderDetails[action.enableWhen.templateKey + "~" + action.enableWhen.metadataKey]
            } else {
                //for subfolders need to dig deeper to get metadata
                if (folderDetails.metadata) {
                    if (folderDetails.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID]) {
                        let folderMetadata = folderDetails.metadata["enterprise_" + window.REACT_APP_ENTERPRISE_ID]
                        if (folderMetadata[action.enableWhen.templateKey]) {
                            if (folderMetadata[action.enableWhen.templateKey][action.enableWhen.metadataKey]) {
                                metadataValue = folderMetadata[action.enableWhen.templateKey][action.enableWhen.metadataKey]
                            }
                        }
                    }
                }
            }
        } else {
            //enable if no enableWhen criteria specified
            disabled = false
        }

        if (metadataValue) {
            if (metadataValue.indexOf(",") !== -1) {
                let metadataValueArray = metadataValue.split(",");
                for (let i = 0; i < metadataValueArray.length; i++) {
                    if (this.props.actionConfig.enableWhen.values.includes(metadataValueArray[i])) {
                        disabled = false;
                    }
                }
            } else {
                if (this.props.actionConfig.enableWhen.values.includes(metadataValue)) {
                    disabled = false;
                }
            }
        }

        return disabled;
    }

    render() {

        const { anchorEl } = this.state;
        const isDisabled = this.actionButtonDisabled();

        return (
            <React.Fragment>
                <Tooltip title={this.props.actionConfig.label}>
                    <IconButton
                        aria-label={this.props.actionConfig.label}
                        aria-owns={anchorEl ? 'simple-menu' : undefined}
                        aria-haspopup="true"
                        color ={this.props.actionConfig.iconColour ? this.props.actionConfig.iconColour : "secondary"}
                        onClick={this.handleClickIcon}
                        disabled ={isDisabled}
                        size="medium">
                        <i className='material-icons' style ={{color: isDisabled ? "grey" : this.props.actionConfig.iconColour}}>
                            <i className="material-icons">{this.props.actionConfig.icon}</i>
                        </i>

                    </IconButton>
                </Tooltip>

                {
                    this.state.showDialog &&
                    window.REACT_APP_FOLDER_SOURCE === "elastic" &&
                        <ActionDialogCase
                        folderDetails={this.props.folderDetails}
                        userDetails={this.props.userDetails}
                        triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                        handleCloseDialog={this.handleCloseDialog}
                        actionConfig={this.props.actionConfig}
                        updateFolderDetailsFromWorkflowAction={this.props.updateFolderDetailsFromWorkflowAction}
                        updateFolderDetails = {this.props.updateFolderDetails}
                        />
                 }

                {
                    this.state.showDialog &&
                    window.REACT_APP_FOLDER_SOURCE === "box" &&

                    <ActionDialogBox
                            folderDetails={this.props.folderDetails}
                            userDetails={this.props.userDetails}
                            triggerRefreshAuthToken={this.props.triggerRefreshAuthToken}
                            handleCloseDialog={this.handleCloseDialog}
                            actionConfig={this.props.actionConfig}
                            updateFolderDetailsFromWorkflowAction={this.props.updateFolderDetailsFromWorkflowAction}
                            updateFolderDetails = {this.props.updateFolderDetails}
                        />
                }

            </React.Fragment>
        );
    }
}

ActionButton.propTypes = {
    folderDetails: PropTypes.object.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    actionConfig: PropTypes.object.isRequired,
    updateFolderDetailsFromWorkflowAction: PropTypes.func,
    updateFolderDetails: PropTypes.func
};

export default withStyles(styles)(ActionButton);