import {hasAccess} from "../../common/helper"

export function getWorkflowActionsMulti(workflowConfig, userRoles){

    //return Workflow Actions for Multi-select that are enabled, and that the user has access to
    let actions = [];
    if (workflowConfig) {
        workflowConfig.forEach(action => {
            if (action.enabled) {
                if (hasAccess(action, userRoles)) {
                    actions.push(action)
                }
            }
        })
    }
    return actions;
}



