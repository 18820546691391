import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import ViewFileLinkDialog from "./ViewFileLinkDialog";

function ViewFileLinkButton(props) {

    const [showDialog, setShowDialog] = useState(false)


    const handleClickIcon = () => {
        setShowDialog(true)
    };

    const handleCloseDialog = () => {
       setShowDialog(false)

    };

    return (
        <div>
            <Tooltip title={props.actionConfig.label}>
                <IconButton
                    aria-label="Menu"
                    aria-haspopup="true"
                    color={"secondary"}
                    onClick={handleClickIcon}
                    size="medium">
                    <i className='material-icons'>link</i>
                </IconButton>
            </Tooltip>

            {
                showDialog &&
                <ViewFileLinkDialog
                    boxDocID={props.boxDocID}
                    handleCloseDialog={handleCloseDialog}
                />
            }

        </div>
    );

}

ViewFileLinkButton.propTypes = {
    boxDocID: PropTypes.string.isRequired,
    actionConfig: PropTypes.object.isRequired
};

export default (ViewFileLinkButton);