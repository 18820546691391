import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack'
import SubfolderGroupMembersDisplay from "./SubfolderGroupMembersDisplay";
import DialogTitle from "@mui/material/DialogTitle";
import {hasAccess} from "../../../common/helper";
import AddSubfolderUserButton from "./AddSubfolderUserButton";

function SubfolderGroupMembersDialog (props) {

    const [open, setOpen] = useState(true)
    const [triggerRemountFolderGroupMembersDisplay, setTriggerRemountFolderGroupMembersDisplay] = useState(0)
    
    const handleCloseDialog = () => {
       setOpen(false)
        //Added this to address issue of dialog not re-opening after initial open
        props.handleCloseDialog && props.handleCloseDialog()
    };

    const remountFolderGroupMembersDisplay = () => {
        setTriggerRemountFolderGroupMembersDisplay(triggerRemountFolderGroupMembersDisplay + 1) //used to trigger useEffect on groupMembers component
    }

    //const showSubfolderGroupMembers =  props.subfolderGroupMembersConfig && props.subfolderGroupMembersConfig.show;

    const addFolderUserConfig = props.actionsConfig &&  props.actionsConfig.addSubfolderUser;
    let showAddFolderUser = addFolderUserConfig && addFolderUserConfig.enabled && hasAccess(addFolderUserConfig, props.userDetails.userRoles)
    if (showAddFolderUser) {
        //also check for existence of folderGroupPrefix on folder details to cater for portals where we have more than one case type
        const prefixConfig = addFolderUserConfig.folderGroupPrefix;
        if (prefixConfig && prefixConfig.templateKey && prefixConfig.metadataKey){
            if ( ! props.folderDetails || !props.folderDetails[prefixConfig.templateKey +"~" + prefixConfig.metadataKey]) {
                showAddFolderUser = false
            }
        }
    }

    //TODO calculate folderGroupName from Issuer Name
    const groupNameConfig = props.subfolderGroupMembersConfig.groupName;
    const metadata = props.folderDetails;


    //Calculate the groupName
    let groupNameElements = [];
    let groupName = "";
    groupNameConfig.forEach( g => {
        g.value ?
            groupNameElements.push(g.value):
            g.templateKey && g.metadataKey &&
            g.templateKey === "n/a" && g.metadataKey === "id" ?
                groupNameElements.push(props.folderDetails.id) :
                metadata[g.templateKey + '~' + g.metadataKey ] ?
                    groupNameElements.push(metadata[g.templateKey + '~' + g.metadataKey ]) :
                    enqueueSnackbar('Unable to get metadata value for ' + g.templateKey + '~' + g.metadataKey, {variant: 'error'});
    })

    groupName = groupNameElements.join('.')


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle  style={{paddingBottom: '0px'}}>{props.title + ' Users'}
                    {
                        showAddFolderUser &&
                        <div style={{display: "inline"}}>
                            <AddSubfolderUserButton
                                actionConfig={props.actionsConfig.addSubfolderUser}
                                triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                                userDetails={props.userDetails}
                                folderDetails={props.folderDetails}
                                remountFolderGroupMembersDisplay= {remountFolderGroupMembersDisplay}
                                groupName ={groupName}
                                title={props.title}
                            />
                        </div>
                    }
                </DialogTitle>
                <DialogContent>
                    <SubfolderGroupMembersDisplay
                        handleCloseDialog={handleCloseDialog}
                        actionsConfig={props.actionsConfig}
                        folderLabel={props.folderLabel}
                        classes={props.classes}
                        triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                        userDetails={props.userDetails}
                        groupName={groupName}
                        folderDetails={props.folderDetails}
                        triggerRemount={triggerRemountFolderGroupMembersDisplay}
                        remountComponent={remountFolderGroupMembersDisplay}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDialog}
                            variant="contained"
                            color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

SubfolderGroupMembersDialog.propTypes = {
    classes: PropTypes.object,
    subfolderGroupMembersConfig: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    folderLabel: PropTypes.string.isRequired,
    folderDetails: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired
};


export default (SubfolderGroupMembersDialog);