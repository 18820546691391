import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack'
import {withStyles} from '@mui/styles';
import {getOktaGroupMembers, hasAccess} from "../../common/helper";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl/FormControl";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress"
import EditFolderUserDialog from "./EditFolderUserDialog";
import RemoveFolderAccessDialog from "./RemoveFolderAccessDialog";
import IconButton from "@mui/material/IconButton";
import CancelIcon from '@mui/icons-material/Cancel';

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperNoBorder: {
        boxShadow: 'none',
    },
    folderMetadata: {
        // paddingTop : theme.spacing(2),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});


function FolderGroupMembersDisplay(props) {

    const actionConfig = props.actionsConfig.addFolderUser;
    const [folderGroupName, setFolderGroupName]= useState("")
    const [folderGroupMembers, setFolderGroupMembers] = useState([]);
    const [fetching, setFetching] = useState(false)
    const [showEditUserDialog, setShowEditUserDialog] = useState(false);
    const [showRemoveUserAccessDialog, setShowRemoveUserAccessDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState({})

    function closeEditUserDialog() {
        setShowEditUserDialog(false)
        setSelectedUser({})
    }

    function closeRemoveUserAccessDialog(groupsUpdated) {
        setShowRemoveUserAccessDialog(false)
        setSelectedUser({})
        //refresh list of folder users
        if (groupsUpdated) {
            props.remountComponent()
        }
    }

    useEffect(() => {

        console.log ('FolderGroupMembersDisplay useEffect' + props.triggerRemount)
        //useEffect should trigger if props.triggerRemount is incremented

        const debug = window.location.pathname.toLowerCase().includes("debug");
        debug && console.log ('FolderGroupMembersDisplay useEffect = ', props);

        //Calculate the folder group name (prefix + folder id)
        let prefix = "";
        //get prefix from folder metadata
        if (actionConfig && actionConfig.folderGroupPrefix && actionConfig.folderGroupPrefix.templateKey && actionConfig.folderGroupPrefix.metadataKey){
            if (props.folderDetails && props.folderDetails[actionConfig.folderGroupPrefix.templateKey +"~" + actionConfig.folderGroupPrefix.metadataKey]) {
                prefix = props.folderDetails[actionConfig.folderGroupPrefix.templateKey +"~" + actionConfig.folderGroupPrefix.metadataKey]
            }
        }
        const folderGroupName = (prefix + props.folderDetails.id)
        setFolderGroupName(folderGroupName)

        // Get list of existing group members
        // declare the async data fetching function
        const fetchUsers = async () => {
            let currentUsers = [];
            let currentUserEmails = [];
            setFetching(true);
            currentUsers = await getOktaGroupMembers(folderGroupName, props.userDetails, props.triggerRefreshAuthToken)
            debug && console.log ('currentUsers=', currentUsers)
            currentUsers && currentUsers.length > 0 &&
                currentUsers.forEach(user => {
                    user.user && user.user.login && currentUserEmails.push (user.user.login)
                })
            setFetching(false);
            setFolderGroupMembers(currentUserEmails)

        }
        // call the function
       fetchUsers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.triggerRemount])

    const editUser = (user) => {
        setSelectedUser(user)
        setShowEditUserDialog(true)
    };

    const removeUser = (user) => {
        setSelectedUser(user)
        setShowRemoveUserAccessDialog(true)
    };

    const {classes} = props;
    const editFolderUserEnabled = props.actionsConfig.editFolderUser && props.actionsConfig.editFolderUser.enabled && hasAccess(props.actionsConfig.editFolderUser,props.userDetails.userRoles)

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel shrink htmlFor={"folderGroupMembersDisp"} className={classes.customLabel} >
                {actionConfig && actionConfig.groupMembersFieldLabel ? actionConfig.groupMembersFieldLabel : "Users" }
            </InputLabel>
            {
                fetching && folderGroupMembers.length === 0 ?
                    <div id="folderGroupMembersDisp" style={{paddingTop: '20px'}}>
                        <CircularProgress color="secondary" size={15}/>
                    </div>:
                    <div id="folderGroupMembersDisp" style={{paddingTop: '20px'}}>
                        {
                            folderGroupMembers.map(member =>
                                <Chip
                                    icon={<i className='material-icons'
                                             style={{fontSize: '18px', marginLeft: '0px'}}>person</i>}
                                    label={member} size="small" style={{border: 'none'}} variant="outlined"
                                    clickable
                                    onClick={() => editUser(member)}
                                    onDelete={() => removeUser(member)}
                                    disabled = {!editFolderUserEnabled}
                                    deleteIcon={ editFolderUserEnabled ? <IconButton color={"secondary"} size="medium"><CancelIcon/></IconButton> : <span/>}
                                />)
                        }
                        {
                            fetching && <CircularProgress color="secondary" size={15}/> //updating
                        }

                    </div>
            }

            {
                showEditUserDialog && selectedUser &&

                <EditFolderUserDialog
                    actionConfig={props.actionsConfig.editFolderUser}
                    classes={props.classes}
                    handleCloseDialog={closeEditUserDialog}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    userDetails={props.userDetails}
                    user={selectedUser}
                />
            }

            {
                showRemoveUserAccessDialog && selectedUser &&

                <RemoveFolderAccessDialog
                    classes={props.classes}
                    handleCloseDialog={closeRemoveUserAccessDialog}
                    triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                    userDetails={props.userDetails}
                    user={selectedUser}
                    folderLabel={props.folderLabel}
                    folderGroupName={folderGroupName}
                    remountComponent={props.remountComponent}
                    actionConfig={props.actionsConfig.editFolderUser}
                />
            }

        </FormControl>
    );
}

FolderGroupMembersDisplay.propTypes = {
    actionsConfig: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    unmountComponent: PropTypes.func.isRequired,
    remountComponent: PropTypes.func.isRequired,
    triggerRemount: PropTypes.number.isRequired, //used to trigger useEffect
    folderLabel: PropTypes.string.isRequired
};


export default (withStyles(styles, { withTheme: true })(FolderGroupMembersDisplay));